import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomDeleteIcon from '@/components/Common/CustomIcons/CustomDeleteIcon';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductSearchByHidden from '@/components/Common/CustomSearch/Products/ProductByHidden';
import { getLocationByDetail, getProductById, getUnit } from '@/services';
import { hide_product_by_location, hide_product_globally } from '@/services/products/mutations';
import { get_product_active_lots, get_product_list_ids } from '@/services/products/queries';
import { checkProductLocationHidden } from '@/services/products/services';
import { IProductDetails } from '@/services/products/types';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';
import {
  Alert,
  Button,
  Form,
  message,
  Modal,
  PageHeader,
  Select,
  SelectProps,
  Spin,
  Tag
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { ILotWithDetails } from '@/services/purchases/types';

interface FormValues {
  locationId?: number;
  productId: number;
  isHidden: boolean;
}

type ProductWithDefaultUnit = IProductDetails & { defaultUnit?: string };

function ProductHiddenStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();

  const isHidden = Form.useWatch('isHidden', form);
  const locationId = Form.useWatch('locationId', form);

  const [selectedProducts, setSelectedProducts] = useState<ProductWithDefaultUnit[]>([]);
  const [prevSelectedProduct, setPrevSelectedProduct] = useState<number>();
  const [prevSelectedLocation, setPrevSelectedLocation] = useState<number | string>('');

  function handleLocationChange(value: number | string) {
    if (prevSelectedLocation === value) return;
    setPrevSelectedLocation(value);
    setSelectedProducts([]);
  }

  async function onInitialSubmit() {
    await form.validateFields();
    if (selectedProducts.length === 0) {
      return CustomErrorModal({ message: 'Please add at least one product' });
    }

    // If global and user wants to hide a product that has active lots
    const productIds = selectedProducts.map((p) => getInt(p.id));
    if (!locationId && isHidden) {
      const { hasLots } = await checkProductLot(productIds);
      if (hasLots) return;
    }
    setIsVisible(true);
  }

  function getInt(value: number | string) {
    return typeof value === 'number' ? value : parseInt(value);
  }

  async function handleSave() {
    try {
      setIsLoading(true);
      setIsVisible(false);
      const values = await form.validateFields();
      const productIds = selectedProducts.map((p) => getInt(p.id));

      if (values.locationId) {
        await hide_product_by_location({
          hidden: values.isHidden,
          locationId: values.locationId,
          productIds
        });
        message.success(
          `${productIds.length} product(s) successfully ${
            values.isHidden ? 'hidden' : 'unhidden'
          } for the selected location.`
        );
      } else {
        await hide_product_globally({ hidden: values.isHidden, productIds });
        message.success(
          `${productIds.length} product(s) successfully ${
            values.isHidden ? 'hidden' : 'unhidden'
          } globally.`
        );
      }

      const productDetails = await get_product_list_ids(productIds);
      await ProductsDB.addProducts(productDetails.data.results);
      navigate('/products');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  async function checkProductLot(productIds: number[]) {
    const currentActiveLots = (await get_product_active_lots(productIds)) as ILotWithDetails[];

    if (currentActiveLots.length > 0) {
      for (const lot of currentActiveLots) {
        const product = await getProductById(lot.productId);
        const location = await getLocationByDetail(lot.locationId);

        lot.locationName = location.name;
        lot.productName = product.name;
      }

      CustomErrorModal({
        width: 1000,
        message: (
          <div>
            <p>Some of the selected products have active lots so they can't be hidden globally.</p>
            <Table
              columns={[
                { title: 'Product', dataIndex: 'productName', key: 'productName' },
                { title: 'Location', dataIndex: 'locationName', key: 'locationName' },
                { title: 'Lot Number', dataIndex: 'lotNumber', key: 'lotNumber' },
                { title: 'Grade', dataIndex: 'grade', key: 'grade' },
                { title: 'Available (qty.)', dataIndex: 'qtyAvailable', key: 'qtyAvailable' }
              ]}
              dataSource={currentActiveLots}
              pagination={false}
            />
          </div>
        )
      });
    }

    return { hasLots: currentActiveLots.length > 0 };
  }

  async function onProductChange(product?: IProductDetails) {
    try {
      if (!product || prevSelectedProduct === product.id) return;

      setIsLoading(true);
      const productIds = selectedProducts.map((p) => getInt(p.id));
      const isDuplicate = productIds.find((p) => p === product.id);
      setPrevSelectedProduct(parseInt(product.id as string));
      if (isDuplicate) {
        CustomErrorModal({ message: `Product ${product.name} is already added` });
        return;
      }

      const defaultUnit = product.productUnits.find((curr) => curr.isDefault === true);
      if (!defaultUnit) {
        setSelectedProducts((prev) => [...prev, product]);
        return;
      }

      const unit = await getUnit(defaultUnit.unitId);
      setSelectedProducts((prev) => [...prev, { ...product, defaultUnit: unit.name }]);
    } catch (error) {
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  const columns: ColumnsType<ProductWithDefaultUnit> = [
    { title: 'S.N', key: 'SN', width: 10, render: (_, __, index) => index + 1 },
    { title: 'Name', key: 'name', width: 50, dataIndex: 'name' },
    { title: 'Default Unit', dataIndex: 'defaultUnit', key: 'unit', width: 15 },
    { title: 'SKU', key: 'sku', width: 15, dataIndex: 'sku' },
    {
      title: 'Global Status',
      key: 'gStatus',
      width: 15,
      render: (record) => (
        <Tag color={record.hidden ? 'red' : 'green'}>{record.hidden ? 'Inactive' : 'Active'}</Tag>
      )
    },
    {
      title: '',
      key: 'delete',
      width: 15,
      render: (record) => {
        function onDelete() {
          const updatedProducts = selectedProducts.filter((p) => getInt(p.id) !== record.id);
          setSelectedProducts(updatedProducts);
        }

        return <CustomDeleteIcon onClick={onDelete} />;
      }
    }
  ];

  if (locationId) {
    columns.splice(columns.length - 1, 0, {
      title: 'Location Status',
      key: 'lStatus',
      width: 15,
      render: (record) => {
        const isHidden = checkProductLocationHidden(record, locationId);
        return <Tag color={isHidden ? 'red' : 'green'}>{isHidden ? 'Inactive' : 'Active'}</Tag>;
      }
    });
  }

  const onHiddenChange: SelectProps['onChange'] = (isHiddenFilterActive) => {
    setSelectedProducts([]);
    if (isHiddenFilterActive) {
      message.info(
        'Hidden products are now filtered out. You can select unhidden products to hide them.',
        5
      );
    } else {
      message.info(
        'Unhidden products are now filtered out. You can select hidden products to unhide them.',
        5
      );
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Modal
        title={isHidden ? 'Hide Products' : 'Unhide Products'}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={handleSave}
        okText="Change Status"
        cancelText="Cancel">
        Are you sure you want to {isHidden ? 'hide' : 'unhide'} the selected products?
      </Modal>

      <AppContent breadcrumbItems={[{ label: 'Product', link: '/products' }, { label: 'Status' }]}>
        <Alert
          message="Note"
          description={
            <div>
              <p>
                You can hide or unhide products based on specific locations if provided. If no
                location is specified, the change will be applied globally.
              </p>

              <p>
                Do keep in note, products is filtered based on the selected location and hidden
                status.
                <ul>
                  <li>
                    If hidden status is set to Unhide, you can only select products that are hidden.
                    Unhidden products are filtered.
                  </li>
                  <li>If you believe a product isn't showing up, try sycing the products.</li>
                  <li>Double check the location and hidden status.</li>
                  <li>
                    Global hidden status takes precedence. So, please check if the product is hidden
                    globally or not before updating hidden status by location.
                  </li>
                </ul>
              </p>
            </div>
          }
          type="info"
          showIcon
        />

        <Form form={form} layout="vertical" initialValues={{ locationId: '', isHidden: false }}>
          <PageHeader title="Product Status" style={{ padding: '8px 0px' }} />
          <div
            className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mb-5'}>
            <LocationSearchV2
              showAll
              hasParentFormItem={false}
              name={['locationId']}
              onSelect={handleLocationChange}
            />
            <Form.Item label={'Hide Products'} name={'isHidden'}>
              <Select onChange={onHiddenChange}>
                <Select.Option value={true}>Hide</Select.Option>
                <Select.Option value={false}>Unhide</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div>
            <ProductSearchByHidden
              locationId={locationId}
              showHidden={!isHidden}
              name={['productId']}
              hasParentFormItem={false}
              setSelected={onProductChange}
            />
          </div>
        </Form>

        <div className="mt-5">
          <PageHeader
            subTitle={`These products will be ${isHidden ? 'hidden' : 'unhidden'}.`}
            style={{ padding: '8px 0' }}
          />
          <CustomizeTable
            data={selectedProducts}
            columns={columns}
            notshowPagination
            customScroll={{ y: '75vh', x: 700 }}
          />
        </div>

        <div className="flex justify-end mt-5 gap-1.5">
          <Button type="primary" onClick={onInitialSubmit}>
            {isHidden ? 'Hide' : 'Unhide'} Products
          </Button>

          <Button type="primary" onClick={() => navigate(-1)} ghost>
            Go Back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ProductHiddenStatus;
