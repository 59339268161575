import React from 'react';
import ReactDOM from 'react-dom/client';
// import { BrowserRouter as Router } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import AppRoutes from './routes';
import { ProfileProvider } from './contexts/auth.context';
import history from './utils/history.utils';
import { store } from './store/store';
import 'antd/dist/antd.min.css';
import './theme.less';
import './index.css';
import { CustomRouter } from './utils/CustomRouter';
import 'antd/dist/antd.variable.min.css';
import { ConfigProvider } from 'antd';
// navigator.serviceWorker.register('./firebase-messaging-sw.js');
// console.log('registering in index');
// navigator.serviceWorker.register('/ERP-UI/firebase-messaging-sw.js');

import 'react-quill/dist/quill.snow.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
import 'nepali-datepicker-reactjs/dist/index.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Context } from './components/Common/ContextSetupForMenu/Context';
import { WebSocketProvider } from './contexts/websocket.context';
import BroadcastDeleteProvider from './contexts/broadcast.provider';
import { GlobalContextProvider } from './contexts/global.context';
import SyncPermsProvider from './contexts/update_perms.provider';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}; // eslint-disable-line
  // console.error = () => {};
  // console.debug = () => {};
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConfigProvider>
    <Context>
      <Provider store={store}>
        {/* <Router history={history}> */}
        {/* <Router history={history}> */}
        <CustomRouter history={history}>
          <QueryClientProvider client={queryClient}>
            <GlobalContextProvider>
              <ProfileProvider>
                <WebSocketProvider>
                  <BroadcastDeleteProvider>
                    <SyncPermsProvider>
                      <AppRoutes />
                    </SyncPermsProvider>
                  </BroadcastDeleteProvider>
                </WebSocketProvider>
              </ProfileProvider>
            </GlobalContextProvider>
          </QueryClientProvider>
        </CustomRouter>
        {/* </Router> */}
        {/* </Router> */}
      </Provider>
    </Context>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
