import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Menu, Form, Select, TableProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { useNavigate } from 'react-router-dom';
import AppContent from '../../../components/Common/Content';
import { get_location_list } from '../../../services/locations/queries';
import DiscussionChatModal from '../../../components/Common/DiscussionChatModal';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import moment from 'moment';
import TableFilter from '../../../components/FliterTable';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { get_routes_list } from '../../../services/routes/queries';
import { checkAccess } from '../../../routes/acl';
import { ConvertObjectToURL } from '../../../utils/converturl';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { SorterResult } from 'antd/lib/table/interface';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../utils/convertToUTC';
import { getUserData } from '../../../utils/auth.utils';
import { get_price_groups_by_location } from '../../../services/products/queries';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import { ICreatePriceGroup } from '../../../services/products/types';
import CustomViewIcon from '../../../components/Common/CustomIcons/CustomViewIcon';
import CustomUpdateIcon from '../../../components/Common/CustomIcons/CustomUpdateIcon';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

interface DataType {
  id: number;
  name: string;
  age: number;
  address: string;
  description: string;
}

const PriceGroupListing: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [allPriceGroups, setAllPriceGroups] = useState<{
    total: number;
    results: ICreatePriceGroup[];
  }>({ total: 0, results: [] });
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  // const [allSellList, setAllProducts] = useState({ total: 0, results: [] });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [urlData, setUrlData] = useState('');

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  //   const deleteSellMutation = useMutation(delete_sell, {
  //     onSuccess: () => {
  //       message.success('Sell deleted successfully');
  //       getInfo();
  //     }
  //   });

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 15,
      render: (record) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Name',
      width: 70,
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>{record.name}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Location',
      width: 70,
      key: 'locationName',
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>{record.locationName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Created Date',
      width: 40,
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {/* {moment(record.date).local().format(DEFAULT_DATE_FORMAT)} */}
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            {/* {record.date.slice(0, 10)} */}
          </TableCell>
        );
      }
    },
    {
      title: 'Updated Date',
      width: 40,
      key: 'updatedAt',
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {/* {moment(record.date).local().format(DEFAULT_DATE_FORMAT)} */}
            {convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)}
            {/* {record.date.slice(0, 10)} */}
          </TableCell>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 20,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          //   deleteSellMutation.mutate(record.id);
        };
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [
          {
            key: '7',
            label: <CustomViewIcon link={`/price-groups/${record.id}`} />,
            onClick: () => {
              localStorage.setItem('pricegroupinfo', JSON.stringify(record));
            }
          }
        ];

        if (checkAccess('UPDATE_PRODUCT')) {
          menuItems.push({
            key: '2',
            label: <CustomUpdateIcon link={`/price-groups/details/${record.id}`} />
          });
        }

        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: <DiscussionChatModal slug="price-groups" id={record.id} />
          });
        }
        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Price Groups',
      link: '/price-groups'
    }
  ];
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(10);
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    setUrlData(filter);
    const response = await get_price_groups_by_location(form.getFieldValue('locationId'));
    const data = { results: response.data, total: response.data.length };
    data.results = response.data;
    for (let index = 0; index < data.results.length; index++) {
      if (data.results[index].locationId === null) {
        continue;
      }
      let locD: any = await LocationsDB.getLocation(data.results[index].locationId);
      if (!locD) {
        // locD = await get_location_details(response.data.results[index].locationId);
        const allLocations = await get_location_list();
        // console.log('allLocations-->', allLocations);
        await LocationsDB.addLocations([...allLocations.data.results]);
        // await LocationsDB.addLocations([locD]);
        locD = await LocationsDB.getLocation(data.results[index].locationId);
      }
      data.results[index].locationName = locD.name;
    }
    console.log(data);
    setAllPriceGroups(data);
    setIsloading(false);
    return response;
  };
  const { Option } = Select;
  const { data: routesList } = useQuery(['routes'], async () => get_routes_list());

  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // const response = await get_sell_lists_v2(url);
    // setallSellList(response.data);
    await getInfo(url);
    // setIsloading(false);
    // ProductsDB.addProducts(productResponse.data.results);
  };

  return (
    <AppContent
      breadcrumbItems={breadcrumbItems}
      withfilter={true}
      button={
        <>
          <div>
            {locationsList && (
              <TableFilter
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                form={form}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  locationId: preferenceLocationId ? preferenceLocationId : ''
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                buttons={
                  <>
                    <div>
                      <CustomButton
                        onClick={() => navigate(`/price-groups-new`)}
                        text="Add"
                        backgroundColor="#1890ff"
                        Linkto="/price-groups-new"
                      />
                    </div>
                  </>
                }>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
              </TableFilter>
            )}
          </div>
          {/* </div> */}
        </>
      }>
      <CustomizeTable
        form={form}
        columns={columns}
        data={allPriceGroups.results}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 1000, y: '60vh' }}
        paginationDatas={{
          page: page,
          total: allPriceGroups.total,
          size: size,
          onPagination
        }}
        tableName={'pricegroups-list'}
        toSort={handleChange}
      />
    </AppContent>
  );
};

export default PriceGroupListing;
