import { IUser } from '@/services/auth/types';
import LocalStore, { getTransactionError } from '.';
import { User } from '../../services/users/types';
import { get_user_list_ids } from '@/services/users/queries';

export default class UsersDB extends LocalStore {
  /*Users Functions*/
  /*search for user by name, email, id, phone with limit*/
  static async searchUser(name: string, limit = 10) {
    if (!UsersDB.db) await UsersDB.init();
    name = name.toLowerCase();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const transaction = UsersDB.db.transaction('Users', 'readonly');
      const filteredUsers: any[] = [];
      const objectStore = transaction.objectStore('Users');
      const cursorRequest = objectStore.index('UsersSecondIndex').openCursor();
      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (
            cursor.value.lowercaseName?.includes(name) ||
            cursor.value.email?.toLowerCase().includes(name) ||
            cursor.value.phone?.toLowerCase().includes(name) ||
            JSON.stringify(cursor.value.id)?.toLowerCase()?.includes(name)
          ) {
            filteredUsers.push(cursor.value);
          }
          if (filteredUsers.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredUsers);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async getAllUsers() {
    if (!UsersDB.db) await UsersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UsersDB.db.transaction('Users', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Users');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async removeAllUsers() {
    if (!UsersDB.db) await UsersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UsersDB.db.transaction(['Users'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Users');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async addUsers(usersData: any[]) {
    if (!UsersDB.db) await UsersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UsersDB.db.transaction('Users', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Users');
      usersData.forEach(async (user) => {
        const checkKey = objectStore.count(user.id);
        checkKey.onsuccess = async (event: any) => {
          user.lowercaseName = user.name.toLowerCase();
          if (checkKey.result == 0) {
            const request = objectStore.add(user);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await UsersDB.updateUser(user);
          }
        };
      });
    });
  }
  static async getUser(id: number | string): Promise<User> {
    if (!UsersDB.db) await UsersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UsersDB.db.transaction('Users', 'readonly');
      transaction.oncomplete = (event: any) => {
        // resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Users');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async checkUser(id: number) {
    if (!UsersDB.db) await UsersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UsersDB.db.transaction('Users', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Users');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        if (event.target.result) resolve(true);
        resolve(false);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async updateUser(user: any) {
    if (!UsersDB.db) await UsersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UsersDB.db.transaction('Users', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Users');
      const request = objectStore.get(user.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          user.lowercaseName = user.name.toLowerCase();
          const requestUpdate = objectStore.put(user);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async deleteUser(id: number) {
    if (!UsersDB.db) await UsersDB.init();

    if (await UsersDB.getUser(id)) {
      return new Promise((resolve, reject) => {
        const transaction = UsersDB.db.transaction('Users', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Users');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }

  static async addUsersIfAbsent(userIds: number[]): Promise<IUser[]> {
    if (!UsersDB.db) await UsersDB.init();
    return new Promise((resolve, reject) => {
      const transaction = UsersDB.db.transaction('Users', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore('Users');
      const request = objectStore.getAll();

      request.onsuccess = async (event) => {
        const users = (event.target as IDBRequest).result as IUser[];
        const existingUsersIds = users.map((user) => user.id);

        const newUsersIds = userIds.filter((id) => !existingUsersIds.includes(id) && Boolean(id));

        if (newUsersIds.length) {
          const { data } = await get_user_list_ids(newUsersIds);
          const newUsers = data.results;
          await UsersDB.addUsers(newUsers);

          const newList = [...users, ...newUsers] as IUser[];
          const passedList = newList.filter((user) => userIds.includes(user.id));
          resolve(passedList);
        } else {
          const passedList = users.filter((user) => userIds.includes(user.id));
          resolve(passedList);
        }
      };

      request.onerror = (event) => getTransactionError(event, reject, 'search');
    });
  }
}
