import { useFilterStore, FilterType } from '@/store/zustand/filter.state';
import { Alert } from 'antd';
import { useLocation } from 'react-router-dom';

function FilterIndicator() {
  const location = useLocation();
  const state = useFilterStore((state) => state.getState(location.pathname));

  // Determine the detailed message based on the filter type
  const getMessage = (filterType?: FilterType) => {
    switch (filterType) {
      case 'local':
        return {
          title: 'Local Filter Applied',
          description: (
            <>
              <p className="!mb-2">
                Your previous filter preferences and pagination settings have been restored. The
                data displayed reflects the last selected criteria and page. To reset the filter,
                simply <strong>reload the page</strong>.
              </p>
            </>
          )
        };
      case 'saved':
        return {
          title: 'Saved Filter Applied',
          description: (
            <>
              <p className="!mb-2">
                You are viewing results based on a filter you saved earlier. To clear the filter,
                click <strong>"Reset Filter"</strong> in the filter sidebar.
              </p>
            </>
          )
        };
    }
  };

  const message = getMessage(state?.filterType);

  return message ? (
    <div className="mt-2">
      <Alert
        message={message.title}
        description={message.description}
        type="info"
        closable
        style={{ marginBottom: '16px' }}
      />
    </div>
  ) : null;
}

export default FilterIndicator;
