import AppContent from '@/components/Common/Content/Content';
import GenericTable from '@/components/Common/CustomizeTable';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import { getUser } from '@/services';
import { get_payroll_details } from '@/services/hr/queries';
import { IPayrollLine } from '@/services/hr/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { useQuery } from '@tanstack/react-query';
import { Button, Descriptions, PageHeader, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

function PayrollView() {
  const navigate = useNavigate();
  const params = useParams();
  const payrollId = Number(params.id);
  if (isNaN(payrollId)) return <Navigate to="/hr/payroll" />;

  const { isLoading, isFetching, data } = useQuery(['get_payroll_view', payrollId], async () => {
    const data = await get_payroll_details(payrollId);
    const userDetails = await getUser(data.payroll.userId);

    return { ...data, userName: userDetails.name };
  });

  const columns: ColumnsType<IPayrollLine> = [
    { title: 'S.N.', key: 'id', render: (_, __, index) => index + 1 },
    { title: 'Name', key: 'name', dataIndex: 'name' },
    {
      title: 'Total Amount',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      render: (value) => nepaliNumberFormatter(value)
    },
    {
      title: 'Last Updated',
      key: 'updatedAt',
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    }
  ];

  return (
    <Spin spinning={isLoading || isFetching}>
      <AppContent
        breadcrumbItems={[
          { label: 'HR', link: '/hr' },
          { label: 'Payroll', link: '/hr/payroll' },
          { label: 'View' }
        ]}>
        <PageHeader title="Payroll Details" style={{ padding: '8px 0' }} />
        <Descriptions bordered column={1} size="small" labelStyle={{ width: '30%' }}>
          <Descriptions.Item label="Name">{data?.payroll.name}</Descriptions.Item>
          <Descriptions.Item label="Employee">{data?.userName}</Descriptions.Item>
          <Descriptions.Item label="Tax">
            {data?.payroll.taxId ? (
              <Link
                to={`/users/${data?.payroll.userId}/tax/${data?.payroll.taxId}`}
                target="_blank">
                View
              </Link>
            ) : (
              'N/A'
            )}
          </Descriptions.Item>
        </Descriptions>

        <div className="mt-4">
          <PageHeader
            title="Payroll List"
            style={{ padding: '8px 0 2px' }}
            className="small-title"
          />

          <GenericTable
            data={data?.lines || []}
            columns={columns}
            hideDefaultPagination
            scroll={{ x: 500 }}
            generateSummary
            summaryClassName="text-left"
          />
        </div>

        <div className="flex justify-end mt-5 gap-4">
          {checkAccess('UPDATE_HR_PAYROLL') && (
            <Button type="primary" onClick={() => navigate(`/hr/payroll/details/${payrollId}`)}>
              Edit Payroll
            </Button>
          )}

          <Button type="default" onClick={() => navigate(-1)}>
            Go back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default PayrollView;
