import { Form, Input, Menu, message, Modal, Spin, TableProps, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConvertObjectToURL } from '@/utils/converturl';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import TableFilter from '@/components/FliterTable';
import { checkAccess } from '@/routes/acl';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import AppContent from '@/components/Common/Content/Content';
import { get_customer_group_list_filter } from '@/services/offfers/queries';
import { ICustomerGroupListData, ICustomerGroupListResponse } from '@/services/offfers/types';
import moment from 'moment';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import CustomerSearchV2 from '@/components/Common/CustomSearch/Customer';
import { delete_customer_group_mutation } from '@/services/offfers/mutations';
import getErrorMessage from '@/utils/getError';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomDeleteIcon from '@/components/Common/CustomIcons/CustomDeleteIcon';

const CustomerGroupList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [customerGroupList, setCustomerGroupList] = useState<ICustomerGroupListResponse>({
    results: [],
    count: 0
  });

  const [initialFilter, setInitialFilter] = useState('');
  const [selected, setSelected] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [sortedInfo, setSortedInfo] = useState<SorterResult<ICustomerGroupListData>>({});
  const handleChange: TableProps<ICustomerGroupListData>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<ICustomerGroupListData>);
  };

  const breadcrumbItems = [{ label: 'Customer Group', link: '/offer/customer-group' }];

  function handleDeleteClick(id: number) {
    setIsModalVisible(true);
    setSelected(id);
  }

  async function handleDelete() {
    try {
      setIsLoading(true);
      if (!selected) {
        return message.error('Please select the group to delete');
      }

      setIsModalVisible(false);
      await delete_customer_group_mutation(selected);
      setSelected(undefined);
      getInfo(initialFilter);
      message.success('Customer Group has been deleted successfully');
    } catch (error) {
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmitFilter = async (filter: string) => {
    await getInfo(filter);
    setPage(1);
    setSize(100);
    setInitialFilter(filter);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_customer_group_list_filter(filter);
    // console.log('response', response);
    if (response) {
      setCustomerGroupList(response);
    }
    setIsLoading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const columns: ColumnsType<ICustomerGroupListData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 20,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.name}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 10,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 2,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: () => void;
        }[] = [];

        if (checkAccess('READ_CUSTOMER_GROUP')) {
          menuItems.push({
            key: 'view',
            label: (
              <Tooltip title="View" color="blue">
                <Link to={`/offer/customer-group/view/${record.id}`}>
                  <EyeFilled style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        if (checkAccess('UPDATE_CUSTOMER_GROUP')) {
          menuItems.push({
            key: 'update',
            label: (
              <Tooltip title="Update" color="blue">
                <Link to={`/offer/customer-group/${record.id}`}>
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        if (checkAccess('UPDATE_CUSTOMER')) {
          menuItems.push({
            key: 'delete',
            label: <CustomDeleteIcon onClick={() => handleDeleteClick(record.id)} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <Modal
        title="Delete Customer Group"
        width={600}
        visible={isModalVisible && selected !== undefined}
        onOk={handleDelete}
        onCancel={() => setIsModalVisible(false)}>
        <p>Are you sure you want to delete this customer group?</p>
      </Modal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                dateCustom={false}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100
                }}
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_CUSTOMER_GROUP') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/offer/customer-group/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/offer/customer-group/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <CustomerSearchV2 hasParentFormItem={false} name={'customerId'} label="Customer" />
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={customerGroupList.results}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: customerGroupList.count,
            size: size,
            onPagination
          }}
          tableName={'offers-customer-group-list'}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default CustomerGroupList;
