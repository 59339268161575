import AppContent from '@/components/Common/Content/Content';
import { get_hr_holiday_list } from '@/services/hr/queries';
import { IHRHoliday, IHRHolidayList } from '@/services/hr/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input, message, Select } from 'antd';
import { useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { update_hr_holiday_mutation } from '@/services/hr/mutations';
import { checkAccess } from '@/routes/acl';
import { HRDatePresets } from '@/pages/sqlsource/report/utils/datePresets';

function HRHolidatList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IHRHolidayList>({ total: 0, results: [] });

  const [initialFilter, setInitialFilter] = useState('');

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    setInitialFilter(filter);
  }

  async function handleDisabled(holiday: IHRHoliday) {
    try {
      setIsLoading(true);
      const { id, ...payload } = holiday;
      await update_hr_holiday_mutation(id, {
        ...payload,
        isDisabled: !holiday.isDisabled
      });

      message.success(`Holiday ${holiday.isDisabled ? 'unarchived' : 'archived'} Successfully`);
      await getInfo(initialFilter);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_hr_holiday_list(filter);
      setData(response);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'HR', link: '/hr' }, { label: 'Holiday' }]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            datePresets={HRDatePresets}
            onPagination={(page, size) => setPagination({ page, size })}
            buttons={
              <>
                {checkAccess('CREATE_HOLIDAY') && (
                  <CustomButton text="Add" backgroundColor="#1890ff" Linkto="/hr/holiday/new" />
                )}
              </>
            }
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100,
              archiveStatus: 'ALL'
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <Form.Item name="archiveStatus" label="Status">
              <Select placeholder="Select a Status">
                <Select.Option value="ALL">ALL</Select.Option>
                <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                <Select.Option value="INACTIVE">INACTIVE</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="value" label="Name">
              <Input />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          data={data}
          form={form}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
          handleDisabled={handleDisabled}
        />
      </AppContent>
    </div>
  );
}

export default HRHolidatList;
