import { Button, Checkbox, Divider, Form, Input, PageHeader, Select, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import {
  ILotsConversionAdjustment,
  ILotsConversionAdjustmentModal,
  ILotsConversionFormData
} from '../../../services/products/types';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import {
  get_lots_details,
  get_product_details,
  get_product_list,
  get_units_list
} from '../../../services/products/queries';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { get_reasons_list } from '../../../services/adjustments/queries';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  create_adjustments_mutation,
  create_reason_mutation
} from '../../../services/adjustments/mutations';
import {
  IAdjustmentConversionLines,
  ILotConversionAdjustmentData,
  IReason
} from '../../../services/adjustments/types';

const ReusableConvertLotAdjustment: React.FC<ILotsConversionAdjustment> = ({
  data,
  handleSubmitClick
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allData, setAllData] = useState<ILotsConversionAdjustmentModal>();
  const [form] = Form.useForm();
  const [fornewreason, setfornewreason] = useState(false);
  const { Option } = Select;
  //console.log('data', data);

  const { data: reasonList } = useQuery(['reason'], async () => get_reasons_list('status=ACTIVE'));

  useEffect(() => {
    if (data) {
      getAllData(data);
    }
  }, [data]);

  const getAllData = async (data: ILotsConversionFormData) => {
    form.setFieldValue(['reasonId'], null);
    setfornewreason(false);
    form.setFieldValue(['name'], '');
    form.setFieldValue(['note'], '');
    setIsLoading(true);
    if (data) {
      let modalData: ILotsConversionAdjustmentModal = { ...data };
      let unitMultiplyer = 0;
      let convertUnitMultiplyer = 0;
      if (data.originProductId) {
        let productDetails = await ProductsDB.getProduct(data.originProductId);
        if (!productDetails) {
          const allProducts = await get_product_list();
          await ProductsDB.addProducts(allProducts.data.results);
          productDetails = await ProductsDB.getProduct(data.originProductId);
        }

        if (typeof productDetails === 'object') {
          modalData = { ...modalData, originProductName: productDetails.name };
        }
      }
      if (data.unitId) {
        let unitDetails = await UnitsDB.getUnit(data.unitId);
        if (!unitDetails) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          unitDetails = await UnitsDB.getUnit(data.unitId);
        }
        if (typeof unitDetails === 'object') {
          modalData = { ...modalData, originUnitName: unitDetails.shortName };
          unitMultiplyer = unitDetails.baseUnitMultiplier;
        }
      }
      if (data.productId) {
        let productDetails = await ProductsDB.getProduct(data.productId);
        if (!productDetails) {
          const allProducts = await get_product_list();
          await ProductsDB.addProducts(allProducts.data.results);
          productDetails = await ProductsDB.getProduct(data.productId);
        }

        if (typeof productDetails === 'object') {
          modalData = { ...modalData, productName: productDetails.name };
        }
      }
      if (data.convertUnitId) {
        let unitDetails = await UnitsDB.getUnit(data.convertUnitId);
        if (!unitDetails) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          unitDetails = await UnitsDB.getUnit(data.convertUnitId);
        }
        if (typeof unitDetails === 'object') {
          modalData = { ...modalData, unitName: unitDetails.shortName };
          convertUnitMultiplyer = unitDetails.baseUnitMultiplier;
        }
      }
      if (modalData.originUnitName != undefined) {
        if (data.unitId === data.convertUnitId) {
          const adjustmentQuantity = data.originQuantity - data.quantity;
          modalData = {
            ...modalData,
            adjustmentQuantity: adjustmentQuantity,
            adjustmentUnitName: modalData.originUnitName
          };
        } else {
          const UnitConvertedToOriginUnit =
            (data.quantity / unitMultiplyer) * convertUnitMultiplyer;
          const adjustmentQuantity = data.originQuantity - UnitConvertedToOriginUnit;
          modalData = {
            ...modalData,
            adjustmentQuantity: adjustmentQuantity,
            adjustmentUnitName: modalData.originUnitName
          };
        }
      }
      setAllData(modalData);
    }
    setIsLoading(false);
  };

  const createAdjustmentsMutation = useMutation(create_adjustments_mutation);
  const createReasonsMutation = useMutation(create_reason_mutation);

  const noAdjustmentSubmit = () => {
    setIsLoading(true);
    setfornewreason(false);
    handleSubmitClick();
  };

  const handleSubmit = async (values: ILotConversionAdjustmentData) => {
    setIsLoading(true);
    if (fornewreason) {
      const reasonName = form.getFieldValue(['name']);
      await createReasonsMutation.mutateAsync(
        { name: reasonName },
        {
          onSuccess: async ({ data }: { data: IReason }) => {
            message.success('Reason created successfully');
            form.setFieldValue(['reasonId'], data.id);
          },
          onError: () => {
            setIsLoading(false);
          }
        }
      );
    }

    const sendData: ILotConversionAdjustmentData = { ...values };
    sendData.date = JSON.stringify(new Date()).slice(1, -1);

    if (allData?.locationId) {
      sendData.location = allData.locationId;
    }

    if (allData?.originProductId && allData?.unitId) {
      const line: IAdjustmentConversionLines = {
        discount: 0,
        isTraceable: false,
        lotId: allData?.originLotId,
        misc: 0,
        productId: allData?.originProductId,
        quantity: allData?.adjustmentQuantity,
        unitId: allData?.unitId,
        unitPrice: 0,
        reasonId: form.getFieldValue(['reasonId']),
        reasonName: form.getFieldValue(['name'])
      };

      sendData.lines = [line];
    }

    createAdjustmentsMutation.mutateAsync(
      {
        date: sendData.date,
        location: sendData.location,
        lines: [
          {
            ...sendData.lines[0]
          }
        ],
        reasonId: sendData.reasonId,
        note: sendData.note
      },
      {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            // setIsloading(false);
            message.success('Adjustments created successfully');
            // navigate('/adjustments');
          }
        },
        onError: (e: any) => {
          setIsLoading(false);
        }
      }
    );

    setfornewreason(false);
    handleSubmitClick();
  };

  return (
    <Spin spinning={isLoading}>
      <div className="grid grid-cols-2 gap-5 mb-5">
        <div>
          <PageHeader
            subTitle="Origin Product"
            style={{
              padding: '2px 0px'
            }}
          />
          <div>Product Name: {allData?.originProductName}</div>
          <div>
            Quantity: {allData?.originQuantity} {allData?.originUnitName}
          </div>
        </div>
        <div>
          <PageHeader
            subTitle="Converted Product"
            style={{
              padding: '2px 0px'
            }}
          />
          <div>Product Name: {allData?.productName}</div>
          <div>
            Quantity: {allData?.quantity} {allData?.unitName}
          </div>
        </div>
      </div>
      <Divider />
      <PageHeader
        subTitle="Adjustment"
        style={{
          padding: '2px 0px'
        }}
      />
      <div>Product Name: {allData?.originProductName}</div>
      <div>
        Quantity: {allData?.adjustmentQuantity} {allData?.adjustmentUnitName}
      </div>
      {allData?.adjustmentQuantity != undefined && allData?.adjustmentQuantity > 0 ? (
        <>
          <Divider />
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            disabled={isLoading}
            //   validateTrigger={'onChange'}
            //   onValuesChange={(_, allFields) => {
            //     setProductDetails(allFields);
            //   }}
            autoComplete="off">
            {fornewreason ? (
              <Form.Item
                label="New Reason Name"
                name={['name']}
                rules={[{ required: true, message: 'Please add name!' }]}>
                <Input />
              </Form.Item>
            ) : (
              <Form.Item
                label="Reason"
                name={['reasonId']}
                rules={[{ required: true, message: 'Please select reason!' }]}>
                <Select placeholder="Select a reason!" dropdownMatchSelectWidth={false} allowClear>
                  {reasonList?.data.map((value) => {
                    return (
                      <Option value={value.id} key={value.id}>
                        {value.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Checkbox
              checked={fornewreason}
              onChange={(value) => {
                setfornewreason(value.target.checked);
              }}>
              Create New Reason?
            </Checkbox>
            <Form.Item
              label="Note"
              name={['note']}
              rules={[{ required: false, message: 'Add note' }]}
              className="col-span-2">
              <Input />
            </Form.Item>
            <div className="flex justify-end mt-5">
              <Form.Item>
                <Button type="primary" onClick={form.submit}>
                  Confirm
                </Button>
              </Form.Item>
            </div>
          </Form>
        </>
      ) : (
        <div className="flex justify-end mt-5">
          <Button type="primary" onClick={() => noAdjustmentSubmit()}>
            Confirm
          </Button>
        </div>
      )}
    </Spin>
  );
};

export default ReusableConvertLotAdjustment;
