import { create } from 'zustand';
import moment from 'moment';
import { devtools } from 'zustand/middleware';

export type FilterType = 'local' | 'saved';
type PaginationState = { page: number; size: number };

type PageState = {
  filter: Record<string, unknown>;
  pagination: PaginationState;
  filterType?: FilterType;
};

type StoreState = {
  states: Record<string, PageState>;
  setState: (page: string, urlParams: Record<string, unknown>, filterType?: FilterType) => void;
  getState: (page: string) => PageState | undefined;
  resetState: (page: string) => void;
};

export const useFilterStore = create<StoreState>()(
  devtools((set, get) => ({
    // Object to store states for each page
    // Key: page url, Value: PageState
    states: {},
    setState: (page, urlParams, filterType) =>
      set((state) => {
        const { filter, pagination } = parseFilter(urlParams);

        return {
          states: {
            ...state.states,
            [page]: { filter, pagination, filterType }
          }
        };
      }),

    getState: (page: string): PageState | undefined => {
      const states = get().states[page];

      const filter = states?.filter ?? {};

      // Convert dateSingle and dateCustom to moment
      if (filter.dateSingle && typeof filter.dateSingle === 'string') {
        states.filter.dateSingle = moment(filter.dateSingle);
      }

      if (filter.dateCustom && Array.isArray(filter.dateCustom)) {
        states.filter.dateCustom = filter.dateCustom.map((date) => moment(date));
      }

      return states;
    },

    resetState: (page: string) =>
      set((state) => {
        const newStates = { ...state.states };
        delete newStates[page]; // Remove the key from the object
        return { states: newStates };
      })

    // setFilterType(page, filterType) {
    //   set((state) => {
    //     const newState = { ...state.states };
    //     newState[page].filterType = filterType;
    //     return { states: newState };
    //   });
    // }
  }))
);

const parseFilter = (params: Record<string, unknown>) => {
  //  Get skip and count from params
  const skip = Number(params.skip) || 0;
  const count = Number(params.count) || 100;

  // Calculate page from skip and count
  const page = Math.floor(skip / count) + 1;
  const size = count;

  return { filter: params, pagination: { page, size } };
};
