import { useMutation } from '@tanstack/react-query';
import { Input, message, Tooltip, Menu, Form, TableProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LocationFilterDrawer from '../../../components/@Locations/FilterDrawer';
import AppContent from '../../../components/Common/Content';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import DeleteConfirmModal from '../../../components/Common/Deleteconfirm/DeleteConfirmModal';
import DiscussionChatModal from '../../../components/Common/DiscussionChatModal';
import TableFilter from '../../../components/FliterTable';
import useFilterDrawers from '../../../hooks/useFilterDrawers';
import { checkAccess } from '../../../routes/acl';
import { delete_offer } from '../../../services/offfers/mutations';
import { get_offer_list_filter } from '../../../services/offfers/queries';
import { IOffer } from '../../../services/offfers/types';
import { AppDispatch, RootState } from '../../../store/store';
import { ConvertObjectToURL } from '../../../utils/converturl';
import './list.less';
import { SorterResult } from 'antd/lib/table/interface';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import CustomUpdateIcon from '../../../components/Common/CustomIcons/CustomUpdateIcon';

const OfferList: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { visible, showDrawer, onClose } = useFilterDrawers();
  // const { skip, count, value } = useSelector((state: RootState) => state.lists);
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [allOffers, setAllOffers] = useState<any>({
    total: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_offer_list_filter(filter);
    setAllOffers(response.data);
    setIsloading(false);
    return response;
  };

  const deleteofferMutation = useMutation(delete_offer, {
    onSuccess: () => {
      message.success('offer deleted successfully');
      const values = form.getFieldsValue();
      values.endDate = convertLocalToUTCString(values.endDate);
      values.startDate = convertLocalToUTCString(values.startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      onSubmitFilter(ConvertObjectToURL(values));
      // dispatch(removeLocation(selectedID));
    }
  });

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 3,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/offer/${record.id}`} color="black">
          <TableCell>{record.name}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Code',
      key: 'code',
      width: 15,
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortOrder: sortedInfo.columnKey === 'code' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/offer/${record.id}`} color="black">
          <TableCell>{record.code}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 5,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          deleteofferMutation.mutate(record.id);
        };
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        if (checkAccess('UPDATE_OFFER')) {
          menuItems.push({
            key: '2',
            label: <CustomUpdateIcon link={`/offer/${record.id}`} />
          });
        }
        if (checkAccess('DELETE_OFFER')) {
          menuItems.push({
            key: '1',
            label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '3',
            label: (
              <>
                <DiscussionChatModal slug="offer" rreference="other" id={record.id} />
              </>
            )
          });
        }
        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IOffer[]) => {
      //Do something here
    }
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    // console.log(current, pageSize);
  };

  const breadcrumbItems = [
    {
      label: 'Offer',
      link: '/offer'
    }
  ];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    const response = await get_offer_list_filter(url);
    setAllOffers(response.data);
    setIsloading(false);
    // ProductsDB.addProducts(productResponse.data.results);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            {/* <div className="flex flex-wrap items-center justify-end"> */}
            <div>
              <TableFilter
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                style={'grid sm:grid-cols-1 md:grid-cols-2 gap-2'}
                buttons={
                  <>
                    {checkAccess('CREATE_OFFER') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate(`/offer/new`)}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/offer/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        {/* <Table
          rowSelection={{
            ...rowSelection
          }}
          // scroll={{ x: true }}
          scroll={{ y: '65vh', x: true }}
          columns={columns}
          dataSource={offerList ? offerList : []}
          rowKey={'id'}
          loading={isLoading}
          // loading={locationsLoading}
        /> */}
        <CustomizeTable
          form={form}
          columns={columns}
          data={allOffers.results}
          usersLoading={isLoading}
          customScroll={{ y: '75vh', x: 400 }}
          notshowPagination={true}
          paginationDatas={{
            page: page,
            total: allOffers.total,
            size: size,
            onPagination
          }}
          tableName={'offers-list'}
          toSort={handleChange}
        />

        <LocationFilterDrawer visible={visible} onClose={onClose} />
      </AppContent>
    </div>
  );
};

export default OfferList;
