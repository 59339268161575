import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IPosMoneyTableData } from '@/services/pos-session/types';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { Form, Input, Menu, Spin, TableProps, Tooltip } from 'antd';
import { ConvertObjectToURL } from '@/utils/converturl';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { get_money_info_filter } from '@/services/pos-session/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import TableFilter from '@/components/FliterTable';
import { checkAccess } from '@/routes/acl';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import moment from 'moment';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { EditOutlined } from '@ant-design/icons';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const MoneyList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [moneyList, setMoneyList] = useState<{ result: IPosMoneyTableData[]; total: number }>({
    result: [],
    total: 0
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Money',
      link: '/session/money'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_money_info_filter(filter);
    if (response) {
      const tableData = await Promise.all(
        response.results.map(async (value) => {
          let userDetails = await UsersDB.getUser(value.createdBy);
          if (!userDetails) {
            const allUsers = await get_user_list_ids([
              ...new Set(
                response.results.map((val) => {
                  return val.createdBy;
                })
              )
            ]);
            await UsersDB.addUsers(allUsers.data.results);
            userDetails = await UsersDB.getUser(value.createdBy);
          }

          return { ...value, createdByName: userDetails.name };
        })
      );

      setMoneyList({ result: tableData, total: response.total });
    }
    setIsLoading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const columns: ColumnsType<IPosMoneyTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Amount in Words',
      key: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.name}</TableCell>
    },
    {
      title: 'Amount in Numerals',
      key: 'value',
      width: 15,
      sorter: (a, b) => a.value - b.value,
      sortOrder: sortedInfo.columnKey === 'value' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.value}</TableCell>
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 15,
      sorter: (a, b) => a.createdByName.localeCompare(b.createdByName),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.createdByName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: undefined;
        }[] = [];

        if (checkAccess('ADMIN')) {
          menuItems.push({
            key: '1',
            label: (
              <Link to={`/session/money/${record.id}`}>
                <Tooltip title="Update" color="blue">
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              </Link>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                dateCustom={false}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100
                }}
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('ADMIN') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/session/money/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/session/money/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={moneyList.result}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: moneyList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'session-money-list'}
        />
      </AppContent>
    </Spin>
  );
};

export default MoneyList;
