import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Spin,
  Table,
  Typography
} from 'antd';
import { Option } from 'antd/lib/mentions';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import {
  get_lots_details_bylocationId_productId,
  get_product_list,
  get_product_list_ids,
  get_unexpired_lots_details_bylocationId_productIds,
  get_units_list
} from '@/services/products/queries';
import { ILotDetails, IUnits } from '@/services/products/types';
import { create_sell_return_mutation } from '@/services/sell/mutations';
import {
  get_sell_details,
  get_sell_lines_details,
  get_sell_return_lines_details
} from '@/services/sell/queries';
import { ISellLinesResponseFromServer, Line } from '@/services/sell/types';
import { get_user_details } from '@/services/users/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent, AccountType } from '@/services/accounts/enums';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';
import isAxiosError from '@/utils/isAxiosError';
import getErrorMessage from '@/utils/getError';

const { Text } = Typography;

const breadcrumbItems = [
  { label: 'Sell', link: '/sell' },
  { label: 'Sell Return', link: '/sell/return' },
  { label: 'Return' }
];

interface ISellReturnProps {
  sellId?: string;
  onSaveHandler?: () => void;
}

const SellReturn: React.FC<ISellReturnProps> = ({ sellId, onSaveHandler }) => {
  const navigate = useNavigate();
  const { socket } = useContext(WebSocketContext);
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [form] = Form.useForm();
  let { id } = useParams();
  let isEmbedded = false;
  if (sellId) {
    id = sellId;
    isEmbedded = true;
  }
  const [isLoading, setIsLoading] = useState(true);
  const [hasRule, setHasRule] = useState(true);
  const [isAccountDisabled, setIsAccountDisabled] = useState(false);

  const [locationId, setLocationId] = useState<number>();
  const [totalLots, settotalLots] = useState<any>([]);
  const [data, setData] = useState<ISellLinesResponseFromServer[]>([]);
  const [editingKey, setEditingKey] = useState<number>();
  const [newLotboolArray, setnewLotboolArray] = useState<any[]>([]);
  const [returnAllIndex, setReturnAllIndex] = useState<number[]>([]);
  const { data: sellDetails } = useQuery(['sell-details'], async () => {
    const response = await get_sell_details(parseInt(id as string));
    const lines = await get_sell_lines_details(parseInt(id as string));
    response.lines = lines.data;
    const vendor = await get_user_details(response.address.userId);
    response.vendorName = vendor.user.name;
    setnewLotboolArray(Array(response.lines.length).fill(false));
    const searchProducts: any = {};
    const mySet = new Set<number>();

    // See if account is disabled
    const userId = parseInt(vendor?.user?.id as string);
    if (userId) {
      const userAccount = await get_account_details_by_userid_type(userId, AccountType.USER);
      setIsAccountDisabled(userAccount?.isArchived);

      if (userAccount?.isArchived) {
        message.error({
          content: (
            <span>
              Unable to create a sell return for this customer. <strong>Reason:</strong>{' '}
              <span className="text-red-500">Account Archived</span>
            </span>
          ),
          duration: 5
        });
      }
    }

    //to call and sale_return api
    const productIdAndReturnQuanitityMap: any = {};
    const returnLines: any = [];
    for (let index = 0; index < response?.returns.length; index++) {
      const returnLineResponse = await get_sell_return_lines_details(response.returns[index].id);
      returnLines.push(returnLineResponse.data);

      for (let ind = 0; ind < returnLineResponse.data.length; ind++) {
        if (!productIdAndReturnQuanitityMap[`${returnLineResponse.data[ind].productId}`]) {
          productIdAndReturnQuanitityMap[`${returnLineResponse.data[ind].productId}`] =
            returnLineResponse.data[ind].quantity;
        } else {
          productIdAndReturnQuanitityMap[`${returnLineResponse.data[ind].productId}`] +=
            returnLineResponse.data[ind].quantity;
        }
      }
    }
    // console.log('return lines', returnLines);
    // console.log('product lines', response.lines);
    for (let index = 0; index < response.lines.length; index++) {
      mySet.add(response.lines[index].productId);
      const product: any = await ProductsDB.getProduct(response.lines[index].productId);
      let unit: any = await UnitsDB.getUnit(response.lines[index].unitId);

      if (!product) {
        if (response.lines[index].productId in searchProducts) {
          searchProducts[response.lines[index].productId] = [
            ...searchProducts[response.lines[index].productId],
            index
          ];
        } else {
          searchProducts[response.lines[index].productId] = [index];
        }
      } else {
        response.lines[index].productName = product.name;
      }
      if (!unit) {
        const unitsResponse = await get_units_list();
        await UnitsDB.addUnits(unitsResponse);
        unit = await UnitsDB.getUnit(response.lines[index].unitId);
      }

      if (
        productIdAndReturnQuanitityMap[`${response.lines[index].productId}`] >=
        response.lines[index].quantity
      ) {
        response.lines[index].alreadyreturnQuantity = response.lines[index].quantity;
        productIdAndReturnQuanitityMap[`${response.lines[index].productId}`] -=
          response.lines[index].quantity;
      } else {
        response.lines[index].alreadyreturnQuantity =
          productIdAndReturnQuanitityMap[`${response.lines[index].productId}`];
        productIdAndReturnQuanitityMap[`${response.lines[index].productId}`] = 0;
      }

      response.lines[index].unitName = unit.name;
      response.lines[index].totalQuantity = response.lines[index].quantity;
      response.lines[index].quantity = 0;
      response.lines[index].totalAlreadyReturn =
        parseFloat(response.lines[index].alreadyreturnQuantity as string) *
        response.lines[index].unitPrice;
      response.lines[index].totalReturn = 0;
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: any) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          response.lines[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
    setLocationId(response?.locationId);
    await checkAccountRule(response?.locationId);
    await fetchLotsOnLocationandProductChange(Array.from(mySet), response?.locationId, 'location');
    form.setFieldValue(['lines'], response.lines);
    setData(tempFix());
    setIsLoading(false);
    return response;
  });

  const tempFix = () => {
    let formData = form.getFieldValue(['lines']);
    formData = formData.map((item: any) => {
      return { ...item, lotId: null, unitDiscount: item.discount / item.totalQuantity };
    });
    form.setFieldValue(['lines'], formData);
    return formData;
  };

  const checkAccountRule = async (locationId: number) => {
    if (
      (await checkHasAccountRule(locationId, AccountRulesEvent.SELL_RETURN)) &&
      (await checkHasAccountRule(locationId, AccountRulesEvent.VAT_CREATE))
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.SELL_RETURN}" or "${AccountRulesEvent.VAT_CREATE}" rule has not been created!`
      });
    }
  };

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const productIds = new Set<number>(
        sellDetails?.lines.map((value) => {
          return value.productId;
        })
      );
      if (locationId) {
        await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
      }
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev: any) => {
            return prev.map((a: any) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const productIds = new Set<number>(
          sellDetails?.lines.map((value) => {
            return value.productId;
          })
        );

        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          if (locationId) {
            const updatedProductIds = updatedProducts.map((value) => value.productId);
            await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
          }
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected, locationId, sellDetails]);

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    if (!locationId) {
      throw {
        name: 'Location Error',
        message: 'Please select Location!'
      };
    }
    const currenttotalLots = [];
    if (from === 'productchange') {
      const filterLots = totalLots.find(
        (value: ISellLinesResponseFromServer) => value.productId == productsIdArray[0]
      );
      if (!filterLots) {
        const response = await get_lots_details_bylocationId_productId(
          locationId,
          productsIdArray[0]
        );
        settotalLots([...totalLots, ...response]);
      }
    } else if (from === 'lotsupdate') {
      const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
        ...new Set(productsIdArray)
      ]);

      settotalLots((prev: ILotDetails[]) => {
        const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
        return [...filterLots, ...result];
      });
    } else {
      const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
        ...new Set(productsIdArray)
      ]);
      currenttotalLots.push(...result);
      settotalLots([...currenttotalLots]);
    }
  };

  const FilterLot = (checkCurrentProduct: number, currentLocation: number, unitId: number) => {
    // const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);

    // const currentLocation = form.getFieldValue(['locationId']);

    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }
      // const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => {
            const isExpired = value?.expirationDate
              ? moment().isAfter(value.expirationDate)
              : false;

            return (
              <Option
                value={value.id}
                key={value.id}
                style={{ color: value.qtyAvailable > 0 && !isExpired ? 'green' : 'red' }}>
                {`(${value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)} ${
                  unitInfo?.shortName || ''
                }) ${value.lotNumber}`}{' '}
                {`Grade-${value.grade} Expiry-${
                  value?.expirationDate
                    ? new Date(value.expirationDate).toLocaleDateString()
                    : 'N/A'
                }`}
              </Option>
            );
          })}
        </>
      );
    }
  };
  function strip(number: number) {
    return number.toPrecision(1);
  }

  const calculateTotalReturn = async (
    productId: number,
    value: number,
    index: number,
    unitPrice: number,
    unitDiscount: number
  ) => {
    let productDetails = await ProductsDB.getProduct(productId);
    if (!productDetails) {
      const allProducts = await get_product_list();
      await ProductsDB.addProducts(allProducts.data.results);
      productDetails = await ProductsDB.getProduct(productId);
    }

    const total = value * unitPrice;
    const discount = value * unitDiscount;
    const totalAfterDiscount = total - discount;
    if (typeof productDetails == 'object' && productDetails.vat != undefined) {
      const vat = productDetails.vat;
      const vatAmt = totalAfterDiscount * (vat / 100);

      form.setFieldValue(['lines', index, 'totalReturn'], totalAfterDiscount + vatAmt);
      form.setFieldValue(['lines', index, 'vat'], vatAmt);
      form.setFieldValue(['lines', index, 'discount'], discount);
    } else {
      form.setFieldValue(['lines', index, 'totalReturn'], totalAfterDiscount);
      form.setFieldValue(['lines', index, 'vat'], 0);
      form.setFieldValue(['lines', index, 'discount'], discount);
    }
  };

  const columns = [
    {
      title: 'S.N',
      render: (a: number, b: any, c: number) => {
        return <div className="text-center">{c + 1}</div>;
      },
      width: 40
    },
    {
      title: 'Product',
      dataIndex: 'productName'
    },
    {
      title: 'Unit',
      dataIndex: 'unitName'
    },
    {
      title: 'Rate',
      dataIndex: 'unitPrice',
      render: (text: any) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(text)}</div>;
      }
    },
    {
      title: 'Total Quantity',
      dataIndex: 'totalQuantity',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'Returned Quantity',
      dataIndex: 'alreadyreturnQuantity',
      render: (text: number) => {
        return <div className="text-center">{text ? text : 0}</div>;
      }
    },
    {
      title: 'Total Returned',
      dataIndex: 'totalAlreadyReturn',
      render: (text: number) => {
        return <div className="text-right mr-2">{numberDecimalFormatter(text ? text : 0)}</div>;
      }
    },
    {
      title: 'Return All',
      dataIndex: 'returnAll',
      render: (value: number, row: any, index: number) => {
        return (
          <Form.Item
            className="text-center"
            name={['lines', index, 'returnAll']}
            key={row.id}
            initialValue={false}>
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  setReturnAllIndex((prev) => [...prev, index]);
                } else {
                  form.setFieldValue(['lines', index, 'quantity'], 0);
                  setReturnAllIndex((prev) => prev.filter((val) => val !== index));
                }
              }}
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Return',
      dataIndex: 'totalQuantity',
      render: (value: number, row: any, index: number) => {
        // form.setFieldValue(['lines', index, 'quantity'], 0);
        if (returnAllIndex.includes(index)) {
          const val = row.alreadyreturnQuantity ? value - row.alreadyreturnQuantity : value;
          form.setFieldValue(['lines', index, 'quantity'], val);
          calculateTotalReturn(row.productId, val, index, row.unitPrice, row.unitDiscount);
          return (
            <Form.Item name={['lines', index, 'quantity']} key={row.id}>
              <InputNumber controls={false} disabled style={{ color: 'black' }} />
            </Form.Item>
          );
        } else {
          return (
            <Form.Item
              name={['lines', index, 'quantity']}
              key={row.id}
              rules={[
                {
                  required: false,
                  message: 'Please add quantity!'
                },
                () => ({
                  validator(_, value: number) {
                    if (!row.alreadyreturnQuantity) {
                      row.alreadyreturnQuantity = 0;
                    }
                    const maxValue = numberDecimalFormatter(
                      row.totalQuantity - row.alreadyreturnQuantity,
                      true
                    ) as number;
                    if (value > maxValue) {
                      return Promise.reject('Quantity exceeded!');
                    }
                    form.setFieldValue(['lines', index, 'quantity'], value);
                    return Promise.resolve();
                  }
                })
              ]}>
              <InputNumber
                controls={false}
                min={0}
                defaultValue={0}
                onChange={async (val) => {
                  if (typeof val === 'number') {
                    await calculateTotalReturn(
                      row.productId,
                      val,
                      index,
                      row.unitPrice,
                      row.unitDiscount
                    );
                  }
                }}
              />
            </Form.Item>
          );
        }
      }
    },
    {
      title: 'Lots',
      dataIndex: 'selectLots',
      render: (value: number, row: any, index: number) => {
        return (
          <>
            {newLotboolArray[index] ? (
              <Form.Item name={['lines', index, 'newLotGrade']} key={row.id}>
                <Input maxLength={1} />
              </Form.Item>
            ) : (
              <Form.Item name={['lines', index, 'lotId']} key={row.id}>
                <Select
                  placeholder="Select a Lot!"
                  dropdownMatchSelectWidth={false}
                  allowClear
                  // onChange={(val: number) => {
                  //   form.setFieldValue(['lines', index, 'lotId'], val);
                  // }}
                >
                  {FilterLot(row.productId, row.locationId, row.unitId)}
                </Select>
              </Form.Item>
            )}
            <Form.Item name={['lines', index, 'newLot']} label="New Lot?">
              <Checkbox
                onChange={(e) => {
                  setnewLotboolArray((prev: any) =>
                    prev.map((curr: any, ind: number) => {
                      if (ind == index) {
                        return !curr;
                      } else {
                        return curr;
                      }
                    })
                  );
                  // form.setFieldValue(['lines', index, 'newLot'], e.target.value.checked);
                }}
              />
            </Form.Item>
          </>
        );
      }
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      render: (value: number, row: any, index: number) => {
        return (
          <Form.Item name={['lines', index, 'discount']} key={row.id}>
            <InputNumber controls={false} min={0} defaultValue={0} disabled />
          </Form.Item>
        );
      }
    },
    {
      title: 'Total Return',
      dataIndex: 'totalQuantity',
      render: (value: number, row: any, index: number) => {
        return (
          <Form.Item name={['lines', index, 'totalReturn']} key={row.id}>
            <InputNumber controls={false} min={0} defaultValue={0} disabled />
          </Form.Item>
        );
      }
    }
  ];

  const sellReturnMutation = useMutation(create_sell_return_mutation, {
    onSuccess: () => {
      message.success('Sell return filed');
      if (!isEmbedded) {
        navigate('/sell');
      } else if (onSaveHandler) {
        onSaveHandler();
      }
    },
    onError: async (data: any) => {
      message.error(data.response.data.message);
    }
  });

  const handleReturn = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const formData: any[] = form.getFieldValue('lines');
      if (!sellDetails) return;
      const value = {
        sellId: sellDetails.id,
        date: JSON.stringify(new Date()).slice(1, -1),
        lines: formData.filter((item) => item.quantity > 0),
        note: form.getFieldValue('note'),
        debitReference: form.getFieldValue(['debitReference'])
      };
      // console.log(formData);
      // console.log(value);
      if (value.lines.length === 0) {
        message.error('Please select at least one product to return');
        setIsLoading(false);
        return;
      }

      await sellReturnMutation.mutateAsync(value);
    } catch (error: any) {
      if (isAxiosError(error)) return;
      if ('message' in error) message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeUnits();
  }, []);

  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Return Information"
          style={{
            padding: '8px 0px'
          }}
        />
        {sellDetails ? (
          <div className="card grid grid-cols-2 gap-5">
            <Text>Financial Reference : {sellDetails.financialReference}</Text>
            <Text>Customer : {sellDetails.vendorName}</Text>
            <Text>Location : {sellDetails.locationId}</Text>
            <Text>Total : {sellDetails.totalAmount}</Text>
          </div>
        ) : (
          <></>
        )}
        <Form form={form} component={false} initialValues={{ debitReference: '' }}>
          <Table
            bordered
            dataSource={data}
            columns={columns}
            pagination={false}
            size="small"
            scroll={{ y: 600, x: 1300 }}
          />
          <Divider />
          <Form.Item label="Debit Reference" name="debitReference">
            <Input placeholder="Enter debit reference here..." />
          </Form.Item>
          {/* add return message */}
          <Form.Item
            label="Return Reason"
            name="note"
            rules={[
              {
                required: true,
                message: 'Please input return message'
              }
            ]}>
            <Input.TextArea placeholder="Write a reason..." />
          </Form.Item>
          <div className="flex justify-end mt-5">
            <Button
              type="primary"
              onClick={handleReturn}
              disabled={editingKey !== undefined || !hasRule || isAccountDisabled}>
              Submit
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default SellReturn;
