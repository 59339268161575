import AppContent from '@/components/Common/Content/Content';
import { checkAccess } from '@/routes/acl';
import { get_hr_shift_details } from '@/services/hr/queries';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, PageHeader, Space, Spin, Typography } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import UsersDB from '@/store/localstorage/UsersDB';
import { getUser } from '@/services';
import VerticalForm from '@/components/FakeForm/VerticalForm';
import GenericTable from '@/components/Common/CustomizeTable';
import { IShiftLines } from '@/services/hr/types';
import { ColumnsType } from 'antd/lib/table';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';

function ViewShiftPage() {
  const params = useParams();
  const shiftId = Number(params.id);
  const navigate = useNavigate();

  if (isNaN(shiftId) || shiftId <= 0) return <Navigate to={'/hr/shift'} />;

  const { isLoading, isFetching, data } = useQuery(['get_shift_details', shiftId], async () => {
    const details = await get_hr_shift_details(shiftId);

    const userIds = details.users.map((u) => u.userId);
    const uniqueUserIds = Array.from(new Set(userIds));
    await UsersDB.addUsersIfAbsent(uniqueUserIds);

    const users = await Promise.all(
      details.users.map(async (user) => {
        const userDetail = await getUser(user.userId);
        return { ...user, name: userDetail.name, phone: userDetail.phone };
      })
    );

    return { ...details, users };
  });

  const columns: ColumnsType<IShiftLines> = [
    { title: 'S.N.', key: 'id', width: 5, render: (_, __, index) => index + 1 },
    { title: 'Day', key: 'name', dataIndex: 'dayOfWeek', width: 10 },
    { title: 'Start Time', key: 'startTime', dataIndex: 'startTime', width: 5 },
    { title: 'End Time', key: 'endTime', dataIndex: 'endTime', width: 5 },
    {
      title: 'Last Updated',
      key: 'updatedAt',
      width: 50,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    }
  ];

  return (
    <Spin spinning={isLoading || isFetching}>
      <AppContent breadcrumbItems={[{ label: 'Shift', link: '/hr/shift' }, { label: 'View' }]}>
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <PageHeader subTitle="Details" style={{ padding: '8px 0' }} />
            <Space direction="vertical" size="small">
              <Typography.Text>
                <strong>Name:</strong> {data?.shift.name}
              </Typography.Text>
              <Typography.Text>
                <strong>Type:</strong> {data?.shift.type}
              </Typography.Text>
              <Typography.Text>
                <strong>Checkout Type:</strong> {data?.shift.checkoutType}
              </Typography.Text>
            </Space>
          </div>

          <div>
            <PageHeader subTitle="Dates" style={{ padding: '8px 0' }} />
            <Space direction="vertical" size="small">
              <Typography.Text>
                <strong>From:</strong> {data?.shift.startDate}
              </Typography.Text>
              <Typography.Text>
                <strong>End:</strong> {data?.shift.endDate}
              </Typography.Text>
            </Space>
          </div>
        </div>

        <div className="mt-4">
          <PageHeader
            title="Shift Details"
            style={{ padding: '8px 0 2px' }}
            className="small-title"
          />
          <GenericTable
            data={data?.lines || []}
            columns={columns}
            hideDefaultPagination
            scroll={{ x: 600 }}
          />
        </div>

        <div>
          <PageHeader
            title="Assigned To"
            style={{ padding: '8px 0 2px' }}
            className="small-title"
          />
          {data?.users.length === 0 && (
            <Typography.Text>
              <strong>No users were assigned to this shift.</strong>
            </Typography.Text>
          )}

          {data && data.users.length > 0 && (
            <div>
              <p>This shift is assigned to following employees.</p>
              <Card style={{ maxHeight: '80vh', overflowY: 'scroll', borderRadius: '9px' }}>
                {data.users.map((user, index) => {
                  return (
                    <div key={user.id} className="flex gap-2 items-center">
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card  grid grid-cols-3 gap-3"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <VerticalForm label="User" value={user.name} />
                        <VerticalForm label="Phone" value={user.phone} />
                      </div>
                    </div>
                  );
                })}
              </Card>
            </div>
          )}
        </div>

        <div className="flex justify-end mt-5 gap-4">
          {checkAccess('UPDATE_HR_SHIFT') && (
            <Button type="primary" onClick={() => navigate(`/hr/shift/details/${shiftId}`)}>
              Edit Shift
            </Button>
          )}

          <Button type="default" onClick={() => navigate(-1)}>
            Go back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewShiftPage;
