import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { IUser } from '@/services/auth/types';
import { get_hr_group_details } from '@/services/hr/queries';
import getErrorMessage from '@/utils/getError';
import { Button, Card, PageHeader, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import UsersDB from '@/store/localstorage/UsersDB';
import { getUser } from '@/services';
import VerticalForm from '@/components/FakeForm/VerticalForm';
import isAxiosError from '@/utils/isAxiosError';

interface FormValues {
  name: string;
  users: { id: number; name: string; phone: string }[];
}

function ViewHRGroups() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState<FormValues>({ name: '', users: [] });

  const groupId = Number(params.id);
  if (isNaN(groupId)) return <Navigate to="/hr/groups" />;

  useEffect(() => {
    if (isNaN(groupId)) return;
    fetchInitialData();
  }, [groupId]);

  async function fetchInitialData() {
    try {
      setIsLoading(true);
      const details = await get_hr_group_details(groupId);
      const userIds = details.lines.map((u) => u.userId);
      await UsersDB.addUsersIfAbsent(userIds);

      const users: FormValues['users'] = [];
      for (const userId of userIds) {
        const user = (await getUser(userId)) as unknown as IUser;
        users.push({ id: user.id, name: user.name, phone: user.phone });
      }

      setData({ name: details.group.name, users });
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={[{ label: 'HR Group', link: '/hr/groups' }, { label: 'View' }]}>
        <PageHeader title="HR Group Information" style={{ padding: '8px 0px' }} />
        <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
          <VerticalForm label="Group Name" value={data.name} />
        </div>

        <Card style={{ maxHeight: '50vh', overflowY: 'scroll', borderRadius: '9px' }}>
          {data.users.map((user, index) => {
            return (
              <div key={user.id} className="flex gap-2 items-center">
                <span className="font-bold text-sm mb-5">{index + 1}.</span>
                <div
                  className="card  grid grid-cols-3 gap-3"
                  style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                  <VerticalForm label="User" value={user.name} />
                  <VerticalForm label="Phone" value={user.phone} />
                </div>
              </div>
            );
          })}
        </Card>

        <div className="flex justify-end mt-5">
          <Button type="default" onClick={() => navigate('/hr/groups')}>
            Go Back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewHRGroups;
