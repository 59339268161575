import React, { useRef, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { Button, Form, Menu, Spin, Tooltip, message } from 'antd';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ISystemLogs, IVendorLogs, IVendorLogsResult } from '@/services/logs/types';
import { get_log_events_ids } from '@/services/logs/events/queries';
import { ConvertObjectToURL } from '@/utils/converturl';
import { get_vendor_logs_list } from '@/services/logs/queries';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { UsersSearch } from '@/components/Common/UsersSearch';
import { EventSearch } from '@/components/Common/EventSearch';
import { ExportColumnType } from '@/utils/exportExcel';
import { Excel } from 'antd-table-saveas-excel';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTableForIrd from '@/components/Common/CustomizeTable/CustomTableForIrd';
import { useReactToPrint } from 'react-to-print';
import CopyButton from '@/components/Common/CopyButton';
import { optionalNumberSorter, optionalStringSorter } from '@/utils/sorter.utils';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import { createEmptyPromise } from '@/utils/createEmptyPromise';
import { exportExcelAutoWidth } from '@/utils/exportExcelAutoWidth';

const VendorLogsList: React.FC = () => {
  const [form] = Form.useForm();
  const printPDFRef = useRef<any>();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullDate, setFullDate] = useState<string>('');
  const [fullYear, setFullYear] = useState<string>('');
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const [vendorLogs, setVendorLogs] = useState<IVendorLogsResult>({
    total: 0,
    results: []
  });
  const [exportData, setExportData] = useState<IVendorLogs[]>([]);

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const vendorLogsLists = await get_vendor_logs_list(filter);
    const fetchEventName = new Map<number, number[]>();

    const absentUsers = [] as number[];

    for (let index = 0; index < vendorLogsLists.results.length; index++) {
      const events = vendorLogsLists.results[index];
      if (events.userId) {
        const createdUser = await UsersDB.getUser(events.userId);
        if (!createdUser && !absentUsers.includes(events.userId)) absentUsers.push(events.userId);
        events.userName = createdUser?.name;
      }

      if (events.eventsId) {
        if (fetchEventName.has(events.eventsId)) {
          fetchEventName.get(events.eventsId)?.push(index);
        } else {
          fetchEventName.set(events.eventsId, [index]);
        }
      }
      if (events.responseBody) {
        events.invoice = JSON.parse(events.responseBody).invoice;
        events.printCount = JSON.parse(events.responseBody).printCount;
      } else {
        events.invoice = '';
        events.printCount = undefined;
      }
    }

    const fetchEventNameLength = fetchEventName.size;

    const usersPromise =
      absentUsers.length > 0
        ? get_user_list_ids([...new Set(absentUsers)])
        : createEmptyPromise({ data: { total: 0, results: [] } });

    const fetchEventNamePromises =
      fetchEventNameLength > 0
        ? get_log_events_ids(Array.from(fetchEventName.keys()))
        : createEmptyPromise({ total: 0, results: [] });

    const [users, eventNames] = await Promise.all([usersPromise, fetchEventNamePromises]);
    await UsersDB.addUsers(users.data.results);

    for (let index = 0; index < vendorLogsLists.results.length; index++) {
      const events = vendorLogsLists.results[index];
      const createdBy = events.userId;

      if (createdBy && absentUsers.includes(createdBy)) {
        const createdUser = await UsersDB.getUser(createdBy);
        events.userName = createdUser?.name;
      }
    }

    if (fetchEventNameLength > 0) {
      const fetchEventList = eventNames;
      for (const entry of Array.from(fetchEventName.entries())) {
        const key = entry[0];
        const value = entry[1];

        const eventName = fetchEventList?.results.find((curEvent: any) => curEvent.id == key);
        if (!eventName) continue;

        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          vendorLogsLists.results[element].eventsName = eventName?.url;
        }
      }
    }
    //console.log('vendorLogsLists', vendorLogsLists);

    const updatedData = vendorLogsLists.results.map((value: any) => {
      return {
        ...value,
        createdAt: convertUTCStringtoLocalString(value.createdAt, DEFAULT_DATE_FORMAT),
        startTime: convertUTCStringtoLocalString(value.startTime, DEFAULT_DATE_FORMAT),
        endTime: convertUTCStringtoLocalString(value.endTime, DEFAULT_DATE_FORMAT)
      };
    });
    const startYear = form.getFieldValue(['startDateNepali']).split('-')[0];
    const startDate = form.getFieldValue(['startDateNepali']);
    const endYear = form.getFieldValue(['endDateNepali']).split('-')[0];
    const endDate = form.getFieldValue(['endDateNepali']);
    const fullYear = startYear === endYear ? startYear : startYear + ' to ' + endYear;
    const fullDate = startYear === endYear ? startDate : startDate + ' to ' + endDate;
    setFullDate(fullDate);
    setFullYear(fullYear);
    setVendorLogs(vendorLogsLists);
    setExportData(updatedData);
    setIsLoading(false);
  };
  const onSubmitFilter = async (val: string) => {
    const values = form.getFieldsValue();

    const dateDiff = moment(values.endDate).diff(values.startDate, 'days');
    if (dateDiff > 30) {
      CustomErrorModal({
        message: `${dateDiff} days selected.Please navigate to Backup to view data more than 30 days!`
      });
      setIsLoading(false);
      return;
    }

    const event = form.getFieldValue(['eventsId']);
    const user = form.getFieldValue(['userId']);
    if (!event && !user) {
      message.warning('Event or User is required!');
      setFullDate('');
      setFullYear('');
      setVendorLogs({ total: 0, results: [] });
      setExportData([]);
      setIsLoading(false);
      return;
    }

    getInfo(val);
    setPage(1);
    setSize(10);
  };

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
    setIsLoading(false);
  };

  const breadcrumbItems = [
    {
      label: 'Logs',
      link: '/ird/logs'
    }
  ];
  const columns: ColumnsType<ISystemLogs> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'createdAt',
      width: 6,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },

    {
      title: 'Event',
      key: 'eventsName',
      width: 5,
      sorter: (a, b) => {
        return a.eventsName.localeCompare(b.eventsName);
      },
      sortOrder: sortedInfo.columnKey === 'eventsName' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return <TableCell>{record.eventsName}</TableCell>;
      }
    },

    {
      title: 'User',
      key: 'user',
      width: 3,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },

    {
      title: 'Method',
      key: 'method',
      width: 3,
      sorter: (a, b) => {
        return a.method.localeCompare(b.method);
      },
      sortOrder: sortedInfo.columnKey === 'method' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return <TableCell>{record.method}</TableCell>;
      }
    },

    {
      title: 'URL',
      key: 'url',
      width: 8,
      sorter: (a, b) => {
        return a.url.localeCompare(b.url);
      },
      sortOrder: sortedInfo.columnKey === 'url' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return <TableCell>{record.url}</TableCell>;
      }
    },
    {
      title: 'Status Code',
      key: 'statusCode',
      width: 3,
      sorter: (a, b) => a.statusCode - b.statusCode,
      sortOrder: sortedInfo.columnKey === 'statusCode' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.statusCode}</TableCell>;
      }
    },
    {
      title: 'Invoice Number',
      key: 'invoice',
      width: 8,
      className: 'invoice',
      sorter: (a, b) => {
        return optionalStringSorter(a.invoice, b.invoice);
      },
      sortOrder: sortedInfo.columnKey === 'invoice' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return (
          <TableCell>
            {record.invoice}
            {record.invoice ? <CopyButton text={record.invoice} /> : null}
          </TableCell>
        );
      }
    },
    {
      title: 'Print Count',
      key: 'printCount',
      width: 3,
      sorter: (a, b) => {
        return optionalNumberSorter(a.printCount, b.printCount);
      },
      sortOrder: sortedInfo.columnKey === 'printCount' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.printCount}</TableCell>;
      }
    },
    {
      title: 'Content Length',
      key: 'contentLength',
      width: 3,
      sorter: (a, b) => a.contentLength - b.contentLength,
      sortOrder: sortedInfo.columnKey === 'contentLength' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.contentLength}</TableCell>;
      }
    },
    {
      title: 'Response Time',
      key: 'responseTime',
      width: 3,
      sorter: (a, b) => a.responseTime - b.responseTime,
      sortOrder: sortedInfo.columnKey === 'responseTime' ? sortedInfo.order : null,
      render: (record: ISystemLogs) => {
        return <TableCell>{record.responseTime}</TableCell>;
      }
    },

    {
      title: 'Start Time',
      key: 'startTime',
      width: 4,
      sorter: (a, b) => {
        return a.startTime.localeCompare(b.startTime);
      },
      sortOrder: sortedInfo.columnKey === 'startTime' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return (
          <TableCell>
            {' '}
            {convertUTCStringtoLocalString(record.startTime, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'End Time',
      key: 'endTime',
      width: 4,
      sorter: (a, b) => {
        return a.endTime.localeCompare(b.endTime);
      },
      sortOrder: sortedInfo.columnKey === 'endTime' ? sortedInfo.order : null,

      render: (record: ISystemLogs) => {
        return (
          <TableCell>
            {' '}
            {convertUTCStringtoLocalString(record.endTime, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    }
  ];

  const handleExcelExport = () => {
    setIsLoading(true);
    try {
      const excelColumns = [
        { title: 'Date', dataIndex: 'createdAt' },
        { title: 'User', dataIndex: 'userName' },
        { title: 'Method', dataIndex: 'method' },
        { title: 'URL', dataIndex: 'eventsName' },
        { title: 'Status Code', dataIndex: 'statusCode' },
        { title: 'Invoice Number', dataIndex: 'invoice' },
        { title: 'Print Count', dataIndex: 'printCount' },
        { title: 'Content Length', dataIndex: 'contentLength' },
        { title: 'Response Time', dataIndex: 'responseTime' },
        { title: 'Start Time', dataIndex: 'startTime' },
        { title: 'End Time', dataIndex: 'endTime' }
      ];
      if (vendorLogs.results.length === 0) {
        message.error('No Data to Export');
        setIsLoading(false);
        return;
      }

      // exportExcelAutoWidth(excelColumns, exportData, 'Logs List');

      exportExcelAutoWidth(excelColumns, vendorLogs.results, 'Logs List');
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: any = [
    {
      title: 'Date',
      width: 20,
      dataIndex: 'createdAt',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'User',
      width: 20,
      dataIndex: 'userName',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'Method',
      width: 15,
      dataIndex: 'method',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'URL',
      width: 15,
      dataIndex: 'eventsName',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'Status Code',
      width: 15,
      dataIndex: 'statusCode',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'Invoice Number',
      width: 20,
      dataIndex: 'invoice',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'Print Count',
      width: 10,
      dataIndex: 'printCount',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'Content Length',
      width: 15,
      dataIndex: 'contentLength',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'Response Time',
      width: 15,
      dataIndex: 'responseTime',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'Start Time',
      width: 20,
      dataIndex: 'startTime',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      title: 'End Time',
      width: 20,
      dataIndex: 'endTime',
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        width="90%"
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="Log List ">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableForIrd
            columns={columsforPrint}
            datas={exportData}
            reff={printPDFRef}
            title={'Log List'}
            //subTitle="(Rule 23 Sub Rule (1) (a))"
            date={fullDate}
            year={fullYear}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <TableFilter
            onPagination={(page, size) => {
              setPage(page);
              setSize(size);
            }}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 10
            }}
            initial={false}
            onSubmit={onSubmitFilter}
            form={form}
            styleforbuttons={'flex justify-end items-center'}
            style={
              'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
            }
            buttons={<></>}>
            <EventSearch
              formData={{ formName: 'eventsId', label: 'Event' }}
              isAll={false}
              required={false}
            />
            <UsersSearch required={false} formData={{ formName: 'userId' }} isAll={false} />
          </TableFilter>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExcelExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (vendorLogs.results.length === 0) {
                            message.error('No Data to Export');
                            setIsLoading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <CustomizeTable
          form={form}
          columns={columns}
          data={vendorLogs.results}
          //usersLoading={isLoading}
          notshowPagination={true}
          customScroll={{ x: 1500, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: vendorLogs.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default VendorLogsList;
