import { AxiosResponse } from 'axios';
import {
  ICustomerPriceGroupCreatePayload,
  ICustomerPriceGroups,
  ICustomerPriceGroupUpdatePayload
} from './types';
import http from '@/utils/http.utils';

export const create_customers_price_group_mutation = async (
  values: ICustomerPriceGroupCreatePayload
) => {
  const response: AxiosResponse<ICustomerPriceGroups> = await http.store(
    'customer-prices/groups',
    values
  );
  return response.data;
};

export const update_customers_price_group_mutation = async ({
  id,
  ...values
}: ICustomerPriceGroupUpdatePayload) => {
  const response: AxiosResponse<ICustomerPriceGroups> = await http.update(
    'customer-prices/groups/' + id,
    values
  );
  return response.data;
};

export const delete_customers_price_group_mutation = async (id: number) => {
  const response = await http.remove('customer-prices/groups/' + id);
  return response.data;
};

export const upload_customer_price_group_mutation = async (values: {
  id: number;
  file: FormData;
}) => {
  const response = await http.store('customer-prices/upload/' + values.id, values.file, {
    showErrorModal: false
  });
  return response.data;
};
