import React, { useState, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Input, message, Menu, Form, TableProps, Tooltip, Spin, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import { get_invoices_list } from '@/services/settings/queries';

import { get_adjustments_list_filter, get_reasons_list } from '@/services/adjustments/queries';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import { ConvertObjectToURL } from '@/utils/converturl';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { SorterResult } from 'antd/lib/table/interface';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';

import ActionDropdown from '@/components/Common/Dropdownactions';
import CopyButton from '@/components/Common/CopyButton';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { IAdjustmentData } from '@/services/adjustments/types';
import {
  approved_adjustments_mutation,
  reject_adjustments_mutation
} from '@/services/adjustments/mutations';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { IAdjustmentInvoice } from '@/services/invoice/types';

import { getAdjustmentPrintData } from '@/components/Common/InvoicePrint/AdjustmentInvoice/services';
import AdjustmentInvoice from '@/components/Common/InvoicePrint/AdjustmentInvoice/AdjustmentInvoice';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import { MultipleAdjustmentApprove } from '@/components/Common/CustomModal/MultipleAdjustmentApprove';
import { find_locationId_preference } from '@/store/localstorage/preferences';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import CategoryDB from '@/store/localstorage/CategoryDB';
import { getCategoryById } from '@/services';
import { exportExcelAutoWidth } from '@/utils/exportExcelAutoWidth';

interface DataType {
  id: number;
  name: string;
  age: number;
  address: string;
  description: string;
}

const AdjustmentsList: React.FC = () => {
  const { Option } = Select;
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const dispatch = useDispatch();
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const [allAdjustments, setAllAdjustments] = useState<IAdjustmentData>({
    total: 0,
    results: []
  });
  const [invoiceData, setInvoiceData] = useState<IAdjustmentInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [openModalForApproveSelected, setOpenModalForApproveSelected] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const onSubmitFilter = async (val: string) => {
    // console.log('values', val);
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  const approveAdjustment = useMutation(approved_adjustments_mutation);
  const rejectAdjustment = useMutation(reject_adjustments_mutation);
  const [selectedRows, setselectedRows] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const preferenceLocationId = find_locationId_preference();
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      // console.log('selectedRows', selectedRowKeys);
      if (selectedRows.length <= 10) {
        setselectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
      } else {
        message.error('Only 10 data can be approved at a time!');
        setselectedRows(selectedRows.slice(0, 10));
        setSelectedRowKeys(selectedRowKeys.slice(0, 10));
      }
    }
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_adjustments_list_filter(filter);
    const createdByUsers: any = {};
    const updatedByUsers: any = {};
    if (response?.data.results) {
      const reasonData = await get_reasons_list();
      // console.log(reasonData);
      for (let index = 0; index < response.data.results.length; index++) {
        if (response.data.results[index].addedBy) {
          const createdByuser: any = await UsersDB.getUser(response.data.results[index].addedBy);
          if (!createdByuser) {
            if (response.data.results[index].addedBy in createdByUsers) {
              createdByUsers[response.data.results[index].addedBy] = [
                ...createdByUsers[response.data.results[index].addedBy],
                index
              ];
            } else {
              createdByUsers[response.data.results[index].addedBy] = [index];
            }
          } else {
            response.data.results[index].createdByName = createdByuser.name;
          }
        }

        if (response.data.results[index].updatedBy) {
          const updatedByUser: any = await UsersDB.getUser(response.data.results[index].updatedBy);
          if (!updatedByUser) {
            if (response.data.results[index].updatedBy in updatedByUsers) {
              createdByUsers[response.data.results[index].updatedBy] = [
                ...createdByUsers[response.data.results[index].updatedBy],
                index
              ];
            } else {
              createdByUsers[response.data.results[index].updatedBy] = [index];
            }
          } else {
            response.data.results[index].updatedByName = updatedByUser.name;
          }
        }

        let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
        if (!locD) {
          locD = await get_location_details(response.data.results[index].locationId);
          await LocationsDB.addLocations([locD]);
        }
        response.data.results[index].locationName = locD.name;
        response.data.results[index].reason = reasonData.data.find(
          (value: any) => value.id === response.data.results[index].reasonId
        )?.name;
        response.data.results[index].date = convertUTCStringtoLocalString(
          response.data.results[index].createdAt,
          'YYYY-MM-DD'
        );
      }

      const createdByUserslength = Object.entries(createdByUsers).length;

      if (createdByUserslength > 0) {
        const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
        for (const key in createdByUsers) {
          const createdByUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          if (!createdByUser) continue;
          for (let i = 0; i < createdByUsers[key].length; i++) {
            response.data.results[createdByUsers[key][i]].createdByName = createdByUser.name;
          }
          await UsersDB.addUsers([createdByUser]);
        }
      }
    }

    const allCategoryId = response.data.results.map((c) => c.categoryId);
    await CategoryDB.addCategoryIfAbsent(allCategoryId);

    for (const index in response.data.results) {
      const data = response.data.results[index];
      const category = await getCategoryById(data.categoryId);
      if (!category) continue;
      response.data.results[index].categoryName = category.name;
    }

    setAllAdjustments(response.data);
    setIsloading(false);
  };

  const generateInvoiceForPrint = async (record: any, curr: any) => {
    setIsloading(true);
    const invoiceModalData = await getAdjustmentPrintData(
      record.id,
      record.status,
      record.reason,
      record.locationId,
      curr
    );
    setIsloading(false);

    setInvoiceData(invoiceModalData);

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = async () => {
    setOpenModalForInvoicePrint(false);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 10,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Id',
      key: 'id',
      width: 15,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Financial Reference.',
      key: 'financialReference',
      className: 'invoice',
      width: 50,
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs">
            <Link to={`/adjustments/${record.id}`} color="black">
              {record.financialReference}
            </Link>
            <CopyButton text={record.financialReference} />
          </TableCell>
        );
      }
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 40,
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/adjustments/${record.id}`} color="black">
          <TableCell>{record.locationName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Category',
      key: 'categoryName',
      width: 25,
      dataIndex: 'categoryName',
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      sortOrder: sortedInfo.columnKey === 'categoryName' ? sortedInfo.order : null
    },
    {
      title: 'Created By',
      key: 'addedBy',
      width: 25,
      sorter: (a, b) => a.createdByName.localeCompare(b.createdByName),
      sortOrder: sortedInfo.columnKey === 'addedBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },
    {
      title: 'Updated By',
      key: 'updatedBy',
      width: 26,
      sorter: (a, b) => a.updatedByName.localeCompare(b.updatedByName),
      sortOrder: sortedInfo.columnKey === 'updatedBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.updatedByName}</TableCell>;
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 35,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/adjustments/${record.id}`} color="black">

          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Reason',
      key: 'reason',
      width: 30,
      sorter: (a, b) => a.reason.localeCompare(b.reason),
      sortOrder: sortedInfo.columnKey === 'reason' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/adjustments/${record.id}`} color="black">
          <TableCell>{record.reason}</TableCell>

          // </Link>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 13,
      fixed: 'right',
      render: (record) => {
        const addMenu = [];
        if (checkAccess('UPDATE_ADJUSTMENT_STATUS') && record.status === 'PENDING') {
          addMenu.push(
            {
              key: '4',
              label: (
                <Tooltip title="Approve" color="blue" className="font-bold">
                  Approve
                </Tooltip>
              ),
              onClick: () => {
                // console.log('this is called');
                // handleApprove(record.id);
                navigate('/adjustments/approve/' + record.id);
              }
            },
            {
              key: '5',
              label: (
                <Tooltip title="Reject" color="blue" className="font-bold">
                  Reject
                </Tooltip>
              ),
              onClick: () => {
                // console.log('this is called');
                handleReject(record.id);
              }
            }
          );
        }
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: <DiscussionChatModal slug="adjustments" id={record.id} />
              },
              {
                key: '2',
                label: (
                  <InvoicePrintButton
                    getInvoice={(curr) => {
                      generateInvoiceForPrint(record, curr);
                    }}
                  />
                )
              },
              {
                key: '3',
                label: (
                  <CustomViewIcon link={`/adjustments/${record.id}/?status=${record.status}`} />
                )
              },
              ...addMenu
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Adjustments',
      link: '/adjustments'
    }
  ];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // const response = await get_adjustments_list_filter(url);
    // setAllAdjustments(response.data);
    getInfo(url);
    setIsloading(false);
    // ProductsDB.addProducts(productResponse.data.results);
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns = [
        { title: 'S.N', dataIndex: 'SN' },
        { title: 'ID', dataIndex: 'id' },
        { title: 'Financial Reference.', dataIndex: 'financialReference' },
        { title: 'AddedBy', dataIndex: 'createdByName' },
        { title: 'Updated By', dataIndex: 'updatedByName' },
        { title: 'Location', dataIndex: 'locationName' },
        { title: 'Category', dataIndex: 'categoryName' },
        { title: 'Total Amount', dataIndex: 'totalAmount' },
        { title: 'Reason', dataIndex: 'reason' },
        { title: 'Date', dataIndex: 'date' }
      ];
      if (allAdjustments.results.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allAdjustments.results.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1,
          date: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
        };
      });

      exportExcelAutoWidth(columns, dataUpdated, 'Adjustment List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: any = [
    {
      label: 'ID',
      dataIndex: 'id',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Financial Reference.',
      dataIndex: 'financialReference',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Location',
      dataIndex: 'locationName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Category',
      dataIndex: 'categoryName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Reason',
      dataIndex: 'reason',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Date',
      dataIndex: 'date',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'CreatedBy',
      dataIndex: 'createdByName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Updated By',
      dataIndex: 'updatedByName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  const handleReject = async (id: number | string) => {
    setIsloading(true);
    await rejectAdjustment.mutateAsync(id, {
      onSuccess: async ({ data }: { data: any }) => {
        if (data) {
          setIsloading(false);
          message.success('Adjustment Rejected!');
          const values = form.getFieldsValue();
          values.endDate = convertLocalToUTCString(values.endDate);
          values.startDate = convertLocalToUTCString(values.startDate);
          delete values.dateCustom;
          delete values.startDateNepali;
          delete values.endDateNepali;
          const filter = ConvertObjectToURL(values);
          getInfo(filter);
        }
      },
      onError: (e: any) => {
        message.error(`${e.response.data.message}`, 5);
        setIsloading(false);
      }
    });
  };

  const handleApproveSelected = () => {
    setOpenModalForApproveSelected(true);
  };

  const handleApproveSelectedModalClose = async () => {
    setOpenModalForApproveSelected(false);
    setselectedRows([]);
    setSelectedRowKeys([]);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    values.skip = 0;
    values.count = size;
    setPage(1);
    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <Spin spinning={isloading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Adjustment Invoice Print">
        <AdjustmentInvoice
          adjustmentDetails={invoiceData.adjustmentDetails}
          lines={invoiceData.lines}
          locationDetails={invoiceData.locationDetails}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
      <CustomModal
        footer={false}
        maskClosable={false}
        isModalOpen={openModalForApproveSelected}
        setIsModalOpen={setOpenModalForApproveSelected}
        isSticky={true}
        title="Multiple Adjustment Approve">
        <MultipleAdjustmentApprove
          data={selectedRows}
          handleModalClose={handleApproveSelectedModalClose}
        />
      </CustomModal>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Adjustment List View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allAdjustments.results}
            reff={printPDFRef}
            title={'Adjustment List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            {' '}
            <div>
              <TableFilter
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  skip: 0,
                  count: 100,
                  status: 'APPROVED',
                  locationId: preferenceLocationId ? preferenceLocationId : 1
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    {checkAccess('CREATE_ADJUSTMENT') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate(`/adjustments/new`)}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/adjustments/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name={'status'} label="Status">
                  <Select
                    placeholder="Select Status"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    // onChange={() => handleChange()}
                    dropdownMatchSelectWidth={false}>
                    <Option value={'PENDING'}>Pending</Option>
                    <Option value={'APPROVED'}>Approved</Option>
                    <Option value={'REJECTED'}>Rejected</Option>
                  </Select>
                </Form.Item>
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={'locationId'}
                  label="Location"
                  showAll
                />
                <ProductCategorySearchV2 hasParentFormItem={false} name={'categoryId'} showAll />
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div className="flex">
            {selectedRows.length > 0 && form.getFieldValue(['status']) === 'PENDING' && (
              <CustomButton
                onClick={() => {
                  handleApproveSelected();
                }}
                text="Approve Selected"
                backgroundColor="#1890ff"
              />
            )}
          </div>
        </div>
        <CustomizeTable
          columns={columns}
          form={form}
          data={allAdjustments.results}
          buttons={
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        setOpenModalforexport(true);
                      }
                    }
                  ]}
                />
              }
            />
          }
          // usersLoading={isloading}
          rowSelection={form.getFieldValue(['status']) === 'PENDING' ? rowSelection : undefined}
          rowSelectionWidth={'10px'}
          notshowPagination={true}
          paginationDatas={{
            page: page,
            total: allAdjustments.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'adjustments-list'}
        />
      </AppContent>
    </Spin>
  );
};

export default AdjustmentsList;
