import { Form, Input, Menu, Spin, TableProps, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConvertObjectToURL } from '@/utils/converturl';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import { checkAccess } from '@/routes/acl';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { IBankResponse, IBankTable, IBankTableData } from '@/services/bank/types';
import { get_bank_list_filter } from '@/services/bank/queries';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import moment from 'moment';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { get_user_list_ids } from '@/services/users/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { EditOutlined } from '@ant-design/icons';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const BankList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [bankList, setBankList] = useState<IBankResponse>(Object);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Bank',
      link: '/bank'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_bank_list_filter(filter);
    // console.log('Response --->', response);
    if (response) {
      const tableData: IBankTable = response;
      for (let ind = 0; ind < tableData.results.length; ind++) {
        if (tableData.results[ind].createdBy !== null) {
          let createdByDetails = await UsersDB.getUser(tableData.results[ind].createdBy);
          if (!createdByDetails) {
            const allUser = await get_user_list_ids([
              ...new Set(
                tableData.results
                  .filter((value) => value.createdBy !== null)
                  .map((value) => {
                    return value.createdBy;
                  })
              )
            ]);
            await UsersDB.addUsers(allUser.data.results);
            createdByDetails = await UsersDB.getUser(tableData.results[ind].createdBy);
          }
          tableData.results[ind].createdByName = createdByDetails.name;
        }
      }
      setBankList(tableData);
    }

    setIsLoading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const columns: ColumnsType<IBankTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 20,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.name}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Account No.',
      key: 'accountNumber',
      width: 15,
      sorter: (a, b) => a.accountNumber.localeCompare(b.accountNumber),
      sortOrder: sortedInfo.columnKey === 'accountNumber' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.accountNumber}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Account Name',
      key: 'accountName',
      width: 15,
      sorter: (a, b) => a.accountName.localeCompare(b.accountName),
      sortOrder: sortedInfo.columnKey === 'accountName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.accountName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Address',
      key: 'address',
      width: 15,
      sorter: (a, b) => a.address.localeCompare(b.address),
      sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.address}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created By',
      key: 'createdByName',
      width: 10,
      sorter: (a, b) =>
        a.createdByName && b.createdByName ? a.createdByName.localeCompare(b.createdByName) : 0,
      sortOrder: sortedInfo.columnKey === 'createdByName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.createdByName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: () => void;
        }[] = [];

        if (checkAccess('READ_BANK')) {
          menuItems.push({
            key: '1',
            label: <CustomViewIcon link={'/bank/view/' + record.id} />
          });
        }

        if (checkAccess('UPDATE_BANK')) {
          menuItems.push({
            key: '2',
            label: (
              <Link to={`/bank/${record.id}`}>
                <Tooltip title="Update" color="blue">
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              </Link>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                dateCustom={false}
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_BANK') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/bank/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/bank/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={bankList.results}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: bankList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'bank-list'}
        />
      </AppContent>
    </Spin>
  );
};

export default BankList;
