import { Button, Checkbox, Form, Input, InputNumber, PageHeader, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { getUserData } from '../../../utils/auth.utils';
import { IAccountTypeResponseData, ICreateAccounts } from '../../../services/accounts/types';
import { useMutation } from '@tanstack/react-query';
import { create_account_type_mutation } from '../../../services/accounts/mutations';
import { AccountsTypeSearch } from '../AccountsTypeSearch';
import { LocationSearch } from '../LocationSearch/LocationSearch';

interface ModalProps {
  handleModalClose: () => void;
}

const ReusableAccountCreate: React.FC<ModalProps> = ({ handleModalClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [locationId, setLocationId] = useState<number>();
  const [parentAccountList, setParentAccountList] = useState<IAccountTypeResponseData[]>([]);

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  form.setFieldsValue({
    name: '',
    balance: 0,
    parentId: null,
    locationId: preferenceLocationId ? preferenceLocationId : 1,
    isSubLedger: false
  });

  useEffect(() => {
    handleLocationChange();
  }, [preferenceLocationId]);

  const onFinish = async (values: ICreateAccounts) => {
    setIsLoading(true);
    //console.log('Values', values);
    await createAccountsMutation.mutate(values);
  };

  const createAccountsMutation = useMutation(create_account_type_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Account added successfully');
      handleModalClose();
      setIsLoading(false);
    },
    onError: (e: any) => {
      setIsLoading(false);
    }
  });

  const handleLocationChange = () => {
    // console.log('locationId', form.getFieldValue(['locationId']));
    form.resetFields(['parentId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        initialValues={{
          balance: 0,
          rules: 'CREDIT_ADD',
          isSubLedger: false,
          locationId: preferenceLocationId ? preferenceLocationId : 1
        }}
        onFinish={onFinish}
        disabled={isLoading}
        layout="vertical"
        validateTrigger={'onChange'}
        autoComplete="off">
        <PageHeader
          title="Accounts Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
          <Form.Item
            label="Account Name"
            name="name"
            rules={[{ required: true, message: 'Please add Account Name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Balance" name="balance">
            <InputNumber controls={false} defaultValue={0} />
          </Form.Item>
          {/* <Form.Item
              label="Rules"
              name="rules"
              rules={[{ required: true, message: 'Please add a rule!' }]}>
              <Select defaultValue={'CREDIT_ADD'}>
                <Select.Option value="CREDIT_ADD">{AccountRule.CREDIT_ADD}</Select.Option>
                <Select.Option value="CREDIT_SUBTRACT">{AccountRule.CREDIT_SUBTRACT}</Select.Option>
                <Select.Option value="DEBIT_ADD">{AccountRule.DEBIT_ADD}</Select.Option>
                <Select.Option value="DEBIT_SUBTRACT">{AccountRule.DEBIT_SUBTRACT}</Select.Option>
              </Select>
            </Form.Item> */}
          <AccountsTypeSearch
            formData={{ formName: 'parentId', label: 'Parent Account' }}
            isAll={false}
            accountList={parentAccountList}
            setAccountList={setParentAccountList}
            required={true}
            locationId={locationId}
          />
          <LocationSearch
            formData={{ formLabel: 'Location', formName: 'locationId' }}
            required={true}
            notAll={true}
            onSelect={() => handleLocationChange()}
          />
          <Form.Item name="isSubLedger" label="Account is Sub-Ledger?" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </div>
        <div className="flex justify-end mt-5">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default ReusableAccountCreate;
