export function safeStringify(data: unknown): string | undefined {
  try {
    // Handle objects and arrays by stringifying them
    if (typeof data === 'object' && data !== null) {
      return JSON.stringify(data);
    }

    // Convert primitive types (string, number, boolean) to string
    if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
      return String(data);
    }

    // For null, return undefined
    return undefined;
  } catch (error) {
    // If stringify fails, gracefully handle it
    console.error('Failed to stringify data:', error);
    return undefined;
  }
}
