import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IUserData } from '../users/types';
import {
  IAttendance,
  IAttendanceList,
  IHRCombinedData,
  IHRGroups,
  IHRGroupsDetails,
  IHRGroupsList,
  IHRHolidayDetails,
  IHRHolidayList,
  IHRLeaves,
  IHRLeavesList,
  IHRShift,
  IHRShiftList,
  IHRUserInfo,
  IHRUserList,
  IPayrollDetails,
  IPayrollList,
  IShiftDetails
} from './types';
import { getDataForIds } from '@/utils/queries.utils';

export const get_hr_list = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IUserData> = await http.get('users/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};

export const get_hr_user_info_list = async (filter = '') => {
  const response: AxiosResponse<IHRUserList> = await http.get('hr-user-info/?' + filter);
  return response.data;
};

export const get_hr_user_info_userId = async (userId: number) => {
  const response: AxiosResponse<IHRUserList> = await http.get('hr-user-info', { userId });
  const data = response.data;
  if (data.results.length > 0) {
    return data.results[0];
  }

  return null;
};

export const get_hr_user_info_details = async (id: number) => {
  const response: AxiosResponse<IHRUserInfo> = await http.get(`hr-user-info/${id}`);
  return response.data;
};

export const get_hr_holiday_list = async (filter = '') => {
  const response: AxiosResponse<IHRHolidayList> = await http.get('hr-holiday/?' + filter);
  return response.data;
};

export const get_hr_holiday_details = async (id: number) => {
  const response: AxiosResponse<IHRHolidayDetails> = await http.get(`hr-holiday/${id}`);
  return response.data;
};

export const get_hr_leave_list = async (filter = '') => {
  const response: AxiosResponse<IHRLeavesList> = await http.get('hr-leaves/?' + filter);
  return response.data;
};

export const get_hr_leave_details = async (id: number) => {
  const response: AxiosResponse<IHRLeaves> = await http.get(`hr-leaves/${id}`);
  return response.data;
};

export const get_hr_group_list = async (filter = '') => {
  const response: AxiosResponse<IHRGroupsList> = await http.get('hr-groups/?' + filter);
  return response.data;
};

export const get_hr_group_list_only = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IUserData> = await http.get('hr-groups/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_hr_group_ids = async (ids: number[]) => {
  return await getDataForIds<IHRGroups>(ids, 'hr-groups/?');
};

export const get_hr_group_details = async (id: number) => {
  const response: AxiosResponse<IHRGroupsDetails> = await http.get(`hr-groups/${id}`);
  return response.data;
};

export const get_hr_shift_list = async (filter = '') => {
  const response: AxiosResponse<IHRShiftList> = await http.get('hr-shift/?' + filter);
  return response.data;
};

export const get_hr_shift_list_v2 = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IUserData> = await http.get('hr-shift/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_hr_shift_ids = async (ids: number[]) => {
  return await getDataForIds<IHRShift>(ids, 'hr-shift/?');
};

export const get_hr_shift_details = async (id: number) => {
  const response: AxiosResponse<IShiftDetails> = await http.get(`hr-shift/${id}`);
  return response.data;
};

export const get_hr_shift_by_user_and_date = async (userId: number, startDate: string) => {
  const response: AxiosResponse<IHRShift[]> = await http.get('hr-shift/shift-by-user-and-date', {
    userId,
    startDate
  });
  return response.data;
};

export const get_attendance_list = async (filter = '') => {
  const response: AxiosResponse<IAttendanceList> = await http.get('hr-attendance/?' + filter);
  return response.data;
};

export const get_self_attendance_list = async (filter = '') => {
  const response: AxiosResponse<IAttendanceList> = await http.get('hr-attendance/self/?' + filter);
  return response.data;
};

export const get_attendance_details = async (id: number) => {
  const response: AxiosResponse<IAttendance> = await http.get(`hr-attendance/${id}`);
  return response.data;
};

// Payroll Queries

export const get_payroll_list = async (filter = '') => {
  const response: AxiosResponse<IPayrollList> = await http.get('hr-payroll/?' + filter);
  return response.data;
};

export const get_payroll_details = async (id: number) => {
  const response: AxiosResponse<IPayrollDetails> = await http.get(`hr-payroll/${id}`);
  return response.data;
};

export const get_hr_self_calendar_data = async (filter = '') => {
  const response: AxiosResponse<IHRCombinedData> = await http.get('hr/self/?' + filter);
  return response.data;
};

export const get_hr_user_calendar_data = async (id: number, filter = '') => {
  const response: AxiosResponse<IHRCombinedData> = await http.get(`hr/${id}/?` + filter);
  return response.data;
};
