import { EWeekDays } from '@/services/hr/enum';

export const dayOfWeekMap: { [key in EWeekDays]: number } = {
  [EWeekDays.SUNDAY]: 0,
  [EWeekDays.MONDAY]: 1,
  [EWeekDays.TUESDAY]: 2,
  [EWeekDays.WEDNESDAY]: 3,
  [EWeekDays.THURSDAY]: 4,
  [EWeekDays.FRIDAY]: 5,
  [EWeekDays.SATURDAY]: 6
};

export const reverseDayOfWeekMap = Object.fromEntries(
  Object.entries(dayOfWeekMap).map(([key, value]) => [value, key])
) as { [key: number]: EWeekDays };

export const hrEventColors = {
  holiday: { light: '#E4FBE4', dark: '#0aa245' },
  leave: { light: '#FBE4E4', dark: '#d61920' },
  shift: { light: 'rgba(228, 244, 251, 1)', dark: '#009eda' },
  attendance: { light: '#F4F4F4', dark: '#333333' }
};
