import { get_hr_holiday_details } from '@/services/hr/queries';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Form, Input, InputNumber, message, Modal, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import HRGroupsSearchV2 from '@/components/Common/CustomSearch/HR/HRGroupSearch';
import { assign_hr_holiday_mutation } from '@/services/hr/mutations';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import HRGroupsDB from '@/store/localstorage/HRGroupsDB';
import { getHRGroup } from '@/services';
import { IHRGroups } from '@/services/hr/types';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import isAxiosError from '@/utils/isAxiosError';

interface FormValues {
  group: number;
  groups: { id: number; name: string }[];
}

function HolidayAssignGroupsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const holidayId = Number(params.id);
  const [form] = Form.useForm<FormValues>();

  if (isNaN(holidayId)) return <Navigate to="/hr/holiday" />;
  const [prevSelected, setPrevSelected] = useState<number>();

  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const {
    isLoading: isParentLoading,
    isFetching,
    data
  } = useQuery(['holiday', holidayId], async () => {
    const data = await get_hr_holiday_details(holidayId);

    const allGroupIds = data.groups.map((group) => group.groupId);
    await HRGroupsDB.addIfAbsent(allGroupIds);

    const groups = await Promise.all(
      data.groups.map(async (group) => {
        const groupDetails = await getHRGroup(group.groupId);
        return { ...group, name: groupDetails.name };
      })
    );

    form.setFieldValue(
      'groups',
      groups.map((u) => ({ id: u.groupId, name: u.name }))
    );

    data.holiday.startDate = moment(data.holiday.startDate).format(DEFAULT_DATE_FORMAT);
    data.holiday.endDate = moment(data.holiday.endDate).format(DEFAULT_DATE_FORMAT);

    return data;
  });

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      setIsVisible(false);

      const groupIds = values.groups.map((u) => u.id);
      await assign_hr_holiday_mutation(holidayId, groupIds);
      message.success('Holiday assigned successfully');
      navigate('/hr/holiday');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  function onGroupChange(group?: IHRGroups) {
    if (!group || prevSelected === group.id) return;
    setPrevSelected(group.id);

    // Check if user is duplicate
    const currentGroups = (form.getFieldValue('groups') as FormValues['groups']) || [];
    const isDuplicate = currentGroups.find((u) => u.id === group.id);

    if (isDuplicate) {
      CustomErrorModal({ message: `Group ${group.name} is already added` });
      return;
    }

    const updatedGroups = [...currentGroups, { id: group.id, name: group.name }];
    form.setFieldsValue({ groups: updatedGroups });
  }

  return (
    <Spin spinning={isLoading || isParentLoading || isFetching}>
      <Modal
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={() => {
          form.submit();
        }}>
        <p>
          Are you sure you want to assign{' '}
          <span className="text-blue-400">{data?.holiday.name}</span> holiday to selected groups?
        </p>
      </Modal>
      <AppContent
        breadcrumbItems={[{ label: 'Holiday', link: '/hr/holiday' }, { label: 'Assign Holiday' }]}>
        <PageHeader title="Details" style={{ padding: '8px 0' }} className="small-title" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader subTitle="Name" style={{ padding: '0px' }} />
            <div>{data?.holiday.name}</div>
          </div>

          <div>
            <PageHeader subTitle="Date" style={{ padding: '0px' }} />
            <div>Start: {data?.holiday.startDate}</div>
            <div className="mt-1">End: {data?.holiday.endDate}</div>
          </div>

          <div>
            <PageHeader subTitle="Description" style={{ padding: '0px' }} />
            <div>{data?.holiday.description}</div>
          </div>
        </div>

        <Form form={form} onFinish={onFinish} layout="vertical">
          <PageHeader title="Assign Holiday" style={{ padding: '8px 0' }} className="small-title" />
          <HRGroupsSearchV2
            hasParentFormItem={false}
            name={'group'}
            hideStyle
            className="w-full max-w-lg"
            placeHolder="Select HR Group"
            setSelected={onGroupChange}
          />

          <Card style={{ maxHeight: '80vh', overflowY: 'scroll', borderRadius: '9px' }}>
            <Form.List name={['groups']}>
              {(fields, { remove }) =>
                fields.map((field, index) => (
                  <div key={field.key} className="flex gap-2 items-center">
                    <span className="font-bold text-sm mb-5">{index + 1}.</span>
                    <div
                      className="card  grid grid-cols-3 gap-3"
                      style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                      <Form.Item name={[field.name, 'id']} hidden>
                        <InputNumber controls={false} disabled />
                      </Form.Item>

                      <Form.Item name={[field.name, 'name']} label={'Group'}>
                        <Input style={{ color: 'black' }} disabled />
                      </Form.Item>

                      <div className="flex items-center justify-start mt-5">
                        <CustomButton
                          backgroundColor="white"
                          text="Remove"
                          textColor="green"
                          onClick={async () => {
                            remove(field.name);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </Form.List>
          </Card>

          <div className="flex justify-end mt-5">
            <Button type="primary" loading={isLoading} onClick={() => setIsVisible(true)}>
              Submit
            </Button>

            <Button
              type="default"
              className="ml-2"
              htmlType="button"
              onClick={() => navigate('/hr/holidays')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default HolidayAssignGroupsPage;
