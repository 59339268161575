import React, { useState, useContext } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Input, message, Table, Menu, Form, TableProps, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';

import AppContent from '@/components/Common/Content';
import ProductsDB from '@/store/localstorage/ProductsDB';
import {
  get_purchase_order_lines_details,
  get_purchase_order_details,
  get_purchase_order_list_for_filter
} from '@/services/purchases/queries';
import { delete_purchase_order } from '@/services/purchases/mutations';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_details, get_user_list_ids, get_vendor_list_ids } from '@/services/users/queries';
import DeleteConfirmModal from '@/components/Common/Deleteconfirm/DeleteConfirmModal';
import { get_product_details } from '@/services/products/queries';
import { get_invoices_list } from '@/services/settings/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details, get_location_list } from '@/services/locations/queries';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import VendorsDB from '@/store/localstorage/VendorDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import ActionDropdown from '@/components/Common/Dropdownactions';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { CustomModal } from '@/components/Common/CustomModal';
import { IPurchaseOrderData } from '@/services/purchases/types';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { getUserData } from '@/utils/auth.utils';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import { PurchaseBuyerType, PurchaseType } from '@/services/purchases/enums';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import { TableExportPrint } from './TableExportPrint';
import { PurchaseOrderListContext } from './context';
import PurchaseInvoice from '@/components/Common/InvoicePrint/PurchaseInvoice/PurchaseInvoice';
import { IPurchaseInvoice } from '@/services/invoice/types';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import { getPurchaseOrderPrintData } from '@/components/Common/InvoicePrint/PurchaseInvoice/services';
import CopyButton from '@/components/Common/CopyButton';
import AgentsSearch from '@/components/Common/AgentsSearch';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { VendorSearch } from '@/components/Common/VendorSearch/VendorSearch';
import GenericTable from '@/components/Common/CustomizeTable';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import AgentSearchV2 from '@/components/Common/CustomSearch/Agents';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';

interface DataType {
  id: number;
  name: string;
  age: number;
  address: string;
  description: string;
}

const PurchaseOrderList: React.FC = () => {
  const navigate = useNavigate();
  const { setIsLoading, isLoading, form } = useContext(PurchaseOrderListContext);
  const [allpurchaseOrderList, setallPurchaseOrderList] = useState<IPurchaseOrderData>({
    count: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<IPurchaseInvoice>(Object);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [total, setTotal] = useState({ totalAmount: 0, totalTax: 0 });

  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();

    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(filter);
    const vendorIds = urlParams.get('vendorId');
    urlParams.delete('vendorId');
    if (vendorIds) {
      const customerList = vendorIds.split(',').map(Number);
      const updatedParams = customerList.map((id) => `vendorIds[]=${id}`).join('&');

      const updatedURL = `${urlParams.toString()}&${updatedParams}`;
      filter = updatedURL;
    } else {
      filter = urlParams.toString();
    }

    let ctotalamount = 0;
    let ctotaltax = 0;

    const response = await get_purchase_order_list_for_filter(filter);
    const searchUsers: any = {};
    const agentUsers: any = {};
    if (response?.data.results) {
      const createdByUsers: any = {};
      for (let index = 0; index < response.data.results.length; index++) {
        if (response?.data?.results[index]?.createdBy) {
          ctotalamount += response.data.results[index].totalAmount;
          ctotaltax += response.data.results[index].totalTax;
          setTotal({ totalAmount: ctotalamount, totalTax: ctotaltax });
          const createdByuser: any = await UsersDB.getUser(response.data.results[index].createdBy);
          if (!createdByuser) {
            if (response.data.results[index].createdBy in createdByUsers) {
              createdByUsers[response.data.results[index].createdBy] = [
                ...createdByUsers[response.data.results[index].createdBy],
                index
              ];
            } else {
              createdByUsers[response.data.results[index].createdBy] = [index];
            }
          } else {
            response.data.results[index].createdByName = createdByuser.name;
          }
        }

        if (response.data.results[index].agentId) {
          const agent = await UsersDB.getUser(response.data.results[index].agentId);
          if (!agent) {
            const agent = await get_user_details(response.data.results[index].agentId);
            await UsersDB.addUsers([agent.user]);
            response.data.results[index].agentName = agent.user.name;
            response.data.results[index].agentPhone = agent.user.phone;
          } else {
            response.data.results[index].agentName = agent.name;
          }
        }

        const user: any = await VendorsDB.getVendors(response.data.results[index].vendorId);
        if (!user) {
          if (response.data.results[index].vendorId in searchUsers) {
            searchUsers[response.data.results[index].vendorId] = [
              ...searchUsers[response.data.results[index].vendorId],
              index
            ];
          } else {
            searchUsers[response.data.results[index].vendorId] = [index];
          }
        } else {
          response.data.results[index].userName = user.user.name;
          response.data.results[index].userData = user;
        }
        let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
        if (!locD) {
          const allLocations = await get_location_list();
          await LocationsDB.addLocations([...allLocations.data.results]);
          locD = await LocationsDB.getLocation(response.data.results[index].locationId);
        }
        response.data.results[index].locationName = locD.name;
        response.data.results[index].date = convertUTCStringtoLocalString(
          response.data.results[index].createdAt,
          'YYYY-MM-DD'
        );
      }
      const searchUserslength = Object.entries(searchUsers).length;

      if (searchUserslength > 0) {
        const customerresponse = await get_vendor_list_ids([...Object.keys(searchUsers)]);
        for (const key in searchUsers) {
          const vendorUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          if (vendorUser) {
            for (let i = 0; i < searchUsers[key].length; i++) {
              response.data.results[searchUsers[key][i]].userName = vendorUser.user.name;
              response.data.results[searchUsers[key][i]].userData = vendorUser;
            }
          }
          await VendorsDB.addVendors([vendorUser]);
        }
      }

      const createdByUserslength = Object.entries(createdByUsers).length;
      if (createdByUserslength > 0) {
        const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
        for (const key in createdByUsers) {
          const createdByUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          if (!createdByUser) continue;
          for (let i = 0; i < createdByUsers[key].length; i++) {
            response.data.results[createdByUsers[key][i]].createdByName = createdByUser.name;
          }
          await UsersDB.addUsers([createdByUser]);
        }
      }
    }
    setallPurchaseOrderList(response.data);
    setIsLoading(false);
  };

  const deletePurchaseOrderMutation = useMutation(delete_purchase_order, {
    onSuccess: () => {
      message.success('Purchase Order deleted successfully');
      const values = form.getFieldsValue();
      values.endDate = convertLocalToUTCString(values.endDate);
      values.startDate = convertLocalToUTCString(values.startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      onSubmitFilter(ConvertObjectToURL(values));
    },
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
    }
  });

  const generatePDF = async (record: any, curr: any) => {
    setIsLoading(true);
    const response = await get_purchase_order_details(parseInt(record.id as string));
    let vendorDetails: any = await UsersDB.getUser(record.vendor.userId);
    if (!vendorDetails) {
      vendorDetails = (await get_user_details(record.vendor.userId)).user;
      await UsersDB.addUsers([vendorDetails]);
    }

    let locationDetails: any = await LocationsDB.getLocation(record.locationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(record.locationId);
      await LocationsDB.addLocations([locationDetails]);
    }

    const linesResponse = await get_purchase_order_lines_details(record.id);
    const lines = linesResponse.data;

    for (let index = 0; index < lines.length; index++) {
      let product: any = await ProductsDB.getProduct(lines[index].productId);
      if (!product) {
        product = await get_product_details(lines[index].productId);
        await ProductsDB.addProducts([product]);
      }
      lines[index].productName = product.name;
    }

    localStorage.setItem(
      'referrer',
      JSON.stringify({
        purchaseDetails: response.data,
        locationDetails,
        vendorDetails,
        lines,
        invoiceLayouts: curr
      })
    );
    setIsLoading(false);
    window.open('/ERP-UI#/custom-invoice', '_blank', 'noopener,noreferrer');
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    { title: 'ID', key: 'id', width: 25, dataIndex: 'id' },
    {
      title: 'Vendor',
      key: 'userName',
      width: 50,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      className: 'invoice',
      width: 70,
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center">
            <Link to={`/purchase/order/view/${record.id}`} color="black">
              {record.financialReference}
            </Link>
            {record.financialReference ? <CopyButton text={record.financialReference} /> : null}
          </TableCell>
        );
      }
    },
    {
      title: 'Buyer Type',
      key: 'purchaseBuyerType',
      width: 30,
      sorter: (a, b) => a.purchaseBuyerType.localeCompare(b.purchaseBuyerType),
      sortOrder: sortedInfo.columnKey === 'purchaseBuyerType' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {PurchaseBuyerType[record.purchaseBuyerType as keyof typeof PurchaseBuyerType]}
          </TableCell>
        );
      }
    },
    {
      title: 'Purchase Type',
      key: 'purchaseType',
      width: 30,
      sorter: (a, b) => a.purchaseType.localeCompare(b.purchaseType),
      sortOrder: sortedInfo.columnKey === 'userNpurchaseTypeame' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>{PurchaseType[record.purchaseType as keyof typeof PurchaseType]}</TableCell>
        );
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 50,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },
    {
      title: 'Agent Name',
      key: 'agentName',
      width: 50,
      sorter: (a, b) => a.agentName.localeCompare(b.agentName),
      sortOrder: sortedInfo.columnKey === 'agentName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.agentName}</TableCell>;
      }
    },
    {
      title: 'Location',
      width: 50,
      key: 'locationName',
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.locationName}</TableCell>;
      }
    },
    {
      title: 'Total Amount',
      key: 'totalAmount',
      className: 'highlight',
      dataIndex: 'totalAmount',
      width: 35,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell className="text-right text-xs mr-2">
            <Link to={`/purchase/order/view/${record.id}`} color="black">
              {nepaliNumberFormatter(record.totalAmount)}
            </Link>
          </TableCell>
        );
      }
    },
    {
      title: 'Discount.',
      key: 'manualDiscount',
      dataIndex: 'manualDiscount',
      width: 35,
      sorter: (a, b) => a.manualDiscount - b.manualDiscount,
      sortOrder: sortedInfo.columnKey === 'manualDiscount' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell className="text-right text-xs mr-2">
            {nepaliNumberFormatter(record.manualDiscount)}
          </TableCell>
        );
      }
    },
    {
      title: 'Total Tax',
      key: 'totalTax',
      dataIndex: 'totalTax',
      width: 35,
      sorter: (a, b) => a.totalTax - b.totalTax,
      sortOrder: sortedInfo.columnKey === 'totalTax' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell className="text-right text-xs mr-2">
            {nepaliNumberFormatter(record.totalTax)}
          </TableCell>
        );
      }
    },
    {
      title: 'Date',
      key: 'date',
      width: 50,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 20,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          deletePurchaseOrderMutation.mutate(record.id);
        };
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('DELETE_PURCHASE_ORDER')) {
          menuItems.push({
            key: '1',
            label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
          });
        }
        if (checkAccess('UPDATE_PURCHASE_ORDER')) {
          menuItems.push({
            key: '2',
            label: <CustomUpdateIcon link={`/purchase/order/${record.id}`} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <DiscussionChatModal
                slug="purchase_order"
                id={record.id}
                fromSellorPurahcaseid={record.vendor.userId}
              />
            )
          });
        }
        menuItems.push({
          key: '3',
          label: (
            <InvoicePrintButton
              getInvoice={(curr) => {
                generateInvoiceForPrint(record, curr);
              }}
            />
          )
        });
        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Purchase Order',
      link: '/purchase/order'
    }
  ];

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }

    const url = ConvertObjectToURL(values);
    const response = await get_purchase_order_list_for_filter(url);
    setallPurchaseOrderList(response.data);
    setIsLoading(false);
  };

  const generateInvoiceForPrint = async (record: any, curr: any) => {
    setIsLoading(true);
    const invoiceModalData = await getPurchaseOrderPrintData(record.id, record.locationId, curr);
    setIsLoading(false);

    setInvoiceData(invoiceModalData);

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    const urlData = ConvertObjectToURL(values);
    getInfo(urlData);
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Purchase Order Invoice Print">
        <PurchaseInvoice
          purchaseDetails={invoiceData.purchaseDetails}
          vendorDetails={invoiceData.vendorDetails}
          lines={invoiceData.lines}
          locationDetails={invoiceData.locationDetails}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  locationId: preferenceLocationId ? preferenceLocationId : 1,
                  value: '',

                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                style={'sm:grid md:grid grid-cols-3 gap-2'}
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_PURCHASE_ORDER') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/purchase/order/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/purchase/order/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <VendorSearchV2 hasParentFormItem={false} name="vendorId" isMultiple />
                <AgentSearchV2
                  hasParentFormItem={false}
                  name="agentId"
                  onSelect={(val) => {
                    form.setFieldValue(['agentId'], val);
                  }}
                />
                <ProductCategorySearchV2
                  hasParentFormItem={false}
                  name="categoryId"
                  label="Product Category"
                />
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
          </>
        }>
        <TableExportPrint allpurchaseOrderList={allpurchaseOrderList} />
        <GenericTable
          form={form}
          columns={columns}
          data={allpurchaseOrderList.results}
          scroll={{ x: 1750, y: '75vh' }}
          hideDefaultPagination
          tableName={'purcahse-order-list'}
          generateSummary
          excludeSummaryByKeys={['id']}
          summaryClassName="text-right !pr-3"
          pagination={{
            page: page,
            total: allpurchaseOrderList.count,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default PurchaseOrderList;
