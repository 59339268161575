import moment from 'moment';
import { getDateRangeFromSingle } from '@/utils/getDateRangeFromSingle';
import { IRow } from '../grid.types';
import roundNumber from '@/utils/round.utils';
import { IOutletPricingByDate } from '@/services/products/types';
import { getProductById, getUnit } from '@/services';
import { emptyRow } from './constant';
import { fetchProductAllPriceDetails } from '../services';
import { get_products_lines_avg_qty_by_range } from '@/services/products/queries';
import { LineType } from '@/services/report/enums';
import ProductsDB from '@/store/localstorage/ProductsDB';

interface IDate {
  startDate: string;
  endDate: string;
}

export function buildQuery(dateRange: IDate, productIds: number[]) {
  const params = new URLSearchParams();
  params.append('startDate', dateRange.startDate);
  params.append('endDate', dateRange.endDate);
  params.append('ids[]', productIds.toString());
  return params.toString();
}

function getMorningDate(date: string) {
  const momentDate = moment(date);
  const startOfMorning = momentDate.clone().startOf('day').toISOString();
  const endOfMorning = momentDate
    .clone()
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .toISOString();

  return { startDate: startOfMorning, endDate: endOfMorning };
}

export function getAllFilterPayload(date: string, productIds: number[]) {
  const yesterdaySingleDate = moment(date).subtract(1).format('YYYY-MM-DD');

  const morningDate = getMorningDate(date);
  const todayDate = getDateRangeFromSingle(date);
  const yesterdayDate = getDateRangeFromSingle(yesterdaySingleDate);

  const todayFilter = buildQuery(todayDate, productIds);
  const yesterdayFilter = buildQuery(yesterdayDate, productIds);
  const morningFilter = buildQuery(morningDate, productIds);

  return {
    todayFilter,
    yesterdayFilter,
    morningFilter,
    morningDate,
    todayDate,
    yesterdayDate
  };
}

export function calculateRowValues(row: IRow) {
  const marginAmount = roundNumber(row.todayPrice - row.purchasePrice);
  const marginPercentage =
    row.purchasePrice !== 0 ? roundNumber((marginAmount / row.purchasePrice) * 100) : 0;

  const marginSellAmount = roundNumber(row.sellTodayPrice - row.sellPurchasePrice);
  const marginSellPercentage = row.sellPurchasePrice
    ? roundNumber((marginSellAmount / row.sellPurchasePrice) * 100)
    : 0;

  return {
    ...row,
    totalStock: row.openingStock + row.estimatedData,
    isPriceChanged: row.yesterdaySellingRate !== row.todayPrice,
    marginAmount,
    marginPercentage,
    isPurchasePriceChanged: row.sellPurchasePrice !== row.todayPrice,
    isSellRateChanged: row.sellYesterdayPrice !== row.sellTodayPrice,
    marginSellAmount,
    marginSellPercentage
  } as IRow;
}

async function getBaseUnitMultiplier(products: { id: number; unitId: number }[]) {
  const productDetails: { id: number; baseUnitMultiplier: number }[] = [];

  for (const product of products) {
    const { id, unitId } = product;
    const alreadyExists = productDetails.find((p) => p.id === id);
    if (alreadyExists) continue;

    const unitDetails = await getUnit(unitId);
    const baseUnitMultiplier = unitDetails.baseUnitMultiplier || 1;
    productDetails.push({ id, baseUnitMultiplier });
  }

  return productDetails;
}

async function getFormattedGridRows(
  productPricingDetails: Record<number, Partial<IRow>>,
  uniqueProductIds: number[]
) {
  const rows: IRow[] = [];
  await ProductsDB.addProductsIfAbsent(uniqueProductIds);
  for (const productId of uniqueProductIds) {
    const product = await getProductById(productId);
    const allUnits = product.productUnits.map((p) => p.unitId);
    const defaultUnit = product.productUnits.find((u) => u.isDefault);

    const productDetails = productPricingDetails[productId] || {};
    const unitId = defaultUnit ? defaultUnit.unitId : allUnits.length ? allUnits[0] : undefined;
    let unitName = '';

    if (unitId) {
      const unitDetail = await getUnit(unitId);
      unitName = unitDetail.shortName;
    }

    const rowData = {
      ...emptyRow,
      ...productDetails,
      productId,
      productName: product.name,
      productUnits: allUnits,
      unitId: defaultUnit ? defaultUnit.unitId : allUnits.length ? allUnits[0] : undefined,
      unitName
    };

    const calculatedRows = calculateRowValues(rowData);
    rows.push(calculatedRows);
  }

  return rows;
}

export async function prefillAllData(priceAnalysis: IOutletPricingByDate, date: string) {
  const { outlet, purchase } = priceAnalysis;
  const purchaseData = purchase.map((p) => ({ id: p.productId, unitId: p.unitId }));
  const outletData = outlet.map((p) => ({ id: p.productId, unitId: p.unitId }));

  const productWithMultiplier = await getBaseUnitMultiplier([...purchaseData, ...outletData]);
  const productPricingDetails = await fetchProductAllPriceDetails(
    productWithMultiplier,
    date,
    priceAnalysis
  );

  const productIdsFromPurchase = purchase.map((p) => p.productId);
  const productIdsFromOutlet = outlet.map((p) => p.productId);

  const uniqueProductIds = [...new Set([...productIdsFromPurchase, ...productIdsFromOutlet])];
  return await getFormattedGridRows(productPricingDetails, uniqueProductIds);
}

export async function loadLastPurchaseProduct(date: string): Promise<{
  isPresent: boolean;
  rows: IRow[];
}> {
  const currentTime = moment();
  const endTime = moment(date, 'YYYY-MM-DD').set({
    hour: currentTime.hour(),
    minute: currentTime.minute()
  });

  const startTime = endTime.clone().subtract(24, 'hours');
  const range = { startDate: startTime.toISOString(), endDate: endTime.toISOString() };

  const allLines = await get_products_lines_avg_qty_by_range(range, [LineType.PURCHASE]);
  const productIds = allLines.map((p) => p.productId);

  if (productIds.length === 0) return { isPresent: false, rows: [] };

  const productWithDefaultUnit = await assignUnitIdToProducts(productIds);
  const productWithMultiplier = await getBaseUnitMultiplier(productWithDefaultUnit);
  const productPricingDetails = await fetchProductAllPriceDetails(productWithMultiplier, date);
  const rows = await getFormattedGridRows(productPricingDetails, productIds);
  return { isPresent: true, rows };
}

async function assignUnitIdToProducts(productIds: number[]) {
  const productWithUnitIds: { id: number; unitId: number }[] = [];
  await ProductsDB.addProductsIfAbsent(productIds);

  for (const productId of productIds) {
    const product = await getProductById(productId);
    const allUnits = product.productUnits.map((p) => p.unitId);
    const defaultUnit = product.productUnits.find((u) => u.isDefault);

    if (allUnits.length) {
      productWithUnitIds.push({
        id: productId,
        unitId: defaultUnit?.unitId || allUnits[0]
      });
    }
  }
  return productWithUnitIds;
}
