import moment from 'moment';
import { useState } from 'react';
import { Form, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';

import {
  IExpiredProductResponse,
  IExpiredProductTableData,
  IProductListResponse
} from '@/services/products/types';
import { get_expired_products_list, get_product_list_ids } from '@/services/products/queries';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';
import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import { createEmptyPromise } from '@/pages/accounts/journal/detailedLedger/services/reference.service';
import { AxiosResponse } from 'axios';
import { ILocations } from '@/services/locations/types';

function ExpiredProducts() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 20 });

  const [sortedInfo, setSortedInfo] = useState<SorterResult<IExpiredProductResponse>>({});
  const handleChange: TableProps<IExpiredProductResponse>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IExpiredProductResponse>);
  };

  const [expiredProducts, setExpiredProducts] = useState<IExpiredProductResponse>({
    total: 0,
    results: []
  });

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 20 });
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_expired_products_list(filter);
      const expiredProductList = response.results as IExpiredProductTableData[];

      const absentProductIds: number[] = [];
      const absentLocationIds: number[] = [];

      for (const expiredProduct of expiredProductList) {
        const product = (await ProductsDB.getProduct(expiredProduct.productId)) as any;
        const locationId = expiredProduct.__lot__.locationId;
        if (!product) {
          absentProductIds.push(expiredProduct.productId);
        }

        const locationDetails = await LocationsDB.getLocation(locationId);
        if (!locationDetails) {
          absentLocationIds.push(locationId);
        }

        expiredProduct.lotNumber = `${expiredProduct.__lot__.lotNumber} (Grade: ${expiredProduct.__lot__.grade})`;
        expiredProduct.productName = product?.name;
        expiredProduct.location = locationDetails?.name;
      }

      const locationPromises =
        absentLocationIds.length > 0
          ? get_location_list_for_ids([...new Set(absentLocationIds)])
          : createEmptyPromise([]);

      const productPromises =
        absentProductIds.length > 0
          ? get_product_list_ids([...new Set(absentProductIds)])
          : createEmptyPromise({ data: { results: [] } });

      const allResponses = await Promise.all([locationPromises, productPromises]);
      const locations = allResponses[0] as ILocations[];
      const allProducts = allResponses[1] as AxiosResponse<IProductListResponse>;

      if (absentLocationIds.length > 0) {
        await LocationsDB.addLocations(locations);
      }

      if (absentProductIds.length > 0) {
        await ProductsDB.addProducts(allProducts.data.results);
      }

      for (const expiredProduct of expiredProductList) {
        const product = (await ProductsDB.getProduct(expiredProduct.productId)) as any;
        const locationDetails = await LocationsDB.getLocation(expiredProduct.__lot__.locationId);

        expiredProduct.productName = product?.name;
        expiredProduct.location = locationDetails?.name;
      }

      setExpiredProducts({
        total: response.total,
        results: expiredProductList
      });
    } catch (error) {
      message.error('Failed to fetch expired products');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const columns: ColumnsType<IExpiredProductTableData> = [
    {
      title: 'S.N',
      key: 'sn',
      render: (_, __, index) => <span>{index + 1}</span>,
      width: 5
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      width: 40,
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      key: 'lotNumber',
      width: 40,
      sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
      sortOrder: sortedInfo.columnKey === 'lotNumber' ? sortedInfo.order : null
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 30,
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortOrder: sortedInfo.columnKey === 'location' ? sortedInfo.order : null
    },
    {
      title: 'Expired Date',
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (expireDate: string) => moment(expireDate).format('YYYY-MM-DD'),
      width: 15,
      sorter: (a, b) => moment(a.expireDate).unix() - moment(b.expireDate).unix(),
      sortOrder: sortedInfo.columnKey === 'expireDate' ? sortedInfo.order : null
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => moment(createdAt).format('YYYY-MM-DD'),
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null
    }
  ];

  const onPagination = async (page = 1, size = 20, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <AppContent
      withfilter
      breadcrumbItems={[
        { label: 'Products', link: '/products' },
        { label: 'Expired Products', link: '/products/expired' }
      ]}
      button={
        <TableFilter
          initial
          form={form}
          onSubmit={onSubmitFilter}
          onPagination={(page, size) => setPagination({ page, size })}
          defaultValues={{
            dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
            locationId: '',
            skip: 0,
            count: 20
          }}
          styleforbuttons={'flex justify-end items-center'}>
          <LocationSearch
            formData={{ formLabel: 'Location', formName: 'locationId' }}
            allowClear={true}
          />
        </TableFilter>
      }>
      <CustomizeTable
        form={form}
        columns={columns}
        toSort={handleChange}
        data={expiredProducts.results}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 1300, y: '75vh' }}
        paginationDatas={{
          page: pagination.page,
          total: expiredProducts.total,
          size: pagination.size,
          onPagination
        }}
        tableName={'product-expiry-list'}
      />
    </AppContent>
  );
}

export default ExpiredProducts;
