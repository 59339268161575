import React, { useState, useEffect, useContext } from 'react';
import { RollbackOutlined } from '@ant-design/icons';
import { Input, Tooltip, Menu, Form, Select, TableProps, Spin, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';
import { get_sell_lists_v2 } from '@/services/sell/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_customer_list_ids, get_user_list_ids } from '@/services/users/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details, get_location_list } from '@/services/locations/queries';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import moment from 'moment';
import TableFilter from '@/components/FliterTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import { ConvertObjectToURL } from '@/utils/converturl';
import CustomersDB from '@/store/localstorage/CustomerDB';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CopyButton from '@/components/Common/CopyButton';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { ISellFromServer, ISellResponseFromServer } from '@/services/sell/types';
import PayoutStatus from '@/components/Common/PayoutStatus';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { ProductRoutePrintModal } from './ProductRoutePrint';
import { SellListContext } from './context';
import { SellInvoicePrintModal } from './SellInvoicePrintModal';
import { BillPrintModal } from './BillPrintModal';
import { TableExportModal } from './TableExportModal';
import { CreateCratesModal } from './CreateCratesModal';
import { UpdateCratesModal } from './UpdateCratesModal';
import { SellReturnModal } from './SellReturnModal';
import { TableExportAllModal } from './TableExportAllModal';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import CustomerSearchByRoute from '@/components/Common/CustomSearch/Customer/CustomerByRoute';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import { useRequestSignal } from '@/hooks/useRequestSignal';
const { Option } = Select;
const colorCode: any = {
  full: 'green',
  pending: 'red',
  partial: 'purple'
};
const breadcrumbItems = [{ label: 'Sell', link: '/sell' }];

const SellList: React.FC = () => {
  const { form, isLoading, setIsLoading } = useContext(SellListContext);
  const navigate = useNavigate();
  const [allSellList, setallSellList] = useState<ISellResponseFromServer>({
    total: 0,
    results: []
  });
  const [total, setTotal] = useState<any>({
    totalAmount: 0,
    totalReturn: 0
  });
  const [urlData, setUrlData] = useState('');
  const [allExportUrl, setAllExportUrl] = useState('');
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const routeId = Form.useWatch('routeId', form);
  const abortSignal = useRequestSignal();

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  useEffect(() => {
    if (preferenceLocationId) {
      fetchPreferenceRouteId(preferenceLocationId);
    }
  }, [preferenceLocationId]);

  const fetchPreferenceRouteId = async (preferenceLocationId: number) => {
    let locationDetails: any = await LocationsDB.getLocation(preferenceLocationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(preferenceLocationId);
      await LocationsDB.addLocations([locationDetails]);
    }
    if (locationDetails.routeId) form.setFieldValue(['routeId'], locationDetails.routeId);
    // else form.setFieldValue(['routeId'], '');
  };

  const columns: ColumnsType<ISellFromServer> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 40,
      render: (record: number) => {
        return <TableCell>{record}</TableCell>;
      }
    },
    {
      title: 'Financial Reference',
      width: 80,
      key: 'financialReference',
      className: 'invoice',
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          <TableCell>
            <div className="flex flex-row justify-between items-center">
              <Link to={`/sell/${record.id}`}>{record.financialReference}</Link>
              {record.financialReference ? <CopyButton text={record.financialReference} /> : null}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Customer',
      width: 50,
      key: 'userName',
      sorter: (a, b) => (a.userName ?? '').localeCompare(b.userName ?? ''),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },
    {
      title: 'Amount',
      width: 40,
      key: 'amount',
      dataIndex: 'initialAmount',
      sorter: (a, b) => a.totalTaxable + a.totalNonTaxable - b.totalTaxable + b.totalNonTaxable,
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      render: (initialAmount: number, record: ISellFromServer) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.totalTaxable + record.totalNonTaxable)}
          </TableCell>
        );
      }
    },

    {
      title: 'VAT',
      width: 40,
      key: 'totalVat',
      dataIndex: 'totalVat',
      sorter: (a, b) => a.totalVat - b.totalVat,
      sortOrder: sortedInfo.columnKey === 'totalVat' ? sortedInfo.order : null,
      render: (totalVat: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(totalVat)}
          </TableCell>
        );
      }
    },
    {
      title: 'Shipping',
      width: 45,
      key: 'shippingCharge',
      dataIndex: 'shipping',
      sorter: (a, b) => {
        return a.shipping - b.shipping;
      },
      sortOrder: sortedInfo.columnKey === 'amountPaid' ? sortedInfo.order : null,
      render: (shipping: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(shipping)}
          </TableCell>
        );
      }
    },
    {
      title: 'Shipping Tax',
      width: 45,
      key: 'shippingTax',
      dataIndex: 'shippingTax',
      sorter: (a, b) => {
        return a.shippingTax - b.shippingTax;
      },
      sortOrder: sortedInfo.columnKey === 'shippingTax' ? sortedInfo.order : null,
      render: (shippingTax: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(shippingTax)}
          </TableCell>
        );
      }
    },
    {
      title: 'Total',
      width: 50,
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (totalAmount: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(totalAmount)}
          </TableCell>
        );
      }
    },
    {
      title: 'Paid',
      width: 40,
      key: 'amountPaid',
      dataIndex: 'amountPaid',
      sorter: (a, b) => a.amountPaid - b.amountPaid,
      sortOrder: sortedInfo.columnKey === 'amountPaid' ? sortedInfo.order : null,
      render: (amountPaid: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(amountPaid)}
          </TableCell>
        );
      }
    },
    {
      title: 'Return',
      width: 40,
      key: 'amountReturned',
      dataIndex: 'amountReturned',
      sorter: (a, b) => a.amountReturned - b.amountReturned,
      sortOrder: sortedInfo.columnKey === 'amountReturned' ? sortedInfo.order : null,
      render: (amountReturned: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(amountReturned)}
          </TableCell>
        );
      }
    },
    {
      title: 'Remaining',
      width: 50,
      key: 'amountRemaining',
      dataIndex: 'remainingAmount',
      sorter: (a, b) => {
        const c = a.totalAmount - a.amountReturned - a.amountPaid;
        const d = b.totalAmount - b.amountReturned - b.amountPaid;
        return c - d;
      },
      sortOrder: sortedInfo.columnKey === 'amountRemaining' ? sortedInfo.order : null,
      render: (remainingAmount: number, record: ISellFromServer) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.totalAmount - record.amountReturned - record.amountPaid)}
          </TableCell>
        );
      }
    },
    {
      title: 'Net Sell',
      width: 40,
      key: 'netsell',
      className: 'highlight',
      dataIndex: 'netSell',
      sorter: (a, b) => {
        const c = a.totalAmount - a.amountReturned;
        const d = b.totalAmount - b.amountReturned;
        return c - d;
      },
      sortOrder: sortedInfo.columnKey === 'netsell' ? sortedInfo.order : null,
      render: (record: number) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">{nepaliNumberFormatter(record)}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Profit',
      width: 40,
      key: 'profit',
      dataIndex: 'profit',
      sorter: (a, b) => {
        return a.profit - b.profit;
      },
      sortOrder: sortedInfo.columnKey === 'profit' ? sortedInfo.order : null,
      render: (profit: number) => {
        return (
          <div style={{ color: profit > 0 ? 'green' : 'red' }}>
            <TableCell className="text-right mr-2 text-xs">
              {nepaliNumberFormatter(profit)}
            </TableCell>
          </div>
        );
      }
    },
    {
      title: 'Payment Status',
      width: 50,
      key: 'paymentStatus',
      render: (record: ISellFromServer) => {
        return (
          <Tag className="uppercase" color={colorCode[record.paymentStatus]}>
            {record.paymentStatus}
          </Tag>
        );
      }
    },

    {
      title: 'Date',
      width: 50,
      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          <TableCell>
            <div color="black">
              {/* {moment(record.date).local().format(DEFAULT_DATE_FORMAT)} */}
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Crates Sent',
      width: 35,
      key: 'cratesSent',
      dataIndex: 'cratesSent',
      sorter: (a, b) => a.cratesSent - b.cratesSent,
      sortOrder: sortedInfo.columnKey === 'cratesSent' ? sortedInfo.order : null,
      render: (data: number) => {
        return (
          // <Link to={`/sell/${data.id}`} color="black">
          <TableCell className="text-right">{data}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Crates Received',
      width: 45,
      key: 'cratesReceived',
      dataIndex: 'cratesReceived',
      sorter: (a, b) => a.cratesReceived - b.cratesReceived,
      sortOrder: sortedInfo.columnKey === 'cratesReceived' ? sortedInfo.order : null,
      render: (data: number) => {
        return <TableCell className="text-right">{data}</TableCell>;
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 40,
      sorter: (a, b) => (a.userName ?? '').localeCompare(b.userName ?? ''),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },
    {
      title: 'Location',
      width: 50,
      key: 'locationName',
      sorter: (a, b) => (a.locationName ?? '').localeCompare(b.locationName ?? ''),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>{record.locationName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Note',
      width: 30,
      key: 'note',
      sorter: (a, b) => a.note.localeCompare(b.note),
      sortOrder: sortedInfo.columnKey === 'note' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          <TableCell>
            <Tooltip title={record.note} color="blue">
              <div className="cursor-pointer">
                {record.note ? `${record.note}`.slice(0, 10) + '...' : ''}
              </div>
            </Tooltip>
          </TableCell>
        );
      }
    },
    {
      title: 'Print Count',
      width: 30,
      key: 'printcount',
      sorter: (a, b) => a.printCount - b.printCount,
      sortOrder: sortedInfo.columnKey === 'printCount' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return <TableCell>{record.printCount}</TableCell>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 20,
      fixed: 'right',
      render: (record: ISellFromServer) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [
          {
            key: '3',
            label: (
              <CreateCratesModal
                urlData={urlData}
                record={record}
                getInfo={getInfo}
                key={record.id}
              />
            )
          },
          {
            key: '6',
            label: (
              <UpdateCratesModal
                urlData={urlData}
                record={record}
                getInfo={getInfo}
                key={record.id}
              />
            )
          },
          {
            key: '7',
            label: <CustomViewIcon link={`/sell/${record.id}`} />
          }
        ];
        if (checkAccess('READ_SELL_RETURN')) {
          if (record.amountReturned > 0) {
            menuItems.push({
              key: '5',
              label: <SellReturnModal record={record} getInfo={getInfo} key={record.id} />
            });
          }
          //  else {
          menuItems.push({
            key: '1',
            label: (
              <Tooltip title="Return" color="blue">
                <Link to={`/sell/return/${record.id}`}>
                  <RollbackOutlined
                    type="danger"
                    style={{ transform: 'scale(1.4)', width: '100%' }}
                  />
                </Link>
              </Tooltip>
            )
          });
          // }
        }

        menuItems.push({
          key: 'billandinvoice',
          label: <SellInvoicePrintModal key={record.id} record={record} getInfo={getInfo} />
        });

        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <DiscussionChatModal
                slug="sell"
                id={record.id}
                fromSellorPurahcaseid={record.userData.userId}
              />
            )
          });
        }
        const menu = <Menu items={menuItems} />;
        return (
          <TableCell>
            <ActionDropdown menu={menu} />
          </TableCell>
        );
      }
    }
  ];

  const onSubmitFilter = async (val: string) => {
    const data = await getInfo(val);
    const total = data.total;
    const urlParams = new URLSearchParams(val);
    urlParams.set('count', total.toString());
    setAllExportUrl(urlParams.toString());
    setPage(1);
    setSize(100);
  };
  const getInfo = async (filter = '', forExportAll = false) => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(filter);
    const customerIds = urlParams.get('customerId');
    urlParams.delete('customerId');

    if (customerIds) {
      const customerList = customerIds.split(',').map(Number);
      const updatedParams = customerList.map((id) => `customerIds[]=${id}`).join('&');

      const updatedURL = `${urlParams.toString()}&${updatedParams}`;
      filter = updatedURL;
    } else {
      filter = urlParams.toString();
    }

    if (!forExportAll) setUrlData(filter);
    const response = await get_sell_lists_v2(filter, { signal: abortSignal });
    if (response?.data.results) {
      const searchUsers: any = {};
      let ctotalamount = 0,
        creturnamount = 0;
      const createdByUsers: any = {};
      for (let index = 0; index < response.data.results.length; index++) {
        const currentData = response.data.results[index];

        currentData.initialAmount = currentData.totalTaxable + currentData.totalNonTaxable;
        currentData.remainingAmount =
          currentData.totalAmount - currentData.amountReturned - currentData.amountPaid;
        currentData.netSell = currentData.totalAmount - currentData.amountReturned;

        if (response?.data?.results[index]?.createdBy) {
          const createdByuser: any = await UsersDB.getUser(response.data.results[index].createdBy);
          if (!createdByuser) {
            // user = await get_customer_details(response.data.resultss[index].customerId);
            // user = { ...user.customer, user: user.user.user };
            // await CustomersDB.addCustomers([user]);
            if (response.data.results[index].createdBy in createdByUsers) {
              createdByUsers[response.data.results[index].createdBy] = [
                ...createdByUsers[response.data.results[index].createdBy],
                index
              ];
            } else {
              createdByUsers[response.data.results[index].createdBy] = [index];
            }
          } else {
            response.data.results[index].createdByName = createdByuser.name;
            // response.data.resultsWithCustomer[index].userData = user;
          }
        }

        const user: any = await CustomersDB.getCustomer(response.data.results[index].customerId);
        if (!user) {
          // user = await get_customer_details(response.data.results[index].customerId);
          // user = { ...user.customer, user: user.user.user };
          // await CustomersDB.addCustomers([user]);
          if (response.data.results[index].customerId in searchUsers) {
            searchUsers[response.data.results[index].customerId] = [
              ...searchUsers[response.data.results[index].customerId],
              index
            ];
          } else {
            searchUsers[response.data.results[index].customerId] = [index];
          }
        } else {
          response.data.results[index].userName = user.user.name;
          response.data.results[index].userData = user;
        }
        // let user: any = await UsersDB.getUser(response.data.results[index].address.userId);
        // if (!user) {
        //   user = (await get_user_details(response.data.results[index].address.userId)).user;
        //   await UsersDB.addUsers([user]);
        // }
        // response.data.results[index].userName = user.name;
        let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
        if (!locD) {
          // locD = await get_location_details(response.data.results[index].locationId);
          const allLocations = await get_location_list();
          // console.log('allLocations-->', allLocations);
          await LocationsDB.addLocations([...allLocations.data.results]);
          // await LocationsDB.addLocations([locD]);
          locD = await LocationsDB.getLocation(response.data.results[index].locationId);
        }
        response.data.results[index].locationName = locD.name;
        ctotalamount += response.data.results[index].totalAmount;
        creturnamount += response.data.results[index].amountReturned;
        response.data.results[index].date = convertUTCStringtoLocalString(
          response.data.results[index].createdAt,
          'YYYY-MM-DD'
        );
        response.data.results[index].remainingAmount = numberDecimalFormatter(
          response.data.results[index].totalAmount -
            response.data.results[index].amountReturned -
            response.data.results[index].amountPaid
        );
      }
      if (!forExportAll) setTotal({ totalAmount: ctotalamount, totalReturn: creturnamount });
      const searchUserslength = Object.entries(searchUsers).length;
      if (searchUserslength > 0) {
        const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);
        for (const key in searchUsers) {
          const customerUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          for (let i = 0; i < searchUsers[key].length; i++) {
            response.data.results[searchUsers[key][i]].userName = customerUser?.user.name;
            response.data.results[searchUsers[key][i]].userData = customerUser;
          }
          await CustomersDB.addCustomers([customerUser]);
        }
      }

      const createdByUserslength = Object.entries(createdByUsers).length;
      // console.log('createdbyuserslength-->', createdByUsers);
      if (createdByUserslength > 0) {
        const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
        // console.log('Customer response-->', customerresponse);
        for (const key in createdByUsers) {
          // console.log('keys->', key);
          const createdByUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          // console.log('createdByUser-->', createdByUser);
          if (!createdByUser) continue;
          for (let i = 0; i < createdByUsers[key].length; i++) {
            response.data.results[createdByUsers[key][i]].createdByName = createdByUser.name;
          }
          await UsersDB.addUsers([createdByUser]);
        }
      }
      // setSellList(response.data.results);
    }
    // console.log('Response data-->', response.data);
    setIsLoading(false);
    if (!forExportAll) {
      setallSellList(response.data);
      return response.data;
    } else {
      response.data.results = response.data.results.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1,
          netsell: item.totalAmount - item.amountReturned
        };
      });
      return response.data;
    }
  };

  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      <BillPrintModal urlData={urlData} getInfo={getInfo} />
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            {/* <div className="flex items-center justify-end"> */}
            <div>
              {
                <TableFilter
                  form={form}
                  onPagination={(page, size) => {
                    setPage(page);
                    setSize(size);
                  }}
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    locationId: preferenceLocationId ? preferenceLocationId : 1,
                    skip: 0,
                    count: 100,
                    customerGroup: '',
                    user: '',
                    paymentStatus: '',
                    customerId: [],
                    createdBy: '',
                    routeId: ''
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  style={'grid grid-cols-1 gap-2'}
                  styleforbuttons={'flex justify-end items-center'}
                  buttons={
                    <>
                      {checkAccess('CREATE_SELL') && (
                        <div>
                          <CustomButton
                            onClick={() => navigate('/sell/new')}
                            text="Add"
                            backgroundColor="#1890ff"
                            marginLeft="10px"
                            Linkto="/sell/new"
                          />
                        </div>
                      )}
                      <div>
                        <ProductRoutePrintModal getInfo={getInfo} />
                      </div>
                    </>
                  }>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
                  <RouteSearchV2
                    hasParentFormItem={false}
                    name="routeId"
                    showAll
                    onSelect={() => {
                      form.setFieldValue(['customerId'], []);
                    }}
                  />
                  <CustomerSearchByRoute
                    hasParentFormItem={false}
                    name="customerId"
                    showAll
                    isMultiple
                    routeId={routeId}
                  />

                  <PayoutStatus />
                  <Form.Item name="value" label="Search">
                    <Input />
                  </Form.Item>
                  {/* <CustomerSearch formData={{ formName: 'customerId' }} all={true} /> */}

                  <Form.Item name="type" label="Select">
                    <Select
                      placeholder="Select a type"
                      style={{ borderRadius: '10px' }}
                      allowClear
                      dropdownMatchSelectWidth={false}>
                      <Option value={''} key={'none'}>
                        All
                      </Option>

                      <Option value={'POS'} key={'POS'}>
                        POS
                      </Option>
                      <Option value={'B2B'} key={'B2B'}>
                        B2B
                      </Option>
                    </Select>
                  </Form.Item>
                  <UserSearchV2 hasParentFormItem={false} name="user" label="Created By" showAll />
                  <ProductCategorySearchV2
                    hasParentFormItem={false}
                    name="categoryId"
                    label="Product Category"
                    showAll
                  />
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        {/* <div className="flex gap-2 my-2 justify-end">
          <TableExportAllModal getInfo={getInfo} allExportUrl={allExportUrl} />
          <TableExportModal allSellList={allSellList} />
        </div> */}
        <div>
          <GenericTable
            columns={columns}
            form={form}
            data={allSellList.results}
            tableName="sell-list"
            hideDefaultPagination
            scroll={{ x: 2400, y: '75vh' }}
            pagination={{
              page: page,
              total: allSellList.total,
              size: size,
              onPagination
            }}
            toSort={handleChange}
            generateSummary
            summaryClassName="text-right !pr-4"
            excludeSummaryByKeys={['id']}
            buttons={
              <div className="flex">
                <TableExportAllModal getInfo={getInfo} allExportUrl={allExportUrl} />
                <TableExportModal allSellList={allSellList} />
              </div>
            }
          />
        </div>
      </AppContent>
    </Spin>
  );
};

export default SellList;
