import http from '@/utils/http.utils';
import { ILogSupport, ILogSupportCreate, ILogSupportUpdate } from './types';
import { AxiosResponse } from 'axios';

export const create_logs_support = async (data: ILogSupportCreate) => {
  const response: AxiosResponse<ILogSupport> = await http.store('/logs-support', data, {
    showErrorModal: false
  });
  return response.data;
};

export const update_logs_support_mutaion = async (id: number, data: ILogSupportUpdate) => {
  const response: AxiosResponse<ILogSupport> = await http.update('/logs-support/' + id, data);
  return response.data;
};
