import AppContent from '@/components/Common/Content/Content';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { get_hr_holiday_details } from '@/services/hr/queries';
import { useQuery } from '@tanstack/react-query';
import { Button, PageHeader, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import HRGroupsDB from '@/store/localstorage/HRGroupsDB';
import { getHRGroup } from '@/services';
import { checkAccess } from '@/routes/acl';
import { ColumnsType } from 'antd/lib/table';
import GenericTable from '@/components/Common/CustomizeTable';
import { IHRHolidayGroup } from '@/services/hr/types';

function ViewHoliday() {
  const params = useParams();
  const holidayID = Number(params.id);
  const navigate = useNavigate();

  if (isNaN(holidayID)) return <Navigate to="/hr/holiday" />;

  const { isLoading, isFetching, data } = useQuery(['holiday', holidayID], async () => {
    const data = await get_hr_holiday_details(holidayID);

    const allGroupIds = data.groups.map((group) => group.groupId);
    await HRGroupsDB.addIfAbsent(allGroupIds);

    data.groups = await Promise.all(
      data.groups.map(async (group) => {
        const groupDetails = await getHRGroup(group.groupId);
        return { ...group, name: groupDetails.name };
      })
    );

    data.holiday.startDate = moment(data.holiday.startDate).format(DEFAULT_DATE_FORMAT);
    data.holiday.endDate = moment(data.holiday.endDate).format(DEFAULT_DATE_FORMAT);

    return data;
  });

  const columns: ColumnsType<IHRHolidayGroup> = [
    { title: 'S.N.', key: 'id', width: 4, render: (_, __, index) => index + 1 },
    {
      title: 'Group',
      key: 'name',
      dataIndex: 'name',
      width: 400,
      render: (name, record) => <Link to={`/hr/groups/${record.groupId}`}>{name}</Link>
    }
  ];

  return (
    <Spin spinning={isLoading || isFetching}>
      <AppContent breadcrumbItems={[{ label: 'Holiday', link: '/hr/holiday' }, { label: 'View' }]}>
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <PageHeader subTitle="Details" style={{ padding: '8px 0' }} />
            <Space direction="vertical" size="small">
              <Typography.Text>
                <strong>Name:</strong> {data?.holiday.name}
              </Typography.Text>
              <Typography.Text>
                <strong>Description:</strong> {data?.holiday.description}
              </Typography.Text>
            </Space>
          </div>

          <div>
            <PageHeader subTitle="Dates" style={{ padding: '8px 0' }} />
            <Space direction="vertical" size="small">
              <Typography.Text>
                <strong>From:</strong> {data?.holiday.startDate}
              </Typography.Text>
              <Typography.Text>
                <strong>End:</strong> {data?.holiday.endDate}
              </Typography.Text>
            </Space>
          </div>
        </div>

        <div>
          <PageHeader title="Assigned To" style={{ padding: '8px 0 2px' }} />
          {data?.groups.length === 0 && (
            <Typography.Text>
              <strong>No group is assigned to this holiday.</strong>
            </Typography.Text>
          )}

          {data && data.groups.length > 0 && (
            <div>
              <p>This holiday is assigned to following groups.</p>
              <GenericTable
                data={data?.groups || []}
                columns={columns}
                hideDefaultPagination
                scroll={{ x: 600 }}
              />
            </div>
          )}
        </div>

        <div className="flex justify-end mt-5 gap-4">
          {checkAccess('UPDATE_HOLIDAY') && (
            <Button
              type="primary"
              htmlType="button"
              onClick={() => navigate(`/hr/holiday/details/${holidayID}`)}>
              Edit Holiday
            </Button>
          )}

          <Button type="default" htmlType="button" onClick={() => navigate(-1)}>
            Go back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewHoliday;
