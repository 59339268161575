import moment from 'moment';
import { FormInstance, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { IAgentsTableProps } from '@/services/agents/types';

interface Props {
  form: FormInstance;
  pagination: { page: number; total: number; size: number; onPagination: (page: number) => void };
  data: IAgentsTableProps[];
  onClickDisabled: (value: IAgentsTableProps) => void;
}

function Table({ pagination, data, onClickDisabled, form }: Props) {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'S.N',
      dataIndex: 'sn',
      key: 'sn',
      width: 20
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 100
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 120
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 70,
      render: (value: string) => moment(value).format('YYYY-MM-DD')
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 30,
      render: (record: IAgentsTableProps) => {
        const actions = [
          {
            key: 'edit-agents',
            label: 'Edit',
            onClick: () => navigate(`/users/agents/${record.id}`)
          },
          {
            key: 'delete-agents',
            label: `${record.isDisabled ? 'Enable' : 'Disable'} agent`,
            onClick: () => onClickDisabled(record)
          }
        ];

        const menu = <Menu items={actions} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <CustomizeTable
      form={form}
      columns={columns}
      data={data}
      notshowPagination
      customScroll={{ x: 700, y: '75vh' }}
      tableName="agents-list"
      paginationDatas={pagination}
    />
  );
}

export default Table;
