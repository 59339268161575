import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { create_payroll_mutation } from '@/services/hr/mutations';
import { get_user_details } from '@/services/users/queries';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  FormListOperation,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Select,
  Spin
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  taxId: number;
  userId: number;
  name: string;
  payrollLines: { name: string; totalAmount: number }[];
}

function CreatePayroll() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const selectedUserId = Form.useWatch('userId', form);
  const [prevSelectedEmployee, setPrevSelectedEmployee] = useState<number>();
  const [employeeTax, setEmployeeTax] = useState<{ label: string; value: number }[]>([]);

  async function onFinish(values: FormValues) {
    try {
      setIsModalOpen(false);
      setIsLoading(true);

      await create_payroll_mutation(values);
      message.success('Payroll created successfully');
      navigate('/hr/payroll');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  async function onCreate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  async function onUserChange(userId: number) {
    try {
      setIsLoading(true);
      if (!userId || prevSelectedEmployee === userId) return;
      setPrevSelectedEmployee(userId);

      const userDetails = await get_user_details(userId);
      const taxList = userDetails.tax_informations.map((tax) => ({
        label: `${tax.taxNumber} (${tax.taxType})`,
        value: tax.id as number
      }));

      setEmployeeTax(taxList);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function onAddLines(add: FormListOperation['add']) {
    const values = form.getFieldValue('payrollLines') as FormValues['payrollLines'];
    const lastLine = values[values.length - 1];
    const isLastLineFilled = lastLine ? lastLine.name && lastLine.totalAmount : true;
    if (!isLastLineFilled) {
      message.error('Please fill out the last line before adding more.');
      return;
    }

    add({ name: '', totalAmount: 0 });
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={form.submit}>
        <p>Are you sure you want to create this payroll?</p>
      </Modal>

      <AppContent
        breadcrumbItems={[
          { label: 'HR', link: '/hr' },
          { label: 'Payroll', link: '/hr/payroll' },
          { label: 'Create' }
        ]}>
        <PageHeader title="Create Payroll" className="small-title" style={{ padding: '8px 0' }} />

        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{ payrollLines: [{ name: '', totalAmount: 0 }] }}>
          <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <Form.Item
              name={'name'}
              label="Name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>

            <UserSearchV2
              hasParentFormItem={false}
              name="userId"
              label="Employee"
              required
              placeHolder="Select Employee"
              allowClear={false}
              onSelect={onUserChange}
            />

            <Form.Item
              name={'taxId'}
              label="Tax"
              hidden={!selectedUserId}
              rules={[{ required: true, message: 'Please select tax!' }]}>
              <Select placeholder="Select Tax" options={employeeTax} />
            </Form.Item>
          </div>

          <div>
            <PageHeader
              title="Payroll Details"
              style={{ padding: '8px 0 2px' }}
              className="small-title"
            />

            <Form.List name="payrollLines">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key} className="flex gap-2 items-center">
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card w-full"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div className={'grid grid-cols-1 gap-2 sm:grid-cols-3 lg:grid-cols-6'}>
                          <Form.Item
                            name={[field.name, 'name']}
                            label={'Name'}
                            rules={[{ required: true }]}>
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name={[field.name, 'totalAmount']}
                            label={'Amount'}
                            rules={[{ required: true }]}>
                            <InputNumber controls={false} style={{ color: 'black' }} />
                          </Form.Item>

                          <div className="flex items-center justify-start mt-5">
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="green"
                              onClick={async () => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Add Button */}
                  <Form.Item hidden={fields.length >= 7}>
                    <Button type="dashed" onClick={() => onAddLines(add)} block>
                      <PlusOutlined /> Add Payroll
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={onCreate} disabled={isLoading}>
              Create Payroll
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CreatePayroll;
