import CustomAddUserIcon from '@/components/Common/CustomIcons/CustomAddUserIcon';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import { IHRShift, IHRShiftList } from '@/services/hr/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { FormInstance, Menu, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  isLoading?: boolean;
  data: IHRShiftList;
  form: FormInstance;
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<void>;
}

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IHRShift>>({});

  const handleChange: TableProps<IHRShift>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IHRShift>);
  };

  const columns: ColumnsType<IHRShift> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (name: string, record) => <Link to={`/hr/shift/${record.id}`}>{name}</Link>
    },
    {
      title: 'Employees',
      key: 'total',
      width: 7,
      sorter: (a, b) => a.shiftUsers.length - b.shiftUsers.length,
      sortOrder: sortedInfo.columnKey === 'total' ? sortedInfo.order : null,
      render: (record) => record.shiftUsers.length
    },
    {
      title: 'Start',
      key: 'startDate',
      width: 15,
      dataIndex: 'startDate',
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (startDate: string) => {
        return convertUTCStringtoLocalString(startDate, DEFAULT_DATE_FORMAT);
      }
    },
    {
      title: 'End',
      key: 'endDate',
      width: 15,
      dataIndex: 'endDate',
      sorter: (a, b) => a.endDate.localeCompare(b.endDate),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (endDate: string) => {
        return convertUTCStringtoLocalString(endDate, DEFAULT_DATE_FORMAT);
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 7,
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null
    },
    {
      title: 'Checkout',
      key: 'checkoutType',
      width: 7,
      dataIndex: 'checkoutType',
      sorter: (a, b) => a.checkoutType.localeCompare(b.checkoutType),
      sortOrder: sortedInfo.columnKey === 'checkoutType' ? sortedInfo.order : null
    },
    {
      title: 'Last Updated',
      key: 'lastUpdated',
      width: 15,
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'lastUpdated' ? sortedInfo.order : null,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 6,
      fixed: 'right',
      render: (record: IHRShift) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: 'view',
            label: <CustomViewIcon link={`/hr/shift/${record.id}`} />
          }
        ];

        if (checkAccess('UPDATE_HR_SHIFT')) {
          menuItems.push({
            key: 'update',
            label: <CustomUpdateIcon link={`/hr/shift/details/${record.id}`} />
          });
        }

        if (checkAccess('READ_USER')) {
          menuItems.push({
            key: 'assign',
            label: (
              <CustomAddUserIcon
                link={`/hr/shift/assign/${record.id}`}
                label="Assign to Employees"
              />
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 1300 }}
        hideDefaultPagination
        tableName={'hr-shift-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
