import AppContent from '@/components/Common/Content/Content';
import { IOfferExcludedCustomerTable } from '@/services/offfers/types';
import { Menu, message, Modal, Spin, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import CustomDeleteIcon from '@/components/Common/CustomIcons/CustomDeleteIcon';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import getErrorMessage from '@/utils/getError';
import { get_offer_excluded_customers } from '@/services/offfers/queries';
import { getCustomer } from '@/services';
import { update_offer_excluded_discount_mutation } from '@/services/offfers/mutations';

function OfferExcludedCustomerList() {
  const [isLoading, setIsLoading] = useState(false);

  const [customers, setCustomers] = useState<IOfferExcludedCustomerTable[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IOfferExcludedCustomerTable>>({});

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState<{ id: number; name: string }>();

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const excludedCustomers = await get_offer_excluded_customers();
        const updatedCustomer = await Promise.all(
          excludedCustomers.map(async (customer) => {
            const detail = await getCustomer(customer.customerId);
            return {
              ...customer,
              customer: detail.user.name,
              phone: detail.user.phone,
              email: detail.user.email
            };
          })
        );

        setCustomers(updatedCustomer);
      } catch (error) {
        message.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleChange: TableProps<IOfferExcludedCustomerTable>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<IOfferExcludedCustomerTable>);
  };

  function handleDeleteClick(record: IOfferExcludedCustomerTable) {
    setIsModalVisible(true);
    setSelected({ id: record.id, name: record.customer });
  }

  async function handleExclude() {
    try {
      setIsLoading(true);
      if (!selected) {
        return message.error('Please select the customer to exclude');
      }

      setIsModalVisible(false);

      const updatedCustomers = customers.filter((customer) => customer.id !== selected.id);
      const ids = updatedCustomers.map((customer) => customer.customerId);
      await update_offer_excluded_discount_mutation(ids);
      setCustomers(updatedCustomers);
      setSelected(undefined);
      message.success('Customer has been excluded successfully');
    } finally {
      setIsLoading(false);
    }
  }

  const columns: ColumnsType<IOfferExcludedCustomerTable> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 4,
      render: (a, __, index) => index + 1
    },
    {
      title: 'Customer',
      key: 'name',
      width: 15,
      dataIndex: 'customer',
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null
    },
    {
      title: 'Email',
      key: 'email',
      width: 15,
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 8,
      dataIndex: 'phone',
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 10,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT);
      }
    }
  ];

  if (checkAccess('UPDATE_DISCOUNT')) {
    columns.push({
      title: 'Action',
      key: 'actions',
      width: 4,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: { key: string; label: JSX.Element; onClick?: () => void }[] = [
          {
            key: 'update',
            label: <CustomDeleteIcon onClick={() => handleDeleteClick(record)} />
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    });
  }

  return (
    <Spin spinning={isLoading}>
      <Modal
        title="Remove Customer"
        width={600}
        visible={isModalVisible && selected !== undefined}
        onOk={handleExclude}
        onCancel={() => setIsModalVisible(false)}>
        <p>
          Are you sure you want to remove <strong>{selected?.name || 'customer'}</strong> from offer
          excluded list?
        </p>
      </Modal>
      <AppContent
        breadcrumbItems={[
          { label: 'Offer', link: '/offer/discount' },
          { label: 'Excluded Customers', link: '/offer/excluded-customers' }
        ]}
        withfilter={false}
        button={
          <>
            {checkAccess('UPDATE_DISCOUNT') && (
              <div>
                <CustomButton
                  marginLeft="0"
                  marginTop="2px"
                  text="Update"
                  backgroundColor="#1890ff"
                  Linkto="/offer/excluded-customers/details"
                />
              </div>
            )}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={customers}
          notshowPagination={true}
          customScroll={{ x: 900, y: '75vh' }}
          tableName={'offers-customer-excluded-list'}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
}

export default OfferExcludedCustomerList;
