import { CalendarOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface CustomCalendarIconProps {
  link?: string;
  title?: string;
  onlyIcon?: boolean;
}

const CustomCalendarIcon: React.FC<CustomCalendarIconProps> = ({
  link,
  title,
  onlyIcon = true
}) => {
  return (
    <Tooltip title={title ? title : 'Calendar'} color="blue">
      {link ? (
        <Link to={link} className="flex gap-2">
          <CalendarOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
          {!onlyIcon && title}
        </Link>
      ) : (
        <CalendarOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
      )}
    </Tooltip>
  );
};

export default CustomCalendarIcon;
