import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import {
  get_lots_details,
  get_product_details,
  get_product_list,
  get_units_list,
  get_product_list_ids
} from '../../../../services/products/queries';
import {
  get_sell_order_details,
  get_sell_order_line_details,
  get_sell_list
} from '../../../../services/sell/queries';
import { useEffect, useState } from 'react';
import { get_invoices_list } from '../../../../services/settings/queries';
import type { ColumnsType } from 'antd/es/table';
import AppContent from '../../../../components/Common/Content/Content';
import { PageHeader, Table, Button, Spin, Divider, message, Menu, Tooltip } from 'antd';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import { get_location_details } from '../../../../services/locations/queries';
import { get_user_details } from '../../../../services/users/queries';
import { createPDF } from '../list/pdfMaker';
import UsersDB from '../../../../store/localstorage/UsersDB';
import ReuseChannel from '../../../channel/Reuse';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import { Link } from 'react-router-dom';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { shortNameHiearchy } from '.';
interface Props {
  id: number;
}
const ReusableSellOrderView: React.FC<Props> = ({ id }) => {
  const [productList, setProductList] = useState<any[]>([]);
  const [lines, setProductLines] = useState<any>([]);
  const [isloading, setIsloading] = useState<boolean>(true);
  const [vendorDetails, setvendorDetails] = useState<any>();
  const [locationDetails, setLocationDetails] = useState<any>();
  const [sellorderDetails, setSellorderDetails] = useState<any>();
  const [invoiceLayouts, setinvoiceLayouts] = useState<any>();
  const [createdUser, setcreatedUser] = useState<any>();
  const [invoiceLists, setInvoiceLists] = useState<any>([]);
  const [data, setData] = useState({
    taxable: 0,
    nonTaxable: 0,
    vat: 0
  });
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setInvoiceLists(response.data);
    setinvoiceLayouts(response.data[0]);
    return response;
  });
  const [allSellData, setAllSellData] = useState<any>();
  useEffect(() => {
    getInfo();
  }, [id]);

  const getInfo = async () => {
    setIsloading(true);

    const response: any = await get_sell_order_details(id);
    let createdByDetails: any;
    if (response.createdBy) {
      createdByDetails = await UsersDB.getUser(response.createdBy);
      if (!createdByDetails) {
        createdByDetails = (await get_user_details(response.createdBy)).user;
        UsersDB.addUsers([createdByDetails]);
      }
    }
    setcreatedUser(createdByDetails);
    const linesResponse = await get_sell_order_line_details(response.data.id);
    const totalQuantity: any = {};
    if (linesResponse.data.length > 0) {
      // const allProducts = [...productList];
      // for (let i = 0; i < linesResponse.data.length; i++) {
      //   let findProduct = allProducts.find((value) => value.id == linesResponse.data[i].productId);
      //   if (!findProduct) {
      //     findProduct = await get_product_details(linesResponse.data[i].productId);
      //     allProducts.push(findProduct);
      //   }
      //   linesResponse.data[i].productName = findProduct.name;
      //   // await checkAndGetLot(linesResponse.data[i].productId);
      //   let findUnit: any = await UnitsDB.getUnit(linesResponse.data[i].unitId);
      //   if (!findUnit) {
      //     const allUnits = await get_units_list();
      //     await UnitsDB.addUnits(allUnits);
      //     findUnit = await UnitsDB.getUnit(linesResponse.data[i].unitId);
      //   }
      //   linesResponse.data[i].unitName = findUnit.name;
      // }
      // //   console.log('all Products', allProducts);
      // setProductList(allProducts);
      const searchProducts: any = {};
      let taxable = 0;
      let nonTaxable = 0;
      let vatTotal = 0;
      for (let index = 0; index < linesResponse.data.length; index++) {
        const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
        if (!product) {
          // product = await get_product_details(linesResponse.lines[index].productId);
          // await ProductsDB.addProducts([product]);
          if (linesResponse.data[index].productId in searchProducts) {
            searchProducts[linesResponse.data[index].productId] = [
              ...searchProducts[linesResponse.data[index].productId],
              index
            ];
          } else {
            searchProducts[linesResponse.data[index].productId] = [index];
          }
        } else {
          linesResponse.data[index].productName = product.name;
        }

        let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        }
        if (!totalQuantity[findUnit.shortName]) {
          totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
        } else {
          totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
        }
        // linesResponse.lines[index].unitName = findUnit.name;
        linesResponse.data[
          index
        ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;

        const vat =
          (linesResponse.data[index].unitPrice * linesResponse.data[index].quantity -
            linesResponse.data[index].discount) *
          (product.vat / 100);
        linesResponse.data[index].vat = vat;
        if (vat > 0) {
          vatTotal += vat;
          linesResponse.data[index].totalAmount = linesResponse.data[index].totalAmount - vat;
          taxable += linesResponse.data[index].totalAmount;
        } else {
          nonTaxable += linesResponse.data[index].totalAmount;
        }
      }
      taxable += response.data.shipping - response.data.shippingTax;
      setData({ taxable: taxable, nonTaxable: nonTaxable, vat: vatTotal });
      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: any) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
    }
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        shortName: key
      });
    }
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString += ` ${totalQuantityArray[ind].tqty} ${totalQuantityArray[ind].shortName}`;
    }

    if (typeof response.data.misc == 'string') {
      response.data.misc = parseInt(response.data.misc);
    }
    if (response.data?.locationId) {
      let locData: any = await LocationsDB.getLocation(response.data.locationId);
      if (!locData) {
        locData = await get_location_details(response.data.locationId);
        LocationsDB.addLocations([locData]);
      }
      setLocationDetails(locData);
      //   console.log('Location from location Id', locData);
    }
    if (response.data?.address.userId) {
      let userData: any = await UsersDB.getUser(response.data.address.userId);
      if (!userData) {
        userData = (await get_user_details(response.data.address.userId)).user;
        await UsersDB.addUsers([userData]);
      }
      setvendorDetails(userData);
      //   console.log('UserData-->', userData);
    }
    //console.log('Line response data--->', linesResponse.data);
    setProductLines(linesResponse.data);
    // console.log('Response data--->', response.data);
    setSellorderDetails({ ...response.data, totalQuantity: totalQuantityString });
    setAllSellData({
      lines: linesResponse.data,
      ...response.data,
      userId: response.data.address.userId
    });
    setIsloading(false);
  };

  const columns: ColumnsType<{ name: string }> = [
    {
      title: `S.N`,
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.productLabel : 'ProductName'}`,
      dataIndex: 'productName',
      width: 100,
      render: (text: string) => <a>{text}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.unitPriceLabel : 'UnitPrice'}`,
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <a>{text}</a>
    },
    {
      title: `${'UnitName'}`,
      dataIndex: 'unitName',
      width: 100,
      render: (text: number) => <a>{text}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.quantityLabel : 'Quantity'}`,
      dataIndex: 'quantityWithUnit',
      width: 100,
      render: (text: number) => <a>{text}</a>
    },
    {
      title: 'Misc',
      dataIndex: 'misc',
      width: 100,
      render: (text: number) => <a>{nepaliNumberFormatter(text)}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.discountLabel : 'Discount'}`,
      dataIndex: 'discount',
      width: 100,
      render: (text: number) => <a>{nepaliNumberFormatter(text)}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.vatColumnLabel : 'VAT'}`,
      dataIndex: 'vat',
      width: 100,
      render: (text: number) => <a>{nepaliNumberFormatter(text)}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.totalLabel : 'Total Amount'}`,
      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <a>{nepaliNumberFormatter(text)}</a>
    }
  ];

  return (
    <div>
      <Spin spinning={isloading}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader
              subTitle="Customer"
              style={{
                padding: '8px 0px'
              }}
            />
            {vendorDetails ? (
              <>
                <div style={{ color: 'black' }}>
                  {vendorDetails?.name ? vendorDetails.name : ''}
                  {/* </p>
                  <p> */}
                  {`${vendorDetails?.phone ? `,${vendorDetails.phone}` : ''} ${
                    vendorDetails.email ? `,${vendorDetails.email}` : ''
                  }`}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <PageHeader
              subTitle="Location"
              style={{
                padding: '8px 0px'
              }}
            />
            {locationDetails ? (
              <>
                <div style={{ color: 'black' }}>
                  {locationDetails?.name ? locationDetails.name : ''}
                  {/* </p>
              <p> */}
                  {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                    locationDetails?.address ? `${locationDetails.address},` : ''
                  } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                    locationDetails?.country ? `${locationDetails.country}` : ''
                  }`}
                  {/* </p>
              <p> */}
                  {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                    locationDetails?.email ? `${locationDetails.email}` : ''
                  }`}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <PageHeader
              subTitle="Details"
              style={{
                padding: '8px 0px'
              }}
            />
            {sellorderDetails ? (
              <>
                <div style={{ color: 'black' }}>
                  Created : {convertLocalToUTCString(sellorderDetails.createdAt, 'YYYY-MM-DD')}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={lines ? lines : []}
          rowKey={'id'}
          pagination={false}
          scroll={{ y: 400, x: true }}
        />
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader
              subTitle="Payment Info"
              style={{
                padding: '8px 0px'
              }}
            />
            {sellorderDetails ? (
              <>
                <div style={{ color: 'name' }}>
                  <span className="block">Total Quantity:{sellorderDetails.totalQuantity}</span>
                  <span className="block">
                    Total Amount : {nepaliNumberFormatter(sellorderDetails.totalAmount)}
                  </span>
                  <span className="block">
                    Total Tax : {nepaliNumberFormatter(sellorderDetails.totalTax)}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 mb-5">
          {sellorderDetails?.note ? (
            <>
              <PageHeader
                subTitle="Note"
                style={{
                  padding: '8px 0px'
                }}
              />

              <div style={{ color: 'black' }}>{sellorderDetails.note}</div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="flex justify-end  gap-5 mt-5">
          {/* <Button
            type="primary"
            onClick={() => {
              const { createdAt, totalAmount, totalTax } = sellorderDetails;
              createPDF({
                createdAt,
                totalAmount,
                totalTax,
                lines,
                invoiceLayouts,
                vendorDetails,
                locationDetails,
                columns: [
                  `${invoiceLayouts ? invoiceLayouts.content.productLabel : 'Product'}`,
                  `${invoiceLayouts ? invoiceLayouts.content.unitPriceLabel : 'unit price'}`,
                  `${'unit Name'}`,
                  `${invoiceLayouts ? invoiceLayouts.content.quantityLabel : 'quantity'}`,
                  'Misc',
                  `${invoiceLayouts ? invoiceLayouts.content.discountLabel : 'discount'}`,
                  `${invoiceLayouts ? invoiceLayouts.content.totalLabel : 'total'}`
                ],
                columnspay: ['Date', 'Reference No', 'Amount', 'Payment Mode', 'Note']
              });
            }}>
            Print
          </Button> */}
          {/* <Link
            to="/custom-invoice"
            target={'_blank'}
            className="link"
            onClick={() =>
              localStorage.setItem(
                'referrer',
                JSON.stringify({
                  purchaseDetails: sellorderDetails,
                  locationDetails,
                  customerDetails: vendorDetails,
                  lines,
                  invoiceLayouts,
                  createdByDetails: createdUser
                })
              )
            }>
            <Button type="primary">Print</Button>
          </Link> */}
          <div
            style={{
              width: '3rem',
              // backgroundColor: 'red',
              // border: '1px solid gray',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <ActionDropdown
              trigger={'click'}
              insideaction={true}
              menu={
                <Menu
                  items={invoiceLists?.map((curr: any, ind: number) => {
                    const content = JSON.parse(curr.content);
                    return {
                      key: ind,
                      label: (
                        <Tooltip title="Print receipt" color="blue">
                          <div className="text-center">{curr.name}</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        localStorage.setItem(
                          'referrer',
                          JSON.stringify({
                            purchaseDetails: sellorderDetails,
                            locationDetails,
                            customerDetails: vendorDetails,
                            lines,
                            invoiceLayouts,
                            createdByDetails: createdUser
                          })
                        );

                        if (content.design == 'bill') {
                          window.open('/ERP-UI#/billprint', '_blank', 'noopener,noreferrer');
                        } else {
                          window.open(
                            '/ERP-UI#/custom-invoice-sellandsellorder',
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }
                      }
                    };
                  })}
                />
              }
            />
          </div>
        </div>
      </Spin>
      <Divider />
      {/* <ReuseChannel slug={`sell_order_${id}`} /> */}
      <ReuseChannel
        slug={`sell_order_${id}`}
        fromSellorPurchaseId={vendorDetails?.id}
        withReferenceandId={{ reference: 'sell_order', referenceId: id || 0 }}
      />
    </div>
  );
};

export default ReusableSellOrderView;
