import { get_hr_leave_details } from '@/services/hr/queries';
import { useQuery } from '@tanstack/react-query';
import { Button, Descriptions, Image, message, Modal, PageHeader, Spin, Tag } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { getImagesFromServer } from '@/services/upload/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { getUser } from '@/services';
import AppContent from '@/components/Common/Content/Content';
import { CalendarOutlined, FileTextOutlined, UserOutlined } from '@ant-design/icons';
import { checkAccess } from '@/routes/acl';
import { useState } from 'react';
import { update_hr_leaves_mutation } from '@/services/hr/mutations';
import getErrorMessage from '@/utils/getError';

function ViewLeaves() {
  const params = useParams();
  const leaveId = Number(params.id);
  const navigate = useNavigate();

  const [isDisabledLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isNaN(leaveId) || leaveId <= 0) return <Navigate to={'/hr/leaves'} />;

  const { isLoading, isFetching, data, refetch } = useQuery(
    ['get_hr_leaves_details', leaveId],
    async () => {
      const details = await get_hr_leave_details(leaveId);
      const startDate = moment(details.startDate).format(DEFAULT_DATE_FORMAT);
      const endDate = moment(details.endDate).format(DEFAULT_DATE_FORMAT);

      const [medias, user] = await Promise.all([
        getImagesFromServer(details.mediaIds),
        getUser(details.userId)
      ]);

      return {
        ...details,
        medias: medias.map((m) => m.url),
        startDate,
        endDate,
        userName: user.name,
        originalLeave: details
      };
    }
  );

  async function handleDisabled() {
    try {
      setIsLoading(true);
      setIsModalOpen(false);
      const leave = data?.originalLeave;
      if (!leave) {
        return message.error('Leave not found');
      }

      const { id, ...payload } = leave;
      const updatedLeave = await update_hr_leaves_mutation(id, {
        ...payload,
        isDisabled: !leave.isDisabled
      });

      message.success(`Leave ${updatedLeave.isDisabled ? 'rejected' : 'approved'} Successfully`);
      refetch();
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading || isFetching || isDisabledLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={handleDisabled}>
        <p>Are you sure you want to {data?.isDisabled ? 'approve' : 'reject'} this leave?</p>
      </Modal>

      <AppContent breadcrumbItems={[{ label: 'Leave', link: '/hr/leaves' }, { label: 'View' }]}>
        <div className="card" style={{ borderRadius: '15px', padding: '0px 2px 0px 0px' }}>
          <PageHeader
            className="site-page-header"
            title="Leave Details"
            extra={
              <Tag color={data?.isDisabled ? 'red' : 'green'}>
                {data?.isDisabled ? 'Rejected' : 'Approved'}
              </Tag>
            }
          />
          <Descriptions bordered column={2} size="small" labelStyle={{ width: 200 }}>
            <Descriptions.Item
              span={2}
              label={
                <span>
                  <UserOutlined className="mr-2" />
                  Employee Name
                </span>
              }>
              {data?.userName}
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <span>
                  <CalendarOutlined className="mr-2" />
                  Start Date
                </span>
              }>
              {data?.startDate}
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <span>
                  <CalendarOutlined className="mr-2" />
                  End Date
                </span>
              }>
              {data?.endDate}
            </Descriptions.Item>

            <Descriptions.Item
              span={2}
              label={
                <span>
                  <FileTextOutlined className="mr-2" />
                  Description
                </span>
              }>
              {data?.description}
            </Descriptions.Item>

            {data?.medias && data.medias.length > 0 && (
              <Descriptions.Item
                label={
                  <span>
                    <FileTextOutlined className="mr-2" />
                    Attachments
                  </span>
                }>
                <Image.PreviewGroup>
                  <div className="flex gap-2">
                    {data?.medias.map((media, index) => (
                      <div
                        key={`media-${index}`}
                        className="w-[104px] h-[104px] p-2 rounded-sm flex items-center justify-center"
                        style={{ border: '1px solid #d9d9d9' }}>
                        <Image src={media} className="object-contain" />
                      </div>
                    ))}
                  </div>
                </Image.PreviewGroup>
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>

        <div className="flex justify-end mt-5 gap-1.5">
          {checkAccess('UPDATE_LEAVES') && (
            <Button
              type="primary"
              htmlType="button"
              onClick={() => navigate(`/hr/leaves/details/${leaveId}`)}>
              Edit Leave
            </Button>
          )}

          {checkAccess('UPDATE_LEAVES') && (
            <Button
              danger={!data?.isDisabled}
              type="primary"
              htmlType="button"
              onClick={() => setIsModalOpen(true)}
              ghost>
              {data?.isDisabled ? 'Approve' : 'Reject'} Leave
            </Button>
          )}
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewLeaves;
