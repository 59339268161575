import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import VerticalForm from '@/components/FakeForm/VerticalForm';
import { update_payroll_mutation } from '@/services/hr/mutations';
import { get_payroll_details } from '@/services/hr/queries';
import { IPayrollPayload } from '@/services/hr/types';
import { get_user_details } from '@/services/users/queries';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Form,
  FormListOperation,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Select,
  Spin
} from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

function UpdatePayroll() {
  const [form] = Form.useForm<IPayrollPayload>();
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const payrollId = Number(params.id);
  if (isNaN(payrollId)) return <Navigate to="/hr/payroll" />;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const selectedUserId = Form.useWatch('userId', form);
  const [employeeTax, setEmployeeTax] = useState<{ label: string; value: number }[]>([]);

  const {
    isLoading: isDetailLoading,
    isFetching,
    data
  } = useQuery(['get_payroll_details', payrollId], async () => {
    const { payroll, lines } = await get_payroll_details(payrollId);
    const userDetails = await get_user_details(payroll.userId);
    const taxList = userDetails.tax_informations.map((tax) => ({
      label: `${tax.taxNumber} (${tax.taxType})`,
      value: tax.id as number
    }));

    const payrollLines = lines.map((line) => ({
      id: line.id,
      name: line.name,
      totalAmount: line.totalAmount,
      payloadId: line.payrollId
    }));

    form.setFieldsValue({
      name: payroll.name,
      userId: payroll.userId,
      taxId: payroll.taxId,
      payrollLines
    });

    setEmployeeTax(taxList);

    return { user: userDetails.user };
  });

  async function onFinish(values: IPayrollPayload) {
    try {
      setIsModalOpen(false);
      setIsLoading(true);

      await update_payroll_mutation(payrollId, values);
      message.success('Payroll updated successfully');
      navigate(`/hr/payroll/${payrollId}`);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  async function onUpdate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  async function onAddLines(add: FormListOperation['add']) {
    const values = form.getFieldValue('payrollLines') as IPayrollPayload['payrollLines'];
    const lastLine = values[values.length - 1];
    const isLastLineFilled = lastLine ? lastLine.name && lastLine.totalAmount : true;
    if (!isLastLineFilled) {
      message.error('Please fill out the last line before adding more.');
      return;
    }

    add({ name: '', totalAmount: 0 });
  }

  return (
    <Spin spinning={isLoading || isDetailLoading || isFetching}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={form.submit}>
        <p>Are you sure you want to update this payroll?</p>
      </Modal>

      <AppContent
        breadcrumbItems={[
          { label: 'HR', link: '/hr' },
          { label: 'Payroll', link: '/hr/payroll' },
          { label: 'Update' }
        ]}>
        <PageHeader title="Update Payroll" className="small-title" style={{ padding: '8px 0' }} />

        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <Form.Item
              name={'name'}
              label="Name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>

            <VerticalForm label="Employee" value={data?.user?.name} />

            <Form.Item name={'userId'} label="Employee" hidden>
              <InputNumber />
            </Form.Item>

            <Form.Item
              name={'taxId'}
              label="Tax"
              hidden={!selectedUserId}
              rules={[{ required: true, message: 'Please select tax!' }]}>
              <Select placeholder="Select Tax" options={employeeTax} />
            </Form.Item>
          </div>

          <div>
            <PageHeader
              title="Payroll Details"
              style={{ padding: '8px 0 2px' }}
              className="small-title"
            />

            <Form.List name="payrollLines">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key} className="flex gap-2 items-center">
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card w-full"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div className={'grid grid-cols-1 gap-2 sm:grid-cols-3 lg:grid-cols-6'}>
                          <Form.Item
                            name={[field.name, 'name']}
                            label={'Name'}
                            rules={[{ required: true }]}>
                            <Input />
                          </Form.Item>

                          <Form.Item name={'id'} hidden>
                            <InputNumber />
                          </Form.Item>

                          <Form.Item name={'payrollId'} hidden>
                            <InputNumber />
                          </Form.Item>

                          <Form.Item
                            name={[field.name, 'totalAmount']}
                            label={'Amount'}
                            rules={[{ required: true }]}>
                            <InputNumber controls={false} style={{ color: 'black' }} />
                          </Form.Item>

                          <div className="flex items-center justify-start mt-5">
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="green"
                              onClick={async () => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Add Button */}
                  <Form.Item hidden={fields.length >= 7}>
                    <Button type="dashed" onClick={() => onAddLines(add)} block>
                      <PlusOutlined /> Add Payroll
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={onUpdate} disabled={isLoading}>
              Update Payroll
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default UpdatePayroll;
