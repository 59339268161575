export enum EWeekDays {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY'
}

export enum EShiftType {
  'One-Time' = 'One-Time',
  'Recurring' = 'Recurring'
}

export enum EShiftCheckOut {
  NONE = 'NONE',
  NOON = 'NOON',
  MIDNIGHT = 'MIDNIGHT'
}
