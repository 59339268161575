import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { IUser } from '@/services/auth/types';
import { get_hr_user_info_userId } from '@/services/hr/queries';
import { IHRUserInfo, IHRUserInfoPayload } from '@/services/hr/types';
import getErrorMessage from '@/utils/getError';
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Select,
  Spin
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { bloodGroupOptions, genderOptions, maritalStatusOptions } from '../mutation.constant';
import { create_hr_user_info_mutation } from '@/services/hr/mutations';
import isAxiosError from '@/utils/isAxiosError';

interface FormValues extends IHRUserInfoPayload {
  joinDateMoment: moment.Moment;
  terminationDateMoment: moment.Moment | null;
}

function HRUserInfoCreate() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const [prevSelectedUser, setPrevSelectedUser] = useState<number>();
  const [usersInfo, setUsersInfo] = useState<Record<number, IHRUserInfo | null>>({});

  function showErrorModal(id: number) {
    return CustomErrorModal({
      message: (
        <div>
          <p>This employee's information is already recorded in the system.</p>
          <p>If you need to make any changes, you will be redirected to the details page.</p>
        </div>
      ),
      onOkClick: () => navigate(`/hr/details/${id}`),
      onCancel: () => {
        form.setFieldValue('userId', undefined);
        setPrevSelectedUser(undefined);
      },
      closable: true
    });
  }

  async function onUserChange(user?: IUser) {
    try {
      setIsLoading(true);
      if (!user || prevSelectedUser === user.id) return;
      setPrevSelectedUser(user.id);

      // If in cache, return
      const cacheUserInfo = usersInfo[user.id];

      // No user Information Present
      if (cacheUserInfo === null) return;

      // User Information Present, so show modal
      if (cacheUserInfo) {
        showErrorModal(cacheUserInfo.id);
        return;
      }

      // Fetch User Information if not in cache
      const employeeInfo = await get_hr_user_info_userId(user.id);
      setUsersInfo({ ...usersInfo, [user.id]: employeeInfo });

      if (employeeInfo) showErrorModal(employeeInfo.id);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      setIsModalOpen(false);

      const { joinDateMoment, terminationDateMoment, ...payload } = values;
      payload.joinDate = joinDateMoment?.format('YYYY-MM-DD');
      payload.dateOfTermination = terminationDateMoment
        ? terminationDateMoment?.format('YYYY-MM-DD')
        : null;

      await create_hr_user_info_mutation(payload);
      message.success('Information added successfully.');
      navigate('/hr');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  async function onConfirmation() {
    setIsModalOpen(false);
    form.submit();
  }

  async function onCreate() {
    const values = await form.validateFields();
    const cacheUserInfo = usersInfo[values.userId];
    if (cacheUserInfo) {
      showErrorModal(cacheUserInfo.id);
      return;
    }
    setIsModalOpen(true);
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={onConfirmation}>
        <p>Are you sure you want to add this information?</p>
      </Modal>

      <AppContent breadcrumbItems={[{ label: 'Employee', link: '/hr' }, { label: 'Add Details' }]}>
        <PageHeader title="Add Information" style={{ padding: '8px 0' }} />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <UserSearchV2
            hasParentFormItem={false}
            name={'userId'}
            label="Employee"
            required
            setSelected={onUserChange}
          />

          <div className="mt-4">
            <Collapse activeKey={['1', '2', '3']}>
              <Collapse.Panel header="Personal Information" key="1">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[{ required: true, message: 'Please select gender!' }]}>
                    <Select
                      options={genderOptions.map((gender) => ({ value: gender, label: gender }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Blood Group"
                    name="bloodGroup"
                    rules={[{ required: false, message: 'Please select blood group!' }]}>
                    <Select
                      options={bloodGroupOptions.map((bloodGroup) => ({
                        value: bloodGroup,
                        label: bloodGroup
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Martial Status"
                    name="maritalStatus"
                    rules={[{ required: false, message: 'Please select martial status!' }]}>
                    <Select
                      options={maritalStatusOptions.map((maritalStaus) => ({
                        value: maritalStaus,
                        label: maritalStaus
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Permanent Location"
                    name="permanentLocation"
                    rules={[{ required: true, message: 'Please input permanent location!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Current Location"
                    name="currentLocation"
                    rules={[{ required: true, message: 'Please input current location!' }]}>
                    <Input />
                  </Form.Item>
                </div>
              </Collapse.Panel>

              <Collapse.Panel header="Bank Information" key="2">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                  <Form.Item
                    label="Account Number"
                    name="accountNo"
                    rules={[{ required: true, message: 'Please input account number!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Bank Name"
                    name="bankName"
                    rules={[{ required: true, message: 'Please input bank name!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Branch"
                    name="branchName"
                    rules={[{ required: true, message: 'Please input branch!' }]}>
                    <Input />
                  </Form.Item>
                </div>
              </Collapse.Panel>

              <Collapse.Panel header="Job Information" key="3">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <Form.Item
                    label="Job Type"
                    name="jobType"
                    rules={[{ required: true, message: 'Please input job type!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Department"
                    name="departments"
                    rules={[{ required: true, message: 'Please input Department!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Join Date"
                    name="joinDateMoment"
                    rules={[{ required: true, message: 'Please input join date!' }]}>
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item label="Termination Date" name="terminationDateMoment">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </div>

                <div className="mt-4">
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: 'Please input description!' }]}>
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={onCreate} disabled={isLoading}>
              Add Information
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default HRUserInfoCreate;
