import { Form, Menu, Tooltip } from 'antd';
import { useState } from 'react';
import { get_log_backup } from '../../../../services/logs/backup/queries';
import { ILogBackup, ILogBackupResults } from '../../../../services/logs/backup/types';
import { ColumnsType, TableProps } from 'antd/lib/table';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import moment from 'moment';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../../utils/convertToUTC';
import { SorterResult } from 'antd/lib/table/interface';
import AppContent from '../../../../components/Common/Content/Content';
import TableFilter from '../../../../components/FliterTable';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import FetchDocument from '../../../../components/Common/FetchDocument';
import { checkAccess } from '../../../../routes/acl';
import DiscussionChatModal from '../../../../components/Common/DiscussionChatModal';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const LogsBackup: React.FC = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logBackup, setLogBackup] = useState<ILogBackupResults>({ total: 0, results: [] });

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const logBackupList = await get_log_backup(filter);
    setLogBackup(logBackupList);
    console.log('logbackup list', logBackupList);
    setIsLoading(false);
  };

  const onSubmitFilter = async (val: string) => {
    getInfo(val);
    setPage(1);
    setSize(100);
  };
  const breadcrumbItems = [
    {
      label: 'Backup',
      link: '/logs/backup'
    }
  ];

  const columns: ColumnsType<ILogBackup> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'createdAt',
      width: 3,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Media Id',
      key: 'mediaId',
      width: 3,
      sorter: (a, b) => a.mediaId - b.mediaId,
      sortOrder: sortedInfo.columnKey === 'mediaId' ? sortedInfo.order : null,
      render: (record: ILogBackup) => {
        return (
          <TableCell>
            <FetchDocument id={record.mediaId} />
          </TableCell>
        );
      }
    },
    {
      title: 'Start Date',
      key: 'startDate',
      width: 3,
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      sortOrder: sortedInfo.columnKey === 'startDate' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.startDate, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'End Date',
      key: 'endDate',
      width: 3,
      sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix(),
      sortOrder: sortedInfo.columnKey === 'endDate' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.endDate, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Created At',
      key: 'createdAt',
      width: 3,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Updated At',
      key: 'updatedAt',
      width: 3,
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 2,
      fixed: 'right',
      render: (record, arr, index) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        menuItems.push({
          key: '1',
          label: (
            <Tooltip title="Download" color="blue">
              <FetchDocument id={record.mediaId} isIcon={true} />
            </Tooltip>
          )
        });

        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '2',
            label: <DiscussionChatModal slug="logs" id={record.id} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);

    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
    setIsLoading(false);
  };
  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <TableFilter
            onPagination={(page, size) => {
              setPage(page);
              setSize(size);
            }}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100
            }}
            dateCustom={true}
            initial={true}
            onSubmit={onSubmitFilter}
            form={form}
            styleforbuttons={'flex justify-end items-center'}
            style={
              'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
            }></TableFilter>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={logBackup.results}
          usersLoading={isLoading}
          notshowPagination={true}
          customScroll={{ x: 500, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: logBackup.total,
            size: size,
            onPagination
          }}
          tableName={'logs-backup-list'}
          toSort={handleChange}
        />
      </AppContent>
    </div>
  );
};

export default LogsBackup;
