import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

interface CustomIconProps {
  onClick?: () => void;
  currentState?: boolean;
}

const CustomDisableIcon: React.FC<CustomIconProps> = ({ onClick, currentState }) => {
  return (
    <Tooltip title={currentState ? 'Enable' : 'Disable'}>
      <ExclamationCircleOutlined
        type="danger"
        onClick={onClick}
        style={{ transform: 'scale(1.2)', width: '105%' }}
      />
    </Tooltip>
  );
};

export default CustomDisableIcon;
