import moment from 'moment';
import GenericTable from '@/components/Common/CustomizeTable';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { TableProps } from 'antd/es/table';
import CopyButton from '@/components/Common/CopyButton';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ISalesRegisterListItemWise } from '@/services/ird/sales/types';
import { FormInstance } from 'antd';

interface Props {
  form: FormInstance;
  data: { total: number; results: ISalesRegisterListItemWise[]; qtySold: number };
  pagination: { page: number; size: number };
  onPagination: (page: number, size: number, isSize?: boolean) => void;
}

function Table({ data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ISalesRegisterListItemWise>>({});

  const handleChange: TableProps<ISalesRegisterListItemWise>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<ISalesRegisterListItemWise>);
  };

  const columns: ColumnsType<ISalesRegisterListItemWise> = [
    {
      title: 'INVOICE',
      width: 100,
      children: [
        {
          title: 'DATE',
          key: 'date',
          width: 20,
          dataIndex: 'date',
          sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
          sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null
        },
        {
          title: 'MITI',
          key: 'miti',
          width: 25,
          dataIndex: 'miti',
          sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
          sortOrder: sortedInfo.columnKey === 'miti' ? sortedInfo.order : null
        },
        {
          title: 'TIME',
          key: 'time',
          width: 20,
          dataIndex: 'time'
        },
        {
          title: 'BILL NUMBER',
          key: 'financialReference',
          className: 'invoice',
          width: 40,
          sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
          sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
          render: (a, record) => {
            return (
              <TableCell className="flex flex-row justify-between items-center text-xs px-1">
                {record.financialReference}
                {record.financialReference && <CopyButton text={record.financialReference} />}
              </TableCell>
            );
          }
        },
        {
          title: 'BUYER NAME',
          key: 'buyersName',
          width: 35,
          sorter: (a, b) =>
            a.buyersName && b.buyersName ? a.buyersName.localeCompare(b.buyersName) : 0,
          sortOrder: sortedInfo.columnKey === 'buyersName' ? sortedInfo.order : null,
          render: (a, record) => {
            return <TableCell>{record.buyersName}</TableCell>;
          }
        },
        {
          title: 'BUYER PAN',
          key: 'customerPan',
          width: 20,
          render: (a, record) => {
            return <TableCell>{record.customerPan}</TableCell>;
          }
        },
        {
          title: 'ITEM NAME',
          key: 'productName',
          dataIndex: 'productName',
          width: 40,
          sorter: (a, b) => a.productName.localeCompare(b.productName),
          sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null
        },
        {
          title: 'QUANTITY',
          key: 'quantity',
          dataIndex: 'quantity',
          width: 20,
          sorter: (a, b) => a.quantity - b.quantity,
          sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
          render: (a) => {
            return <TableCell className="text-right">{nepaliNumberFormatter(a)}</TableCell>;
          }
        },
        { title: 'UNIT', width: 20, key: 'unit', dataIndex: 'unit' }
      ]
    },
    {
      title: 'GROSS SALES AMOUNT',
      width: 26,
      key: 'grossSalesAmount',
      dataIndex: 'grossSalesAmount',
      sorter: (a, b) => a.grossSalesAmount - b.grossSalesAmount,
      sortOrder: sortedInfo.columnKey === 'totalSalesExport' ? sortedInfo.order : null,
      render: (a) => {
        return <TableCell className="text-right">{nepaliNumberFormatter(a)}</TableCell>;
      }
    },
    {
      title: 'TOTAL SALES',
      width: 20,
      key: 'totalSales',
      dataIndex: 'totalSale',
      sorter: (a, b) =>
        a.taxableAmount + a.nonTaxableAmount - (b.taxableAmount + b.nonTaxableAmount),
      sortOrder: sortedInfo.columnKey === 'totalSales' ? sortedInfo.order : null,
      render: (a) => {
        return <TableCell className="text-right">{nepaliNumberFormatter(a)}</TableCell>;
      }
    },
    {
      title: 'NON TAXABLE SALES',
      width: 26,
      key: 'nonTaxableAmount',
      dataIndex: 'nonTaxableAmount',
      sorter: (a, b) => a.nonTaxableAmount - b.nonTaxableAmount,
      sortOrder: sortedInfo.columnKey === 'nonTaxableAmount' ? sortedInfo.order : null,
      render: (a) => {
        return <TableCell className="text-right">{nepaliNumberFormatter(a)}</TableCell>;
      }
    },
    {
      title: 'TAXABLE SALES',
      width: 20,
      children: [
        {
          title: 'AMOUNT',
          key: 'taxableAmount',
          dataIndex: 'taxableAmount',
          width: 25,
          render: (a, record) => {
            return (
              <TableCell className="text-right">
                {nepaliNumberFormatter(record.taxableAmount)}
              </TableCell>
            );
          }
        },
        {
          title: 'VAT',
          key: 'vat',
          dataIndex: 'vat',
          width: 25,
          render: (a, record) => {
            return (
              <TableCell className="text-right">{nepaliNumberFormatter(record.vat)}</TableCell>
            );
          }
        }
      ]
    },
    {
      title: 'EXPORT',
      width: 20,
      children: [
        {
          title: 'AMOUNT',
          width: 20,
          key: 'exportAmount',
          render: (a, record) => {
            return (
              <TableCell className="text-right">
                {record.exportAmount && nepaliNumberFormatter(record.exportAmount)}
              </TableCell>
            );
          }
        },
        {
          title: 'COUNTRY',
          width: 20,
          key: 'exportCountry',
          render: () => {
            return <TableCell>{''}</TableCell>;
          }
        },
        {
          title: 'PGP NUMBER',
          width: 30,
          key: 'lcNumber',
          render: () => {
            return <TableCell>{''}</TableCell>;
          }
        },
        {
          title: 'PGP DATE',
          width: 20,
          key: 'lcDateNumber',
          render: () => {
            return <TableCell>{''}</TableCell>;
          }
        }
      ]
    }
  ];

  return (
    <GenericTable
      form={form}
      columns={columns}
      data={data.results}
      hideDefaultPagination={true}
      scroll={{ x: 2000, y: '75vh' }}
      generateSummary
      summaryClassName="text-right"
      pagination={{
        ...pagination,
        total: data.total,
        onPagination
      }}
      toSort={handleChange}
    />
  );
}

export default Table;
