import { IHRShift } from '@/services/hr/types';
import LocalStore, { getTransactionError } from '.';
import { get_hr_shift_ids } from '@/services/hr/queries';

const DB_NAME = 'Shifts';
const DB_SECONDARY_INDEX = 'ShiftsSecondIndex';

export class ShiftsDB extends LocalStore {
  static async addShifts(data: IHRShift[]) {
    if (!ShiftsDB.db) await ShiftsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ShiftsDB.db.transaction(DB_NAME, 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore(DB_NAME);

      data.forEach(async (shift) => {
        const checkKey = objectStore.count(shift.id);
        checkKey.onerror = (event) => getTransactionError(event, reject, 'add');

        checkKey.onsuccess = async () => {
          const data = { ...shift, lowercaseName: shift.name.trim().toLowerCase() };
          if (checkKey.result == 0) {
            const request = objectStore.add(data);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await ShiftsDB.updateshift(data);
          }
        };
      });
    });
  }

  static async searchShifts(name: string, limit = 10): Promise<IHRShift[]> {
    if (!ShiftsDB.db) await ShiftsDB.init();
    const givenName = name.trim().toLowerCase();

    return new Promise((resolve, reject) => {
      const transaction = ShiftsDB.db.transaction(DB_NAME, 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore(DB_NAME);
      const index = objectStore.index(DB_SECONDARY_INDEX);
      const request = index.openCursor();

      const shifts: IHRShift[] = [];
      let count = 0;

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor && count < limit) {
          const shift = cursor?.value as IHRShift;
          if (shift?.name?.trim()?.toLowerCase()?.includes(givenName)) {
            shifts.push(shift);
            count++;
          }

          cursor.continue();
        } else {
          resolve(shifts);
        }
      };
    });
  }

  static async searchShiftsByUser(name: string, userId: number, limit = 10): Promise<IHRShift[]> {
    if (!ShiftsDB.db) await ShiftsDB.init();
    const givenName = name.trim().toLowerCase();

    return new Promise((resolve, reject) => {
      const transaction = ShiftsDB.db.transaction(DB_NAME, 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore(DB_NAME);
      const index = objectStore.index(DB_SECONDARY_INDEX);
      const request = index.openCursor();

      const shifts: IHRShift[] = [];
      let count = 0;

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor && count < limit) {
          const shift = cursor?.value as IHRShift;

          const isNameAvailable = shift?.name?.trim()?.toLowerCase()?.includes(givenName);

          if (shift && shift.shiftUsers.some((user) => user.id === userId) && isNameAvailable) {
            shifts.push(shift);
            count++;
          }

          cursor.continue();
        } else {
          resolve(shifts);
        }
      };
    });
  }

  static async getAll(): Promise<IHRShift[]> {
    if (!ShiftsDB.db) await ShiftsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ShiftsDB.db.transaction(DB_NAME, 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getShifts');
      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.getAll();
      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
    });
  }

  static async getByID(id: number): Promise<IHRShift> {
    if (!ShiftsDB.db) await ShiftsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ShiftsDB.db.transaction(DB_NAME, 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getShifts');
      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.get(id);

      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      request.onerror = (event) => getTransactionError(event, reject, 'get');
    });
  }

  static async getByUser(id: number): Promise<IHRShift[]> {
    if (!ShiftsDB.db) await ShiftsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ShiftsDB.db.transaction(DB_NAME, 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getShifts');
      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.getAll();

      request.onsuccess = (event) => {
        const shifts: IHRShift[] = (event.target as IDBRequest).result;
        const filteredShifts = shifts.filter((shift) =>
          shift.shiftUsers.some((user) => user.id === id)
        );

        resolve(filteredShifts);
      };

      request.onerror = (event) => getTransactionError(event, reject, 'getByUser');
    });
  }

  static async updateshift(shift: IHRShift) {
    if (!ShiftsDB.db) await ShiftsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ShiftsDB.db.transaction(DB_NAME, 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.get(shift.id);

      request.onerror = (event) => getTransactionError(event, reject, 'update');
      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(shift);
          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };
    });
  }

  static async addIfAbsent(ids: number[]): Promise<void> {
    if (!ShiftsDB.db) await ShiftsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ShiftsDB.db.transaction(DB_NAME, 'readwrite');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore(DB_NAME);
      const request = objectStore.getAll();

      request.onsuccess = async (event) => {
        const shifts: IHRShift[] = (event.target as IDBRequest).result;
        const shiftsIds = shifts.map((shift) => shift.id);

        const idsToAdd = ids.filter((id) => {
          return Boolean(id) && !shiftsIds.includes(id);
        });

        if (idsToAdd.length) {
          const response = await get_hr_shift_ids(idsToAdd);
          await ShiftsDB.addShifts(response.results);
          resolve(undefined);
        } else resolve(undefined);
      };
    });
  }
}
