import AppContent from '@/components/Common/Content/Content';
import { get_payroll_list } from '@/services/hr/queries';
import { IPayrollList } from '@/services/hr/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input } from 'antd';
import { useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import UsersDB from '@/store/localstorage/UsersDB';
import { getUser } from '@/services';

function PayrollList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IPayrollList>({ total: 0, results: [] });

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_payroll_list(filter);
      const data = response.results;

      const uniqueUserIds = Array.from(new Set(data.map((u) => u.userId)));
      await UsersDB.addUsersIfAbsent(uniqueUserIds);

      for (const payroll of data) {
        const user = await getUser(payroll.userId);
        payroll.employeeName = user.name;
      }

      setData(response);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'HR', link: '/hr' }, { label: 'Payroll' }]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            onPagination={(page, size) => setPagination({ page, size })}
            buttons={
              <>
                {checkAccess('CREATE_HR_PAYROLL') && (
                  <CustomButton text="Add" backgroundColor="#1890ff" Linkto="/hr/payroll/new" />
                )}
              </>
            }
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100,
              userId: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <UserSearchV2 name="userId" hasParentFormItem={false} label="Employee" showAll />
            <Form.Item name="value" label="Name">
              <Input />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          data={data}
          form={form}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default PayrollList;
