import CustomSearch, { SelectProps } from '..';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { IProductDetails } from '@/services/products/types';
import { get_product_list_all_status_only } from '@/services/products/queries';
import { message } from 'antd';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IProductDetails) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IProductDetails[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps &
  MultipleProps & {
    locationId?: number;
    showHidden?: boolean;
  };

function ProductSearchByHidden({ setSelected, locationId, showHidden, ...props }: Props) {
  async function dbSearchCallback(name: string, limit?: number) {
    return ProductsDB.searchProductByLocationHidden(name, limit, locationId, showHidden);
  }

  async function serverCallback(skip?: number, count?: number, value?: string, filter?: string) {
    const params = new URLSearchParams(filter || '');
    const updatedHidden = showHidden ?? false;

    if (!locationId) {
      params.set('hidden', updatedHidden.toString());
      params.delete('locationId');
      params.delete('locationHidden');
    } else {
      params.set('locationId', locationId.toString());
      params.set('locationHidden', updatedHidden.toString());
      params.delete('hidden');
    }

    return get_product_list_all_status_only(skip, count, value, params.toString());
  }

  return (
    <CustomSearch
      key={`${locationId}-${showHidden}`}
      formFor="product"
      setSelected={(product) => {
        if (product && !Array.isArray(product)) {
          const currentProduct = product as IProductDetails;
          const hasDefaultUnit = currentProduct.productUnits?.find((unit) => unit.isDefault);
          if (!hasDefaultUnit) {
            message.warning('This product does not have a default unit', 5);
          }
        }

        setSelected?.(product);
      }}
      addCallback={ProductsDB.addProducts}
      serverCallback={serverCallback}
      dbSearchById={ProductsDB.getProductDetails}
      dbSearchCallback={dbSearchCallback}
      {...props}
    />
  );
}

export default ProductSearchByHidden;
