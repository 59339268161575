import { message } from 'antd';
import { AxiosError } from 'axios';

function getErrorMessage(error: unknown, showError = false) {
  if (error instanceof AxiosError) {
    const messages = (error?.response?.data?.message ?? error.message) as string | string[];
    const singleMessage = Array.isArray(messages) ? messages[0] : messages;
    if (showError) message.error(singleMessage);
    return singleMessage;
  }

  if (error instanceof Error) {
    if (showError) message.error(error.message);
    return error.message;
  }

  if (error && typeof error === 'object' && ('message' in error || 'messages' in error)) {
    const errors = error as { message?: string; messages?: string[] };
    const messages = errors.message ?? errors.messages;
    if (messages) {
      const singleMessage = Array.isArray(messages) ? messages[0] : messages;
      if (showError) message.error(singleMessage);
      return singleMessage;
    }
  }

  if (typeof error === 'string') {
    if (showError) message.error(error);
    return error;
  }

  const messages = 'An error occurred. Please try again.';
  if (showError) message.error(messages);

  return messages;
}

export default getErrorMessage;
