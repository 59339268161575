import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { FormInstance } from 'antd/es/form/Form';
import React, { useEffect, useRef, useState } from 'react';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { CloseOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, message, Spin } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';

import { cn, getDeepCopy } from '@/utils';
const { RangePicker } = DatePicker;
import { ConvertObjectToURL } from '../../utils/converturl';
import { convertLocalToUTCString } from '../../utils/convertToUTC';
import {
  CustomDatePresets,
  DashboardDatePresets
} from '../../pages/sqlsource/report/utils/datePresets';
import { find_date_preference, find_time_limit } from '../../store/localstorage/preferences';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '../../utils/nepaliDateConverter';
import {
  deleteUserFilter,
  get_current_page_filter,
  updateUserFilter
} from '@/services/users/services.users';
import CustomErrorModal from '../Common/CustomErrorModal';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore, FilterType } from '@/store/zustand/filter.state';

interface IFilterDrawers {
  defaultValues: any;
  dateCustom?: boolean;
  singleDate?: boolean;
  onSubmit: (value: any) => Promise<void>;
  initial?: boolean;
  form?: FormInstance;
  noUrl?: boolean;
  style?: string;
  buttons?: React.ReactNode;
  styleforbuttons?: string;
  outsidestyle?: string;
  timeVal?: boolean;
  hasHours?: boolean;
  datePresets?: Record<string, [moment.Moment, moment.Moment]>;
  buttonWrapperClassName?: string;
  allowFull?: boolean;
  onPagination?: (page: number, size: number) => void;
}
let timer: any;

const TableFilter: React.FC<IFilterDrawers> = ({
  initial = false,
  onSubmit,
  defaultValues,
  dateCustom = true,
  singleDate = false,
  children,
  form = Form.useForm()[0],
  noUrl,
  buttons,
  styleforbuttons = '',
  outsidestyle = '',
  timeVal = false,
  hasHours = true,
  buttonWrapperClassName = '',
  allowFull = false,
  datePresets,
  onPagination
}) => {
  const location = useLocation();
  const nepaliType = find_date_preference();
  const [showFilters, setShowFilters] = useState(false);
  const formParentRef = useRef<HTMLDivElement | null>(null);
  const filterRef = useRef<HTMLElement | null>(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isFilterSaved, setIsFilterSaved] = useState(false);

  const currentDatePresets = datePresets || (allowFull ? DashboardDatePresets : CustomDatePresets);

  const toggleDrawer = () => {
    setShowFilters((prev) => !prev);
  };

  const closeModal = () => {
    setShowFilters(false);
  };

  function handleKeyboardClick(key: string) {
    if (showFilters && key === 'Enter') {
      filterRef.current?.click();
    }

    if (key === 'Escape') {
      toggleDrawer();
    }
  }

  useEffect(() => {
    const parentElement = formParentRef.current;
    if (!parentElement) return;

    function handleClickOutside(event: KeyboardEvent) {
      event.stopPropagation();
      const target = event.target as HTMLElement;
      const isSelectOpen = target.closest('.ant-select-open');
      setOpenDatePicker(false);

      if (isSelectOpen) return;
      handleKeyboardClick(event.key);
    }

    parentElement.addEventListener('keydown', handleClickOutside, true);
    return () => parentElement.removeEventListener('keydown', handleClickOutside, true);
  }, [showFilters]);

  const zustandFilter = useFilterStore();

  useEffect(() => {
    function handleKeyboard(event: KeyboardEvent) {
      handleKeyboardClick(event.key);
    }

    document.addEventListener('keydown', handleKeyboard);
    return () => document.removeEventListener('keydown', handleKeyboard);
  }, [showFilters]);

  useEffect(() => {
    document.body.style.overflow = showFilters ? 'hidden' : 'auto';
  }, [showFilters]);

  const defaultTime = find_time_limit();
  const onFinish = async (values: any, filterType?: FilterType) => {
    setShowFilters(false);
    // Pagination state is only saved for zustand filter
    if (filterType !== 'local') {
      values.skip = defaultValues.skip || 0;
      values.count = defaultValues.count || 100;
    }

    const originalValues = getDeepCopy(values);

    if (dateCustom) {
      values.endDate = convertLocalToUTCString(values.endDate);
      values.startDate = convertLocalToUTCString(values.startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
    } else {
      delete values.startDate;
      delete values.endDate;
    }
    if (singleDate) {
      values.date = moment(convertLocalToUTCString(values.date)).format('YYYY-MM-DD');
      delete values.dateSingle;
    } else {
      delete values.date;
    }

    const filter = noUrl ? values : ConvertObjectToURL(values);
    zustandFilter.setState(location.pathname, originalValues, filterType);
    await onSubmit(filter);
  };

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['startDate'], dateStrings[0]);
      form.setFieldValue(['endDate'], dateStrings[1]);
    }
  };
  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart) form.setFieldValue(['startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['endDate'], convert_nepali_to_english_date(val));
  };
  const onChangeSingle: DatePickerProps['onChange'] = (dates, dateStrings) => {
    // console.log('date', dateStrings);
    form.setFieldValue(['date'], dateStrings);
  };
  const onChangeSingleNepali = (val: string) => {
    form.setFieldValue(['date'], convert_nepali_to_english_date(val));
  };

  function handleFormChange() {
    const customDateValues = defaultValues.dateCustom;

    if (dateCustom) {
      const nepaliDateStart = convert_string_to_nepali_date_string(
        customDateValues[0].format('YYYY-MM-DD')
      );

      const nepaliDateEnd = convert_string_to_nepali_date_string(
        customDateValues[1].format('YYYY-MM-DD')
      );

      form.setFieldsValue({
        ...defaultValues,
        dateCustom: customDateValues,
        startDateNepali: nepaliDateStart,
        endDateNepali: nepaliDateEnd,
        startDate: customDateValues[0].format('YYYY-MM-DD'),
        endDate: customDateValues[1].format('YYYY-MM-DD')
      });
    } else if (singleDate) {
      const nepaliDate = convert_string_to_nepali_date_string(moment().format());
      const dateFormat = nepaliType ? nepaliDate : moment();
      form.setFieldsValue({
        ...defaultValues,
        dateSingle: dateFormat,
        date: moment().format('YYYY-MM-DD')
      });
    } else {
      form.setFieldsValue({
        ...defaultValues
      });
    }
  }

  useEffect(() => {
    const hasFilterPresist = checkFilterPersist();
    if (hasFilterPresist) return;

    handleFormChange();
    if (initial) {
      if (timeVal) {
        timer = setTimeout(() => {
          onFinish(form.getFieldsValue());
        }, defaultTime * 1000);
      }

      onFinish(form.getFieldsValue());
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  async function onSaveFilter() {
    try {
      setIsLoading(true);
      const formValues = form.getFieldsValue();
      if (formValues['dateCustom']) {
        const dates = formValues['dateCustom'] as [moment.Moment, moment.Moment];
        formValues['dateCustom'] = [dates[0].toISOString(), dates[1].toISOString()];
      }

      if (formValues['dateSingle']) {
        const date = formValues['dateSingle'] as moment.Moment;
        formValues['dateSingle'] = date.toISOString();
      }

      await updateUserFilter(location.pathname, formValues);
      setIsFilterSaved(true);
      message.success('Filter saved successfully for current page');
    } catch (error: any) {
      if (isAxiosError(error)) return;
      if (error.type === 'Error') {
        CustomErrorModal({
          title: 'Error',
          message: error.message || 'Error saving dashboard layout'
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function onResetFilter() {
    try {
      setIsLoading(true);
      handleFormChange();
      await deleteUserFilter(location.pathname);
      message.success('Filter reset successfully for current page');
      setIsFilterSaved(false);
    } catch (error: any) {
      if (isAxiosError(error)) return;
      if (error.type === 'Error') {
        CustomErrorModal({
          title: 'Error',
          message: error.message || 'Error saving dashboard layout'
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const checkFilterPersist = (): boolean => {
    const currentFilter = zustandFilter.getState(location.pathname);
    if (currentFilter) {
      const { filter, pagination } = currentFilter;
      form.setFieldsValue(filter);
      onFinish(filter, 'local').then(() => onPagination?.(pagination.page, pagination.size));
      return true;
    }

    const savedFilter = get_current_page_filter(location.pathname);
    setIsFilterSaved(!!savedFilter);
    if (!savedFilter) return false;

    form.setFieldsValue(savedFilter);
    onFinish(savedFilter, 'saved');
    return true;
  };

  return (
    <div ref={formParentRef}>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        className={outsidestyle ? outsidestyle : ''}
        autoComplete="off">
        <div
          className={cn(
            'flex flex-row relative items-center justify-end mt-2 flex-wrap gap-2 sm:gap-0',
            buttonWrapperClassName
          )}>
          <Button
            className="flex items-center justify-end secondary-button"
            style={{ borderRadius: '9px' }}
            onClick={toggleDrawer}>
            Filters
          </Button>

          {buttons && <div className={styleforbuttons}>{buttons}</div>}
        </div>
        <div
          className={`fixed inset-0 bg-gray-800 bg-opacity-25 z-[1001] transition-opacity duration-300 ${
            showFilters ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
          }`}>
          <div className="fixed inset-0 z-0" onClick={closeModal}></div>
          <div className="fixed top-0 right-0 bottom-0 max-h-[100vh] w-full md:w-1/3 lg:w-1/4 transition-transform duration-300 ">
            <div className="h-full p-6 bg-gray-100 overflow-y-auto">
              <div className="absolute top-3 right-4 cursor-pointer" onClick={closeModal}>
                <CloseOutlined />
              </div>

              <Spin
                spinning={isLoading}
                wrapperClassName="h-full filter-wrapper"
                className="h-full">
                <div className="flex h-full flex-col justify-between gap-2 space-y-2">
                  <div className="grid grid-cols-1 gap-2">
                    <Form.Item label={'Start Date'} name={['skip']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'End Date'} name={['count']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'Select Date'} name={['dateSingle']} hidden={!singleDate}>
                      {nepaliType ? (
                        <NepaliDatePicker
                          onChange={onChangeSingleNepali}
                          value={'2079-09-29'}
                          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                        />
                      ) : (
                        <DatePicker
                          onChange={onChangeSingle}
                          format={'YYYY-MM-DD'}
                          allowClear={false}
                          className="w-full"
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      label={'Select Start Date'}
                      name={['startDateNepali']}
                      hidden={!dateCustom || !nepaliType}>
                      <NepaliDatePicker
                        onChange={(val) => onChangeNepali(val, true)}
                        options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={'Select End Date'}
                      name={['endDateNepali']}
                      hidden={!dateCustom || !nepaliType}>
                      <NepaliDatePicker
                        onChange={(val) => onChangeNepali(val, false)}
                        options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={'Select Date'}
                      name={['dateCustom']}
                      hidden={!dateCustom || nepaliType}>
                      <RangePicker
                        open={openDatePicker}
                        onOpenChange={(status) => setOpenDatePicker(status)}
                        showTime={hasHours}
                        ranges={currentDatePresets}
                        onChange={onChange}
                        className="w-full"
                        format={hasHours ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
                        allowClear={false}
                      />
                    </Form.Item>
                    <Form.Item label={'Date'} name={['date']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'Start Date'} name={['startDate']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'End Date'} name={['endDate']} hidden>
                      <Input />
                    </Form.Item>
                    {/* <div className={`grid grid-cols-${React.Children.count(children)} gap-2`}> */}
                    {children}
                    {/* </div> */}
                  </div>

                  <div className="flex gap-2 justify-end flex-wrap pb-2">
                    <Button
                      type="primary"
                      onClick={onSaveFilter}
                      className="!rounded-md text-base"
                      icon={<SaveOutlined size={20} className="[&>svg]:m-0" />}>
                      Save Filter
                    </Button>

                    {isFilterSaved && (
                      <Button
                        type="default"
                        onClick={onResetFilter}
                        className="!flex justify-center items-center !rounded-md"
                        icon={<DeleteOutlined size={20} className="[&>svg]:m-0" />}>
                        Reset Filter
                      </Button>
                    )}

                    <Button
                      ref={filterRef}
                      type="default"
                      disabled={isLoading}
                      className="!rounded-md !text-green-500 !border !border-green-500 text-base"
                      onClick={() => {
                        if (timer) {
                          clearInterval(timer);
                        }
                        if (timeVal) {
                          timer = setInterval(() => {
                            onFinish(form.getFieldsValue());
                          }, defaultTime * 1000);
                        }
                        onFinish(form.getFieldsValue());
                      }}>
                      Filter
                    </Button>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TableFilter;
