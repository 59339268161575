import { useEffect, useState } from 'react';

const channelName = 'broadcast-channel';

function useBroadcast() {
  const [attendanceStatus, setAttendanceStatus] = useState<'in' | 'out'>('out');

  useEffect(() => {
    const handleSignOut = async (event: MessageEvent) => {
      const { action } = event.data;

      switch (action) {
        case 'checkIn':
          setAttendanceStatus('in');
          break;

        case 'checkOut':
          setAttendanceStatus('out');
          break;

        default:
          break;
      }
    };

    // Listen for deletion messages from other tabs
    const channel = new BroadcastChannel(channelName);
    channel.addEventListener('message', handleSignOut);

    return () => {
      // Remove the event listener when the component unmounts
      channel.removeEventListener('message', handleSignOut);
      channel.close();
    };
  }, []);

  function handleAttendance(type: 'in' | 'out') {
    const channel = new BroadcastChannel(channelName);
    channel.postMessage({ action: type === 'in' ? 'checkIn' : 'checkOut' });
    channel.close();
  }

  return { attendanceStatus, handleAttendance };
}

export default useBroadcast;
