import { CSSProperties, useEffect, useRef, useState } from 'react';
import DataGrid, {
  type ColumnOrColumnGroup,
  DataGridHandle,
  RenderCellProps
} from 'react-data-grid';

import { IRow } from '../../grid.types';
import { Button, Input, InputNumber, message, Modal, Tooltip } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ProductSearchForNonForm from '@/components/Common/CustomSearch/Products/ProductSearchFoNonForm';
import {
  ICreatePurchasePriceAnalysis,
  ICreateSalePriceAnalysis,
  IOutletPricingByDate,
  IProductDetails
} from '@/services/products/types';
import { emptyRow, GRID_MIN_HEIGHT, GRID_ROW_HEIGHT } from '../constant';
import { calculateRowValues, loadLastPurchaseProduct, prefillAllData } from '../services';
import getErrorMessage from '@/utils/getError';
import { create_price_analysis_mutation } from '@/services/products/mutations';
import { CustomModal } from '@/components/Common/CustomModal';
import { Navigate, useNavigate } from 'react-router-dom';
import { getUnit } from '@/services';
import { fetchProductAllPriceDetails } from '../../services';
import { get_create_price_analysis_perms } from '@/routes/acl';

interface Props {
  date?: string;
  priceAnalysis?: IOutletPricingByDate;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExcelLikeGrid = ({ date, setIsLoading, priceAnalysis }: Props) => {
  const navigate = useNavigate();

  // State to manage the grid data
  const [rows, setRows] = useState<IRow[]>([]);
  const [gridHeight, setGridHeight] = useState(GRID_MIN_HEIGHT);

  const [openLoadPrevProduct, setOpenLoadPrevProduct] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{ id?: number; name: string }>();

  const [lastChangedRow, setLastChangedRow] = useState(-1);

  const userCreatePermission = get_create_price_analysis_perms();
  if (!userCreatePermission) {
    message.error('You do not have permission to create price analysis');
    return <Navigate to="/404" replace />;
  }

  if (userCreatePermission === 'outlet') {
    message.error('You do not have permission to create price analysis');
    return <Navigate to="/price-analysis" replace />;
  }

  // {rowIndex: productId}
  const gridRef = useRef<DataGridHandle>(null);

  function onDeleteProduct() {
    if (!selectedProduct) {
      return message.error('Please select a product first.');
    }

    deleteRow(selectedProduct.id);
    setOpenDeleteModal(false);
  }

  useEffect(() => {
    const rowsTotalHeight = rows.length * GRID_ROW_HEIGHT + 126.8; // column header height
    const height = Math.min(rowsTotalHeight, 600);
    if (height > GRID_MIN_HEIGHT) {
      setGridHeight(height);
    }

    // Scroll to the last row when rows are updated
    const lastRowIndex = rows.length - 1;
    // +1 is added because the row index starts from 0 and we added new empty row
    if (gridRef.current && lastRowIndex >= 0 && lastRowIndex === lastChangedRow + 1) {
      const scrollTop = lastRowIndex * GRID_ROW_HEIGHT;
      gridRef.current.element?.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }
  }, [rows]);

  async function handleLoadPrevProduct() {
    try {
      setIsLoading(true);
      setOpenLoadPrevProduct(false);
      if (priceAnalysis && date) {
        const updatedRows = await prefillAllData(priceAnalysis, date);
        updatedRows.sort((a, b) => a.productName.trim().localeCompare(b.productName.trim()));
        setRows([...updatedRows, emptyRow]);
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function loadProductWhenPriceAnalysisAbsent() {
    try {
      setIsLoading(true);
      if (date) {
        const { isPresent, rows } = await loadLastPurchaseProduct(date);

        const rowsCopy = [...rows];
        rowsCopy.sort((a, b) => a.productName.trim().localeCompare(b.productName.trim()));
        isPresent ? setRows([...rowsCopy, emptyRow]) : addEmptyRow();
      }
    } catch (error) {
      getErrorMessage('Failed to load previous product', true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    !date
      ? setRows([])
      : !priceAnalysis
      ? loadProductWhenPriceAnalysisAbsent()
      : setOpenLoadPrevProduct(true);
  }, [date, priceAnalysis]);

  async function onProductChange(
    productId: number,
    product: IProductDetails,
    props: RenderCellProps<IRow>
  ) {
    try {
      if (!date) {
        message.error('Please select date');
        return;
      }

      setIsLoading(true);
      const allUnits = product.productUnits.map((p) => p.unitId);
      const defaultUnit = product.productUnits.find((u) => u.isDefault);

      let baseUnitMultiplier = 1;
      const currentUnitId = defaultUnit?.unitId || allUnits.length ? allUnits[0] : undefined;

      if (currentUnitId) {
        const unitDetails = await getUnit(currentUnitId);
        baseUnitMultiplier = unitDetails.baseUnitMultiplier;
      }

      const products = [{ id: productId, baseUnitMultiplier }];
      const productDetails = await fetchProductAllPriceDetails(products, date, priceAnalysis);
      const rowData = productDetails[productId];

      if (!rowData) {
        message.error('No data found for this product. Consult IT team for help.');
        return;
      }

      let unitName = '';
      if (currentUnitId) {
        const unitDetails = await getUnit(currentUnitId);
        unitName = unitDetails.shortName;
      }

      const updatedRow = {
        ...props.row,
        ...rowData,
        productId,
        productName: product.name,
        productUnits: allUnits,
        unitId: currentUnitId,
        unitName
      };

      const calculatedRows = calculateRowValues(updatedRow);
      props.onRowChange(calculatedRows);

      const lastRow = rows[rows.length - 1];
      const isLastRowFull = Boolean(lastRow.productId && lastRow.productName);
      setLastChangedRow(props.rowIdx);
      if (isLastRowFull || props.rowIdx === rows.length - 1) {
        addEmptyRow();
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  function addEmptyRow() {
    setRows((prevRows) => [...prevRows, emptyRow]);
  }

  const deleteRow = (productId?: number) => {
    setRows((prev) => {
      const filteredRows = prev.filter((row) => row.productId !== productId);
      return filteredRows;
    });
  };

  function renderInputNumber(
    { onRowChange, column, row }: RenderCellProps<IRow>,
    disabled = false
  ) {
    const rowValue = row as Record<string, any>;
    const isTodayPrice = column.key === 'todayPrice';

    return (
      <div
        className="w-full h-full custom-grid-input"
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}>
        <InputNumber
          disabled={disabled}
          controls={false}
          className="antd-grid-input"
          value={rowValue[column.key]}
          onChange={(value) => {
            const rowData = { ...row, [column.key]: value || 0 };
            if (isTodayPrice) {
              rowData.sellPurchasePrice = rowData.todayPrice;
            }

            const updatedValue = calculateRowValues(rowData);
            onRowChange(updatedValue);
          }}
          min={0}
        />
      </div>
    );
  }

  function renderInput({ onRowChange, column, row }: RenderCellProps<IRow>, disabled = false) {
    const rowValue = row as Record<string, any>;

    return (
      <div
        className="w-full h-full custom-grid-input"
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}>
        <Input
          className="antd-grid-input"
          value={rowValue[column.key]}
          disabled={disabled}
          onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value || '' })}
          min={0}
        />
      </div>
    );
  }

  function renderReadonlyInput(value?: string | number, style?: CSSProperties) {
    return (
      <div className="w-full h-full custom-grid-input" onClick={(e) => e.stopPropagation()}>
        <Input value={value} className="antd-grid-input" style={style} readOnly disabled />
      </div>
    );
  }

  function handleNewRow() {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IRow);
    const isRowFilled = Boolean(lastRow.productId && lastRow.productName);

    if (!isRowFilled && rows.length > 0) {
      message.destroy();
      message.error('Please fill current row before adding new one');
      return;
    }

    addEmptyRow();
  }

  function handleSubmitClick(type: 'check' | 'save') {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IRow);
    const isRowFilled = Boolean(lastRow.productId && lastRow.productName);

    // If last row is not filled, exclude it
    const updatedRows = isRowFilled ? rows : rows.slice(0, -1);
    if (updatedRows.length === 0) {
      message.destroy();
      message.error('Please fill atleast one row');
      return;
    }

    const someRowEmpty = updatedRows.some((row) => !row.productId || !row.productName);
    if (someRowEmpty) {
      message.destroy();
      message.error('Please fill all rows or remove empty rows');
      return;
    }

    setOpenSubmitModal(type === 'check');
    return updatedRows;
  }

  async function onFinish() {
    try {
      setIsLoading(true);

      const updatedRows = handleSubmitClick('save');
      if (!updatedRows) return;

      const purchaseCreateList: ICreatePurchasePriceAnalysis[] = [];
      const sellCreateList: ICreateSalePriceAnalysis[] = [];

      for (const row of updatedRows) {
        const productId = row.productId as number;
        const unitId = row.unitId as number;

        purchaseCreateList.push({
          productId,
          unitId,
          purchasePrice: row.purchasePrice,
          kalimatiRate: row.kalimatiRate,
          morningPurchase: row.morningPurchase,
          kalimatiSurveyRate: row.kalimatiSurveyRate,
          yesterdaySellingRate: row.yesterdaySellingRate,
          yesterdaySoldQty: row.yesterdaySoldQty,
          totalStock: row.totalStock,
          todayPrice: row.todayPrice,
          openingStock: row.openingStock,
          totalOrder: row.totalOrder,
          estimatedData: row.estimatedData
        });

        sellCreateList.push({
          productId,
          unitId,
          purchasePrice: row.sellPurchasePrice,
          yesterdayPrice: row.sellYesterdayPrice,
          todayPrice: row.sellTodayPrice
        });
      }

      const format = { date: date as string, purchase: purchaseCreateList, sale: sellCreateList };
      await create_price_analysis_mutation(format);
      message.success('Price Analysis created successfully');
      navigate('/price-analysis');
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const columnsWithActions: ColumnOrColumnGroup<IRow>[] = [
    {
      key: 'id',
      name: '',
      frozen: true,
      width: 40,
      minWidth: 40,
      renderCell(props) {
        return <strong>{props.rowIdx + 1}</strong>;
      }
    },
    {
      key: 'delete',
      name: '',
      frozen: true,
      width: 40,
      minWidth: 40,
      cellClass: 'p-0',
      renderCell(props) {
        return (
          <div
            onClick={(event) => {
              event.stopPropagation();
              if (props.row.productId === undefined) {
                return deleteRow();
              }
              setSelectedProduct({ id: props.row.productId, name: props.row.productName });
              setOpenDeleteModal(true);
            }}
            className="flex justify-center items-center cursor-pointer h-full">
            <DeleteOutlined style={{ fontSize: 16 }} className="!text-red-500 [&>svg]:!mt-0" />
          </div>
        );
      }
    },
    {
      name: 'Purchase',
      headerCellClass: 'text-center',
      children: [
        {
          key: 'productName',
          name: 'Product',
          width: 250,
          minWidth: 200,
          frozen: true,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderHeaderCell(props) {
            return (
              <Tooltip title="Product Name">
                <div>{props.column.name}</div>
              </Tooltip>
            );
          },
          renderCell(props) {
            return (
              <div onClick={(e) => e.stopPropagation()} className="w-full">
                <ProductSearchForNonForm
                  className="w-full"
                  allowClear={false}
                  disabled={!date}
                  defaultValue={props.row.productId}
                  onSelect={async (productId, product) => {
                    const isExist = rows.some((row) => row.productId === productId);
                    if (isExist) {
                      message.destroy();
                      message.error('Product already added');
                      props.onRowChange(emptyRow);
                      throw new Error('Product already added');
                    }

                    await onProductChange(productId, product, props);
                  }}
                />
              </div>
            );
          }
        },
        {
          key: 'unitName',
          name: 'Unit',
          cellClass: 'p-0',
          width: 50,
          frozen: true,
          minWidth: 50,
          headerCellClass: 'text-wrap-header',
          renderHeaderCell(props) {
            return (
              <Tooltip title="Unit">
                <div>{props.column.name}</div>
              </Tooltip>
            );
          },
          renderCell: ({ row }) => renderReadonlyInput(row.unitName)
        },
        {
          key: 'purchasePrice',
          name: 'Purchase Price',
          cellClass: 'p-0',
          width: 77,
          minWidth: 77,
          frozen: true,
          headerCellClass: 'text-wrap-header',
          renderHeaderCell(props) {
            return (
              <Tooltip title="Purchase Price">
                <div>{props.column.name}</div>
              </Tooltip>
            );
          },
          renderCell: (props) => renderInputNumber(props)
        },
        {
          key: 'kalimatiRate',
          name: 'Kalimati Rate',
          headerCellClass: 'text-wrap-header',
          cellClass: 'p-0',
          minWidth: 60,
          renderCell: (props) => renderInputNumber(props),
          renderHeaderCell(props) {
            return (
              <Tooltip title="Kalimati Rate">
                <div>{props.column.name}</div>
              </Tooltip>
            );
          }
        },
        {
          key: 'morningPurchase',
          name: 'Morning Purchase',
          minWidth: 60,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: (props) => renderInputNumber(props),
          renderHeaderCell(props) {
            return (
              <Tooltip title="Morning Purchase">
                <div>{props.column.name}</div>
              </Tooltip>
            );
          }
        },
        {
          key: 'kalimatiSurveyRate',
          name: 'Kali Surv Rate',
          renderHeaderCell: (props) => (
            <Tooltip title="Kalimati Survey Rate">
              <div>{props.column.name}</div>
            </Tooltip>
          ),
          minWidth: 70,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: (props) => renderInput(props)
        },
        {
          key: 'yesterdaySellingRate',
          name: 'Yest. Sell Rate',
          minWidth: 77,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: (props) => renderInputNumber(props),
          renderHeaderCell: (props) => (
            <Tooltip title="Yesterday Selling Rate">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'yesterdaySoldQty',
          name: 'Yesterday Sold Qty',
          minWidth: 60,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: (props) => renderInputNumber(props),
          renderHeaderCell: (props) => (
            <Tooltip title="Yesterday Sold Quantity">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'totalStock',
          name: 'Total Stock',
          minWidth: 74,
          headerCellClass: 'text-wrap-header',
          cellClass: 'reset-padding',
          renderCell: ({ row }) => renderReadonlyInput(row.totalStock),
          renderHeaderCell: (props) => (
            <Tooltip title="Total Stock">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'todayPrice',
          name: 'Today Price',
          cellClass: 'p-0',
          minWidth: 60,
          headerCellClass: 'text-wrap-header',
          renderCell: (props) => renderInputNumber(props),
          renderHeaderCell: (props) => (
            <Tooltip title="Today Price">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'isPriceChanged',
          name: 'Price Chngd?',
          minWidth: 60,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: ({ row }) => (
            <div className="flex justify-center">{row.isPriceChanged ? '✅' : '❌'}</div>
          ),
          renderHeaderCell: (props) => (
            <Tooltip title="Is Price Changed">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'marginAmount',
          minWidth: 68,
          name: 'Margin (Rs)',
          headerCellClass: 'text-wrap-header',
          renderHeaderCell: (props) => (
            <Tooltip title="Margin Amount">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'marginPercentage',
          minWidth: 68,
          name: 'Margin (%)',
          headerCellClass: 'text-wrap-header',
          renderHeaderCell: (props) => (
            <Tooltip title="Margin Percentage">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'openingStock',
          minWidth: 74,
          name: 'Opening Stock',
          headerCellClass: 'text-wrap-header',
          renderHeaderCell: (props) => (
            <Tooltip title="Opening Stock">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'totalOrder',
          name: 'Total Order',
          cellClass: 'p-0',
          minWidth: 60,
          headerCellClass: 'text-wrap-header',
          renderCell: (props) => renderInputNumber(props),
          renderHeaderCell: (props) => (
            <Tooltip title="Total Order">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        },
        {
          key: 'estimatedData',
          name: 'Estimated Data',
          minWidth: 60,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: (props) => renderInputNumber(props),
          renderHeaderCell: (props) => (
            <Tooltip title="Estimated Data">
              <div>{props.column.name}</div>
            </Tooltip>
          )
        }
      ]
    }
  ];

  if (userCreatePermission === 'b2b-outlet') {
    columnsWithActions.push({
      name: 'FreshKtm Outlet',
      headerCellClass: 'text-center text-white bg-red-500',
      children: [
        {
          key: 'sellPurchasePrice',
          name: 'Purchase Price',
          headerCellClass: 'text-wrap-header',
          minWidth: 60,
          cellClass: 'p-0',
          renderHeaderCell: (props) => (
            <Tooltip title="Purchase Price">
              <div>{props.column.name}</div>
            </Tooltip>
          ),
          renderCell: (props) => renderInputNumber(props)
        },
        {
          key: 'isPurchasePriceChanged',
          name: 'Price Chngd?',
          minWidth: 45,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderHeaderCell: (props) => (
            <Tooltip title="Purchase Price Changed?">
              <div>{props.column.name}</div>
            </Tooltip>
          ),
          renderCell: ({ row }) => (
            <div className="flex justify-center">{row.isPurchasePriceChanged ? '✅' : '❌'}</div>
          )
        },
        {
          key: 'sellYesterdayPrice',
          minWidth: 60,
          name: 'Yest S. Rate',
          renderHeaderCell(props) {
            return (
              <Tooltip title="Yesterday Selling Rate">
                <div>{props.column.name}</div>
              </Tooltip>
            );
          },
          headerCellClass: 'text-wrap-header'
        },
        {
          key: 'sellTodayPrice',
          name: 'Today Sell Rate',
          minWidth: 67,
          renderHeaderCell(props) {
            return <Tooltip title="Today Selling Rate">{<div>{props.column.name}</div>}</Tooltip>;
          },
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: (props) => renderInputNumber(props)
        },
        {
          key: 'isSellRateChanged',
          name: 'Sell Chngd?',
          minWidth: 63,
          cellClass: 'p-0',
          renderHeaderCell: (props) => (
            <Tooltip title="Selling Rate Changed?">
              <div>{props.column.name}</div>
            </Tooltip>
          ),
          headerCellClass: 'text-wrap-header',
          renderCell: ({ row }) => (
            <div className="flex justify-center">{row.isSellRateChanged ? '✅' : '❌'}</div>
          )
        },
        {
          key: 'marginSellAmount',
          minWidth: 68,
          name: 'Margin (Rs)',
          headerCellClass: 'text-wrap-header',
          renderHeaderCell(props) {
            return (
              <Tooltip title="Margin Amount (Rs)">
                <div>{props.column.name}</div>
              </Tooltip>
            );
          }
        },
        {
          key: 'marginSellPercentage',
          minWidth: 68,
          name: 'Margin (%)',
          renderHeaderCell(props) {
            return (
              <Tooltip title="Margin Percentage">
                <div>{props.column.name}</div>
              </Tooltip>
            );
          },
          headerCellClass: 'text-wrap-header'
        }
      ]
    });
  }

  return (
    <div>
      <CustomModal
        width="600px"
        footer={false}
        isModalOpen={openLoadPrevProduct}
        setIsModalOpen={setOpenLoadPrevProduct}
        title="Load Product?">
        <div className="flex flex-col justify-center items-center gap-5">
          <div>
            Based on the selected date, we have found products from the previous day. Would you like
            to load them?
          </div>

          <div className="flex justify-end items-center w-full flex-wrap gap-2.5">
            <Button onClick={() => setOpenLoadPrevProduct(false)}>Cancel</Button>
            <Button type="primary" onClick={handleLoadPrevProduct}>
              Load
            </Button>
          </div>
        </div>
      </CustomModal>

      <Modal visible={openSubmitModal} onCancel={() => setOpenSubmitModal(false)} onOk={onFinish}>
        Do you want to create this price analysis?
      </Modal>

      <Modal
        title="Delete Product?"
        visible={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onOk={onDeleteProduct}>
        Are you sure you want to remove{' '}
        <span className="text-red-500">{selectedProduct?.name}</span> product from this list?
      </Modal>

      <DataGrid
        ref={gridRef}
        className="rdg-light"
        style={{ height: gridHeight }}
        columns={columnsWithActions}
        rows={rows}
        rowHeight={GRID_ROW_HEIGHT}
        headerRowHeight={60}
        renderers={{
          noRowsFallback: (
            <div className="relative col-span-full">
              <div className="sticky top-0 h-full left-0 w-screen flex items-center justify-center">
                <p>No products have been added yet.</p>
              </div>
            </div>
          )
        }}
        defaultColumnOptions={{ resizable: true }}
        onRowsChange={setRows}
        enableVirtualization
      />

      <div className="mt-2.5">
        <Button
          disabled={!date}
          type="dashed"
          onClick={handleNewRow}
          block
          style={{ width: '100%' }}>
          <PlusOutlined /> Add field
        </Button>
      </div>

      <div className="mt-2.5 flex justify-end gap-2.5">
        {/* <Button type="default" onClick={() => console.log(rows)}>
          Console Product
        </Button> */}

        <Button
          type="primary"
          onClick={() => handleSubmitClick('check')}
          disabled={!date || !rows.length}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ExcelLikeGrid;
