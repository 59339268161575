import capitalizeFirstWord from '@/utils/capitalizeFirstWord';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import { useState } from 'react';

interface Props {
  isHidden?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  hideFor: string;
  target?: string;

  additionalMessage?: React.ReactNode;
  additionalTooltip?: string;
}

function HideConfirm({
  onOk,
  onCancel,
  hideFor,
  isHidden = false,
  target,
  additionalMessage,
  additionalTooltip = ''
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
    onOk?.();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    onCancel?.();
  };

  return (
    <div>
      <Modal
        title={`${isHidden ? 'Unhide' : 'Hide'} ${hideFor.toLowerCase()}?`}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>
          {`You are about to ${isHidden ? 'unhide' : 'hide'} this ${hideFor.toLowerCase()}.`}{' '}
          {additionalMessage}
        </p>
        {target && (
          <p>
            <strong>{capitalizeFirstWord(hideFor)}:</strong> {target}
          </p>
        )}
      </Modal>

      <Tooltip title={isHidden ? 'Unhide ' : 'Hide ' + additionalTooltip} color="blue">
        <div onClick={() => setIsModalOpen(true)} className="flex items-center cursor-pointer">
          {isHidden ? (
            <EyeInvisibleOutlined style={{ transform: 'scale(1.4)', width: '105%' }} />
          ) : (
            <EyeOutlined style={{ transform: 'scale(1.4)', width: '105%' }} />
          )}
        </div>
      </Tooltip>
    </div>
  );
}

export default HideConfirm;
