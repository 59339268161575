import { CSSProperties, useState } from 'react';
import moment from 'moment';

import AppContent from '@/components/Common/Content/Content';
import TimelineCalendar from '@/components/Common/TimelineCalendar';
import { fetchAndCacheHRData } from '../services';
import { message } from 'antd';
import getErrorMessage from '@/utils/getError';
import { get_hr_self_calendar_data } from '@/services/hr/queries';
import { getImagesFromServer } from '@/services/upload/queries';
import { ICalendarEvent } from '../types';
import { LeaveContent, HolidayContent, AttendanceContent, ShiftContent } from '../ModalContent';
import { ShiftsDB } from '@/store/localstorage/ShiftDB';
import { getShift } from '@/services';
import CalendarLegend from '@/components/Common/CalendarLegend';
import { hrEventColors } from '../constant';

export interface CachedRange {
  start: string;
  end: string;
}

function HRSelfCalendar() {
  const [events, setEvents] = useState<ICalendarEvent[]>([]);
  const [cachedData, setCachedData] = useState<CachedRange[]>([]);

  const [filteredEvents, setFilteredEvents] = useState<ICalendarEvent[]>([]); // Filtered events
  const [activeStatuses, setActiveStatuses] = useState<Record<string, boolean>>(() =>
    Object.keys(hrEventColors).reduce((acc, key) => ({ ...acc, [key]: true }), {})
  );

  const handleLegendToggle = (updatedStatuses: Record<string, boolean>) => {
    setActiveStatuses(updatedStatuses);
    setFilteredEvents(events.filter((event) => updatedStatuses[event.type]));
  };

  const updateCache = (newRange: CachedRange | CachedRange[]) => {
    if (Array.isArray(newRange)) return setCachedData((prev) => [...prev, ...newRange]);
    setCachedData((prev) => [...prev, newRange]);
  };

  async function onFetchEvents(startDate: string, endDate: string) {
    try {
      const newEvents = await fetchAndCacheHRData({
        startDate,
        endDate,
        cachedData,
        updateCache,
        apiCallFunc
      });

      // Handle duplicate events by checking `type` and `id`
      setEvents((prev) => {
        const existingEventSet = new Set(
          prev.map((event) => `${event.type}-${event.id}`) // Combine `type` and `id` for uniqueness
        );

        const deduplicatedNewEvents = newEvents.filter(
          (event: ICalendarEvent) => !existingEventSet.has(`${event.type}-${event.id}`)
        );

        const newEventLists = [...prev, ...deduplicatedNewEvents];

        // Create a Set of holiday dates for easy lookup
        const holidayRanges = newEventLists
          .filter((event) => event.type === 'holiday')
          .map((holiday) => ({
            start: moment(holiday.start).startOf('day').valueOf(),
            end: holiday.end ? moment(holiday.end).endOf('day').valueOf() : null
          }));

        // Flag shifts that overlap with holidays
        const updatedEvents = newEventLists.map((event) => {
          if (event.type === 'shift') {
            const eventStart = new Date(event.start).getTime();
            const eventEnd = event.end ? new Date(event.end).getTime() : null;

            const overlaps = holidayRanges.some(
              (holiday) =>
                eventStart >= holiday.start &&
                (holiday.end && eventEnd ? eventEnd <= holiday.end : true)
            );

            if (overlaps) {
              return { ...event, isOverlapping: true };
            }
          }
          return event;
        });

        setFilteredEvents(updatedEvents.filter((event) => activeStatuses[event.type]));
        return updatedEvents;
      });
    } catch (error) {
      message.error(getErrorMessage(error));
    }
  }

  async function apiCallFunc(start: string, end: string) {
    const params = new URLSearchParams({ startDate: start, endDate: end, archiveStatus: 'ACTIVE' });
    const response = await get_hr_self_calendar_data(params.toString());

    // Fetch medias for each leave
    const allMediaIds = response.leaves.flatMap((leave) => leave.mediaIds);
    const uniqueMediaIds = Array.from(new Set(allMediaIds));

    const medias = await getImagesFromServer(uniqueMediaIds);
    for (const leave of response.leaves) {
      const allMedias = medias.filter((media) => leave.mediaIds.includes(media.id));
      leave.medias = allMedias.map((media) => media.url);
    }

    // Add Shift Name to Attendance
    const allShiftIds = response.attendances
      .map((attendance) => attendance.shiftId)
      .filter(Boolean) as number[];

    await ShiftsDB.addIfAbsent(allShiftIds);

    for (const attendance of response.attendances) {
      if (!attendance.shiftId) continue;
      const shift = await getShift(attendance.shiftId);
      attendance.shiftName = shift?.name || '';
    }

    return response;
  }

  const eventPropGetter = (event: ICalendarEvent) => {
    const currentType = event.type;
    const color = hrEventColors[currentType];

    const style: CSSProperties = {
      backgroundColor: color.light,
      borderRadius: '3px',
      border: 'none',
      color: '#000',
      fontSize: '14px',
      fontWeight: '500',
      borderLeft: `3px solid ${color.dark}`,
      margin: '0px 3px 3px',
      width: 'calc(100% - 6px)'
    };

    if (event.type === 'shift' && event.isOverlapping) {
      style.display = 'none';
    }

    return { style };
  };

  const renderModalContent = (event: ICalendarEvent) => {
    switch (event.type) {
      case 'leave':
        return <LeaveContent event={event} />;
      case 'holiday':
        return <HolidayContent event={event} />;
      case 'shift':
        return <ShiftContent event={event} />;
      case 'attendance':
        return <AttendanceContent event={event} />;
      default:
        return null;
    }
  };

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'HR', link: '/hr' },
        { label: 'Calendar', link: '/hr/calendar' },
        { label: 'Self' }
      ]}>
      <div className="space-y-3">
        <CalendarLegend eventColors={hrEventColors} onToggle={handleLegendToggle} />
        <TimelineCalendar
          events={filteredEvents}
          onFetchEvents={onFetchEvents}
          eventPropGetter={eventPropGetter}
          renderModalContent={renderModalContent}
        />
      </div>
    </AppContent>
  );
}

export default HRSelfCalendar;
