import { Image } from 'antd';
import { useState } from 'react';

interface Props {
  medias: string[];
  noMediaContent?: JSX.Element;
}

function PreviewMultipleImage({ medias, noMediaContent }: Props) {
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  if (!medias || medias.length === 0) {
    if (noMediaContent) return noMediaContent;
    return <div>-</div>;
  }

  return (
    <div className="">
      <span
        className="underline text-blue-500 cursor-pointer"
        onClick={() => setPreviewVisible(true)}>
        View
      </span>
      <Image.PreviewGroup
        preview={{
          visible: isPreviewVisible,
          onVisibleChange: setPreviewVisible,
          bodyProps: { className: 'custom-scale' }
        }}>
        {medias?.map((media, index) => (
          <Image key={`media-${index}`} src={media} style={{ display: 'none' }} />
        ))}
      </Image.PreviewGroup>
    </div>
  );
}

export default PreviewMultipleImage;
