import { useEffect, useState } from 'react';
import {
  IAccountDuplicateMultiple,
  IAccountTypeResponseData,
  IAccountTypeTableData,
  ICreateAccounts
} from '../../../services/accounts/types';
import { Button, Divider, Form, Input, InputNumber, PageHeader, Spin, message } from 'antd';
import LocationSearchMultiple from '../LocationSearch/LocationSearchMultiple';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  create_duplicate_account_with_children_mutation,
  create_multiple_account_mutation
} from '../../../services/accounts/mutations';
import { AccountsTypeSearch } from '../AccountsTypeSearch';
import { DoubleRightOutlined } from '@ant-design/icons';
import CustomButton from '../CustomButton/CustomButton';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_list, get_location_list_for_ids } from '../../../services/locations/queries';
import isAxiosError from '@/utils/isAxiosError';

interface ModalProps {
  data: IAccountTypeTableData;
  handleModalClose: () => void;
}
const ReusableAccountMultipleDuplicationWithChildrenModal: React.FC<ModalProps> = ({
  data,
  handleModalClose
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [parentAccountList, setParentAccountList] = useState<IAccountTypeResponseData[]>([]);
  const [allLocationIds, setAllLocationIds] = useState<number[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue(['locationIds'], []);
    form.resetFields(['lines']);
  }, [data]);

  useQuery(['locations'], async () => await getAllLocations());

  const getAllLocations = async () => {
    const allLocations = await get_location_list();
    const allLocationIds = allLocations.data.results.map((value) => {
      return value.id;
    });
    setAllLocationIds(allLocationIds);
  };

  const onFinish = async (values: IAccountDuplicateMultiple) => {
    setIsLoading(true);
    // console.log('Values', values);
    try {
      const lines = values.lines;
      if (lines === undefined) {
        throw {
          name: 'Lines Error',
          message: 'Please click continue after selecting location!'
        };
      }
      if (lines.length === 0) {
        throw {
          name: 'Lines Error',
          message: 'Please click continue after selecting location!'
        };
      }

      await Promise.all(
        lines.map(async (value) => {
          const mutationObj = {
            locationId: value.locationId,
            parentId: value.parentId,
            accountId: data.id
          };

          await createAccountsDuplicationWithChildrenMutation.mutateAsync(mutationObj);
        })
      );
      setIsLoading(false);
      message.success('Account duplicated successfully');
      handleModalClose();
    } catch (errors: any) {
      setIsLoading(false);
      if (isAxiosError(errors)) return;
      message.error(errors.message);
    }
  };

  const createAccountsDuplicationWithChildrenMutation = useMutation(
    create_duplicate_account_with_children_mutation,
    {
      onError: (e: any) => {
        setIsLoading(false);
      }
    }
  );

  const handleContinueClick = async (locationIds: number[]) => {
    setIsLoading(true);
    // const locationIds = form.getFieldValue(['locationIds']);
    // console.log('locationIds', locationIds);

    // create lines
    for (let ind = 0; ind < locationIds.length; ind++) {
      let locationDetails = await LocationsDB.getLocation(locationIds[ind]);
      if (!locationDetails) {
        const allLocations = await get_location_list_for_ids(locationIds);
        await LocationsDB.addLocations(allLocations);
        locationDetails = await LocationsDB.getLocation(locationIds[ind]);
      }
      const locationName = locationDetails.name;
      form.setFieldValue(['lines', ind, 'locationId'], locationIds[ind]);
      form.setFieldValue(['lines', ind, 'locationName'], locationName);
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        initialValues={{ locationIds: [] }}
        onFinish={onFinish}
        disabled={isLoading}
        layout="vertical"
        validateTrigger={'onChange'}
        autoComplete="off">
        <PageHeader
          subTitle="Duplicate From"
          style={{
            padding: '2px 0px'
          }}
        />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>Location: {data.locationName}</div>
          <div>Type: {data.type}</div>
          <div>Name: {data.name}</div>
          <div>Parent Account: {data.parentName}</div>
          <div>Balance: {nepaliNumberFormatter(data.balance)}</div>
          <div>Created By: {data.createdByName}</div>
        </div>
        <Divider />
        <PageHeader
          subTitle="Duplicate To"
          style={{
            padding: '2px 0px'
          }}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="col-span-2">
            <LocationSearchMultiple
              formData={{ formLabel: 'Location', formName: ['locationIds'] }}
              notAll={false}
              required={true}
              excludedLocation={data.locationId}
              onSelect={(val) => {
                if (val.includes('All')) {
                  form.setFieldValue(['locationIds'], ['All']);
                }
              }}
            />
          </div>
          <Form.List name={['lines']}>
            {(fields, { add, remove }) => (
              <>
                <Button
                  type="primary"
                  className="lg:mt-7"
                  onClick={() => {
                    try {
                      const locationIds = form.getFieldValue(['locationIds']);
                      if (locationIds.length === 0) {
                        throw {
                          name: 'LinesError',
                          message: 'Please select atleast one Location.'
                        };
                      }
                      form.resetFields(['lines']);
                      if (locationIds.includes('All')) {
                        allLocationIds.forEach(() => add());
                        handleContinueClick(allLocationIds);
                      } else {
                        locationIds.forEach(() => add());
                        handleContinueClick(locationIds);
                      }
                    } catch (errors: any) {
                      setIsLoading(false);
                      message.error(errors.message);
                    }
                  }}>
                  Continue <DoubleRightOutlined />
                </Button>
                <div className="col-span-2 lg:col-span-4">
                  {fields.map((field, index) => (
                    <div className="flex gap-2 items-center" key={field.key}>
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div
                          className={
                            'grid grid-cols-3 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'
                          }>
                          <Form.Item name={[field.name, 'locationId']} hidden>
                            <InputNumber controls={false} />
                          </Form.Item>
                          <Form.Item name={[field.name, 'locationName']} label="Location">
                            <Input disabled />
                          </Form.Item>
                          <AccountsTypeSearch
                            formData={{
                              formName: [field.name, 'parentId'],
                              label: 'Parent Account'
                            }}
                            isAll={false}
                            accountList={parentAccountList}
                            setAccountList={setParentAccountList}
                            required={true}
                            locationId={form.getFieldValue(['lines', field.name, 'locationId'])}
                          />
                          <div className="flex items-center justify-start mt-5">
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="#1890ff"
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Form.List>
        </div>

        <div className="flex justify-end mt-5">
          <Button type="primary" onClick={form.submit}>
            Submit
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default ReusableAccountMultipleDuplicationWithChildrenModal;
