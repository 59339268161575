import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICustomerDiscount,
  ICustomerDiscountResponse,
  ICustomerGroupLines,
  ICustomerGroupListData,
  ICustomerGroupListResponse,
  IDiscountCategoryDetails,
  IDiscountCategoryListResponse,
  IDiscountDetails,
  IDiscountListResponse,
  IDiscountProductDetails,
  IDiscountProductListResponse,
  IOffer,
  IOfferDatas,
  IOfferExcludedCustomer,
  ITopOfferResponse
} from './types';
import { getDataForIds } from '@/utils/queries.utils';

/* Get Location lists
 * @param {number} skip
 * @param {number} count
 * @params {value} string
 * @returns {Promise<any>}
 */
export const get_offer_list = async (skip = 0, count = 0, value = '', ids: number[] = []) => {
  const response: AxiosResponse<IOfferDatas, any> = await http.get('offer', {
    skip,
    count,
    value,
    ids
  });
  return response;
};
export const get_offer_list_filter = async (filter = '') => {
  const response: AxiosResponse<IOfferDatas, any> = await http.get('offer/?' + filter);
  return response;
};

export const get_customer_discount_list_filter = async (filter = '') => {
  const response: AxiosResponse<ICustomerDiscountResponse> = await http.get(
    'offer/customer-range/?' + filter
  );
  return response.data;
};
/* Get single location details
 * @param {number} id
 */
export const get_offer_details = async (id: number) => {
  const response: AxiosResponse<IOffer, any> = await http.get('offer/' + id);
  return response.data;
};

export const get_customer_discount_details = async (id: number) => {
  const response: AxiosResponse<ICustomerDiscount, any> = await http.get(
    'offer/customer-range/' + id
  );
  return response.data;
};

export const download_offer_file = async () => {
  const response = await http.get('ports/sample/offer');
  return response;
};

export const get_customer_group_list_filter = async (filter = '') => {
  const response: AxiosResponse<ICustomerGroupListResponse, any> = await http.get(
    'customer-group/?' + filter
  );
  return response.data;
};

export const get_customer_group_list = async (skip = 0, count = 0, value = '') => {
  const response: AxiosResponse<ICustomerGroupListResponse, any> = await http.get(
    'customer-group',
    { skip, count, value }
  );
  return response.data;
};

export const get_customer_group_list_only = async (
  skip = 0,
  count = 0,
  value = '',
  filter = ''
) => {
  const response: AxiosResponse<ICustomerGroupListResponse> = await http.get(
    'customer-group/?' + filter,
    { skip, count, value }
  );
  return response.data.results;
};

export const get_customer_group_list_ids = async (ids: number[]) => {
  return getDataForIds<ICustomerGroupListData>(ids, 'customer-group/?');
};

export const get_customer_group_details = async (id: number) => {
  const response: AxiosResponse<ICustomerGroupListData, any> = await http.get(
    'customer-group/' + id
  );
  return response.data;
};

export const get_customer_group_lines_list = async (id: number) => {
  const response: AxiosResponse<ICustomerGroupLines[], any> = await http.get(
    'customer-group/lines/' + id
  );
  return response.data;
};

export const get_discount_list = async (filter = '') => {
  const response: AxiosResponse<IDiscountListResponse, any> = await http.get(
    'offer/customer-group/?' + filter
  );
  return response.data;
};

export const get_discount_details = async (id: number) => {
  const response: AxiosResponse<IDiscountDetails, any> = await http.get(
    'offer/customer-group/' + id
  );
  return response.data;
};

export const get_discount_category_list = async (filter = '') => {
  const response: AxiosResponse<IDiscountCategoryListResponse, any> = await http.get(
    'offer/category/?' + filter
  );
  return response.data;
};

export const get_discount_category_details = async (id: number) => {
  const response: AxiosResponse<IDiscountCategoryDetails, any> = await http.get(
    'offer/category/' + id
  );
  return response.data;
};

export const get_discount_product_list = async (filter = '') => {
  const response: AxiosResponse<IDiscountProductListResponse, any> = await http.get(
    'offer/product/?' + filter
  );
  return response.data;
};

export const get_discount_product_details = async (id: number) => {
  const response: AxiosResponse<IDiscountProductDetails, any> = await http.get(
    'offer/product/' + id
  );
  return response.data;
};

export const get_top_offer = async (locationId: number, ids: number[], customerId?: number) => {
  const response: AxiosResponse<ITopOfferResponse[], any> = await http.get('offer/top-offer', {
    locationId,
    ids,
    customerId
  });
  return response.data;
};

export const get_offer_excluded_customers = async () => {
  const response: AxiosResponse<IOfferExcludedCustomer[]> = await http.get(
    'offer-excluded-customers'
  );
  return response.data;
};

export const check_offer_excluded_customer = async (customerId: number) => {
  const response: AxiosResponse<true | false> = await http.get(
    'offer-excluded-customers/check/' + customerId
  );
  return response.data;
};
