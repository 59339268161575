import { ToolbarProps, Navigate, Views } from 'react-big-calendar';
import { Button, Select } from 'antd';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import moment from 'moment';
import { useEffect } from 'react';

const options = [
  { label: 'Day', value: Views.DAY, shortcut: 'd' },
  { label: 'Week', value: Views.WEEK, shortcut: 'w' },
  { label: 'Month', value: Views.MONTH, shortcut: 'm' },
  { label: 'Agenda', value: Views.AGENDA, shortcut: 'a' }
];

function Toolbar({ label, onNavigate, view, onView }: ToolbarProps<any>) {
  const todayFormattedText = moment().format('MMMM DD, YYYY');

  // To trigger onRangeChange on initial render
  useEffect(() => {
    onView(view);
  }, []);

  // Register keyboard shortcuts
  useEffect(() => {
    const keyActionMap: Record<string, () => void> = {
      arrowleft: () => onNavigate(Navigate.PREVIOUS),
      arrowright: () => onNavigate(Navigate.NEXT),
      d: () => onView(Views.DAY),
      w: () => onView(Views.WEEK),
      m: () => onView(Views.MONTH),
      a: () => onView(Views.AGENDA),
      escape: () => onNavigate(Navigate.TODAY)
    };

    function onKeyboardShortcuts(event: KeyboardEvent) {
      const key = event.key.toLowerCase();
      const action = keyActionMap[key];

      const isInput = event.target instanceof HTMLInputElement;
      const isTextArea = event.target instanceof HTMLTextAreaElement;

      const isUsingModifier = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
      if (isInput || isTextArea || isUsingModifier) return;

      action?.();
    }

    window.addEventListener('keydown', onKeyboardShortcuts);
    return () => window.removeEventListener('keydown', onKeyboardShortcuts);
  }, []);

  return (
    <div className="mb-4 flex gap-4 items-center justify-between">
      <div className="flex gap-4 items-center">
        <span className="text-lg font-medium min-w-[8.5625rem] inline-block">{label}</span>

        <div className="flex gap-2">
          <Button onClick={() => onNavigate(Navigate.TODAY)} title={todayFormattedText}>
            Today
          </Button>

          <div className="flex items-center gap-1">
            <MdChevronLeft
              size={22}
              title={`Previous ${view}`}
              className="cursor-pointer select-none hover:bg-gray-100 rounded-full text-gray-500"
              onClick={() => onNavigate(Navigate.PREVIOUS)}
            />
            <MdChevronRight
              size={22}
              title={`Next ${view}`}
              className="cursor-pointer select-none hover:bg-gray-100 rounded-full text-gray-500"
              onClick={() => onNavigate(Navigate.NEXT)}
            />
          </div>
        </div>
      </div>

      <div>
        <Select
          dropdownMatchSelectWidth={false}
          className="w-24"
          value={view}
          optionLabelProp="label"
          onChange={(value) => onView(value)}>
          {options.map((option) => (
            <Select.Option key={option.label} value={option.value} label={option.label}>
              <div className="flex justify-between w-40 items-center">
                <span>{option.label}</span>
                <span className="text-[0.6875rem] text-gray-400 uppercase">{option.shortcut}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default Toolbar;
