import { useState, useRef, useContext, useEffect } from 'react';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { Button, Form, Menu, message, Spin, TableProps, Tag, Tooltip } from 'antd';
import {
  get_product_list_ids,
  get_total_lots_with_location,
  get_units_list
} from '@/services/products/queries';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { getUserData } from '@/utils/auth.utils';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import type { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TotalLotsAvailableDB from '@/store/localstorage/TotalAvailableLotsDB';
import { CustomModal } from '@/components/Common/CustomModal';
import Lotsinfo from './Lotsinfo';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { useReactToPrint } from 'react-to-print';
import { ILotsTotal, IProductType, IProductUnitsType } from '@/services/products/types';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const TotalLotsAndProducts = () => {
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [allproducts, setAllproducts] = useState<ILotsTotal[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [productInfoforModal, setProductInfoforModal] = useState<any>();
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  const breadcrumbItems = [
    {
      label: 'Products and Lots Total',
      link: '/products/totalLots'
    }
  ];
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const val = form.getFieldsValue();
      await onSubmitFilter(val);
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['locationId']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          const val = form.getFieldsValue();
          await onSubmitFilter(val);
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const productIds = new Set<number>(allproducts.map((value) => value.productId));
        let updatedProducts = data.data as { productId: number; locationId: number }[];
        const locationId = form.getFieldValue(['locationId']);

        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const val = form.getFieldsValue();
          await onSubmitFilter(val);
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected, allproducts]);

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const locationId = form.getFieldValue(['locationId']);
    // console.log('response ', response);
    const dbresult: any = await TotalLotsAvailableDB.getData(locationId);
    const tofetch = true;
    // if (dbresult) {
    //   // console.log('dbresult', dbresult);
    //   const minDiff = moment().diff(moment(dbresult.date), 'minutes');
    //   // console.log('minDiff', minDiff);
    //   if (minDiff <= 30) {
    //     console.log('this runs');
    //     setAllproducts(dbresult.results);
    //     tofetch = false;
    //   }
    // }
    const productSearch: any = {};
    if (tofetch) {
      const response = await get_total_lots_with_location(locationId);
      for (let ind = 0; ind < response.data.results.length; ind++) {
        const productInfo: any = await ProductsDB.getProduct(response.data.results[ind].productId);
        if (!productInfo) {
          if (productSearch[response.data.results[ind].productId]) {
            productSearch[response.data.results[ind].productId] = [
              ...productSearch[response.data.results[ind].productId],
              ind
            ];
          } else {
            productSearch[response.data.results[ind].productId] = [ind];
          }
          // productInfo = await get_product_details(responseSold.data[i].id);
          // ProductsDB.addProducts([productInfo]);
        } else {
          response.data.results[ind].productName = productInfo.name;
          let findUnit = productInfo.productUnits.find((curr: any) => curr.isDefault == true);
          // console.log('Find Unit->', findUnit);
          if (!findUnit) {
            findUnit = productInfo.productUnits[0];
          }
          if (!findUnit) continue;
          // console.log('product Info', productInfo);
          let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
          // console.log('unit id', findUnit.unitId);
          if (!unitInfo) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            unitInfo = await UnitsDB.getUnit(findUnit.unitId);
          }
          response.data.results[ind].unitName = unitInfo.shortName;
          response.data.results[ind].baseUnitMultiplier = unitInfo.baseUnitMultiplier;
          response.data.results[ind].qty =
            parseInt(response.data.results[ind].quantity) /
            (unitInfo.baseUnitMultiplier ? unitInfo.baseUnitMultiplier : 1);
          response.data.results[ind].purchasePriceAvg = `${
            parseFloat(response.data.results[ind].purchasePriceAvg) * unitInfo.baseUnitMultiplier
          }`;
        }
      }
      // console.log('productsearchlength', productSearch);
      const productSearchlength = Object.entries(productSearch).length;
      if (productSearchlength > 0) {
        const allproducts = await get_product_list_ids([...Object.keys(productSearch)]);
        for (const key in productSearch) {
          const findproduct = allproducts?.data?.results.find(
            (currProduct: IProductType) => currProduct.id == key
          );
          if (findproduct) {
            let findUnit = findproduct.productUnits.find(
              (curr: IProductUnitsType) => curr.isDefault === true
            );
            if (!findUnit) {
              findUnit = findproduct.productUnits[0];
            }
            if (!findUnit) continue;
            // console.log('Find Unit->', findUnit);
            let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
            // console.log('unit id', findUnit.unitId);
            if (!unitInfo) {
              const allUnits = await get_units_list();
              await UnitsDB.addUnits(allUnits);
              unitInfo = await UnitsDB.getUnit(findUnit.unitId);
            }

            for (let i = 0; i < productSearch[key].length; i++) {
              response.data.results[productSearch[key][i]].baseUnitMultiplier =
                unitInfo.baseUnitMultiplier;
              response.data.results[productSearch[key][i]].productName = findproduct.name;
              response.data.results[productSearch[key][i]].unitName = unitInfo.shortName;
              response.data.results[productSearch[key][i]].qty =
                response.data.results[productSearch[key][i]].quantity /
                (unitInfo.baseUnitMultiplier ? unitInfo.baseUnitMultiplier : 1);
            }
            await ProductsDB.addProducts([findproduct]);
          }
        }
      }
      TotalLotsAvailableDB.addDatas([
        {
          locationId,
          date: moment().utc().format(),
          results: response.data.results
        }
      ]);
      setAllproducts(response.data.results);
    }
    setIsloading(false);
    // return response;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{index + 1}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 50,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.productName}</TableCell>;
      }
    },

    {
      title: 'Quantity',
      key: 'quantity',
      width: 20,
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (record: any) => {
        return (
          <TableCell>
            {parseInt(record.quantity) /
              (record.baseUnitMultiplier ? record.baseUnitMultiplier : 1)}
          </TableCell>
        );
      }
    },
    {
      title: 'Price Avg.',
      key: 'purchasePriceAvg',
      className: 'highlight',
      width: 20,
      sorter: (a, b) => a.purchasePriceAvg - b.purchasePriceAvg,
      sortOrder: sortedInfo.columnKey === 'purchasePriceAvg' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{parseFloat(record.purchasePriceAvg).toFixed(2)}</TableCell>;
      }
    },
    {
      title: 'Unit',
      key: 'unit',
      width: 20,
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      sortOrder: sortedInfo.columnKey === 'unit' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.unitName}</TableCell>;
      }
    },
    {
      title: 'Active',
      key: 'hidden',
      width: 10,
      sorter: (a, b) => a.hidden - b.hidden,
      sortOrder: sortedInfo.columnKey === 'hidden' ? sortedInfo.order : null,
      render: (record: any) => {
        return (
          <Tag color={record.hidden ? 'red' : 'green'}>{record.hidden ? 'Inactive' : 'Active'}</Tag>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 13,
      fixed: 'right',
      render: (record) => {
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: <CustomViewIcon title="View All Lots" />,
                onClick: () => {
                  setProductInfoforModal(record);
                  setIsModalOpen(true);
                }
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'Product',
          dataIndex: 'productName',
          width: 350
        },
        {
          title: 'Active',
          dataIndex: 'hiddenStatus',
          width: 80
        },
        {
          title: 'Price Avg',
          width: 100,
          dataIndex: 'purchasePriceAvg'
        },
        {
          title: 'Unit',
          width: 100,
          dataIndex: 'unitName'
        },
        {
          title: 'Quantity',
          width: 100,
          dataIndex: 'qty'
        }
      ];
      if (allproducts?.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allproducts?.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1
        };
      });
      exportExcel(columns, dataUpdated, 'Total Lots and Products');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const columsforPrint: any = [
    {
      label: 'Name',
      dataIndex: 'productName',
      render: (text: string) => {
        return <div className="ml-2">{text}</div>;
      }
    },
    {
      label: 'Active',
      dataIndex: 'hidden',
      render: (record: any) => {
        return <div className="text-center">{record.hidden ? 'Inactive' : 'Active'}</div>;
      }
    },
    {
      label: 'Quantity',
      dataIndex: 'qty',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Unit',
      dataIndex: 'unitName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Price Avg',
      dataIndex: 'purchasePriceAvg',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Product List View For PDF print">
        <div className="flex justify-end mt-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allproducts}
            reff={printPDFRef}
            title={'Product List'}
          />
        </div>
      </CustomModal>
      <CustomModal
        footer={false}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={`${productInfoforModal?.productName} Lots Information`}>
        {productInfoforModal && (
          <Lotsinfo
            locationId={form.getFieldValue(['locationId'])}
            productinfo={productInfoforModal}
          />
        )}
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  skip: 0,
                  count: 100,
                  locationId: preferenceLocationId ? preferenceLocationId : ''
                }}
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex justify-end items-center'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    {/* <CustomButton
                        text="Export"
                        backgroundColor="#1890ff"
                        onClick={handleExport}
                      />
                      <CustomButton
                        text="PDF Export"
                        backgroundColor="#1890ff"
                        onClick={() => setOpenModalforexport(true)}
                      /> */}
                    <div>
                      <ActionDropdown
                        button={true}
                        menu={
                          <Menu
                            items={[
                              {
                                key: '1',
                                label: (
                                  <Tooltip title="Export Excel" color="blue">
                                    <div className="text-center">Excel</div>
                                  </Tooltip>
                                ),
                                onClick: () => {
                                  handleExport();
                                }
                              },
                              {
                                key: '2',
                                label: (
                                  <Tooltip title="Export PDF" color="blue">
                                    <div className="text-center">PDF</div>
                                  </Tooltip>
                                ),
                                onClick: () => {
                                  setOpenModalforexport(true);
                                }
                              }
                            ]}
                          />
                        }
                      />
                    </div>
                  </>
                }>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={allproducts}
          toSort={handleChange}
          customScroll={{ y: '75vh', x: 1000 }}
          notshowPagination={true}
        />
      </AppContent>
    </Spin>
  );
};

export default TotalLotsAndProducts;
