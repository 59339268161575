import {
  IAttendance,
  IHRAttendancePayload,
  IHRCreateRequest,
  IHRCreateResponse,
  IHRGroups,
  IHRHoliday,
  IHRHolidayDetails,
  IHRHolidayPayload,
  IHRLeaves,
  IHRLeavesPayload,
  IHRShiftPayload,
  IHRUpdateRequest,
  IHRUserInfoPayload,
  IPayrollPayload,
  IPayrollDetails,
  IShiftCreateResponse,
  IShiftDetails
} from './types';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';
import { ICreateCommonResponse } from '../category/types';

export const create_hr_mutation = async (values: IHRCreateRequest) => {
  const response: AxiosResponse<IHRCreateResponse> = await http.store('users/information', values);
  return response;
};

export const update_hr_mutation = async (values: IHRUpdateRequest) => {
  const response: AxiosResponse<ICreateCommonResponse> = await http.update(
    'users/information/' + values.id,
    values
  );
  return response;
};

export const create_hr_user_info_mutation = async (values: IHRUserInfoPayload) => {
  const response: AxiosResponse<IHRHoliday> = await http.store('hr-user-info', values);
  return response.data;
};

export const update_hr_user_info_mutation = async (id: number, values: IHRUserInfoPayload) => {
  const response: AxiosResponse<IHRHoliday> = await http.update('hr-user-info/' + id, values);
  return response.data;
};

export const create_hr_holiday_mutation = async (values: IHRHolidayPayload) => {
  const response: AxiosResponse<IHRHoliday> = await http.store('hr-holiday', values);
  return response.data;
};

export const update_hr_holiday_mutation = async (id: number, values: IHRHolidayPayload) => {
  const response: AxiosResponse<IHRHoliday> = await http.update('hr-holiday/' + id, values);
  return response.data;
};

export const assign_hr_holiday_mutation = async (holidayId: number, groupIds: number[]) => {
  const response: AxiosResponse<IHRHolidayDetails> = await http.update(
    'hr-holiday/assign/' + holidayId,
    { groupIds }
  );
  return response.data;
};

export const create_hr_leaves_mutation = async (values: IHRLeavesPayload) => {
  const response: AxiosResponse<IHRLeaves> = await http.store('hr-leaves', values);
  return response.data;
};

export const update_hr_leaves_mutation = async (id: number, values: IHRLeavesPayload) => {
  const response: AxiosResponse<IHRLeaves> = await http.update('hr-leaves/' + id, values);
  return response.data;
};

export const create_hr_group_mutation = async (values: { name: string; userIds: number[] }) => {
  const response: AxiosResponse<IHRGroups> = await http.store('hr-groups', values);
  return response.data;
};

export const update_hr_group_mutation = async (
  id: number,
  values: { name: string; userIds: number[] }
) => {
  const response: AxiosResponse<IHRGroups> = await http.update('hr-groups/' + id, values);
  return response.data;
};

export const create_hr_shift_mutation = async (payload: IHRShiftPayload) => {
  const response: AxiosResponse<IShiftCreateResponse> = await http.store('hr-shift/', payload);
  return response.data;
};

export const update_hr_shift_mutation = async (id: number, payload: IHRShiftPayload) => {
  const response: AxiosResponse<IShiftCreateResponse> = await http.update(
    'hr-shift/' + id,
    payload
  );
  return response.data;
};

export const assign_hr_shift_mutation = async (shiftId: number, userIds: number[]) => {
  const response: AxiosResponse<IShiftDetails> = await http.update(
    'hr-shift/assign-user/' + shiftId,
    { userIds }
  );
  return response.data;
};

// API TO CHECK IN AND CHECK OUT
export const check_in_out_attendance_mutation = async (type: 'in' | 'out') => {
  const response: AxiosResponse<IAttendance> = await http.store(`hr-attendance/check-${type}/`);
  return response.data;
};

export const create_hr_attendance_mutation = async (values: IHRAttendancePayload) => {
  const response: AxiosResponse<IAttendance> = await http.store('hr-attendance', values, {
    showErrorModal: false
  });
  return response.data;
};

export const update_hr_attendance_mutation = async (id: number, values: IHRAttendancePayload) => {
  const response: AxiosResponse<IAttendance> = await http.update('hr-attendance/' + id, values);
  return response.data;
};

// Payroll Mutation
export const create_payroll_mutation = async (values: IPayrollPayload) => {
  const response: AxiosResponse<IPayrollDetails> = await http.store('hr-payroll', values);
  return response.data;
};

export const update_payroll_mutation = async (id: number, values: IPayrollPayload) => {
  const response: AxiosResponse<IPayrollDetails> = await http.update('hr-payroll/' + id, values);
  return response.data;
};
