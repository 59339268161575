import { Button, Form, Input, message, Modal, PageHeader, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useRef, useState } from 'react';

import AppContent from '@/components/Common/Content/Content';
import { SchedulerStatusType } from '@/services/schedule/enum';
import { get_scheduler_list } from '@/services/schedule/queries';
import { IScheduler } from '@/services/schedule/types';
import { isValidCron } from 'cron-validator';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { useNavigate } from 'react-router-dom';
import CronSelector, { CronSelectorRef } from '@/components/Common/CronSelector';
import getErrorMessage from '@/utils/getError';
import { useMutation } from '@tanstack/react-query';
import { create_update_schedule } from '@/services/schedule/mutation';
import isAxiosError from '@/utils/isAxiosError';

type IMutationTypes = { uiType: 'create' } | { uiType: 'update'; id: number };
type Props = {
  isParentLoading?: boolean;
  data?: IScheduler;
  breadcrumbs: { label: string; link?: string }[];
} & IMutationTypes;

type ISchedularType = { label: string; value: string; disabled?: boolean };

interface FormValues {
  type: SchedulerStatusType;
  cronExpression: string;
}

function ScheduleMutationUI({ breadcrumbs, isParentLoading = false, ...props }: Props) {
  const [form] = useForm<FormValues>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [schedularType, setSchedularType] = useState<ISchedularType[]>([]);

  const create_update_mutation = useMutation(create_update_schedule);
  const cronSelectorRef = useRef<CronSelectorRef>(null);

  const [isConfirm, setIsConfirm] = useState(false);

  function unfocusAllElement() {
    const activeElement = document.activeElement as HTMLElement | null;
    if (activeElement && typeof activeElement.blur === 'function') {
      activeElement.blur();
    }
  }

  async function onConfirmation() {
    // Validate the form and proceed with confirmation
    await form.validateFields();
    setIsConfirm(true);
  }

  function checkIsCronValid(cronExpression: string) {
    const isValid = isValidCron(cronExpression, {
      seconds: true,
      alias: false,
      allowBlankDay: false,
      allowSevenAsSunday: false
    });

    const isInternalErrors = cronSelectorRef.current?.errors.some((error) => error.trim());
    if (!isValid || isInternalErrors) {
      CustomErrorModal({
        message: 'Please enter a valid cron expression'
      });
    }

    return isValid;
  }

  useEffect(() => {
    if (props.uiType === 'update' && props.data) {
      form.setFieldsValue({
        type: props.data.type,
        cronExpression: props.data.cronExpression
      });
    }
  }, [props.data]);

  useEffect(() => {
    fetchSchedularType();
  }, []);

  async function fetchSchedularType() {
    try {
      setIsLoading(true);

      if (props.uiType === 'update') {
        const newType = Object.keys(SchedulerStatusType).map((label) => {
          const value = SchedulerStatusType[label as keyof typeof SchedulerStatusType];
          return { label, value };
        });

        setSchedularType(newType);
        return;
      }

      const schedulers = await get_scheduler_list();
      const presentSchedulerTypes = schedulers.map((scheduler) => scheduler.type);

      const newType = Object.keys(SchedulerStatusType).map((label) => {
        const value = SchedulerStatusType[label as keyof typeof SchedulerStatusType];
        return { label, value, disabled: presentSchedulerTypes.includes(value) };
      });

      setSchedularType(newType);
    } catch (error) {
      message.error('Failed to get schedular types');
    } finally {
      setIsLoading(false);
    }
  }

  const onFinish = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      const isValidCron = checkIsCronValid(values.cronExpression);
      if (!isValidCron) return;

      const payload = {
        type: values.type,
        cronExpression: values.cronExpression,
        isDisabled: props.uiType === 'update' ? props.data?.isDisabled || false : false
      };

      await create_update_mutation.mutateAsync(payload);
      const successMessage =
        props.uiType === 'update'
          ? 'Scheduler updated successfully'
          : 'Scheduler created successfully';

      message.success(successMessage);
      navigate('/scheduler');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading || isParentLoading}>
      <Modal
        visible={isConfirm}
        onCancel={() => setIsConfirm(false)}
        onOk={() => {
          setIsConfirm(false);
          onFinish();
        }}>
        <p>Are you sure you want to {props.uiType} this scheduler?</p>
      </Modal>
      <AppContent breadcrumbItems={breadcrumbs}>
        <div className="font-bold text-lg">Scheduler Configuration</div>
        <PageHeader
          subTitle="Configure a new scheduler by selecting a type and setting a cron expression"
          style={{ padding: '0 0 8px' }}
        />

        <Form form={form} layout="vertical">
          <div className="grid max-w-lg">
            <Form.Item
              name="type"
              label="Scheduler Type"
              rules={[{ required: true, message: 'Please select a scheduler type!' }]}>
              <Select
                placeholder="Select scheduler type"
                showSearch
                disabled={props.uiType === 'update'}
                options={schedularType}
                optionLabelProp="label"
                optionFilterProp="label"
              />
            </Form.Item>
          </div>

          <div className="flex gap-4 max-w-lg ">
            <Form.Item
              className="flex-1"
              name="cronExpression"
              label="Cron Expression"
              rules={[{ required: true, message: 'Please select a cron expression!' }]}>
              <Input disabled />
            </Form.Item>
          </div>

          <div
            onMouseLeave={unfocusAllElement}
            className="card w-full mt-4"
            style={{ borderRadius: '9px', backgroundColor: 'white' }}>
            <CronSelector
              form={form}
              ref={cronSelectorRef}
              defaultCron={props.uiType === 'update' ? props.data?.cronExpression : ''}
            />
          </div>

          <div className="flex mt-4 justify-end gap-2">
            <Button type="primary" ghost onClick={() => navigate(-1)}>
              Go Back
            </Button>

            <Button type="primary" onClick={onConfirmation}>
              {props.uiType === 'create' ? 'Create' : 'Update'} Scheduler
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default ScheduleMutationUI;
