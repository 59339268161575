import moment from 'moment';

import { useState } from 'react';
import { Form, Input, Menu, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link, useNavigate } from 'react-router-dom';

import { checkAccess } from '@/routes/acl';
import TableFilter from '@/components/FliterTable';
import UsersDB from '@/store/localstorage/UsersDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import handlePagination from '@/utils/handlePagination';
import { get_user_details } from '@/services/users/queries';
import AppContent from '@/components/Common/Content/Content';
import { UsersSearch } from '@/components/Common/UsersSearch';
import { get_expense_list } from '@/services/expense/queries';
import ExpenseCategoryDB from '@/store/localstorage/ExpenseCategoryDB';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { find_locationId_preference } from '@/store/localstorage/preferences';
import { IExpenseListRevamp, IExpenseResult } from '@/services/expense/types';
import { ExpenseCategorySearch } from '@/components/Common/ExpenseCategorySearch';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { get_expense_category_id } from '@/services/expense/expense-category/queries';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { CustomModal } from '@/components/Common/CustomModal';
import ExpenseInvoice from '@/components/Common/InvoicePrint/ExpensePrint';
import { getExpensePrintData } from '@/components/Common/InvoicePrint/ExpensePrint/services';
import { IExpenseInvoiceData } from '@/services/expense/expense-category/types';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import ExpenseCategorySearchV2 from '@/components/Common/CustomSearch/ExpenseCategory';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

function ExpenseList() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [expenses, setExpenses] = useState<IExpenseResult>({ total: 0, results: [] });

  const [invoiceData, setInvoiceData] = useState<IExpenseInvoiceData>(Object);

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
  }

  const generateInvoiceForPrint = async (
    record: IExpenseListRevamp,
    current: ICreateInvoiceResponse
  ) => {
    try {
      setIsloading(true);
      // Get the expense details
      const details = await getExpensePrintData(record.id, current);
      setInvoiceData(details);

      setTimeout(() => {
        setIsloading(false);
        setOpenPrintModal(true);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  async function getInfo(filter = '') {
    try {
      setIsloading(true);
      const response = await get_expense_list(filter);
      const expenseList = await Promise.all(
        response.results.map(async (expense) => {
          const { createdBy, categoryId } = expense;

          let user = await UsersDB.getUser(createdBy);
          if (!user) {
            user = (await get_user_details(createdBy)).user;
            await UsersDB.addUsers([user]);
          }

          let category = (await ExpenseCategoryDB.get(categoryId)) as any;
          if (!category) {
            category = await get_expense_category_id(categoryId);
            await ExpenseCategoryDB.add([category]);
          }

          return {
            ...expense,
            createdUser: user?.name,
            categoryName: category?.name
          };
        })
      );

      setExpenses({ total: response.total, results: expenseList });
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const columns: ColumnsType<IExpenseListRevamp> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => <span>{index + 1}</span>,
      fixed: 'right'
    },
    {
      title: 'Reference Number',
      width: 8,
      className: 'invoice',
      dataIndex: 'referenceNumber',
      key: 'referenceNumber',
      render(value, record) {
        return <Link to={`/expense/${record.id}`}>{value}</Link>;
      }
    },
    {
      title: 'Bill Number',
      width: 4,
      dataIndex: 'billNumber',
      key: 'billNumber',
      className: 'invoice'
    },
    {
      title: 'Bill Date',
      width: 8,
      dataIndex: 'billDate',
      key: 'billDate',
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },
    { title: 'Category', width: 8, dataIndex: 'categoryName', key: 'categoryId' },
    {
      title: 'Total Amount',
      width: 5,
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      className: 'highlight',
      render: (text) => nepaliNumberFormatter(text)
    },
    {
      title: 'Created At',
      width: 8,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },
    { title: 'Created By', width: 5, dataIndex: 'createdUser', key: 'createdBy' },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menuItems = [
          {
            key: 'menu-view',
            label: <CustomViewIcon link={`/expense/${record.id}`} title="View" />
          },
          {
            key: 'menu-print',
            label: (
              <InvoicePrintButton
                getInvoice={(current) => {
                  generateInvoiceForPrint(record, current);
                }}
              />
            )
          }
        ];

        return <ActionDropdown menu={<Menu items={menuItems} />} />;
      }
    }
  ];

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsloading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <>
      <CustomModal
        footer={false}
        title="Print Expense Invoice"
        isModalOpen={openPrintModal}
        setIsModalOpen={setOpenPrintModal}>
        <ExpenseInvoice
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          expenseDetail={invoiceData.expenseDetails}
          journalDetails={invoiceData.journalDetails}
          handleModalClose={() => setOpenPrintModal(false)}
        />
      </CustomModal>
      <AppContent
        breadcrumbItems={[{ label: 'Expense', link: '/expense' }]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            onSubmit={onSubmitFilter}
            onPagination={(page, size) => setPagination({ page, size })}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              locationId: find_locationId_preference() || 1,
              value: '',
              skip: 0,
              count: 100,
              createdBy: '',
              categoryId: ''
            }}
            styleforbuttons={'flex justify-end items-center'}
            style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}
            buttons={
              <>
                {checkAccess('CREATE_EXPENSE') && (
                  <CustomButton
                    onClick={() => navigate('/expense/new')}
                    text="Add"
                    backgroundColor="#1890ff"
                    Linkto="/expense/new"
                  />
                )}
              </>
            }>
            <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
            <UserSearchV2 hasParentFormItem={false} name="createdBy" label="Created By" showAll />
            <ExpenseCategorySearchV2 hasParentFormItem={false} name="categoryId" showAll />
            <Form.Item name="value" label="Search">
              <Input placeholder="Search" />
            </Form.Item>

            <Form.Item name="archiveStatus" label="Status">
              <Select defaultValue={'ACTIVE'}>
                <Select.Option value="ALL">All</Select.Option>
                <Select.Option value="ACTIVE">Active</Select.Option>
                <Select.Option value="INACTIVE">Inactive</Select.Option>
              </Select>
            </Form.Item>
          </TableFilter>
        }>
        <GenericTable
          form={form}
          columns={columns}
          data={expenses.results}
          isLoading={isLoading}
          hideDefaultPagination={true}
          scroll={{ x: 1000, y: '75vh' }}
          generateSummary
          excludeSummaryByKeys={['billNumber']}
          summaryClassName="text-left"
          pagination={{
            page: pagination.page,
            total: expenses.total,
            size: pagination.size,
            onPagination
          }}
          tableName={'expense-list'}
        />
      </AppContent>
    </>
  );
}

export default ExpenseList;
