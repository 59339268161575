import { Button, Form, Input, Menu, Spin, TableProps, Tooltip, message } from 'antd';
import { useRef, useState } from 'react';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { ConvertObjectToURL } from '@/utils/converturl';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { getUserData } from '@/utils/auth.utils';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import {
  IPurchaseRegisterList,
  IPurchaseRegisterListData,
  IPurchaseRegisterListExport
} from '@/services/ird/purchase/types';
import { get_purchase_register_list } from '@/services/ird/purchase/queries';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CopyButton from '@/components/Common/CopyButton';
import VendorsDB from '@/store/localstorage/VendorDB';
import { get_user_pan_ids, get_vendor_list_ids } from '@/services/users/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { PurchaseType } from '@/services/purchases/enums';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { CustomModal } from '@/components/Common/CustomModal';
import { useReactToPrint } from 'react-to-print';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { IExportNestedColumn } from '@/utils/exportNestedExcel';
import { exportRegisterItemWiseExcel } from '../../exportRegister';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import CustomTableForIRDItemWise from '@/components/Common/CustomizeTable/CustomTableForIrdItemWise';

const PurchaseRegisterList = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const [allPurchaseList, setAllPurchaseList] = useState<IPurchaseRegisterListData>({
    total: 0,
    results: []
  });
  const [exportData, setExportData] = useState<{
    data: IPurchaseRegisterListExport[];
    range: { from: string; to: string };
  }>({ data: [], range: { from: '', to: '' } });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const breadcrumbItems = [
    {
      label: 'Purchase Register',
      link: '/ird/purchase-register'
    }
  ];

  const columns: ColumnsType<IPurchaseRegisterList> = [
    {
      title: 'Bill number',
      width: 100,
      children: [
        {
          title: 'Date',
          key: 'date',
          width: 20,
          dataIndex: 'date',
          sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
          sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null
        },
        {
          title: 'Miti',
          key: 'miti',
          width: 20,
          dataIndex: 'miti',
          sorter: (a, b) => a.miti.localeCompare(b.miti),
          sortOrder: sortedInfo.columnKey === 'miti' ? sortedInfo.order : null
        },
        {
          title: 'Time',
          key: 'time',
          width: 20,
          dataIndex: 'time'
        },
        {
          title: 'Bill Number',
          key: 'financialReference',
          className: 'invoice',
          width: 30,
          sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
          sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
          render: (a, record) => {
            return (
              <TableCell className="flex flex-row justify-between items-center text-xs px-1">
                {record.financialReference}
                {record.financialReference && <CopyButton text={record.financialReference} />}
              </TableCell>
            );
          }
        },
        {
          title: 'Name of Supplier',
          key: 'supplierName',
          width: 20,
          sorter: (a, b) =>
            a.supplierName && b.supplierName ? a.supplierName.localeCompare(b.supplierName) : 0,
          sortOrder: sortedInfo.columnKey === 'supplierName' ? sortedInfo.order : null,
          render: (a, record) => {
            return <TableCell>{record.supplierName}</TableCell>;
          }
        },
        {
          title: 'PAN of Supplier',
          key: 'supplierPan',
          width: 25,
          render: (a, record) => {
            return <TableCell>{record.vendorPan}</TableCell>;
          }
        },
        {
          title: 'Details of Goods imported/ purchase',
          key: 'purchaseImportGoodsDetails',
          width: 25,
          render: (a, record) => {
            return <TableCell>{'Goods for: ' + record.financialReference}</TableCell>;
          }
        },
        {
          title: 'Details of Service imported/ purchase',
          key: 'purchaseImportServiceDetails',
          width: 25,
          render: (a, record) => {
            return <TableCell>{'FreshKtm Service for: ' + record.financialReference}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Total Purchase Price',
      width: 20,
      key: 'totalPurchasePrice',
      dataIndex: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalPurchasePrice' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{nepaliNumberFormatter(record.totalAmount)}</TableCell>;
      }
    },
    {
      title: 'Non Taxable Purchase/Import',
      width: 25,
      key: 'totalNonTaxable',
      dataIndex: 'totalNonTaxable',
      sorter: (a, b) => a.totalNonTaxable - b.totalNonTaxable,
      sortOrder: sortedInfo.columnKey === 'totalNonTaxable' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{nepaliNumberFormatter(record.totalNonTaxable)}</TableCell>;
      }
    },
    {
      title: 'Taxable Purchase',
      width: 20,
      children: [
        {
          title: 'Amount',
          key: 'taxablePurchaseAmount',
          dataIndex: 'localPurchase',
          width: 15,
          render: (a, record) => {
            return (
              <TableCell>
                {nepaliNumberFormatter(
                  record.purchaseType === PurchaseType['LOCAL'] ? record.totalTaxable : 0
                )}
              </TableCell>
            );
          }
        },
        {
          title: 'Tax',
          key: 'taxablePurchaseTax',
          width: 15,
          dataIndex: 'localPurchaseTax',
          render: (a, record) => {
            return (
              <TableCell>
                {nepaliNumberFormatter(
                  record.purchaseType === PurchaseType['LOCAL'] ? record.totalVat : 0
                )}
              </TableCell>
            );
          }
        }
      ]
    },
    {
      title: 'Taxable Import',
      width: 20,
      children: [
        {
          title: 'Amount',
          key: 'taxableImportAmount',
          width: 15,
          dataIndex: 'importPurchase',
          render: (a, record) => {
            return (
              <TableCell>
                {nepaliNumberFormatter(
                  record.purchaseType === PurchaseType['IMPORT'] ? record.totalTaxable : 0
                )}
              </TableCell>
            );
          }
        },
        {
          title: 'Tax',
          key: 'taxableImportTax',
          width: 15,
          dataIndex: 'importPurchaseTax',
          render: (a, record) => {
            return (
              <TableCell>
                {nepaliNumberFormatter(
                  record.purchaseType === PurchaseType['IMPORT'] ? record.totalVat : 0
                )}
              </TableCell>
            );
          }
        }
      ]
    },
    {
      title: 'Capital Purchase/ Import',
      width: 20,
      children: [
        {
          title: 'Amount',
          key: 'capitalAmount',
          dataIndex: 'capitalAmount',
          width: 15,
          render: () => {
            return <TableCell>{nepaliNumberFormatter(0)}</TableCell>;
          }
        },
        {
          title: 'Tax',
          key: 'capitalTax',
          dataIndex: 'capitalTax',
          width: 15,
          render: () => {
            return <TableCell>{nepaliNumberFormatter(0)}</TableCell>;
          }
        }
      ]
    }
  ];

  const columsforPrint: any = [
    {
      title: 'Bill number',
      width: 1180,
      children: [
        {
          title: 'Date',
          width: 150,
          dataIndex: 'date',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Miti',
          dataIndex: 'miti',
          width: 150,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Time',
          dataIndex: 'time',
          width: 150,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Bill Number',
          width: 230,
          dataIndex: 'financialReference',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Name of Supplier',
          width: 125,
          dataIndex: 'supplierName',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'PAN of Supplier',
          width: 115,
          dataIndex: 'vendorPan',
          render: (text: number) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Details of Goods imported/ purchase',
          width: 270,
          dataIndex: 'purchaseImportGoodsDetails',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Details of Service imported/ purchase',
          width: 290,
          dataIndex: 'purchaseImportServiceDetails',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        }
      ]
    },
    {
      title: 'Total Purchase Price',
      width: 60,
      dataIndex: 'totalAmount',
      isNumber: true,
      render: (text: number) => {
        return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
      }
    },
    {
      title: 'Non Taxable Purchase/ Import',
      width: 80,
      dataIndex: 'totalNonTaxable',
      isNumber: true,
      render: (text: number) => {
        return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
      }
    },
    {
      title: 'Taxable Purchase',
      width: 85,
      children: [
        {
          title: 'Amount',
          width: 55,
          dataIndex: 'taxablePurchaseAmount',
          isNumber: true,
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        },
        {
          title: 'Tax',
          width: 30,
          dataIndex: 'taxablePurchaseTax',
          isNumber: true,
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        }
      ]
    },
    {
      title: 'Taxable Import',
      width: 85,
      children: [
        {
          title: 'Amount',
          width: 55,
          isNumber: true,
          dataIndex: 'taxableImportAmount',
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        },
        {
          title: 'Tax',
          width: 30,
          isNumber: true,
          dataIndex: 'taxableImportTax',
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        }
      ]
    },
    {
      title: 'Capital Purchase/ Import',
      width: 85,
      children: [
        {
          title: 'Amount',
          width: 55,
          dataIndex: 'capitalAmount',
          isNumber: true,
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        },
        {
          title: 'Tax',
          width: 30,
          dataIndex: 'capitalTax',
          isNumber: true,
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        }
      ]
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_purchase_register_list(filter);
    // console.log('response', response);
    if (response) {
      const vendorUserIds = new Set<number>();
      for (let index = 0; index < response.results.length; index++) {
        const item = response.results[index];
        const date = convertUTCStringtoLocalString(item.date, 'YYYY-MM-DD');
        const miti = convert_string_to_nepali_date_string(date).replaceAll('-', '.');
        const time = moment(item.date, 'HH:mm').format('hh:mm a');

        response.results[index].date = date;
        response.results[index].miti = miti;
        response.results[index].time = time;

        // set vendor name
        let vendor = await VendorsDB.getVendors(response.results[index].vendorId);
        if (!vendor) {
          const allVendors = await get_vendor_list_ids([
            ...new Set(
              response.results.map((value) => {
                return value.vendorId;
              })
            )
          ]);
          await VendorsDB.addVendors(allVendors.data.results);
          vendor = await VendorsDB.getVendors(response.results[index].vendorId);
        }

        if (typeof vendor === 'object') {
          vendorUserIds.add(vendor.userId);
          response.results[index].supplierName = vendor.user.name;
          response.results[index].vendorUserId = vendor.userId;
        }

        const currentData = response.results[index];
        currentData.localPurchase =
          currentData.purchaseType === PurchaseType['LOCAL'] ? currentData.totalTaxable : 0;

        currentData.localPurchaseTax =
          currentData.purchaseType === PurchaseType['LOCAL'] ? currentData.totalVat : 0;

        currentData.importPurchase =
          currentData.purchaseType === PurchaseType['IMPORT'] ? currentData.totalTaxable : 0;

        currentData.importPurchaseTax =
          currentData.purchaseType === PurchaseType['IMPORT'] ? currentData.totalVat : 0;
      }

      //set pan
      const { data: userPanList } =
        vendorUserIds.size > 0 ? await get_user_pan_ids([...vendorUserIds]) : { data: [] };

      for (let index = 0; index < response.results.length; index++) {
        const item = response.results[index];
        const vendorPan =
          userPanList.find((user) => user.userId === item.vendorUserId)?.taxNumber || '';
        response.results[index].vendorPan = vendorPan;
      }

      const dataUpdated = response.results.map((item) => {
        return {
          ...item,
          purchaseImportGoodsDetails: 'Goods for: ' + item.financialReference,
          purchaseImportServiceDetails: 'FreshKtm Service for: ' + item.financialReference,
          taxablePurchaseAmount:
            item.purchaseType === PurchaseType['LOCAL'] ? item.totalTaxable : 0,
          taxablePurchaseTax: item.purchaseType === PurchaseType['LOCAL'] ? item.totalVat : 0,
          taxableImportAmount: item.purchaseType === PurchaseType['IMPORT'] ? item.totalTaxable : 0,
          taxableImportTax: item.purchaseType === PurchaseType['IMPORT'] ? item.totalVat : 0,
          capitalAmount: 0,
          capitalTax: 0
        };
      });

      // set year and date
      const startDateEnglish = form.getFieldValue(['startDate']);
      const endDateEnglish = form.getFieldValue(['endDate']);

      const stringStartDate = moment(startDateEnglish).format('YYYY-MM-DD');
      const stringEndDate = moment(endDateEnglish).format('YYYY-MM-DD');

      const nepaliStartDate = convert_string_to_nepali_date_string(stringStartDate);
      const nepaliEndDate = convert_string_to_nepali_date_string(stringEndDate);

      setAllPurchaseList(response);
      setExportData({
        data: dataUpdated,
        range: {
          from: `${stringStartDate} (${nepaliStartDate.replaceAll('-', '.')})`,
          to: `${stringEndDate} (${nepaliEndDate.replaceAll('-', '.')})`
        }
      });
    }
    setIsloading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  function handleExcelExportDynamic() {
    const exportColumns: IExportNestedColumn<IPurchaseRegisterListExport>[] = [
      {
        title: 'Bill Number',
        dataIndex: '',
        children: [
          { title: 'Date', dataIndex: 'date', width: 12 },
          { title: 'Miti', dataIndex: 'miti', width: 12 },
          { title: 'Time', dataIndex: 'time', width: 12 },
          { title: 'Bill Number', dataIndex: 'financialReference', width: 25 },
          { title: `Supplier's Name`, dataIndex: 'supplierName', width: 25 },
          { title: `Supplier's PAN`, dataIndex: 'vendorPan', width: 15 },
          {
            title: 'Details of Goods imported/purchase',
            dataIndex: 'purchaseImportGoodsDetails',
            width: 35
          },
          {
            title: 'Details of Service imported/purchase',
            dataIndex: 'purchaseImportServiceDetails',
            width: 35
          }
        ]
      },
      {
        title: 'Total Purchase Price',
        width: 12,
        dataIndex: 'totalAmount',
        alignment: { horizontal: 'right' },
        formatByComma: true,
        showDashIfAbsent: true
      },
      {
        title: 'Non Taxable Purchase/Import',
        width: 12,
        dataIndex: 'totalNonTaxable',
        alignment: { horizontal: 'right' },
        formatByComma: true,
        showDashIfAbsent: true
      },
      {
        title: 'Taxable Purchase',
        dataIndex: '',
        children: [
          {
            title: 'Amount',
            width: 12,
            dataIndex: 'taxablePurchaseAmount',
            alignment: { horizontal: 'right' },
            formatByComma: true,
            showDashIfAbsent: true
          },
          {
            title: 'Tax',
            width: 12,
            dataIndex: 'taxablePurchaseTax',
            alignment: { horizontal: 'right' },
            formatByComma: true,
            showDashIfAbsent: true
          }
        ]
      },
      {
        title: 'Taxable Import',
        dataIndex: '',
        children: [
          {
            title: 'Amount',
            width: 12,
            dataIndex: 'taxableImportAmount',
            alignment: { horizontal: 'right' },
            formatByComma: true,
            showDashIfAbsent: true
          },
          {
            title: 'Tax',
            width: 12,
            dataIndex: 'taxableImportTax',
            alignment: { horizontal: 'right' },
            formatByComma: true,
            showDashIfAbsent: true
          }
        ]
      },
      {
        title: 'Capital Purchase/Import',
        dataIndex: '',
        children: [
          {
            title: 'Amount',
            width: 12,
            dataIndex: 'capitalAmount',
            alignment: { horizontal: 'right' },
            formatByComma: true,
            showDashIfAbsent: true
          },
          {
            title: 'Tax',
            width: 12,
            dataIndex: 'capitalTax',
            alignment: { horizontal: 'right' },
            formatByComma: true,
            showDashIfAbsent: true
          }
        ]
      }
    ];

    if (allPurchaseList.results.length === 0) {
      message.error('No Data to Export');
      return;
    }

    exportRegisterItemWiseExcel({
      data: exportData.data,
      columns: exportColumns,
      exportYear: exportData.range,
      title: 'Purchase Register',
      fileName: 'Rule 23 Report (Purchase Register)',
      isPurchase: true
    });
  }

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableForIRDItemWise
            columns={columsforPrint}
            data={exportData.data}
            reff={printPDFRef}
            title={'Purchase Register'}
            subTitle={'Related With Rule 23 SubRule (1) Part (G)'}
            exportYear={exportData.range}
            highlightHeader
            showEmptyRow
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  onPagination={(page, size) => {
                    setPage(page);
                    setSize(size);
                  }}
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    value: '',
                    locationId: preferenceLocationId ? preferenceLocationId : 1,
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end items-center'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  form={form}>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: handleExcelExportDynamic
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (allPurchaseList.results.length === 0) {
                            message.error('No Data to Export');
                            setIsloading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <GenericTable
          form={form}
          columns={columns}
          data={allPurchaseList.results}
          hideDefaultPagination={true}
          scroll={{ x: 1750, y: '75vh' }}
          pagination={{
            page: page,
            total: allPurchaseList.total,
            size: size,
            onPagination
          }}
          generateSummary
          summaryClassName="text-left"
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default PurchaseRegisterList;
