import { get_customer_list_ids } from '@/services/users/queries';
import LocalStore from '.';
import { ICustomer } from '../../services/users/types';

export default class CustomersDB extends LocalStore {
  /*search for customer by name, email, customer id, user id, phone with limit*/
  static async searchCustomer(name: string, limit = 10) {
    if (!CustomersDB.db) await CustomersDB.init();
    name = name.toLowerCase();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const filteredCustomers: any[] = [];
      const transaction = CustomersDB.db.transaction('Customers', 'readonly');
      const objectStore = transaction.objectStore('Customers');
      const cursorRequest = objectStore.index('CustomersSecondIndex').openCursor();

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (
            cursor.value?.lowercaseName.includes(name) ||
            cursor.value?.user?.name?.toLowerCase().includes(name) ||
            cursor.value?.name?.toLowerCase().includes(name) ||
            cursor.value?.user?.email?.toLowerCase().includes(name) ||
            cursor.value?.email?.toLowerCase().includes(name) ||
            cursor.value?.user?.phone?.toLowerCase().includes(name) ||
            cursor.value?.phone?.toLowerCase().includes(name) ||
            JSON.stringify(cursor.userId)?.toLowerCase().includes(name) ||
            JSON.stringify(cursor.id)?.toLowerCase().includes(name)
          ) {
            filteredCustomers.push(cursor.value);
          }
          if (filteredCustomers.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredCustomers);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async searchCustomerByNameAndId(name: string, id: number, limit = 10) {
    if (!CustomersDB.db) await CustomersDB.init();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const filteredCustomers: any[] = [];
      const transaction = CustomersDB.db.transaction('Customers', 'readonly');
      const objectStore = transaction.objectStore('Customers');
      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('CustomersSecondIndex').openCursor();

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor && (cursor.value.id === id || cursor.value.id === null)) {
          const customer = cursor.value;

          if (
            customer?.lowercaseName?.includes(lowercaseString) ||
            customer?.name?.toLowerCase().includes(lowercaseString) ||
            customer?.user?.name?.toLowerCase().includes(lowercaseString)
          ) {
            filteredCustomers.push(cursor.value);
          }

          if (filteredCustomers.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredCustomers);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  /*Customers Functions*/
  static async getAllCustomers() {
    if (!CustomersDB.db) await CustomersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CustomersDB.db.transaction('Customers', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Customers');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async removeAllCustomers() {
    if (!CustomersDB.db) await CustomersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CustomersDB.db.transaction(['Customers'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Customers');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async addCustomers(usersData: any[]) {
    if (!CustomersDB.db) await CustomersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CustomersDB.db.transaction('Customers', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Customers');
      usersData.forEach(async (user) => {
        const checkKey = objectStore.count(user.id);
        checkKey.onsuccess = async (event: any) => {
          if (checkKey.result == 0) {
            const username = user?.name || user?.user?.name;
            user.lowercaseName = username?.toLowerCase();

            const request = objectStore.add(user);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await CustomersDB.updateCustomer(user);
          }
        };
      });
    });
  }
  static async getCustomer(id: number | string): Promise<ICustomer> {
    if (!CustomersDB.db) await CustomersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CustomersDB.db.transaction('Customers', 'readonly');
      transaction.oncomplete = (event: any) => {
        //resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Customers');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async checkCustomer(id: number) {
    if (!CustomersDB.db) await CustomersDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CustomersDB.db.transaction('Customers', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Customers');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        if (event.target.result) resolve(true);
        resolve(false);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async updateCustomer(user: any) {
    if (!CustomersDB.db) await CustomersDB.init();
    return new Promise((resolve, reject) => {
      const transaction = CustomersDB.db.transaction('Customers', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Customers');
      const request = objectStore.get(user.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          const username = user?.name || user?.user?.name;
          user.lowercaseName = username?.toLowerCase();

          const requestUpdate = objectStore.put(user);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async deleteCustomer(id: number) {
    if (!CustomersDB.db) await CustomersDB.init();

    if (await CustomersDB.getCustomer(id)) {
      return new Promise((resolve, reject) => {
        const transaction = CustomersDB.db.transaction('Customers', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Customers');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }

  static async addCustomersIfAbsent(customerIds: number[]): Promise<ICustomer[]> {
    if (!CustomersDB.db) await CustomersDB.init();
    return new Promise((resolve, reject) => {
      const transaction = CustomersDB.db.transaction('Customers', 'readwrite');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore('Customers');
      const request = objectStore.getAll();

      request.onsuccess = async (event) => {
        const storedCustomers = (event.target as IDBRequest).result as ICustomer[];
        const storedCustomerIds = storedCustomers.map((customer) => customer.id);
        const missingCustomerIds = customerIds.filter(
          (id) => Boolean(id) && !storedCustomerIds.includes(id)
        );

        if (missingCustomerIds.length) {
          const response = await get_customer_list_ids(missingCustomerIds);
          const fetchedCustomers = response.data.results;
          await CustomersDB.addCustomers(fetchedCustomers);

          const newCustomerList = [...storedCustomers, ...fetchedCustomers];
          const passedCustomer = newCustomerList.filter((customer) =>
            customerIds.includes(customer.id)
          );
          resolve(passedCustomer);
        } else {
          const passedCustomer = storedCustomers.filter((customer) =>
            customerIds.includes(customer.id)
          );
          resolve(passedCustomer);
        }
      };
    });
  }
}

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} agents on indexDB`));
}
