// import React from 'react';

// const POSCreate = () => {
//   return <div>POSCreate</div>;
// };

// export default POSCreate;

import {
  CalculatorFilled,
  CalculatorTwoTone,
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  CreditCardTwoTone,
  LeftCircleFilled,
  LeftCircleTwoTone,
  MinusCircleOutlined,
  MoneyCollectTwoTone,
  PlusOutlined,
  ProfileTwoTone
} from '@ant-design/icons';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Form,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Drawer,
  Input,
  Card,
  Tooltip,
  Spin
} from 'antd';
// import { useWatch } from 'antd/lib/form/Form';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { get_location_details } from '../../../services/locations/queries';
import { get_offer_list } from '../../../services/offfers/queries';
import {
  get_product_list,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productId,
  prices_by_groupId,
  get_price_groups_by_location
} from '../../../services/products/queries';
import { IUnits, IProductType } from '../../../services/products/types';
import { create_sell_mutation, create_sell_payment } from '../../../services/sell/mutations';
import { IAddPayment, IProductUnits, Line } from '../../../services/sell/types';
import {
  get_customer_details,
  get_customer_list,
  get_customer_list_route,
  get_user_details,
  get_user_list
} from '../../../services/users/queries';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { ILocations } from '../../../services/locations/types';
import { IUSerlocation } from '../../../services/routes/types';
import { IValuesforsell } from '../../../services/offfers/types';
import { ICustomer, ISystemNotification, IUserFromServer } from '../../../services/users/types';
import { create_tasks_mutation } from '../../../services/procurement/mutations';
import useDebounce from '../../../hooks/useDebounce';
import GoBackButton from '../../../components/Common/GoBackButton/GoBackButton';
import ScoreBoard from '../../score-board';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { CustomerSearch } from '../../../components/Common/CustomerSearch/CustomerSearch';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { RouteSearch } from '../../../components/Common/RouteSearch/RouteSearch';
import { get_routes_list } from '../../../services/routes/queries';
import ProductSearchForLines from '../../../components/Common/ProductSearch2';
import { getUserData } from '../../../utils/auth.utils';
import {
  find_invoice_due_limit,
  find_locationId_preference,
  find_shipping_cost
} from '../../../store/localstorage/preferences';
import ReusableQuantity from '../../../components/Common/ReusableQuantity';
import CustomersDB from '../../../store/localstorage/CustomerDB';
import UsersDB from '../../../store/localstorage/UsersDB';
import RoutesDB from '../../../store/localstorage/RoutesDB';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_sell_details, get_sell_lines_details } from '../../../services/sell/queries';
import ResuableCalculator from '../../../components/Common/ReusableCalculator';
import { async } from 'q';
import moment from 'moment';
import { get_category_list_filter } from '../../../services/category/queries';
import { CustomModal } from '../../../components/Common/CustomModal';
import MultiplePay from './MultiplePay';
import CustomErrorModal from '../../../components/Common/CustomErrorModal';
import RecentPos from './RecentPos';
import { get_invoices_list } from '../../../services/settings/queries';
import ReusableCustomerCreate from '../../../components/Common/CustomModal/ReusableCustomerCreate';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { Socket } from 'socket.io-client';
import { configSocket } from '../../../utils/websocket.utils';
import { SocketNameSpaces } from '../../../constants/websocketConfig';

// interface ITaxtype {
//   taxNumber: string;
//   taxType: string;
//   id?: string;
//   userId?: number;
// }

interface ILotsType {
  createdAt: string;
  expirationDate?: string;
  grade: string;
  id: number;
  locationId: number;
  lotIndex: number;
  lotNumber: string;
  lotStatus: string;
  productId: number;
  qtyAvailable: number;
  qtyClosing: number;
  qtyOpening: number;
  qtyReceived: number;
  qtySold: number;
  updatedAt: string;
}

const { Option } = Select;

const POSCreate = () => {
  const navigate = useNavigate();
  const [isTask, setIsTask] = useState<boolean>(true);
  const [form] = Form.useForm();
  // const [tax, setTax] = useState<ITaxtype[]>([]);
  const [address, setAddress] = useState<IUSerlocation[]>([]);
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [calculatorVisible, setCalculatorVisible] = useState<boolean>(false);
  const createSellPayment = useMutation(create_sell_payment);
  const [sellData, setSellData] = useState<any>();
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [offerList, setOfferList] = useState<any>([]);
  // const { data: usersList } = useQuery(['users'], async () => get_customer_list());
  useQuery(['offers'], async () => {
    const response = await get_offer_list();
    setOfferList(response?.data?.results);
  });
  // This is needed to get the product details update as its written
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAddCostumerModalOpen, setIsAddCostumerModalOpen] = useState<boolean>(false);
  const [productDetails, setProductDetails] = useState<any>();
  const [scoreBoardData, setScoreBoardData] = useState<any>();
  const [totalLots, settotalLots] = useState<any>([]);
  const [defaultPriceBylocationId, setDefaultPriceBylocationId] = useState<any>({});
  const [showgrandTotal, setShowgrandtotal] = useState<boolean>(false);
  const [defaultRoutes, setDefaultRoute] = useState<any>([]);
  // const { data: routeList } = useQuery(['routeslist'], async () => get_routes_list());
  const [pricegroupDropdown, setpricegroupDropdown] = useState<any>([]);
  const [pricegroupsgroupId, setpricegroupsId] = useState<any>({});
  const [pricegrouplocationId, setpricegrouplocationId] = useState<any>({});
  const [defaultPriceGroup, setdefaultPriceGroup] = useState<any>([]);
  const loggedInUser = getUserData();
  const autofocusRef: React.Ref<any> = useRef(null);
  const preferenceLocationId = find_locationId_preference();
  const [customerDetails, setCustomerDetails] = useState<any>();
  const [isModalOpen2, setIsModalOpen2] = useState<boolean>(false);
  // const [isRouteSelected, setIsRouteSelected] = useState<boolean>(false);
  const breadcrumbItems = [
    {
      label: 'POS Sell Create',
      link: 'sell/possell-create'
    }
  ];
  const [searchValueUser, setSearchValueUser] = useState<string>('');
  const debouncedSearchValueUser = useDebounce(searchValueUser, 500);
  const [selectValue, setselectValue] = useState<any>();
  useEffect(() => {
    searchUser(debouncedSearchValueUser);
    // searchUser(debouncedSearchValue);
  }, [debouncedSearchValueUser]);
  const [userSearch, setUserSearch] = useState<any>([]);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  // const [categoryProductList, setCategoryProductList] = useState<any>({});
  const [categoryId, setCategoryId] = useState<number | string>('all');
  const [allProductList, setALlProductList] = useState<any>([]);
  const [invoiceLists, setInvoiceLists] = useState<any>([]);
  useQuery(['category-List'], async () => {
    const categoryResponse = await get_category_list_filter('');
    // console.log('res', categoryResponse);
    if (categoryResponse.data) {
      setCategoryList(categoryResponse.data.results);
    }
  });
  const [socket, setSocket] = useState<Socket>();
  useEffect(() => {
    if (!socket?.connected) {
      const socket = configSocket(SocketNameSpaces.NOTIFICATION);

      socket.on('connect', () => {
        //console.log('Socket Id --->', socket.id);
        setSocket(socket);
      });
    }

    socket?.on('SYSTEM_NOTIFICATION', async (data: ISystemNotification) => {
      //console.log('Args', data);
      if (data.type == 'NEW_CUSTOMER') {
        await CustomersDB.addCustomers([data.data]);
      }

      //const customer = await CustomersDB.getCustomer(data.data.id);
      //console.log('customer', customer);
    });

    return () => {
      socket?.off('SYSTEM_NOTIFICATION');
    };
  }, [socket?.connected]);

  useQuery(['product-List'], async () => {
    if (allProductList.length == 0) {
      const allProducts = await get_product_list(0, 1000);
      if (allProducts.data.results) {
        setALlProductList(allProducts.data.results);
      }
    }
  });

  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(response.data);
    return response;
  });

  useQuery(['customerList'], async () => {
    setIsloading(true);
    const value = '';
    const response = await get_customer_list(0, 10, value);
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any customer with that value in server!');
      setUserSearch([]);
      setIsMore(false);
      setSkip(0);
    } else {
      setUserSearch(response.data.results);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) setIsMore(false);
      else setIsMore(true);
      setSkip(10);
    }
    setIsloading(false);
  });

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const searchUser = async (value: any) => {
    let response;
    try {
      response = await get_customer_list(0, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any customer with that value in server!');
      setUserSearch([]);
      setIsMore(false);
      setSkip(0);
    } else {
      setUserSearch(response.data.results);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) setIsMore(false);
      else setIsMore(true);
      setSkip(10);
    }
  };

  const searchMoreUser = async (value: any) => {
    let response;
    try {
      const routeId = form.getFieldValue('routeId');
      if (routeId != '' && routeId != undefined) {
        response = await get_customer_list_route(skip, 10, value, routeId);
      } else {
        response = await get_customer_list(skip, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more customer with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setUserSearch([...userSearch, ...response.data.results]);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const optionsUser = userSearch.map((value: any) => (
    <Option key={value.id} value={value.id}>
      <div className="flex justify-between">
        <div>
          {' '}
          {value.name ? value.name : value?.user?.name},{' '}
          {value.phone ? value.phone : value?.user?.phone}{' '}
        </div>
        <div style={{ marginRight: '15%', color: 'green' }}>
          {selectValue && selectValue == value.id ? (
            <>
              <span className="text-xl font-bold">{value.grade}</span>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Option>
  ));
  const createSellMutation = useMutation(create_sell_mutation);
  const onUserChange = async (id: number) => {
    let selectedUser: any = await CustomersDB.getCustomer(id);
    //console.log('Selected user', selectedUser);

    let userDetails: any; //for address
    if (!selectedUser) {
      const response = await get_customer_details(id);
      //console.log(response);
      if (response?.user && response?.customer) {
        selectedUser = response.customer;
        CustomersDB.addCustomers([{ ...response.customer, user: response?.user?.user }]);
        userDetails = response.user;
      }
    }
    const scoreDetails = JSON.parse(selectedUser.scoreCard);
    if (scoreDetails) {
      const diff = scoreDetails?.numberOfSales - scoreDetails?.numberOfPayments;
      const limitdiff = find_invoice_due_limit();
      if (diff > limitdiff) {
        message.error({
          content: `This customer has pending payments for ${diff} sales greater than limit.`,
          duration: 3
        });
        form.setFieldValue('userId', null);
        return;
      }
    }
    // let userDetails: any = await UsersDB.getUser(selectedUser.userId);
    if (!userDetails) {
      userDetails = await get_user_details(selectedUser.userId);
    }
    if (userDetails.addresses.length > 0) {
      form.setFieldValue('addressId', userDetails.addresses[0].id);
      // const findOne = userDetails.addresses.find((curr: any) => curr.routeId !== null);
      // if (findOne) form.setFieldValue(['routeId'], findOne.routeId);
    } else {
      form.setFieldValue('addressId', null);
    }
    // if (userDetails.tax_informations.length > 0) {
    //   form.setFieldValue(['taxId'], userDetails.tax_informations[0].id);
    // }
    setCustomerDetails(selectedUser);
    setAddress(userDetails.addresses);
    // setTax(userDetails.tax_informations);
    setScoreBoardData({ userId: id, allData: JSON.parse(selectedUser.scoreCard) });
  };

  const createTaskMutation = useMutation(create_tasks_mutation, {
    onSuccess: (data: any) => {
      message.success('Task added successfully');
    },
    onError: (data: any) => {
      message.error(data.message);
    }
  });

  const onFinish = (values: any, type?: string) => {
    //console.log('valuesdsfds ', { ...values });
    const copyValues = { ...values };
    delete values.userId;
    delete values.routeId;
    delete values.grandtotal;
    delete values.taxable;
    delete values.nonTaxable;
    delete values.total;
    delete values.vat;
    // console.log('Copy values', copyValues);
    values.cratesSent = 0;
    values.adjustment = 0;
    // values.sellOrderId = 0;
    try {
      //validation
      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };
      for (let i = 0; i < values.lines.length; i++) {
        // delete values.lines[i].productName;
        delete values.lines[i].total;
        delete values.lines[i].sellingPrice;
        const filteredLot = totalLots.find((value: any) => value.id == values.lines[i].lotId);
        const unitId = values.lines[i].unitId;
        const findOne: any = allLocalUnits.find((curr: any) => curr.id === unitId);
        if (filteredLot.qtyAvailable < values.lines[i].quantity * findOne.baseUnitMultiplier) {
          form.setFields([
            {
              name: ['lines', i, 'quantity'],
              errors: [
                `available quantity: ${filteredLot.qtyAvailable / findOne.baseUnitMultiplier} ${
                  findOne.shortName
                }`
              ]
            }
          ]);
          throw {
            name: 'QtyError',
            message: `The selected product number ${i + 1}. ${
              values.lines[i].productName
            }'s lot has quantity: ${
              filteredLot.qtyAvailable / findOne.baseUnitMultiplier
            }. Cannot send ${values.lines[i].quantity}.`
          };
        }
      }
      if (type != 'multiple') {
        settotalLots([]);
      }
      values.date = JSON.stringify(new Date()).slice(1, -1);
      values.createdBy = loggedInUser.id;
      values.type = 'POS';
      values.note = 'This is created from POS.';
      values.shipping = 0;
      if (type == 'multiple') {
        let totalAmount = 0;
        values.lines.map((curr: any) => {
          totalAmount += curr.quantity * curr.unitPrice;
        });
        setSellData({ ...values, userId: copyValues.userId, totalAmount });
        return true;
      }
      createSellMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            // console.log('sell added sucessfully');
            if (type == 'cash') {
              addPayment(data);
            }
            // if (type == 'multiple') {
            //   addMultiplePayment();
            //   setSellData(data);
            // }
            if (type == 'card') {
              addPayment(data, 'card');
            }
            if (!type) {
              setIsloading(false);
              form.setFieldValue(['lines'], []);
            }
            // console.log('copyValuesforPdf', copyValues);
            printPDF({
              ...copyValues,
              financialReference: data.financialReference,
              referenceNumber: data.referenceNumber,
              totalAmount: data.totalAmount
            });
            message.success('Sell added successfully');
            form.setFieldValue(['lines'], []);
            form.setFieldValue(['total'], 0);
            form.setFieldValue(['taxable'], 0);
            form.setFieldValue(['nonTaxable'], 0);
            form.setFieldValue(['vat'], 0);
            form.setFieldValue(['grandtotal'], 0);
          }
        },
        onError: (data: any) => {
          message.error(data.message);
          setIsloading(false);
          // return false;
        }
      });
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) message.error(errors.message);
      else message.error('Empty fields found!');
      return false;
    }
  };

  const printPDF = async (values: any) => {
    //console.log('val ', values);
    // console.log('sell created', createSell({...values}));

    // console.log('this runs');
    // console.log('generate pdf clicked', record);
    // const response: any = await get_sell_details(parseInt(record.id as string));
    // console.log('create by id', response.createdBy);
    // let routeDetails: any;
    // console.log('response-->', response);
    // if (values.routeId) {
    //   const routeId = values.routeId;
    //   routeDetails = await RoutesDB.getRoute(routeId);
    //   if (!routeDetails) {
    //     const response = await get_routes_list();
    //     await RoutesDB.addRoutes(response?.data?.results);
    //     routeDetails = await RoutesDB.getRoute(routeId);
    //   }
    // }
    const routeDetails = {
      name: ''
    };
    // let createdByDetails: any;

    // console.log('createdBy details', createdByDetails);
    let vendorDetails: any = await CustomersDB.getCustomer(values.userId);
    vendorDetails = vendorDetails?.user;
    if (!vendorDetails) {
      vendorDetails = (await get_customer_details(values.userId)).user.user;
      // console.log('Vendor Details', vendorDetails);
      // await CustomersDB.addCustomers([vendorDetails]);
    }

    let locationDetails: any = await LocationsDB.getLocation(values.locationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(values.locationId);
      await LocationsDB.addLocations([locationDetails]);
    }
    let totalAmount: any = 0;
    let totalQuantity: any = 0;
    let taxable = 0;
    let nonTaxable = 0;

    let shortName: any = '';
    for (let index = 0; index < values.lines.length; index++) {
      let findUnit: any = await UnitsDB.getUnit(values.lines[index].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(values.lines[index].unitId);
      }
      // values.liness[index].unitName = findUnit.name;
      totalQuantity += values.lines[index].quantity;
      values.lines[index].amount = values.lines[index].unitPrice * values.lines[index].quantity;
      totalAmount += values.lines[index].amount;
      values.lines[index].quantity = `${values.lines[index].quantity} ${findUnit.shortName}`;
      shortName = findUnit.shortName;

      let productDetails = await ProductsDB.getProduct(values.lines[index].productId);
      if (!productDetails) {
        const allProducts = await get_product_list();
        await ProductsDB.addProducts(allProducts.data.results);
        productDetails = await ProductsDB.getProduct(values.lines[index].productId);
      }
      if (typeof productDetails === 'object') {
        if (productDetails?.vat != undefined) {
          const vat = values.lines[index].amount * (productDetails.vat / 100);
          //console.log('values', values);
          if (vat > 0) {
            taxable += values.lines[index].amount;
          } else {
            nonTaxable += values.lines[index].amount;
          }
        }
      }
    }

    values.taxable = taxable;
    values.nonTaxable = nonTaxable;

    // console.log({
    //   purchaseDetails: { ...values, totalQuantity: `${totalQuantity} ${shortName}` },
    //   locationDetails,
    //   customerDetails: vendorDetails,
    //   lines: values.lines,
    //   createdByDetails: loggedInUser,
    //   routeDetails
    // });

    const invoiceLayouts = invoiceLists.find((curr: any) => {
      const content: any = JSON.parse(curr.content);
      return content.design == 'bill';
    });
    console.log('Values', values);
    localStorage.setItem(
      'referrer',
      JSON.stringify({
        purchaseDetails: {
          ...values,
          totalAmount,
          totalQuantity: `${totalQuantity} ${shortName}`,
          fromPos: true
        },
        locationDetails,
        customerDetails: vendorDetails,
        lines: values.lines,
        createdByDetails: loggedInUser,
        routeDetails,
        invoiceLayouts
      })
    );
    // window.open('/ERP-UI#/POSS-Sellinvoice', '_blank', 'noopener,noreferrer');
    window.open('/ERP-UI#/billprint', '_blank', 'noopener,noreferrer');
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }
      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: Line) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
        }
      } else {
        for (let i = 0; i < productsIdArray.length; i++) {
          const result = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[i]
          );
          currenttotalLots.push(...result);
        }
        settotalLots([...currenttotalLots]);
      }
    } catch (errors: any) {
      message.error(errors.message);
    }
  };

  const onLocationChange = async (value: number) => {
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any, ind: number) => {
        mySet.add(curr.productId);
        form.setFieldValue(['lines', ind, 'lotId'], null);
      });
    }
    form.setFieldValue('location', value);
    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');
    // let defaultPrice: any = [];
    let currPriceGroups: any = [];
    if (pricegrouplocationId[value]) {
      setpricegroupDropdown(pricegrouplocationId[value]);
      currPriceGroups = [...pricegrouplocationId[value]];
    } else {
      const response = await get_price_groups_by_location(value);
      let defaultpricegroup = [...defaultPriceGroup];
      if (defaultpricegroup.length == 0) {
        const response2 = await get_price_groups_by_location('');
        setdefaultPriceGroup(response2.data);
        defaultpricegroup = [...response2.data];
      }
      if (response?.data) {
        currPriceGroups = [...response.data, ...defaultpricegroup];
        setpricegroupDropdown([...response.data, ...defaultpricegroup]);
        setpricegrouplocationId((prev: any) => ({
          ...prev,
          [value]: [...response.data, ...defaultpricegroup]
        }));
      }
    }
    if (data.length == 0) {
      return;
    }
    if (currPriceGroups.length == 0) {
      for (let lineindex = 0; lineindex < data.length; lineindex++) {
        form.setFieldValue(['lines', lineindex, 'unitPrice'], 0);
        form.setFieldValue(['lines', lineindex, 'priceGroupId'], null);
      }
    }
    const foundArray = new Array(data.length).fill(false);
    for (let ind = 0; ind < currPriceGroups.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[currPriceGroups[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[currPriceGroups[ind].id];
      } else {
        const response2 = await prices_by_groupId(currPriceGroups[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [currPriceGroups[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      for (let lineindex = 0; lineindex < data.length; lineindex++) {
        if (!foundArray[lineindex]) {
          const selectedProductId = data[lineindex].productId;
          const selectedUnitId = data[lineindex].unitId;
          const findOne = currDefaultPrice.find(
            (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
          );
          if (findOne) {
            form.setFieldValue(['lines', lineindex, 'unitPrice'], findOne.sellingPrice);
            form.setFieldValue(['lines', lineindex, 'priceGroupId'], findOne.priceGroupId);
            foundArray[lineindex] = true;
          } else {
            form.setFieldValue(['lines', lineindex, 'unitPrice'], 0);
            form.setFieldValue(['lines', lineindex, 'priceGroupId'], null);
          }
        }
      }

      const checkallisfound = foundArray.find((curr: any) => !curr);
      if (checkallisfound == undefined) {
        break;
      }
    }
  };
  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    // console.log('check current Product ', checkCurrentProduct);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      // console.log('productSearch', productSearch);
      // console.log('selectedProduct', selectedProduct);
      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);

    const currentLocation = form.getFieldValue(['locationId']);

    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }
      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      // console.log('lotID', form.getFieldValue(['lines', name, 'lotId']));
      if (!form.getFieldValue(['lines', name, 'lotId'])) {
        // console.log('this runs', filteredLots);
        filteredLots.sort((a: any, b: any) => b.qtyAvailable - a.qtyAvailable);
        if (filteredLots.length > 0) {
          form.setFieldValue(['lines', name, 'lotId'], filteredLots[0].id);
        }
      }
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)} ${
                unitInfo?.shortName || ''
              }) ${value.lotNumber}`}{' '}
              {`Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };

  const onProductChange = async (value: number, productDetails?: any) => {
    setIsloading(true);
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['locationId']);

    if (productDetails) {
      // console.log('productDetails', productDetails);
      const unitIdList: IProductUnits[] = productDetails.productUnits;
      const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
      form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
      if (!form.getFieldValue('offerId')) {
        const wholeDiscountOffer = offerList.find((curr: any) => curr.wholeDiscount != null);
        if (wholeDiscountOffer) {
          form.setFieldValue('offerId', wholeDiscountOffer.id);
        } else {
          for (let ind = 0; ind < offerList.length; ind++) {
            if (offerList[ind].productDiscount) {
              const productDiscountArray = JSON.parse(offerList[ind].productDiscount);
              const findProduct = productDiscountArray.find(
                (currPro: any) => currPro.productId == productDetails.id
              );
              if (findProduct) {
                form.setFieldValue('offerId', offerList[ind].id);
                break;
              } else {
                continue;
              }
            }
          }
        }
      }
    } else {
      if (productList.length > 0) {
        const selectedProduct = productList.find((val) => val.id == value);
        // console.log('productSearch', productSearch);
        // console.log('products-> ', selectedProduct);
        // const selectedProduct = productList.data.results.find((val: IProductType) => val.id == value);
        const unitIdList: IProductUnits[] = selectedProduct.productUnits;
        const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
        form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
        let fetch = false;
        for (const value of unitIdList) {
          let unitData;
          try {
            unitData = await UnitsDB.getUnit(value.unitId);
          } catch (e) {
            console.log(e);
          }
          if (!unitData) {
            const response = await get_units_list();
            await UnitsDB.addUnits(response);
            fetch = true;
          }
        }
        if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
        if (!form.getFieldValue('offerId')) {
          const wholeDiscountOffer = offerList.find((curr: any) => curr.wholeDiscount != null);
          if (wholeDiscountOffer) {
            form.setFieldValue('offerId', wholeDiscountOffer.id);
          } else {
            for (let ind = 0; ind < offerList.length; ind++) {
              if (offerList[ind].productDiscount) {
                const productDiscountArray = JSON.parse(offerList[ind].productDiscount);
                const findProduct = productDiscountArray.find(
                  (currPro: any) => currPro.productId == selectedProduct.id
                );
                if (findProduct) {
                  form.setFieldValue('offerId', offerList[ind].id);
                  break;
                } else {
                  continue;
                }
              }
            }
          }
        }
      }
    }
    // form.setFieldValue([name, 'productId'], value);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');

    const selectedUnitId = form.getFieldValue(['lines', data.length - 1, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', data.length - 1, 'productId']);
    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      setIsloading(false);
      return;
    }

    for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
      } else {
        const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [pricegroupDropdown[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      const findOne = currDefaultPrice.find(
        (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
      );
      if (findOne) {
        form.setFieldValue(['lines', data.length - 1, 'unitPrice'], findOne.sellingPrice);
        form.setFieldValue(['lines', data.length - 1, 'priceGroupId'], findOne.priceGroupId);
        break;
      } else {
        form.setFieldValue(['lines', data.length - 1, 'unitPrice'], 0);
        form.setFieldValue(['lines', data.legth - 1, 'priceGroupId'], null);
      }
    }
    setIsloading(false);
  };

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));
  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      locationId: preferenceLocationId ? preferenceLocationId : null,
      addressId: null,
      // taxId: null,
      lines: [],
      discount: 0,
      misc: 0,
      shipping: 0,
      adjustment: 0,
      offerId: null,
      note: ''
      // status: 'pending'
    });
    initializeUnits();
    if (preferenceLocationId) {
      onLocationChange(preferenceLocationId);
    }
  }, []);

  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const onUnitandQuantityChange = async (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    // console.log('total', total);
    if (typeof total == 'number') form.setFieldValue(['lines', name, 'total'], total);

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    let totalAmt = 0;
    let taxable = 0;
    let nonTaxable = 0;
    let vatTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      totalAmt += lines[i].total;
      grandTotal += lines[i].total;

      let productDetails = await ProductsDB.getProduct(lines[i].productId);
      if (!productDetails) {
        const allProducts = await get_product_list();
        await ProductsDB.addProducts(allProducts.data.results);
        productDetails = await ProductsDB.getProduct(lines[i].productId);
      }
      if (typeof productDetails === 'object' && productDetails.vat != undefined) {
        const vat = lines[i].total * (productDetails.vat / 100);
        if (vat > 0) {
          vatTotal += vat;
          taxable += lines[i].total;
        } else {
          nonTaxable += lines[i].total;
        }
      }
    }
    // console.log('---', form.getFieldsValue());
    // console.log('grandtotal', grandTotal);

    form.setFieldValue(['total'], totalAmt);
    form.setFieldValue(['taxable'], taxable);
    form.setFieldValue(['nonTaxable'], nonTaxable);
    form.setFieldValue(['vat'], vatTotal);
    form.setFieldValue(['grandtotal'], grandTotal + vatTotal);
    const shippingCost = find_shipping_cost(grandTotal);
    form.setFieldValue(['shipping'], shippingCost);
    // const grandTotall = form.getFieldValue(['lines','grandtotal']);
    // console.log('grandTotall field value', grandTotall);
  };

  const onPriceGroupChange = async (val: number) => {
    const productInfo = form.getFieldValue(['lines', val]);
    // console.log('product Info', productInfo);
    let defaultPrice: any = [];
    const locationId = form.getFieldValue(['locationId']);
    // console.log('pricegroupsgroupId', pricegroupsgroupId);
    if (locationId && productInfo.priceGroupId) {
      if (!pricegroupsgroupId[productInfo.priceGroupId]) {
        const response2 = await prices_by_groupId(productInfo.priceGroupId);
        defaultPrice = [...response2.data];
        // pricegroupsgroupId[productInfo.priceGroupId] = [...response2.data];
        setpricegroupsId((prev: any) => ({
          ...prev,
          [productInfo.priceGroupId]: response2.data
        }));
      } else {
        defaultPrice = pricegroupsgroupId[productInfo.priceGroupId];
      }
    }

    const alreadySelectedUnitId = form.getFieldValue(['lines', val, 'unitId']);
    const findone = defaultPrice.find(
      (res2elem: any) =>
        res2elem.productId == productInfo.productId && res2elem.unitId == alreadySelectedUnitId
    );
    if (findone) {
      form.setFieldValue(['lines', val, 'unitPrice'], findone.sellingPrice);
    } else {
      form.setFieldValue(['lines', val, 'unitPrice'], 0);
    }
  };

  const onUnitChange = async (name: number) => {
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);
    // console.log('pid', selectedProductId);
    // console.log('uid', selectedUnitId);
    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
      } else {
        const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [pricegroupDropdown[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      const findOne = currDefaultPrice.find(
        (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
      );
      // console.log('findone', findOne);
      if (findOne) {
        form.setFieldValue(['lines', name, 'unitPrice'], findOne.sellingPrice);
        form.setFieldValue(['lines', name, 'priceGroupId'], findOne.priceGroupId);
        break;
      } else {
        form.setFieldValue(['lines', name, 'unitPrice'], 0);
        form.setFieldValue(['lines', name, 'priceGroupId'], null);
      }
    }
  };

  const handleCash = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    // console.log('values', values);
    if (values.lines.length == 0) {
      setIsloading(false);
      message.error('Please select at least one product!');
      return;
    }
    setIsloading(true);
    onFinish(form.getFieldsValue(), 'cash');
  };

  const addPayment = async (values: any, type?: any) => {
    // console.log('sell data', values);
    // console.log('customerDetails', customerDetails);
    const newValues = {
      id: values.id,
      adjustment: false,
      amount: values.totalAmount,
      date: moment().utc().format('YYYY-MM-DD'),
      madeBy: customerDetails.userId,
      note: 'This payment is made from pos sell',
      paymentMethod: type ? type : 'cash',
      reference: values.referenceNumber,
      walletPayment: 0
    };
    createSellPayment.mutate(newValues as IAddPayment, {
      onSuccess: async ({ data }: { data: any }) => {
        message.success('Payment Done successfully!');
        // console.log('Payment done sucessfully');
        form.setFieldValue(['lines'], []);
        setIsloading(false);
      },
      onError: (e: any) => {
        // message.error(`${e.response.data.message}`, 5);
        // CustomErrorModal({
        //   message: e.response.data.message
        // });
        setIsloading(false);
      }
    });
  };

  const addMultiplePayment = async () => {
    // console.log('Multiple payment', values);
    setIsModalOpen(true);
    form.setFieldValue(['lines'], []);
    setIsloading(false);
  };

  const handleMultiplePay = async () => {
    await form.validateFields();
    // console.log('Valuesdssd', form.getFieldsValue());
    if (!onFinish(form.getFieldsValue(), 'multiple')) return;
    setIsModalOpen(true);
  };

  const handleAddCustomer = () => {
    setIsAddCostumerModalOpen(true);
  };

  const handleCategoryChange = async (e: any) => {
    // console.log('change', e);
    setCategoryId(e);

    if (allProductList.length == 0) {
      const allProducts = await get_product_list(0, 1000);
      if (allProducts.data.results) {
        setALlProductList(allProducts.data.results);
      }
    }
    // const categoryIdProductmap: any = {};
    // if (allProducts.data.results) {
    //   for (let ind = 0; ind < allProducts.data.results.length; ind++) {
    //     const { categoryId } = allProducts.data.results[ind];
    //     if (!categoryIdProductmap[categoryId]) {
    //       categoryIdProductmap[categoryId] = [allProducts.data.results[ind]];
    //     } else {
    //       categoryIdProductmap[categoryId] = [
    //         ...categoryIdProductmap[categoryId],
    //         allProducts.data.results[ind]
    //       ];
    //     }
    //   }
    // }
    // setCategoryProductList(categoryIdProductmap);
  };

  const handleAddProductFromCategoryFilter = (productValues: any) => {
    // console.log('--.', productValues);
    const prevLines = form.getFieldValue(['lines']);
    form.setFieldValue(
      ['lines'],
      [
        ...prevLines,
        {
          productId: productValues.id,
          productName: productValues.name,
          unitId: null,
          lotId: null,
          quantity: null,
          unitPrice: 0,
          discount: 0,
          misc: 0,
          total: 0,
          isTraceable: false
        }
      ]
    );
    const find = productSearch.find((val: any) => val.id == productValues.id);
    // console.log('find', find);
    if (!find) {
      // console.log('this runs search');
      // setProductSearch((prev) => [...prev, productValues]);
      setProductList((prev: any) => {
        // console.log('prev', prev);
        return [...prev, productValues];
      });
    }
    onProductChange(productValues.id, productValues);
  };

  const handleAddCustomerModalClose = async (customerId: number, searchValue: string) => {
    setIsloading(true);
    //console.log('customerId', customerId);
    setIsAddCostumerModalOpen(false);
    await searchUser(searchValue);
    setselectValue(customerId);
    form.setFieldValue('userId', customerId);
    await onUserChange(customerId);
    setIsloading(false);
  };

  const handleCreditSell = async () => {
    await form.validateFields();
    setIsloading(true);
    const values = form.getFieldsValue();
    // console.log('values', values);
    if (values.lines.length == 0) {
      message.error('Please select at least one product!');
      setIsloading(false);
      return;
    }
    onFinish(form.getFieldsValue());
  };

  const handleCardSell = async () => {
    await form.validateFields();
    setIsloading(true);
    const values = form.getFieldsValue();
    if (values.lines.length == 0) {
      message.error('Please select at least one product!');
      setIsloading(false);
      return;
    }
    onFinish(form.getFieldsValue(), 'card');
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        isModalOpen={isAddCostumerModalOpen}
        setIsModalOpen={setIsAddCostumerModalOpen}
        title={'Add Customer'}
        footer={false}>
        <ReusableCustomerCreate handleModalClose={handleAddCustomerModalClose} />
      </CustomModal>
      <CustomModal
        footer={false}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={'Add Multiple Payment'}>
        {sellData && (
          <MultiplePay
            sellData={{
              ...sellData,
              userId: customerDetails.userId,
              name: customerDetails.user.name
            }}
            onCancel={() => setIsModalOpen(false)}
            form2={form}
            printPDF={printPDF}
            setTotalLots={settotalLots}
          />
        )}
      </CustomModal>
      {isModalOpen2 && (
        <CustomModal
          footer={false}
          isModalOpen={isModalOpen2}
          setIsModalOpen={setIsModalOpen2}
          title={'Recent Pos Sales'}>
          {isModalOpen2 && <RecentPos isModalOpen={isModalOpen2} />}
        </CustomModal>
      )}
      <div className="m-4">
        <Drawer
          title="Select Product Based on Category"
          placement="right"
          width={'60%'}
          onClose={onClose}
          visible={open}>
          <div>
            <div className="grid grid-cols-2">
              <Select
                placeholder="Select category"
                dropdownMatchSelectWidth={false}
                onChange={handleCategoryChange}
                defaultValue={'all'}
                allowClear>
                <Option value="all">All</Option>
                {categoryList?.map((value: any) => (
                  <Option value={value.id} key={value.id}>
                    {value.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className={classNameStyle}>
            {categoryId == 'all' ? (
              <>
                {allProductList.map((curr: any) => {
                  return (
                    <div
                      key={curr.id}
                      style={cardStyle}
                      onClick={() => handleAddProductFromCategoryFilter(curr)}>
                      {curr.name}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {allProductList
                  .filter((curr: any) => curr.categoryId == categoryId)
                  .map((curr: any) => {
                    return (
                      <div
                        key={curr.id}
                        style={cardStyle}
                        onClick={() => handleAddProductFromCategoryFilter(curr)}>
                        {curr.name}
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </Drawer>
        {calculatorVisible && (
          <div
            style={{
              position: 'absolute',
              right: '2rem',
              top: '2rem',
              visibility: calculatorVisible ? 'visible' : 'hidden',
              zIndex: 10000
            }}>
            <ResuableCalculator />
          </div>
        )}
        {/* <AppContent breadcrumbItems={breadcrumbItems} backgroundWhite={true}> */}
        <div className="flex justify-between items-center mb-3">
          <div className="text-xl font-bold">Sell POS Create</div>
          <div className="flex items-center justify-end gap-5">
            <Tooltip title="Go back" color="blue">
              <LeftCircleTwoTone
                onClick={() => {
                  navigate('/sell');
                }}
                style={{ transform: 'scale(1.4)' }}
              />
            </Tooltip>
            <Tooltip title="Calculator" color="blue">
              <CalculatorTwoTone
                style={{ transform: 'scale(1.4)' }}
                onClick={() => {
                  setCalculatorVisible((prev) => !prev);
                }}
              />
            </Tooltip>
            <Button type="primary" onClick={showDrawer}>
              Open
            </Button>
            <Button type="primary" onClick={handleAddCustomer}>
              Add Customer
            </Button>
          </div>
        </div>
        <div className="grid">
          <Form
            form={form}
            // onFinish={onFinish}
            layout="vertical"
            validateTrigger={'onChange'}
            disabled={isLoading}
            onValuesChange={(_, allFields) => {
              setProductDetails(allFields);
            }}
            autoComplete="off"
            className="p-2">
            <div
              className="card bg-white mb-1"
              style={{ borderRadius: '10px', padding: '0.8rem 0.8rem' }}>
              {/* <PageHeader
              title="Sell Information"
              style={{
                padding: '0px 0px'
              }}
            /> */}
              <div className={'grid cols-1 gap-2 md:grid-cols-3 md:gap-5'}>
                <LocationSearch
                  onSelect={onLocationChange}
                  notAll={true}
                  required={true}
                  disabled={true}
                />
                {/* <RouteSearch
                  form={form}
                  onSelect={(val: number) => searchUser('')}
                  isClear={true}
                /> */}
                {/* <CustomerSearch onSelect={onUserChange} /> */}
                <Form.Item
                  name={['userId']}
                  label="Customer"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose Customer!'
                    }
                  ]}>
                  <Select
                    showSearch
                    placeholder={'search user'}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    // disabled={!isRouteSelected}
                    onChange={(value) => {
                      setselectValue(value);
                      onUserChange(value);
                    }}
                    dropdownMatchSelectWidth={false}
                    onSearch={(val) => {
                      setSearchValueUser(val);
                    }}
                    notFoundContent={null}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />

                        <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                          {isMore ? (
                            <Button
                              type="text"
                              style={{
                                color: 'blue',
                                width: '100%'
                              }}
                              onClick={() => {
                                searchMoreUser(searchValueUser);
                              }}>
                              {/* {'Sync & Search on Server'} */}
                              {'Get More...'}
                            </Button>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                textAlign: 'center'
                              }}>
                              No more data...
                            </div>
                          )}
                        </div>
                      </>
                    )}>
                    {optionsUser}
                  </Select>
                </Form.Item>
                <div></div>
              </div>
            </div>
            {/* {scoreBoardData && (
            <div className="card grid mb-5 mt-5 bg-white" style={{ borderRadius: '10px' }}>
              <ScoreBoard data={scoreBoardData} />
            </div>
          )} */}
            <Form.List name={['lines']}>
              {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
                <>
                  <div>
                    <div
                      className={'card grid mb-1 mt-1 bg-white'}
                      style={{ borderRadius: '10px', padding: '0.8rem 0.8rem' }}>
                      <PageHeader
                        subTitle="Add Product"
                        style={{
                          padding: '0px 0px 0px 0px'
                        }}
                      />
                      <ProductSearchForLines
                        add2={add2}
                        onProductChange={onProductChange}
                        productList={productList}
                        setProductList={setProductList}
                        productSearch={productSearch}
                        setProductSearch={setProductSearch}
                        autofocusRef={autofocusRef}
                        locationId={form.getFieldValue(['locationId'])}
                      />
                    </div>
                    {fields2.length > 0 && (
                      <PageHeader
                        title="All Products"
                        style={{
                          padding: '0px 0px 0px 0px',
                          marginTop: '0px'
                        }}
                      />
                    )}
                  </div>
                  <Card
                    style={{
                      maxHeight: '50vh',
                      overflowY: 'scroll',
                      // backgroundColor: 'gray',
                      borderRadius: '9px'
                    }}>
                    {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                      <>
                        <div className="flex gap-2 items-center">
                          <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                          <div
                            key={key2}
                            className="card"
                            style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                            <div
                              className={
                                'grid grid-cols-2 gap-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8'
                              }
                              key={key2}>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'productId']}
                                hidden></Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'autoFocus']}
                                hidden></Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'sellingPrice']}
                                hidden></Form.Item>
                              <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                                <Input
                                  type={'text'}
                                  disabled
                                  style={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    // border: '0px',
                                    fontWeight: 'bold'
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'unitId']}
                                label="Unit"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please choose a Unit!'
                                  }
                                ]}>
                                {
                                  <Select
                                    placeholder="Select a Unit!"
                                    onChange={() => onUnitChange(name2)}
                                    dropdownMatchSelectWidth={false}
                                    allowClear>
                                    {FilterUnits(name2)}
                                  </Select>
                                }
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'lotId']}
                                label="Lot"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please choose a Lot!'
                                  }
                                ]}>
                                {
                                  <Select
                                    placeholder="Select a Lot!"
                                    dropdownMatchSelectWidth={false}
                                    // onChange={onLocationChange}
                                    allowClear>
                                    {FilterLot(name2)}
                                  </Select>
                                }
                              </Form.Item>

                              <ReusableQuantity
                                name={name2}
                                restField={restField2}
                                onChangeData={() => onUnitandQuantityChange(name2)}
                                onPressEnterData={(e) => {
                                  if (autofocusRef.current) {
                                    autofocusRef.current.focus();
                                  }
                                }}
                              />
                              <Form.Item
                                {...restField2}
                                name={[name2, 'priceGroupId']}
                                label="Price Group"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Select Price Group'
                                  }
                                ]}>
                                {
                                  <Select
                                    placeholder="Select price group"
                                    dropdownMatchSelectWidth={false}
                                    onChange={() => onPriceGroupChange(name2)}
                                    allowClear>
                                    {pricegroupDropdown?.map((value: any) => (
                                      <Option value={value.id} key={value.id}>
                                        {value.name}
                                      </Option>
                                    ))}
                                  </Select>
                                }
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                label="Rate"
                                name={[name2, 'unitPrice']}
                                rules={[
                                  { required: true, message: 'Please add Rate!' },
                                  () => ({
                                    validator(_: any, value: any) {
                                      if (!value) {
                                        return Promise.reject(`Please input valid Rate!`);
                                      }
                                      if (value == 0)
                                        return Promise.reject(`Please Input valid Rate!`);

                                      return Promise.resolve();
                                    }
                                  })
                                ]}>
                                <InputNumber
                                  controls={false}
                                  min={0}
                                  onChange={() => onUnitandQuantityChange(name2)}
                                />
                              </Form.Item>
                              <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                                <InputNumber
                                  controls={false}
                                  min={0}
                                  disabled
                                  style={{ color: 'black' }}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                label="Discount"
                                name={[name2, 'discount']}
                                rules={[{ required: true, message: 'Please add Discount!' }]}
                                hidden>
                                <InputNumber
                                  controls={false}
                                  min={0}
                                  onChange={() => onUnitandQuantityChange(name2)}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                label="Misc"
                                name={[name2, 'misc']}
                                rules={[{ required: true, message: 'Please add Misc!' }]}
                                hidden>
                                <InputNumber
                                  controls={false}
                                  min={0}
                                  onChange={() => onUnitandQuantityChange(name2)}
                                />
                              </Form.Item>

                              {/* <div className="flex items-center justify-between gap-2"> */}

                              {fields2.length > 0 ? (
                                <div className="flex items-center justify-start mt-5">
                                  <CustomButton
                                    backgroundColor="white"
                                    text="Remove"
                                    textColor="green"
                                    onClick={() => remove2(name2)}
                                  />
                                </div>
                              ) : null}
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </Card>
                </>
              )}
            </Form.List>
            {form.getFieldValue(['lines'])?.length > 0 ? (
              <Card style={{ borderRadius: '10px', marginBottom: '10px', marginTop: '10px' }}>
                <div className="grid grid-cols-2 gap-3 mb-3 md:grid-cols-3 lg:grid-cols-4">
                  <Form.Item name={['total']} label="Total">
                    <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                  </Form.Item>
                  <Form.Item name={['taxable']} label="Taxable">
                    <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                  </Form.Item>
                  <Form.Item name={['nonTaxable']} label="Non Taxable">
                    <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                  </Form.Item>
                  <Form.Item name={['vat']} label="VAT">
                    <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                  </Form.Item>
                  <Form.Item name={['grandtotal']} label="Grand Total">
                    <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                  </Form.Item>
                </div>
              </Card>
            ) : null}
            <div
              className={'card grid grid-cols-2 gap-3 mb-3 md:grid-cols-3 lg:grid-cols-4 bg-white'}
              style={{ borderRadius: '10px' }}>
              <Form.Item
                name={['addressId']}
                label="Address"
                rules={[
                  {
                    required: true,
                    message: 'Please choose address!'
                  }
                ]}>
                <Select placeholder="Select a address!" allowClear dropdownMatchSelectWidth={false}>
                  {address?.map((value: IUSerlocation) => {
                    return (
                      <Option value={value.id} key={value.id}>
                        {`${value.addressLine1}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {/* <Form.Item
                name={['taxId']}
                label="Tax"
                rules={[
                  {
                    required: true,
                    message: 'Please choose tax number!'
                  }
                ]}>
                <Select
                  placeholder="Select a tax number!"
                  allowClear
                  dropdownMatchSelectWidth={false}>
                  {tax?.map((value: ITaxtype) => {
                    return (
                      <Option value={value.id} key={value.id}>
                        {`${value.taxNumber}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item> */}
              {/* <Form.Item
                name={['offerId']}
                label="Offer"
                rules={[
                  {
                    required: false,
                    message: 'Please choose offer!'
                  }
                ]}>
                <Select placeholder="Select a offer!" allowClear dropdownMatchSelectWidth={false}>
                  {offerList?.map((value: IValuesforsell) => {
                    return (
                      <Option value={value.id} key={value.id}>
                        {`${value.name}(${value.code})`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item> */}
              <Form.Item
                label="Discount"
                name={['discount']}
                rules={[{ required: true, message: 'Please add Discount!' }]}>
                <InputNumber controls={false} min={0} />
              </Form.Item>
              <Form.Item
                label="Misc"
                name={['misc']}
                rules={[{ required: true, message: 'Please add misc!' }]}
                hidden>
                <InputNumber controls={false} min={0} />
              </Form.Item>
              <Form.Item
                label="Shipping"
                name={['shipping']}
                rules={[{ required: true, message: 'Please add shipping!' }]}
                hidden>
                <InputNumber controls={false} min={0} />
              </Form.Item>
            </div>
            {/* <div className="flex justify-end mt-5">
              <Form.Item>
                <Button type="primary" onClick={form.submit}>
                  Submit
                </Button>
              </Form.Item>
            </div> */}
          </Form>
          {/* <div className="col-span-2 p-2">This is right side</div> */}
        </div>
        {/* </AppContent> */}
      </div>
      <div style={{ height: '3rem' }}></div>
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1.5rem',
          padding: '0.5rem 1.5rem',
          backgroundColor: '#d1d5dc',
          width: '100%',
          height: '3rem'
        }}>
        <div className="flex justify-center items-center gap-3">
          <div
            style={{ ...styleTwo, backgroundColor: '#f5365c' }}
            className="hoverme"
            onClick={() => {
              form.setFieldValue(['lines'], []);
              form.setFieldValue(['total'], 0);
              form.setFieldValue(['taxable'], 0);
              form.setFieldValue(['nonTaxable'], 0);
              form.setFieldValue(['vat'], 0);
              form.setFieldValue(['grandtotal'], 0);
            }}>
            <CloseCircleTwoTone style={styleOne} />
            Clear
          </div>
          <div
            style={{ ...styleTwo, backgroundColor: '#605ca8' }}
            className="hoverme"
            onClick={handleCreditSell}>
            <CheckCircleTwoTone style={styleOne} />
            Credit Sale
          </div>
          <div
            style={{ ...styleTwo, backgroundColor: '#d81b60' }}
            className="hoverme"
            onClick={handleCardSell}>
            <CreditCardTwoTone style={styleOne} />
            Card
          </div>
          <div
            style={{ ...styleTwo, backgroundColor: '#001f3f' }}
            className="hoverme"
            onClick={handleMultiplePay}>
            <MoneyCollectTwoTone style={styleOne} />
            Multiple Pay
          </div>
          <div
            style={{ ...styleTwo, backgroundColor: '#2dce89' }}
            className="hoverme"
            onClick={handleCash}>
            <MoneyCollectTwoTone style={styleOne} />
            Cash
          </div>
        </div>
        <div
          style={{ ...styleTwo, backgroundColor: '#f5365c' }}
          className="hoverme"
          onClick={() => {
            setIsModalOpen2(true);
          }}>
          <ProfileTwoTone style={styleOne} />
          Recent Sell
        </div>
      </div>
    </Spin>
  );
};

export default POSCreate;

const styleOne = {
  transform: 'scale(1.4)',
  marginRight: '0.7rem'
};

const styleTwo = {
  // border: '1px solid black',
  padding: '0.3rem 0.5rem',
  cursor: 'pointer',
  color: 'white'
};

const cardStyle = {
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
  padding: '0.5rem',
  fontWeight: 'bold',
  cursor: 'pointer'
};

const classNameStyle =
  'mt-4 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-2';
