import { IHRCombinedData } from '@/services/hr/types';
import { CachedRange } from './self';
import moment from 'moment';
import { ICalendarEvent } from './types';
import { reverseDayOfWeekMap } from './constant';

function convertToUTC(date: moment.Moment, time: string) {
  // Time is in format HH:mm:ss
  const times = time.split(':').map(Number);
  if (times.length !== 3) throw new Error('Invalid time format');

  const [hour, minute, second] = times;
  return date.clone().set({ hour, minute, second, millisecond: 0 }).toISOString();
}

function transformShifts(shifts: IHRCombinedData['shifts']) {
  const events: ICalendarEvent[] = [];

  shifts.forEach((shift) => {
    let startDate = moment(shift.startDate);
    const endDate = moment(shift.endDate);

    while (startDate.isSameOrBefore(endDate)) {
      const dayIndex = startDate.get('day');
      const currentDay = reverseDayOfWeekMap[dayIndex];

      const currentLines = shift.lines.find((line) => line.dayOfWeek === currentDay);
      if (currentLines) {
        const { startTime, endTime } = currentLines;

        const newStartDate = convertToUTC(startDate, startTime);
        const newEndDate = convertToUTC(startDate, endTime);

        events.push({
          id: shift.id,
          title: shift.name,
          start: new Date(newStartDate),
          end: new Date(newEndDate),
          type: 'shift',
          name: shift.name,
          isOverlapping: false
        });
      }

      startDate = startDate.add(1, 'days');
    }
  });

  return events;
}

function transformHRData(response: IHRCombinedData) {
  const events: ICalendarEvent[] = [];

  // Process leaves
  response.leaves.forEach((leave) => {
    events.push({
      id: leave.id,
      title: 'Leave',
      start: new Date(leave.startDate),
      end: new Date(leave.endDate),
      type: 'leave',
      description: leave.description,
      medias: leave.medias
    });
  });

  // Process attendances
  response.attendances.forEach((attendance) => {
    events.push({
      id: attendance.id,
      title: attendance.shiftName ? `Attendance (${attendance.shiftName})` : 'Attendance',
      start: new Date(attendance.startDate),
      end: attendance.endDate ? new Date(attendance.endDate) : undefined,
      type: 'attendance',
      shiftId: attendance.shiftId,
      shiftName: attendance.shiftName
    });
  });

  // Process holidays
  response.holidays.forEach((holiday) => {
    events.push({
      id: holiday.id,
      title: holiday.name,
      start: new Date(holiday.startDate),
      end: new Date(holiday.endDate),
      type: 'holiday',
      description: holiday.description,
      name: holiday.name
    });
  });

  const shiftEvents = transformShifts(response.shifts);
  events.push(...shiftEvents);

  return events;
}

export async function fetchAndCacheHRData(options: {
  startDate: string;
  endDate: string;
  cachedData: CachedRange[];
  updateCache: (cachedData: CachedRange | CachedRange[]) => void;
  apiCallFunc: (startDate: string, endDate: string) => Promise<IHRCombinedData>;
}) {
  const { startDate, endDate, cachedData, updateCache } = options;
  const momentStart = moment(startDate);
  const momentEnd = moment(endDate);

  // Check if cachedData is empty (first-time fetch)
  if (cachedData.length === 0) {
    const data = await options.apiCallFunc(startDate, endDate);
    const events = transformHRData(data);

    updateCache({ start: startDate, end: endDate });
    return events;
  }

  // Check if entire range is cached
  const fullyCached = cachedData.some((range) => {
    const rangeStart = moment(range.start);
    const rangeEnd = moment(range.end);
    return (
      momentStart.isBetween(rangeStart, rangeEnd, null, '[]') &&
      momentEnd.isBetween(rangeStart, rangeEnd, null, '[]')
    );
  });

  if (fullyCached) {
    return [];
  }

  // Handle Partial Overlap
  // Determine gaps in the cache
  const missingRanges: { start: string; end: string }[] = [];
  let currentStart = momentStart;

  const sortedCachedData = [...cachedData].sort((a, b) => moment(a.start).diff(b.start));
  for (const range of sortedCachedData) {
    const rangeStart = moment(range.start);
    const rangeEnd = moment(range.end);

    if (currentStart.isBefore(rangeStart)) {
      missingRanges.push({ start: currentStart.toISOString(), end: rangeStart.toISOString() });
    }

    if (currentStart.isBefore(rangeEnd)) {
      currentStart = rangeEnd.clone();
    }
  }

  if (currentStart.isBefore(momentEnd)) {
    missingRanges.push({ start: currentStart.toISOString(), end: momentEnd.toISOString() });
  }

  const fetchedDataPromises = missingRanges.map((range) =>
    options.apiCallFunc(range.start, range.end)
  );

  const fetchedData = await Promise.all(fetchedDataPromises);
  const combinedData = fetchedData.flatMap(transformHRData);

  updateCache(missingRanges);
  return combinedData;
}
