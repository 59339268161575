import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { ILogSupport, ILogSupportResponse } from '@/services/logs/support/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { FormInstance, Menu, TableProps, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

interface Props {
  form: FormInstance;
  isLoading?: boolean;
  data: ILogSupportResponse;
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<void>;
}

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ILogSupport>>({});

  const handleChange: TableProps<ILogSupport>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<ILogSupport>);
  };

  const columns: ColumnsType<ILogSupport> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Requested By',
      key: 'requestByName',
      dataIndex: 'requestByName',
      width: 15,
      sorter: (a, b) => (a?.requestByName || '').localeCompare(b?.requestByName || ''),
      sortOrder: sortedInfo.columnKey === 'requestByName' ? sortedInfo.order : null
    },
    {
      title: 'Endpoint',
      key: 'url',
      width: 15,
      dataIndex: 'url',
      sorter: (a, b) => (a?.url || '').localeCompare(b?.url || ''),
      sortOrder: sortedInfo.columnKey === 'url' ? sortedInfo.order : null
    },
    {
      title: 'Method',
      key: 'method',
      width: 7,
      dataIndex: 'method',
      sorter: (a, b) => (a?.method || '').localeCompare(b?.method || ''),
      sortOrder: sortedInfo.columnKey === 'method' ? sortedInfo.order : null
    },
    {
      title: 'Status Code',
      key: 'statusCode',
      width: 7,
      dataIndex: 'statusCode',
      sorter: (a, b) => (a.statusCode || 0) - (b.statusCode || 0),
      sortOrder: sortedInfo.columnKey === 'statusCode' ? sortedInfo.order : null,
      render: (statusCode) => <span>{statusCode || 'unknown'}</span>
    },
    {
      title: 'Front Version',
      key: 'frontVersion',
      dataIndex: 'frontVersion',
      width: 7,
      sorter: (a, b) => (a?.frontVersion || '').localeCompare(b?.frontVersion || ''),
      sortOrder: sortedInfo.columnKey === 'frontVersion' ? sortedInfo.order : null
    },
    {
      title: 'Status',
      key: 'isSolved',
      width: 10,
      sorter: (a) => (a ? 1 : -1),
      sortOrder: sortedInfo.columnKey === 'isSolved' ? sortedInfo.order : null,
      render: (record) => (
        <Tag color={record.isSolved ? 'green' : 'red'}>
          {record.isSolved ? 'Solved' : 'Unsolved'}
        </Tag>
      )
    },
    {
      title: 'Solved By',
      key: 'solvedByName',
      width: 15,
      dataIndex: 'solvedByName',
      sorter: (a, b) => (a?.solvedByName || '').localeCompare(b?.solvedByName || ''),
      sortOrder: sortedInfo.columnKey === 'solvedByName' ? sortedInfo.order : null
    },
    {
      title: 'Last Updated',
      key: 'lastUpdated',
      width: 15,
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'lastUpdated' ? sortedInfo.order : null,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 6,
      fixed: 'right',
      render: (record: ILogSupport) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: 'view',
            label: <CustomViewIcon link={`/logs/support/${record.id}`} />
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 1300 }}
        hideDefaultPagination
        tableName={'hr-logs-support-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
