import { Form, Input, Menu, Select, TableProps, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TableCell from '../../../../../components/Common/CustomizeTable/CustomCell';
import { optionalBooleanSorter, optionalStringSorter } from '../../../../../utils/sorter.utils';
import { checkAccess } from '../../../../../routes/acl';
import DiscussionChatModal from '../../../../../components/Common/DiscussionChatModal';
import ActionDropdown from '../../../../../components/Common/Dropdownactions';
import { convertLocalToUTCString } from '../../../../../utils/convertToUTC';
import AppContent from '../../../../../components/Common/Content/Content';
import TableFilter from '../../../../../components/FliterTable';
import moment from 'moment';
import CustomButton from '../../../../../components/Common/CustomButton/CustomButton';
import CustomizeTable from '../../../../../components/Common/CustomizeTable/CustomizeTable';
import { get_vendor_type_list } from '../../../../../services/vendor-types/queries';
import { IVendorType, IVendorTypeResult } from '../../../../../services/vendor-types/types';
import { VendorTypeSearch } from '../../../../../components/Common/VendorTypeSearch';
import CustomUpdateIcon from '../../../../../components/Common/CustomIcons/CustomUpdateIcon';
import { CustomDatePresets } from '../../../../sqlsource/report/utils/datePresets';

const { Option } = Select;

const VendorTypeList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [vendorCategories, setVendorCategories] = useState<IVendorTypeResult>({
    total: 0,
    results: []
  });

  const [sortedInfo, setSortedInfo] = useState<SorterResult<IVendorType>>({});
  const handleChange: TableProps<IVendorType>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<IVendorType>);
  };
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_vendor_type_list(filter);
    console.log('response', response);
    setVendorCategories(response);
    setIsloading(false);
  };

  const columns: ColumnsType<IVendorType> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 15,
      sorter: (a, b) => optionalStringSorter(a.name, b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>{record.name}</TableCell>
          </>
        );
      }
    },

    {
      title: 'Status',
      key: 'hidden',
      width: 5,
      sorter: (a, b) => optionalBooleanSorter(a.hidden, b.hidden),
      sortOrder: sortedInfo.columnKey === 'hidden' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>{record.hidden ? 'Hidden' : 'Active'}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: undefined;
        }[] = [];

        if (checkAccess('UPDATE_VENDOR_TYPE')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/vendor-type/${record.id}`} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '2',
            label: <DiscussionChatModal slug="vendor-type" id={record.id} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Supplier Category',
      link: '/vendor-type'
    }
  ];
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
  };
  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                defaultValues={{
                  dateCustom: [...CustomDatePresets.Today],
                  value: '',
                  disabled: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_VENDOR_TYPE') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate(`/vendor-type/new`)}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/vendor-type/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="disabled" label="Status">
                  <Select placeholder="Select Status" allowClear>
                    <Option value={'true'}>Hidden</Option>
                    <Option value={'false'}>Active</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={vendorCategories.results}
          usersLoading={isLoading}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: vendorCategories.total,
            size: size,
            onPagination
          }}
          tableName={'users-vendor-types-list'}
          toSort={handleChange}
        />
      </AppContent>
    </div>
  );
};

export default VendorTypeList;
