import { IHRGroups } from '@/services/hr/types';
import LocalStore, { getTransactionError } from '.';
import { get_hr_group_ids } from '@/services/hr/queries';

// DB Name: HRGroups
export default class HRGroupsDB extends LocalStore {
  static async addHRGroups(data: IHRGroups[]) {
    if (!HRGroupsDB.db) await HRGroupsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = HRGroupsDB.db.transaction('HRGroups', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('HRGroups');

      data.forEach(async (HRGroup) => {
        const checkKey = objectStore.count(HRGroup.id);
        checkKey.onerror = (event) => getTransactionError(event, reject, 'add');

        checkKey.onsuccess = async () => {
          const data = { ...HRGroup, lowercaseName: HRGroup.name.trim().toLowerCase() };
          if (checkKey.result == 0) {
            const request = objectStore.add(data);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await HRGroupsDB.updateHRGroup(data);
          }
        };
      });
    });
  }

  static async searchHRGroup(name: string, limit = 10): Promise<IHRGroups[]> {
    if (!HRGroupsDB.db) await HRGroupsDB.init();
    const givenName = name.trim().toLowerCase();

    return new Promise((resolve, reject) => {
      const transaction = HRGroupsDB.db.transaction('HRGroups', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore('HRGroups');
      const index = objectStore.index('HRGroupsSecondIndex');
      const request = index.openCursor();

      const HRGroups: IHRGroups[] = [];
      let count = 0;

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor && count < limit) {
          const HRGroup = cursor?.value as IHRGroups;
          if (HRGroup?.name?.trim()?.toLowerCase()?.includes(givenName)) {
            HRGroups.push(HRGroup);
            count++;
          }

          cursor.continue();
        } else {
          resolve(HRGroups);
        }
      };
    });
  }

  static async getAll(): Promise<IHRGroups[]> {
    if (!HRGroupsDB.db) await HRGroupsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = HRGroupsDB.db.transaction('HRGroups', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getHRGroups');
      const objectStore = transaction.objectStore('HRGroups');
      const request = objectStore.getAll();
      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
    });
  }

  static async getByID(id: number): Promise<IHRGroups> {
    if (!HRGroupsDB.db) await HRGroupsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = HRGroupsDB.db.transaction('HRGroups', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'getHRGroups');
      const objectStore = transaction.objectStore('HRGroups');
      const request = objectStore.get(id);

      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      request.onerror = (event) => getTransactionError(event, reject, 'get');
    });
  }

  static async updateHRGroup(HRGroup: IHRGroups) {
    if (!HRGroupsDB.db) await HRGroupsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = HRGroupsDB.db.transaction('HRGroups', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('HRGroups');
      const request = objectStore.get(HRGroup.id);

      request.onerror = (event) => getTransactionError(event, reject, 'update');
      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(HRGroup);
          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };
    });
  }

  static async addIfAbsent(ids: number[]): Promise<void> {
    if (!HRGroupsDB.db) await HRGroupsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = HRGroupsDB.db.transaction('HRGroups', 'readwrite');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('HRGroups');
      const request = objectStore.getAll();

      request.onsuccess = async (event) => {
        const HRGroups: IHRGroups[] = (event.target as IDBRequest).result;
        const HRGroupsIds = HRGroups.map((HRGroup) => HRGroup.id);

        const idsToAdd = ids.filter((id) => {
          return Boolean(id) && !HRGroupsIds.includes(id);
        });

        if (idsToAdd.length) {
          const response = await get_hr_group_ids(idsToAdd);
          await HRGroupsDB.addHRGroups(response.results);
          resolve(undefined);
        } else resolve(undefined);
      };
    });
  }
}
