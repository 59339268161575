import { IHRGroups } from '@/services/hr/types';
import CustomSearch, { SelectProps } from '..';
import HRGroupsDB from '@/store/localstorage/HRGroupsDB';
import { get_hr_group_list_only } from '@/services/hr/queries';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IHRGroups) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IHRGroups[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function HRGroupsSearchV2(props: Props) {
  return (
    <CustomSearch
      formFor="HR group"
      addCallback={HRGroupsDB.addHRGroups}
      serverCallback={get_hr_group_list_only}
      dbSearchById={(id) => HRGroupsDB.getByID(Number(id))}
      dbSearchCallback={HRGroupsDB.searchHRGroup}
      {...props}
    />
  );
}

export default HRGroupsSearchV2;
