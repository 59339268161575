import { AxiosError, AxiosResponse } from 'axios';

import isAxiosError from '@/utils/isAxiosError';
import { ILogSupportCreate } from '@/services/logs/support/types';
import { safeStringify } from '@/utils/safeStringify';
import { Modal } from 'antd';
import { handleModalOk } from '@/utils/errorModal.utils';
import CustomErrorModal from '../CustomErrorModal';

type Props = {
  title?: string;
  width?: number | string;
  closable?: boolean;
  onCancel?: () => void;

  axiosError: AxiosError;
  message: string | React.ReactNode;
};

function ErrorModalWithLog({
  message,
  width = 600,
  title = 'Error',
  closable,
  onCancel,
  axiosError
}: Props) {
  if (!isAxiosError(axiosError)) {
    return CustomErrorModal({ message, width, title, closable, onCancel });
  }

  const axiosResponse = axiosError?.response as AxiosResponse;
  const request = axiosError?.config;

  // Extract data safely
  const header = request?.headers;
  const frontVersion =
    typeof header?.['front-version'] === 'string' ? header['front-version'] : undefined;

  const formattedRequest = {
    headers: request?.headers,
    payload: request?.data
  };

  const formattedResponse = {
    message: axiosError.message,
    originatedURL: location.href,
    headers: axiosResponse.headers,
    response: axiosResponse.data
  };

  const createPayload: ILogSupportCreate = {
    frontVersion,
    method: request?.method || 'unknown',
    url: request?.url || 'unknown',
    statusCode: axiosResponse.status,
    requestBody: safeStringify(formattedRequest),
    content: safeStringify(formattedResponse)
  };

  const modalRef = Modal.error({
    closable: true,
    title,
    content: message,
    width: 600,
    okText: 'Report to Support',
    cancelText: 'Close',

    onOk: (closeFn) => {
      handleModalOk(modalRef, createPayload);
      return false;
    }
  });
}

export default ErrorModalWithLog;
