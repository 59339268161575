import { AxiosResponse } from 'axios';
import http from '@/utils/http.utils';
import { ILogSupport, ILogSupportResponse } from './types';

export const get_logs_support_list = async (filter = '') => {
  const response: AxiosResponse<ILogSupportResponse> = await http.get('/logs-support/?' + filter);
  return response.data;
};

export const get_logs_support_detail = async (id: number) => {
  const response: AxiosResponse<ILogSupport> = await http.get('/logs-support/' + id);
  return response.data;
};
