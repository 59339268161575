import { Button, Descriptions, Form, Input, message, Modal, PageHeader, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { ISchedulerResponse, ISchedulerTableData } from '@/services/schedule/types';
import { ESchedularSuccessStatus, SchedulerStatusType } from '@/services/schedule/enum';
import { get_scheduler_by_id, get_scheduler_status_list } from '@/services/schedule/queries';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';
import { create_update_schedule, rerun_schedule } from '@/services/schedule/mutation';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import Table from './Table';
import { Navigate, useParams } from 'react-router-dom';
import cronParser from 'cron-parser';
import { useMutation } from '@tanstack/react-query';
import isAxiosError from '@/utils/isAxiosError';

const DEFAULT_SIZE = 10;

function SchedularViewPage() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: DEFAULT_SIZE });

  const [data, setData] = useState<ISchedulerResponse>({ total: 0, results: [] });
  const [openRerunModal, setOpenRerunModal] = useState(false);
  const [isDisableModal, setIsDisableModal] = useState(false);

  const [isLatestFail, setIsLatestFail] = useState(false);

  const params = useParams();
  const selectedSchedularId = Number(params.id);

  if (!selectedSchedularId || isNaN(selectedSchedularId)) return <Navigate to="/scheduler" />;

  const [selectedType, setSelectedType] = useState<SchedulerStatusType>();
  const [schedularData, setSchedularData] = useState<ISchedulerTableData>();

  const enableDisabledScheduler = useMutation(async () => {
    try {
      setIsDisableModal(false);
      setIsLoading(true);
      if (!schedularData) {
        return CustomErrorModal({
          message: 'Something went wrong. Please try again later.'
        });
      }

      const isDisabled = schedularData.isDisabled;

      await create_update_schedule({
        type: schedularData.type,
        cronExpression: schedularData.cronExpression,
        isDisabled: !isDisabled
      });

      message.success(`Scheduler ${isDisabled ? 'enabled' : 'disabled'} successfully`);
      setSchedularData((prev) => {
        if (!prev) return prev;
        return { ...prev, isDisabled: !isDisabled };
      });
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchInitial();
  }, []);

  async function fetchInitial() {
    try {
      setIsLoading(true);
      const data = await get_scheduler_by_id(selectedSchedularId);
      setSelectedType(data.type);

      form.setFieldValue(['type'], data.type);
      await form.submit();

      const nextDate = cronParser.parseExpression(data.cronExpression).next().toDate();
      const momentString = moment(nextDate).format('YYYY-MM-DD LTS');
      setSchedularData({ ...data, nextExecution: momentString });
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmitFilter(filter: string) {
    const response = await getInfo(filter);
    setPagination({ page: 1, size: DEFAULT_SIZE });

    if (response && response.length > 0) {
      setIsLatestFail(response[0].status === ESchedularSuccessStatus.FAIL);
    }
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_scheduler_status_list(filter);
      setData(response);
      return response.results;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = DEFAULT_SIZE, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  async function onRerunConfirm() {
    try {
      if (!selectedType) return;
      setOpenRerunModal(false);
      setSelectedType(undefined);
      setIsLoading(true);

      await rerun_schedule(selectedType);
      await getInfo();
    } catch (error) {
      if (isAxiosError(error)) return;
      const message = getErrorMessage(error);
      CustomErrorModal({ message });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Modal
        title="Rerun Schedular"
        visible={openRerunModal}
        onCancel={() => setOpenRerunModal(false)}
        onOk={onRerunConfirm}>
        <div>
          Selected schedular: <strong> {selectedType} </strong>
          <br />
          Are you sure you want to rerun this schedular?
        </div>
      </Modal>

      <Modal
        title={'Schedular'}
        visible={isDisableModal}
        onCancel={() => setIsDisableModal(false)}
        onOk={async () => await enableDisabledScheduler.mutateAsync()}>
        <div>
          Are you sure you want to {schedularData?.isDisabled ? 'enable' : 'disable'} this
          schedular?
        </div>
      </Modal>

      <AppContent
        breadcrumbItems={[
          { label: 'Schedular', link: '/scheduler' },
          { label: 'Schedular Status' }
        ]}
        withfilter
        button={
          <TableFilter
            initial={false}
            form={form}
            onSubmit={onSubmitFilter}
            onPagination={(page, size) => setPagination({ page, size })}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              skip: 0,
              count: DEFAULT_SIZE,
              status: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <Form.Item name={'type'} hidden>
              <Input />
            </Form.Item>

            <Form.Item name="status" label="Status">
              <Select placeholder="Select status">
                <Select.Option value={''}>All</Select.Option>
                {Object.keys(ESchedularSuccessStatus).map((status) => (
                  <Select.Option key={status} value={status}>
                    {ESchedularSuccessStatus[status as keyof typeof ESchedularSuccessStatus]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </TableFilter>
        }>
        <Descriptions
          bordered
          className="mt-4"
          size="small"
          column={{
            md: 2,
            sm: 1,
            xs: 1
          }}>
          <Descriptions.Item label="Type">{schedularData?.type}</Descriptions.Item>
          <Descriptions.Item label="Cron Expression">
            {schedularData?.cronExpression}
          </Descriptions.Item>
          <Descriptions.Item label="Next Execution">
            {schedularData?.nextExecution}
          </Descriptions.Item>
        </Descriptions>

        <div className="mt-4 flex justify-end gap-2">
          <Button
            danger={!schedularData?.isDisabled}
            type="primary"
            ghost
            onClick={() => setIsDisableModal(true)}
            disabled={!schedularData || isLoading}>
            {schedularData?.isDisabled ? 'Enable' : 'Disable'}
          </Button>
          <Button
            type="primary"
            onClick={() => setOpenRerunModal(true)}
            disabled={isLoading || schedularData?.isDisabled || !isLatestFail}>
            Rerun Schedular
          </Button>
        </div>

        <div>
          <PageHeader style={{ padding: '8px 0' }} subTitle="Schedular Status" />
          <Table form={form} data={data} pagination={pagination} onPagination={onPagination} />
        </div>
      </AppContent>
    </Spin>
  );
}

export default SchedularViewPage;
