import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { IUser } from '@/services/auth/types';
import { update_hr_group_mutation } from '@/services/hr/mutations';
import { get_hr_group_details } from '@/services/hr/queries';
import getErrorMessage from '@/utils/getError';
import { Button, Card, Form, Input, InputNumber, message, Modal, PageHeader, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import UsersDB from '@/store/localstorage/UsersDB';
import { getUser } from '@/services';
import isAxiosError from '@/utils/isAxiosError';

interface FormValues {
  name: string;
  user: number;
  users: { id: number; name: string; phone: string }[];
}

function UpdateHRGroups() {
  const params = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();

  const groupId = Number(params.id);
  if (isNaN(groupId)) return <Navigate to="/hr/groups" />;

  useEffect(() => {
    if (isNaN(groupId)) return;
    fetchInitialData();
  }, [groupId]);

  async function fetchInitialData() {
    try {
      setIsLoading(true);
      const details = await get_hr_group_details(groupId);
      const userIds = details.lines.map((u) => u.userId);
      await UsersDB.addUsersIfAbsent(userIds);

      const users: FormValues['users'] = [];
      for (const userId of userIds) {
        const user = (await getUser(userId)) as unknown as IUser;
        users.push({ id: user.id, name: user.name, phone: user.phone });
      }

      form.setFieldsValue({ name: details.group.name, users });
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  const [prevSelectedUser, setPrevSelectedUser] = useState<number>();

  async function onInitialSubmit() {
    const formValues = await form.validateFields();
    if (formValues.users.length === 0) {
      return CustomErrorModal({ message: 'Please add at least one user' });
    }

    setIsVisible(true);
  }

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      setIsVisible(false);
      const payload = { name: values.name, userIds: values.users.map((u) => u.id) };
      await update_hr_group_mutation(groupId, payload);
      message.success('HR Group updated successfully');
      navigate(`/hr/groups/${groupId}`);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  function onUserChange(user?: IUser) {
    if (!user || prevSelectedUser === user.id) return;
    setPrevSelectedUser(user.id);

    // Check if user is duplicate
    const currentUsers = (form.getFieldValue('users') as FormValues['users']) || [];
    const isDuplicate = currentUsers.find((u) => u.id === user.id);

    if (isDuplicate) {
      CustomErrorModal({ message: `User ${user.name} is already added` });
      return;
    }

    const updatedUsers = [...currentUsers, { id: user.id, name: user.name, phone: user.phone }];
    form.setFieldsValue({ users: updatedUsers });
  }

  return (
    <Spin spinning={isLoading}>
      <Modal
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={() => {
          form.submit();
        }}>
        <p>Are you sure you want to update this HR Group?</p>
      </Modal>
      <AppContent
        breadcrumbItems={[{ label: 'HR Group', link: '/hr/groups' }, { label: 'Update' }]}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          initialValues={{ name: '', users: [] }}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader title="HR Group Information" style={{ padding: '8px 0px' }} />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Group Name"
              name="name"
              rules={[{ required: true, message: 'Please add group Name!' }]}>
              <Input />
            </Form.Item>

            <UserSearchV2 hasParentFormItem={false} name="user" setSelected={onUserChange} />
          </div>

          <Card style={{ maxHeight: '50vh', overflowY: 'scroll', borderRadius: '9px' }}>
            <Form.List name={['users']}>
              {(fields, { remove }) =>
                fields.map((field, index) => (
                  <div key={field.key} className="flex gap-2 items-center">
                    <span className="font-bold text-sm mb-5">{index + 1}.</span>
                    <div
                      className="card  grid grid-cols-3 gap-3"
                      style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                      <Form.Item name={[field.name, 'id']} hidden>
                        <InputNumber controls={false} disabled />
                      </Form.Item>

                      <Form.Item name={[field.name, 'name']} label={'User'}>
                        <Input style={{ color: 'black' }} disabled />
                      </Form.Item>

                      <Form.Item name={[field.name, 'phone']} label={'Phone No.'}>
                        <InputNumber controls={false} style={{ color: 'black' }} disabled />
                      </Form.Item>

                      <div className="flex items-center justify-start mt-5">
                        <CustomButton
                          backgroundColor="white"
                          text="Remove"
                          textColor="green"
                          onClick={async () => {
                            remove(field.name);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </Form.List>
          </Card>

          <div className="flex justify-end mt-5">
            <Button type="primary" loading={isLoading} onClick={onInitialSubmit}>
              Submit
            </Button>

            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/hr/groups')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default UpdateHRGroups;
