export const excelExportColumns = [
  { title: 'Category', dataIndex: 'category_name' },
  { title: 'Product', dataIndex: 'product_name' },
  { title: 'Unit', dataIndex: 'unit_name' },
  { title: 'Supplier', dataIndex: 'supplier_name' },
  { title: 'Location', dataIndex: 'location_name' },
  { title: 'Quantity', dataIndex: 'quantity' },
  { title: 'Total Amount', dataIndex: 'total_amount' },
  { title: 'Vat', dataIndex: 'vat' },
  { title: 'Misc', dataIndex: 'misc' },
  { title: 'Date', dataIndex: 'created_at' }
];

export const tableExportColumns = excelExportColumns.map((c) => ({
  label: c.title,
  dataIndex: c.dataIndex,
  render: (text: string) => {
    return <div className="text-center">{text}</div>;
  }
}));
