import { DollarOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Spin
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { create_purchase_payment } from '@/services/purchases/mutations';
import { create_sell_payment } from '@/services/sell/mutations';
import { ConvertObjectToURL } from '@/utils/converturl';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { get_customer_details } from '@/services/users/queries';
import { AccountsTypeSearch } from '../AccountsTypeSearch';
import { IAccountTypeResponseData } from '@/services/accounts/types';
import CustomErrorModal from '../CustomErrorModal';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { duplicateCheck } from '@/utils/duplicateChecker.utils';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent, AccountType } from '@/services/accounts/enums';
import CustomInfoModal from '../CustomInfoModal';
import { getUserData } from '@/utils/auth.utils';
import DebounceButton from '../DebounceButton';
import useDebounceFunction from '@/hooks/useDebounceFunction';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';
import getErrorMessage from '@/utils/getError';
import isAxiosError from '@/utils/isAxiosError';

const PaymentModal = ({
  visible,
  selectedSale,
  setShow,
  setSelectedSale,
  paymentType,
  fetchAgain,
  form2
}: {
  paymentType: 'sell' | 'purchase';
  visible: boolean;
  selectedSale: any;
  setShow: (value: boolean) => void;
  setSelectedSale: (value: any) => void;
  fetchAgain?: (filter?: string) => Promise<void>;
  form2?: FormInstance;
}) => {
  const user = getUserData();
  const isSuperAdmin = user.perms.includes('SUPER_ADMIN');

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hasRule, setHasRule] = useState(true);
  const [isAccountArchived, setIsAccountArchived] = useState(false);
  // console.log('selected sale', selectedSale);

  const createSellPayment = useMutation(create_sell_payment);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [isBank, setIsBank] = useState<boolean>(false);
  const [accountList, setAccountList] = useState<IAccountTypeResponseData[]>([]);
  const createPurchasePayment = useMutation(create_purchase_payment);
  const { refetch } = useQuery(['customerDetails', selectedSale?.customerId], async () => {
    const customerDetails = await get_customer_details(selectedSale.customerId);
    const { customer } = customerDetails;

    if (customer) {
      const userId = customer.userId as number;
      const userAccount = await get_account_details_by_userid_type(userId, AccountType.USER);

      setIsAccountArchived(userAccount?.isArchived);
      if (userAccount?.isArchived) {
        message.error({
          content: (
            <span>
              Unable to add a payment for this sell. <strong>Reason:</strong>{' '}
              <span className="text-red-500">Account Archived</span>
            </span>
          ),
          duration: 5
        });
        form.setFieldValue('userId', null);
        return;
      }

      setSelectedSale((prev: any) => ({ ...prev, userData: customer }));
    }
    // console.log('customerDetails', customerDetails);
  });

  useEffect(() => {
    if (selectedSale) {
      form.setFieldValue('reference', selectedSale.referenceNumber);
      form.resetFields(['paymentMethod']);
      form.setFieldValue('date', moment());
    }
  }, [selectedSale]);

  const submitForm = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();

      const values = await form.getFieldsValue();
      const maxValue = Math.floor(
        selectedSale.totalAmount - selectedSale.amountPaid - selectedSale.amountReturned
      );
      if (values.amount > maxValue) {
        message.info(
          `Excess amount paid Rs. ${values.amount - maxValue} is added to Customer Wallet.`
        );
      }
      // console.log('values', values);
      values.amount = +values.amount;
      values.id = selectedSale.id;
      values.date = convertLocalToUTCString(values.date, 'YYYY-MM-DD HH:mm:ss');
      if (!values.adjustment) values.adjustment = false;
      if (paymentType == 'sell') {
        values.madeBy = selectedSale.address.userId;
        await createSellPayment.mutateAsync(values, {
          onSuccess: async ({ data }: { data: any }) => {
            message.success('Payment Done successfully!');
            setIsLoading(false);
            if (fetchAgain && form2) {
              const filter = ConvertObjectToURL(form2.getFieldsValue());
              await fetchAgain(filter);
            }
          },
          onError: (e: any) => {
            setIsLoading(false);
          }
        });
      } else if (paymentType == 'purchase') {
        values.madeBy = selectedSale.vendorId;
        await createPurchasePayment.mutateAsync(values, {
          onError: () => setIsLoading(false)
        });
        setIsLoading(false);
      }
      setShow(false);
      setIsBank(false);
      setSelectedSale(null);
      form.resetFields();
      setIsLoading(false);
    } catch (errors: any) {
      setIsLoading(false);
      if (isAxiosError(errors)) return;
      if ('name' in errors) CustomErrorModal({ message: getErrorMessage(errors) });
      else message.error('Empty fields found!');
    } finally {
      setConfirm(false);
    }
  };

  // const handlePaymentOptionChange = (val: string) => {
  //   console.log('vall--> ', val);
  //   if (val == 'wallet') {
  //     if (!selectedSale?.userData?.balance || selectedSale.userData.balance <= 0) {
  //       message.error('No balance in Wallet!');
  //     } else {
  //       const maxValue = Math.floor(
  //         selectedSale.totalAmount - selectedSale.amountPaid - selectedSale.amountReturned
  //       );
  //       console.log('max value', maxValue);
  //       if (selectedSale.userData.balance >= maxValue) {
  //         form.setFieldValue(['amount'], maxValue);
  //       } else {
  //         form.setFieldValue(['amount'], selectedSale.userData.balance);
  //       }
  //     }
  //   }
  //   return;
  // };
  // console.log('Selected sale', selectedSale);

  const checkAccountRule = async (locationId: number, val: string) => {
    if (
      await checkHasAccountRule(
        locationId,
        val === 'bank'
          ? AccountRulesEvent.SELL_PAYMENT_BANK
          : val === 'cash'
          ? AccountRulesEvent.SELL_PAYMENT_CASH
          : AccountRulesEvent.SELL_PAYMENT_OTHER
      )
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${
          val === 'bank'
            ? AccountRulesEvent.SELL_PAYMENT_BANK
            : val === 'cash'
            ? AccountRulesEvent.SELL_PAYMENT_CASH
            : AccountRulesEvent.SELL_PAYMENT_OTHER
        }" rule has not been created!`
      });
    }
  };

  const debouncedSubmit = useDebounceFunction(submitForm);

  return (
    <Modal
      title={<span className="font-semibold">Add payment</span>}
      visible={visible}
      // footer={selectedSale && selectedSale.totalAmount - selectedSale.amountPaid > 0}
      footer={null}
      onCancel={() => {
        setShow(false);
        setSelectedSale(null);
      }}>
      <Spin spinning={isLoading}>
        <Modal
          title={<span className="font-semibold">Confirmation</span>}
          visible={confirm}
          // footer={null}
          okText={'Accept'}
          cancelText={'Back'}
          onOk={async () => {
            setConfirm(false);
            await debouncedSubmit();
          }}
          onCancel={() => {
            setConfirm(false);
          }}>
          <>
            <div> Customer : {selectedSale && selectedSale.userName}</div>
            {/* <div> Invoice No. : {selectedSale && selectedSale.referenceNumber}</div> */}
            <div> Payment Amount : {form.getFieldValue('amount')}</div>
            <div> Wallet Amount : {form.getFieldValue('walletPayment')}</div>
            <div> Date : {form.getFieldValue('date')?.format('YYYY-MM-DD')}</div>
          </>
        </Modal>
        {/* className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 gap-2" */}
        <div>
          <div className="bg-gray-600 text-white p-2" style={{ borderRadius: '9px' }}>
            <div>
              <span className="font-weight inline"> Customer </span>:{' '}
              <span className="font-semibold">{selectedSale && selectedSale.userName}</span>
            </div>
            {/* <div>
            <span className="font-weight inline">Wallet balance</span>:{' '}
            <span className="font-semibold">
              {selectedSale && nepaliNumberFormatter(selectedSale?.userData?.balance || 0)}
            </span>
          </div> */}
            {/* <div className="bg-gray-600 text-white p-2"> */}
            <div>
              <span className="font-weight inline"> Invoice No. </span>:{' '}
              <span className="font-semibold">{selectedSale && selectedSale.referenceNumber}</span>
            </div>
            <div>
              <span className="font-weight inline"> Location </span>:{' '}
              <span className="font-semibold">{selectedSale && selectedSale.locationName}</span>
            </div>
            {/* </div> */}
            {/* <div className="bg-gray-600 text-white p-2"> */}
            <div>
              <span className="font-weight inline"> Total amount </span>:{' '}
              <span className="font-semibold">
                {selectedSale && nepaliNumberFormatter(selectedSale.totalAmount)}
              </span>
            </div>
            <div>
              <span className="font-weight inline"> Amount paid </span>:{' '}
              <span className="font-semibold">
                {selectedSale && nepaliNumberFormatter(selectedSale.amountPaid)}
              </span>
            </div>
            <div>
              <span className="font-weight inline"> Amount Returned </span>:{' '}
              <span className="font-semibold">
                {selectedSale && nepaliNumberFormatter(selectedSale.amountReturned)}
              </span>
            </div>

            <div>
              <span className="font-weight inline"> Remaining Amount to paid</span>:{' '}
              <span className="font-semibold">
                {selectedSale && (
                  <>
                    {selectedSale.amountPaid >=
                    selectedSale.totalAmount - selectedSale.amountReturned
                      ? 0
                      : nepaliNumberFormatter(
                          Math.floor(
                            selectedSale.totalAmount -
                              selectedSale.amountPaid -
                              selectedSale.amountReturned
                          )
                        )}
                  </>
                )}
              </span>
            </div>
            <div>
              <span className="font-weight inline"> Payment Note </span>:{' '}
              <span className="font-semibold">
                {selectedSale && selectedSale?.payments?.[0]?.note}
              </span>
            </div>
          </div>
          <div className="bg-green-600 text-white p-2 mt-2" style={{ borderRadius: '9px' }}>
            <div>
              <span className="font-weight inline">Wallet balance</span>:{' '}
              <span className="font-semibold">
                {selectedSale && nepaliNumberFormatter(selectedSale?.userData?.balance || 0)}
              </span>
            </div>
          </div>
          {/* </div> */}
        </div>

        <hr />

        {selectedSale && selectedSale.adjustment > 0 ? (
          <>
            <div>There is adjustment in this sell.Cannot Add Payment!</div>
          </>
        ) : (
          <>
            {selectedSale &&
              (Math.floor(
                selectedSale.totalAmount - selectedSale.amountPaid - selectedSale.amountReturned
              ) > 0 ||
                selectedSale.payments.filter((val: any) => val.note === 'REVERSE').length > 0) && (
                <>
                  <Form form={form} initialValues={{ walletPayment: 0 }}>
                    <div className="grid grid-cols-1">
                      <Form.Item
                        label="Payment Amount"
                        name="amount"
                        rules={[
                          {
                            required: true,
                            message: 'Please add amount!'
                          },
                          () => ({
                            validator(_: any, value: any) {
                              const maxValue = Math.floor(
                                selectedSale.totalAmount -
                                  selectedSale.amountPaid -
                                  selectedSale.amountReturned
                              );
                              // console.log('maxValue ', maxValue);
                              const walletPayment = form.getFieldValue(['walletPayment']) | 0;
                              // console.log('Wallet Payment', walletPayment);
                              const remVal = maxValue - walletPayment;
                              if (walletPayment > 0) {
                                if (value > remVal) {
                                  return Promise.reject(
                                    `You are using wallet balance and paying more!`
                                  );
                                }
                              }
                              return Promise.resolve();
                            }
                          })
                        ]}>
                        <InputNumber
                          controls={false}
                          min={0}
                          addonBefore={<DollarOutlined color="green" />}
                        />
                      </Form.Item>
                    </div>
                    <div className="grid grid-cols-1">
                      <Form.Item
                        label="Payment from Wallet"
                        name="walletPayment"
                        rules={[
                          {
                            required: true,
                            message: 'Please add amount!'
                          },
                          () => ({
                            validator(_: any, value: any) {
                              if (value == 0) return Promise.resolve();
                              const maxValue = Math.floor(
                                selectedSale.totalAmount -
                                  selectedSale.amountPaid -
                                  selectedSale.amountReturned
                              );
                              const otherPayment = form.getFieldValue(['amount']) | 0;
                              const remVal = maxValue - otherPayment;
                              // console.log('remVal ', remVal);
                              // console.log('Val ', value);
                              if (value > selectedSale.userData.balance) {
                                return Promise.reject(`You wallet balance is out of limit!`);
                              }
                              if (value > remVal) {
                                return Promise.reject(
                                  `You are adding amount more than to be paid!`
                                );
                              }
                              return Promise.resolve();
                            }
                          })
                        ]}>
                        <InputNumber
                          controls={false}
                          min={0}
                          addonBefore={<DollarOutlined color="green" />}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item hidden name="id">
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Payment Method"
                      name="paymentMethod"
                      rules={[
                        {
                          required: true,
                          message: 'Please select payment method!'
                        }
                      ]}>
                      <Select
                        onChange={async (val) => {
                          val === 'bank' ? setIsBank(true) : setIsBank(false);
                          await checkAccountRule(selectedSale.locationId, val);
                        }}>
                        <Select.Option value="cash">Cash</Select.Option>
                        <Select.Option value="card">Card</Select.Option>
                        <Select.Option value="cheque">Cheque</Select.Option>
                        <Select.Option value="bank">Bank Tranfer</Select.Option>
                        <Select.Option value="qr_offline">Offline QR</Select.Option>

                        {/* <Select.Option value="wallet">Wallet</Select.Option> */}
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </Form.Item>
                    {isBank && (
                      <AccountsTypeSearch
                        formData={{ label: 'Account', formName: 'accountId' }}
                        required={true}
                        accountList={accountList}
                        setAccountList={setAccountList}
                        type={'BANK'}
                        isAll={false}
                      />
                    )}
                    <Form.Item
                      label="Reference"
                      name="reference"
                      rules={[
                        {
                          required: true,
                          message: 'Please add reference!'
                        }
                      ]}>
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: 'Please select date!'
                        }
                      ]}>
                      <DatePicker showTime />
                    </Form.Item>

                    <Form.Item
                      label="Note"
                      name="note"
                      rules={[
                        {
                          required: true,
                          message: 'Please add a note!'
                        }
                      ]}>
                      <TextArea />
                    </Form.Item>

                    <Form.Item
                      label="Finalize?"
                      name="adjustment"
                      valuePropName="checked"
                      hidden={!isSuperAdmin}>
                      <Checkbox />
                    </Form.Item>

                    <div className="flex justify-end gap-2">
                      <Button
                        color="white"
                        onClick={() => {
                          setShow(false);
                          setSelectedSale(null);
                        }}>
                        Back
                      </Button>
                      <DebounceButton
                        disabled={!hasRule || isAccountArchived}
                        color="blue"
                        type="primary"
                        onClick={async () => {
                          try {
                            await form.validateFields();
                            setConfirm(true);
                          } catch (errors: any) {
                            setIsLoading(false);
                            if (isAxiosError(errors)) return;
                            if ('name' in errors) CustomErrorModal({ message: errors.message });
                            else message.error('Empty fields found!');
                          }
                        }}>
                        Add
                      </DebounceButton>
                    </div>
                  </Form>
                </>
              )}
            {selectedSale &&
              Math.floor(
                selectedSale.totalAmount - selectedSale.amountPaid - selectedSale.amountReturned
              ) <= 0 &&
              selectedSale.payments.filter((val: any) => val.note === 'REVERSE').length === 0 && (
                <>
                  <span className="font-semibold">Already full payment No payment to Add!</span>
                </>
              )}
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default PaymentModal;
