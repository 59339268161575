import axios from 'axios';
import { message, UploadFile } from 'antd';
import { API_URL, JWT_TOKEN } from '@/constants/config';
import { getLocalStorage } from '@/utils/storage.utils';
import { ImageSaveResponse } from './types';
import { get_image } from '@/components/Common/FetchImage/FetchImage';

export const uploadImagesFormData = async (formData: FormData) => {
  const response = await axios.post(`${API_URL}storage/upload`, formData, {
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`,
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data as ImageSaveResponse;
};

export const uploadImagesToServer = async (files: (UploadFile | undefined)[]) => {
  if (!files || files.length === 0) return [];

  const uploadPromises = files.map(async (file) => {
    const formData = new FormData();
    if (!file) return;
    formData.append('media', file.originFileObj as Blob);

    try {
      return await uploadImagesFormData(formData);
    } catch (error) {
      console.error(`Error uploading file ${file.name}:`, error);
      throw error;
    }
  });

  try {
    return await Promise.all(uploadPromises);
  } catch (error) {
    message.destroy('uploadingImage');
    message.error('Error uploading images');
    throw error;
  }
};

export const getImagesFromServer = async (mediaIds: number[]) => {
  const getImagePromises = mediaIds.map(async (mediaId) => {
    const imageURL: string = await get_image(`${API_URL}storage/media/${mediaId}`);
    return { id: mediaId, url: imageURL, name: `test-${mediaId}.png` };
  });

  return await Promise.all(getImagePromises);
};

export async function uploadImagesAndGetIds(files: UploadFile[]) {
  const mediaIdsWithURL = files.filter((file) => file.url).map((file) => parseInt(file.uid));
  const mediaWithoutURL = files.filter((file) => !file.url);

  const uploadData = mediaWithoutURL.length > 0 ? await uploadImagesToServer(mediaWithoutURL) : [];
  const mediaIdsWithoutURL = uploadData.map((media) => media?.id).filter(Boolean) as number[];

  return [...new Set([...mediaIdsWithoutURL, ...mediaIdsWithURL])];
}
