import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, message, PageHeader, Spin } from 'antd';
import Upload from 'antd/lib/upload/Upload';
import React, { useState } from 'react';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useMutation } from '@tanstack/react-query';
import {
  download_opening_stock_mutation,
  upload_opening_stock_mutation
} from '@/services/stock/mutations';
import AppContent from '@/components/Common/Content/Content';
import ReuseChannel from '../../channel/Reuse';
import { getUserData } from '@/utils/auth.utils';

import { AxiosError } from 'axios';
import ErrorModalWithLog from '@/components/Common/ErrorModal/ErrorModalWithLog';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

interface IUpload {
  file: FormData;
  locationId: number;
}

const OpenStockDownloadAndUpload = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const uploadMutation = useMutation((values: IUpload) =>
    upload_opening_stock_mutation(values.locationId, values.file)
  );

  const selectedLocationId = Form.useWatch('locationId', form) as number | undefined;
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    maxCount: 1,
    fileList: fileList,
    accept: '.xlsx',
    onChange: onChange,
    beforeUpload: () => false,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: true
    }
  };

  const displayError = (axiosError: AxiosError) => {
    const errorData = axiosError.response?.data as { message: string; errors: string[] };

    const content = (
      <div className="grid grid-cols-1 gap-2 ">
        {errorData.errors
          ? errorData.errors.map((error, index) => {
              return (
                <div key={index}>
                  <div className="text-red-600">{`${index + 1}. ${error}`}</div>
                </div>
              );
            })
          : errorData.message}
      </div>
    );

    ErrorModalWithLog({
      message: content,
      axiosError,
      title: errorData?.errors ? errorData?.message : 'Error'
    });
  };

  const handleUpload = async () => {
    setIsloading(true);
    if (!selectedLocationId) {
      message.error('Please select a location!');
      setIsloading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', fileList[0].originFileObj as RcFile);
    const values = { file: formData, locationId: selectedLocationId };
    await uploadMutation.mutateAsync(values, {
      onSuccess: async () => {
        message.success('Uploaded successfully');
        setIsloading(false);
      },
      onError: (error: any) => {
        setIsloading(false);
        displayError(error);
      }
    });
  };

  const breadcrumbItems = [
    {
      label: 'Upload or Download Opening Stock',
      link: '/products/price-group'
    }
  ];

  const handleDownload = async () => {
    setIsloading(true);
    try {
      if (selectedLocationId) {
        const response = await download_opening_stock_mutation(selectedLocationId);
        window.open(response.data, '_blank');
      } else {
        message.error('Please select a location!');
      }
    } catch (error) {
      message.error('unable to fetch');
    }
    setIsloading(false);
  };

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <PageHeader
            title={'Price Information by Location'}
            style={{ padding: '8px 0px 0px 0px' }}
          />

          <Form
            form={form}
            layout={'vertical'}
            initialValues={{ locationId: preferenceLocationId }}>
            <LocationSearchV2
              hasParentFormItem={false}
              name={['locationId']}
              className="max-w-md"
            />
          </Form>

          <div className="flex gap-5 mt-10 mb-5 flex-wrap">
            <Upload {...uploadProps}>
              <Button
                style={{ width: 150 }}
                icon={<UploadOutlined />}
                disabled={fileList.length > 0}>
                Upload File
              </Button>
            </Upload>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={isloading}
              style={{ width: 150 }}>
              {isloading ? 'loading' : 'Start Upload'}
            </Button>
            <Button
              onClick={handleDownload}
              disabled={isloading}
              type={'primary'}
              style={{ borderRadius: '9px' }}>
              Download
            </Button>
          </div>
          <ReuseChannel slug={`opening_stock_upload_${null}`} />
        </AppContent>
      </Spin>
    </div>
  );
};

export default OpenStockDownloadAndUpload;
