import moment from 'moment';
import { get_latest_product_transaction_by_location_product } from '../transfer/queries';
import { ITransferLine } from '../transfer/types';
import { TransactionType } from './enums';
import { IProductType } from './types';

export const get_unit_price_prefill = async (
  productId: number,
  locationId: number,
  types: TransactionType[]
) => {
  let latestTransferLineReturn: ITransferLine | undefined = undefined;
  const latestTransferLineAll: ITransferLine[] = [];
  const currentDate = moment(new Date());

  const latestTransferLines = await Promise.all(
    types.map(async (type) => {
      const latesttransferline = await get_latest_product_transaction_by_location_product(
        productId,
        locationId,
        type
      );

      if (latesttransferline) return { ...latesttransferline };
    })
  );
  for (const latesttransferline of latestTransferLines) {
    if (latesttransferline) {
      // const purchaseDate = moment(latesttransferline.createdAt);
      // const purchaseDiff = currentDate.diff(purchaseDate, 'hours');
      latestTransferLineAll.push(latesttransferline);
    }
  }
  latestTransferLineAll.sort((a, b) => {
    const aDate = moment(a.createdAt);
    const bDate = moment(b.createdAt);
    const aDiff = currentDate.diff(aDate, 'hours');
    const bDiff = currentDate.diff(bDate, 'hours');
    return aDiff - bDiff;
  });
  latestTransferLineReturn = latestTransferLineAll[0];

  return { filtered: latestTransferLineReturn, all: latestTransferLineAll };
};

export const isProductHidden = (product: IProductType, locationId?: number) => {
  if (!product) return true;
  if (product.hidden || !locationId) return product.hidden;
  return checkProductLocationHidden(product, locationId);
};

export const checkProductLocationHidden = (product: IProductType, locationId?: number) => {
  if (!locationId) return product.hidden;
  const locationStatus = product.status?.find((s) => s.locationId === locationId);
  return locationStatus ? locationStatus.hidden : false;
};
