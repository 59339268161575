import React, { useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Tooltip, Menu, Form, Select, TableProps, message, Spin, Tag, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { useNavigate } from 'react-router-dom';
// import ProductFilterDrawers from '../../../../components/@Products/FilterDrawer/FilterDrawers';
import AppContent from '../../../../components/Common/Content';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '../../../../components/FliterTable';
import moment from 'moment';
import {
  get_customer_list_ids,
  get_user_list_ids,
  get_user_pan_ids
} from '../../../../services/users/queries';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { get_invoices_list } from '../../../../services/settings/queries';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { getUserData } from '../../../../utils/auth.utils';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import CopyButton from '../../../../components/Common/CopyButton';
import { ExportColumnType } from '../../../../utils/exportExcel';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../../utils/convertToUTC';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import { CustomModal } from '../../../../components/Common/CustomModal';
import CustomTable from '../../../../components/Common/CustomResuableInvoice/CustomTable';
import { useReactToPrint } from 'react-to-print';
import { UsersSearch } from '../../../../components/Common/UsersSearch';
import { find_to_use_NPR } from '../../../../store/localstorage/preferences';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';
import { get_materialized_lists } from '../../../../services/ird/materialized/queries';
import { Excel } from 'antd-table-saveas-excel';
import CustomersDB from '../../../../store/localstorage/CustomerDB';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';

interface DataType {
  id: number;
  name: string;
  age: number;
  address: string;
  description: string;
}

const MaterializedLists: React.FC = () => {
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState<boolean>(true);
  // const [allpurchaseList, setallPurchaseList] = useState<any>({ total: 0, results: [] });
  const [materializedList, setMaterializedList] = useState<any>({ total: 0, results: [] });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [urlData, setUrlData] = useState('');
  const [exportData, setExportData] = useState<any[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const { preferences, global } = getUserData();
  // console.log('preferences-->', getUserData());
  // console.log('global-->', global);
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const fiscalYear = JSON.parse(global.content).financialYear;
  const [total, setTotal] = useState({ amount: 0, discount: 0, taxable: 0, grandTotal: 0 });

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });
  const getInfo = async (filter = '') => {
    setIsloading(true);
    setUrlData(filter);
    const total = { amount: 0, discount: 0, taxable: 0, grandTotal: 0 };
    const response = await get_materialized_lists(filter);
    // console.log('Response-->', JSON.stringify(response));
    if (response) {
      const customerUserIds = new Set<number>();
      for (let index = 0; index < response.results.length; index++) {
        // set customer name
        // console.log('response.results[index].sell', response.results[index].sell);
        if (!response.results[index].sell) {
          response.results[index].sell = response.results[index].sellReturn;
        }

        const sellStats = response.results[index].sell;
        if (sellStats) {
          total.amount += sellStats.totalTaxable + sellStats.totalNonTaxable;
          total.discount += sellStats.totalDiscount || 0;
          total.taxable += sellStats.totalTaxable;
          total.grandTotal += sellStats.totalAmount;
        }

        let customer = await CustomersDB.getCustomer(response.results[index].sell.customerId);
        // console.log('customer', customer);
        if (!customer) {
          let customerIds = response.results.map((el: any) =>
            el.sell ? el.sell.customerId : el.sellReturn.customerId
          );
          customerIds = [...new Set(customerIds)];
          const allCustomers = await get_customer_list_ids(customerIds);
          await CustomersDB.addCustomers(allCustomers.data.results);
          customer = await CustomersDB.getCustomer(response.results[index].sell.customerId);
        }

        if (typeof customer === 'object' && customer.userId) {
          customerUserIds.add(customer.userId);
          response.results[index].customerName = customer.user.name;
          response.results[index].customerUserId = customer.userId;
        }

        let enteredByUser = await UsersDB.getUser(response.results[index].enteredBy);
        let printedByUser = await UsersDB.getUser(response.results[index].printedBy);
        if (!enteredByUser || !printedByUser) {
          let allUserIds = response.results.map((el: any) => el.enteredBy);
          allUserIds.push(...response.results.map((el: any) => el.printedBy));
          allUserIds = [...new Set(allUserIds)];
          allUserIds = allUserIds.filter((el: any) => el !== null && !Number.isNaN(parseInt(el)));

          const allUsers = await get_user_list_ids(allUserIds);
          await UsersDB.addUsers(allUsers.data.results);
          enteredByUser = await UsersDB.getUser(response.results[index].enteredBy);
          printedByUser = await UsersDB.getUser(response.results[index].printedBy);
        }
        if (typeof enteredByUser === 'object') {
          response.results[index].enteredByName = enteredByUser.name;
        }
        if (typeof printedByUser === 'object') {
          response.results[index].printedByName = printedByUser.name;
        }
        // console.log('response', response);
      }

      // pan
      if (Array.from(customerUserIds).length > 0) {
        const allPan = await get_user_pan_ids([...customerUserIds]);
        //console.log('allPan', allPan);

        for (let ind = 0; ind < allPan.data.length; ind++) {
          response.results.forEach((el: any) =>
            allPan.data[ind].taxNumber !== null
              ? (el.customerPan = allPan.data[ind].taxNumber)
              : 'N/A'
          );
        }
      }

      const dataUpdated = response.results.map((item: any) => {
        return {
          ...item,
          fiscalYear: fiscalYear,
          financialReference: item.sell.financialReference,
          debitReference: item.sell.debitReference,
          printDate: convertUTCStringtoLocalString(item.date, 'HH:mm:ss'),
          billDate: convertUTCStringtoLocalString(item.addedAt, 'YYYY-MM-DD'),
          amount: item.sell.totalTaxable + item.sell.totalNonTaxable,
          totalDiscount: item.sell.discount ? item.sell.discount : 0,
          totalTaxable: item.sell.totalTaxable,
          totalVat: item.sell.totalVat,
          totalAmount: item.sell.totalAmount,
          sync: item.sync ? 'Yes' : 'No',
          billPrinted: item.billPrinted ? 'Yes' : 'No',
          billActive: item.billActive ? 'Yes' : 'No',
          isRealTime: item.isRealTime ? 'Yes' : 'No'
        };
      });
      setMaterializedList(response);
      setExportData(dataUpdated);
      setTotal(total);
    }
    setIsloading(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Fiscal Year',
      key: 'fiscalYear',
      width: 60,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'fiscalYear' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{fiscalYear}</TableCell>;
      }
    },
    {
      title: 'Bill No.',
      width: 80,
      key: 'financialReference',
      className: 'invoice',
      sorter: (a, b) => a.sell.financialReference.localeCompare(b.sell.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div className="flex flex-row justify-between items-center">
              {record.sell.financialReference}
              {record.sell.financialReference ? (
                <CopyButton text={record.sell.financialReference} />
              ) : null}
            </div>
          </TableCell>
        );
      }
    },
    form.getFieldValue(['type']) === 'SELL-RETURN'
      ? {
          title: 'Debit Reference',
          width: 80,
          key: 'debitReference',
          sorter: (a, b) => a.sell.debitReference.localeCompare(b.sell.debitReference),
          sortOrder: sortedInfo.columnKey === 'debitReference' ? sortedInfo.order : null,
          render: (record) => {
            return (
              <TableCell>
                <div className="flex flex-row justify-between items-center">
                  {record.sell.debitReference}
                  {record.sell.debitReference ? (
                    <CopyButton text={record.sell.debitReference} />
                  ) : null}
                </div>
              </TableCell>
            );
          }
        }
      : {
          width: 0
        },
    {
      title: 'Customer',
      width: 60,
      key: 'customerName',
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
      sortOrder: sortedInfo.columnKey === 'customerName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>{record.customerName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Customer PAN',
      width: 60,
      key: 'customerPan',
      sorter: (a, b) => a.customerPan.localeCompare(b.customerPan),
      sortOrder: sortedInfo.columnKey === 'customerPan' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>{record.customerPan}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Bill Date',
      width: 50,
      key: 'billDate',
      sorter: (a, b) => moment(a.addedAt).unix() - moment(b.addedAt).unix(),
      sortOrder: sortedInfo.columnKey === 'addedAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div color="black">
              {/* {moment(record.date).local().format(DEFAULT_DATE_FORMAT)} */}
              {convertUTCStringtoLocalString(record.addedAt, DEFAULT_DATE_FORMAT)}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Amount',
      width: 50,
      key: 'amount',
      sorter: (a, b) =>
        a.totalAmount -
        (a.sell.shipping - a.sell.shippingTax + a.sell.totalVat) -
        (b.sell.totalAmount - (b.sell.shipping - b.sell.shippingTax + b.sell.totalVat)),
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.sell.totalTaxable + record.sell.totalNonTaxable)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Discount',
      width: 40,
      key: 'discount',
      sorter: (a, b) => a.sell.discount - b.sell.discount,
      sortOrder: sortedInfo.columnKey === 'discount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.sell.discount ? record.sell.discount : 0)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Taxable Amount',
      width: 40,
      key: 'taxableAmount',
      sorter: (a, b) => a.sell.totalTaxable - b.sell.totalTaxable,
      sortOrder: sortedInfo.columnKey === 'totalTaxable' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.sell.totalTaxable)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Total Amount',
      width: 50,
      key: 'totalAmount',
      className: 'highlight',
      sorter: (a, b) => a.sell.totalAmount - b.sell.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.sell.totalAmount)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'IRD Sync',
      width: 50,
      key: 'sync',
      sorter: (a, b) => a.sync - b.sync,
      sortOrder: sortedInfo.columnKey === 'sync' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">{record.sync ? 'Yes' : 'No'}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Is Bill Print',
      width: 70,
      key: 'billPrinted',
      sorter: (a, b) => a.billPrinted - b.billPrinted,
      sortOrder: sortedInfo.columnKey === 'billPrinted' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell data-boolean={record.billPrinted} className="text-right mr-2 text-xs">
            {record.billPrinted ? 'Yes' : 'No'}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Is Bill Active ',
      width: 50,
      key: 'billActive',
      sorter: (a, b) => a.billActive - b.billActive,
      sortOrder: sortedInfo.columnKey === 'billActive' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <Tag color={record.billActive ? 'green' : 'red'}>{record.billActive ? 'Yes' : 'No'}</Tag>
        );
      }
    },
    {
      title: 'Printed Time',
      width: 50,
      key: 'printDate',
      sorter: (a, b) => moment(a.printDate).unix() - moment(b.printDate).unix(),
      sortOrder: sortedInfo.columnKey === 'printDate' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div color="black">
              {/* {moment(record.date).local().format(DEFAULT_DATE_FORMAT)} */}
              {convertUTCStringtoLocalString(record.printDate, DEFAULT_DATE_FORMAT)}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Entered By',
      width: 50,
      key: 'enteredByName',
      sorter: (a, b) => a.enteredByName.localeCompare(b.enteredByName),
      sortOrder: sortedInfo.columnKey === 'enteredByName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div color="black">
              {record.enteredByName}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Printed By',
      width: 50,
      key: 'printedByName',
      sorter: (a, b) => a.printedByName.localeCompare(b.printedByName),
      sortOrder: sortedInfo.columnKey === 'printedByName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div color="black">
              {record.printedByName}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Is Realtime',
      width: 50,
      key: 'isRealtime',
      sorter: (a, b) => a.isRealtime - b.isRealtime,
      sortOrder: sortedInfo.columnKey === 'isRealtime' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell data-boolean={record.isRealtime}>
            <div color="black">
              {record.isRealtime ? 'Yes' : 'No'}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Payment Method',
      width: 50,
      key: 'paymentMethod',
      sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
      sortOrder: sortedInfo.columnKey === 'paymentMethod' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div color="black">
              {record.paymentMethod}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'VAT Refund Amount',
      width: 50,
      key: 'vatRefundAmount',
      sorter: (a, b) => a.vatRefundAmount - b.vatRefundAmount,
      sortOrder: sortedInfo.columnKey === 'vatRefundAmount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div color="black">
              {record.vatRefundAmount}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Transaction ID',
      width: 50,
      key: 'transactionId',
      sorter: (a, b) => a.transactionId.localeCompare(b.transactionId),
      sortOrder: sortedInfo.columnKey === 'transactionId' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div color="black">
              {record.transactionId}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    }
  ];
  const breadcrumbItems = [
    {
      label: 'Materialized View Report',
      link: '/ird/materialized-report'
    }
  ];
  const { Option } = Select;

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // const response = await get_purchase_list_filter(url);
    // setallPurchaseList(response.data);
    getInfo(url);
    // setIsloading(false);
    // ProductsDB.addProducts(productResponse.data.results);
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const excelColumns: ExportColumnType[] = [
        {
          title: 'Fiscal Year',
          dataIndex: 'fiscalYear',
          width: 100
        },
        {
          title: 'Bill_No',
          dataIndex: 'financialReference',
          width: 250
        },
        {
          title: 'Debit_Reference',
          dataIndex: 'debitReference',
          width: 250
        },
        {
          title: 'Customer_Name',
          dataIndex: 'customerName',
          width: 250
        },
        {
          title: 'Customer_PAN',
          width: 250,
          dataIndex: 'customerPan'
        },
        {
          title: 'Bill_Date',
          width: 200,
          dataIndex: 'billDate'
        },
        {
          title: 'Amount',
          width: 200,
          dataIndex: 'amount'
        },
        {
          title: 'Discount',
          width: 100,
          dataIndex: 'totalDiscount'
        },
        {
          title: 'Taxable_Amount',
          width: 100,
          dataIndex: 'totalTaxable'
        },
        {
          title: 'Tax_Amount',
          width: 100,
          dataIndex: 'totalVat'
        },
        {
          title: 'Total_Amount',
          width: 200,
          dataIndex: 'totalAmount'
        },
        {
          title: 'Sync with IRD',
          width: 200,
          dataIndex: 'sync'
        },
        {
          title: 'Is_Bill_Printed',
          width: 200,
          dataIndex: 'billPrinted'
        },
        {
          title: 'Is_Bill_Active',
          width: 200,
          dataIndex: 'billActive'
        },
        {
          title: 'Printed_Time',
          width: 200,
          dataIndex: 'printDate'
        },
        {
          title: 'Entered_By',
          width: 200,
          dataIndex: 'enteredByName'
        },
        {
          title: 'Printed_By',
          width: 200,
          dataIndex: 'printedByName'
        },
        {
          title: 'Is_Realtime',
          width: 200,
          dataIndex: 'isRealTime'
        },
        {
          title: 'Payment_Method',
          width: 200,
          dataIndex: 'paymentMethod'
        },
        {
          title: 'VAT Refund Amount',
          width: 200,
          dataIndex: 'vatRefundAmount'
        },
        {
          title: 'Transaction Amount',
          width: 200,
          dataIndex: 'transactionAmount'
        },
        {
          title: 'Transaction Id',
          width: 200,
          dataIndex: 'transactionId'
        }
      ];
      if (materializedList.results.length === 0) {
        message.error('No Data to Export');
        return;
      }

      const excel = new Excel();
      excel.setTHeadStyle({
        background: 'ffffffff'
      });
      excel
        .setTHeadStyle({
          fontSize: 10
        })
        .setTBodyStyle({
          fontSize: 10
        })
        .addSheet('Sales Register')
        .addColumns(excelColumns as any[])
        .addDataSource(exportData, {
          str2Percent: true
        })
        .saveAs('Materialized List.xlsx');

      setIsloading(false);
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: any = [
    {
      label: 'Fiscal Year',
      dataIndex: 'fiscalYear',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Bill No',
      dataIndex: 'financialReference',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Debit Reference',
      dataIndex: 'debitReference',
      width: 50,
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Customer',
      dataIndex: 'customerName',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Customer PAN',
      dataIndex: 'customerPan',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Bill Date',
      dataIndex: 'billDate',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Amount',
      dataIndex: 'amount',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Discount',
      dataIndex: 'totalDiscount',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Taxable Amount',
      dataIndex: 'totalTaxable',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Tax Amount',
      dataIndex: 'totalVat',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Total Amount',
      dataIndex: 'totalAmount',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'IRD Sync',
      dataIndex: 'sync',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text ? 'Yes' : 'No'}</div>;
      }
    },
    {
      label: 'Is Bill Print',
      dataIndex: 'billPrinted',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text ? 'Yes' : 'No'}</div>;
      }
    },
    {
      label: 'Is Bill Active ',
      dataIndex: 'billActive',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text ? 'Yes' : 'No'}</div>;
      }
    },
    {
      label: 'Printed Time',
      dataIndex: 'printDate',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Entered By',
      dataIndex: 'enteredByName',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },

    {
      label: 'Printed By',
      dataIndex: 'printedByName',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Is Realtime',
      dataIndex: 'isRealtime',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text ? 'Yes' : 'No'}</div>;
      }
    },
    {
      label: 'Payment Method',
      dataIndex: 'paymentMethod',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'VAT Refund Amount',
      dataIndex: 'vatRefundAmount',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Transaction ID',
      dataIndex: 'transactionId',
      width: 50,
      render: (text: any) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Purchase List View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={exportData}
            reff={printPDFRef}
            title={'Materialized View Report( Annex 5)'}
            serialNumber={false}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  onPagination={(page, size) => {
                    setPage(page);
                    setSize(size);
                  }}
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    type: 'SELL',
                    locationId: preferenceLocationId ? preferenceLocationId : 1,
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end items-center'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  form={form}
                  buttons={<></>}>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" />
                  <Form.Item name="type" label="Type">
                    <Select
                      placeholder="Select Type"
                      style={{ borderRadius: '10px' }}
                      allowClear
                      dropdownMatchSelectWidth={false}>
                      <Option value="SELL" key="Sell">
                        Sell
                      </Option>
                      <Option value="SELL-RETURN" key="Sell Return">
                        Sell Return
                      </Option>
                    </Select>
                  </Form.Item>

                  <UserSearchV2 hasParentFormItem={false} name="printedBy" label="Printed By" />
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (materializedList.results.length === 0) {
                            message.error('No Data to Export');
                            setIsloading(false);
                            return;
                          }
                          setOpenModalforexport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <CustomizeTable
          form={form}
          columns={columns}
          data={materializedList.results}
          notshowPagination={true}
          customScroll={{ x: 2400, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: materializedList.total,
            size: size,
            onPagination
          }}
          tableSummary={
            <Table.Summary.Row className="text-right">
              <Table.Summary.Cell index={0} colSpan={7}>
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                {nepaliNumberFormatter(total.amount)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                {nepaliNumberFormatter(total.discount)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10}>
                {nepaliNumberFormatter(total.taxable)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={11}>
                {nepaliNumberFormatter(total.grandTotal)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          }
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default MaterializedLists;
