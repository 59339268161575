import moment from 'moment';
import { useState } from 'react';
import { Form, Input, message, Select, Tag } from 'antd';

import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import {
  get_adjustments_lines_list_filter,
  get_reasons_list
} from '@/services/adjustments/queries';
import getError from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';
import { IAdjustmentProductTable, IAdjustmentWithLinesTable } from '@/services/adjustments/types';
import { ColumnsType } from 'antd/lib/table';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { getLocationDetails } from '@/services/locations/services';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from './../../../../store/localstorage/UnitsDB';
import { IProductType, IUnits } from '@/services/products/types';
import { get_units_list } from '@/services/products/queries';
import { Link } from 'react-router-dom';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CopyButton from '@/components/Common/CopyButton';
import getGroupedData from '@/utils/getGroupedData';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { excelExportColumns, tableExportColumns } from './column.export';
import ExportAllData from '@/components/Common/ExportAll';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import ProductbyCategory from '@/components/Common/CustomSearch/Products/ProductsByCategory';
import { getCategoryById, getProductById } from '@/services';
import CategoryDB from './../../../../store/localstorage/CategoryDB';

function AdjustmentLines() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [fullExportURL, setFullExportURL] = useState<string | null>(null);

  const [categoryId, setCategoryId] = useState<number | null>(null);

  const [adjustmentWithLines, setAdjustmentWithLines] = useState<IAdjustmentWithLinesTable>({
    total: 0,
    results: []
  });

  const breadcrumbItems = [
    { label: 'Adjustments', link: '/adjustments' },
    { label: 'Lines', link: '/adjustments/lines' }
  ];

  async function onSubmitFilter(filter: string) {
    const response = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    if (!response) {
      setFullExportURL('');
      return;
    }

    if (response && response.total > 0) {
      const params = new URLSearchParams(filter);
      params.set('count', response.total.toString());
      setFullExportURL(params.toString());
    } else {
      setFullExportURL('');
    }
  }

  async function getInfo(val: string) {
    try {
      setIsLoading(true);
      const [response, reasonResponse] = await Promise.all([
        get_adjustments_lines_list_filter(val),
        get_reasons_list()
      ]);

      const reasonData = reasonResponse.data;

      const lines = response.results as IAdjustmentProductTable[];
      const allProductIds = lines.map((line) => line.lines_product_id);
      const allCategoryIds = lines
        .map((line) => line.Adjustment_category_id)
        .filter(Boolean) as number[];
      await ProductsDB.addProductsIfAbsent(allProductIds);
      await CategoryDB.addCategoryIfAbsent(allCategoryIds);

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const location = await getLocationDetails(line.lines_location_id);
        const product = await getProductById(line.lines_product_id);

        let categoryName = '';
        if (line.Adjustment_category_id) {
          const category = await getCategoryById(line.Adjustment_category_id);
          categoryName = category?.name || '';
        }

        let unit = (await UnitsDB.getUnit(line.lines_unit_id)) as IUnits;

        if (!unit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          unit = (await UnitsDB.getUnit(line.lines_unit_id)) as IUnits;
        }

        const currentReason = reasonData.find((reason) => reason.id === line.Adjustment_reason_id);

        lines[i] = {
          ...line,
          locationName: location.name,
          productName: product?.name,
          unitName: unit?.name,
          status: line.Adjustment_status,
          reasonName: currentReason?.name || '',
          categoryName,
          lines_date: moment(line.lines_date).format(DEFAULT_DATE_FORMAT)
        };
      }

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const product = (await ProductsDB.getProduct(line.lines_product_id)) as IProductType;
        lines[i] = { ...line, productName: product?.name };
      }

      const groupedData = getGroupedData(lines, 'Adjustment_financial_reference');
      const groupedResults = [] as IAdjustmentProductTable[];

      let sn = pagination.size * (pagination.page - 1) + 1;

      Object.keys(groupedData).forEach((key) => {
        const datas = JSON.parse(JSON.stringify(groupedData[key])) as IAdjustmentProductTable[];

        datas.sort((a, b) => a.Adjustment_id - b.Adjustment_id);
        const groupElements = datas.map((data, index) => {
          const row = index === 0 ? datas.length : 0;
          const currentSn = sn + index;
          return { ...data, row, sn: currentSn };
        });

        sn += datas.length;
        groupedResults.push(...groupElements);
      });

      const updatedData = { ...response, results: groupedResults };
      setAdjustmentWithLines(updatedData);
      return updatedData;
    } catch (error) {
      const errorMessage = getError(error);
      message.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  async function onSearchAll() {
    try {
      setIsLoading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }

      const response = await getInfo(fullExportURL);
      return response?.results as any;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const columns: ColumnsType<IAdjustmentProductTable> = [
    {
      title: 'S.N.',
      key: 'sn',
      dataIndex: 'sn',
      width: 10,
      onCell: (record) => ({ rowSpan: record.row })
    },
    {
      title: 'Financial Reference',
      key: 'Adjustment_financial_reference',
      dataIndex: 'Adjustment_financial_reference',
      width: 50,
      onCell: (record) => ({ rowSpan: record.row }),
      render: (financialReference) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs">
            {financialReference}
            <CopyButton text={financialReference} />
          </TableCell>
        );
      }
    },
    {
      title: 'Adjustment ID',
      key: 'lines_identifier_id',
      width: 15,
      render: (record: IAdjustmentProductTable) => (
        <Link to={`/adjustments/${record.lines_identifier_id}`}>{record.lines_identifier_id}</Link>
      )
    },
    {
      title: 'Category',
      key: 'categoryName',
      dataIndex: 'categoryName',
      width: 25
    },
    {
      title: 'Product',
      key: 'productName',
      dataIndex: 'productName',
      width: 25
    },
    {
      title: 'Location',
      key: 'locationName',
      dataIndex: 'locationName',
      width: 40
    },
    {
      title: 'Unit',
      key: 'unitName',
      dataIndex: 'unitName',
      width: 20
    },
    {
      title: 'Quantity',
      key: 'lines_quantity',
      dataIndex: 'lines_quantity',
      width: 20
    },
    {
      title: 'Total Amount',
      key: 'lines_total_amount',
      dataIndex: 'lines_total_amount',
      width: 25,
      render: (value) => nepaliNumberFormatter(Number(value))
    },
    {
      title: 'Date',
      key: 'lines_date',
      dataIndex: 'lines_date',
      width: 35
    },
    {
      title: 'Reason',
      key: 'reasonName',
      dataIndex: 'reasonName',
      width: 30
    },
    {
      title: 'Status',
      key: 'Adjustment_status',
      dataIndex: 'Adjustment_status',
      width: 25,
      render: (text) => (
        <Tag color={text === 'APPROVED' ? 'green' : text === 'REJECTED' ? 'red' : 'orange'}>
          {text}
        </Tag>
      )
    }
  ];

  return (
    <AppContent
      breadcrumbItems={breadcrumbItems}
      withfilter
      button={
        <TableFilter
          initial
          form={form}
          onPagination={(page, size) => setPagination({ page, size })}
          onSubmit={onSubmitFilter}
          styleforbuttons={'flex items-center justify-end'}
          defaultValues={{
            dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
            value: '',
            skip: 0,
            count: 100,
            status: 'APPROVED',
            locationId: '',
            productId: '',
            categoryId: ''
          }}>
          <Form.Item name={'status'} label="Status">
            <Select
              placeholder="Select Status"
              style={{ borderRadius: '10px' }}
              allowClear
              // onChange={() => handleChange()}
              dropdownMatchSelectWidth={false}>
              <Select.Option value={'PENDING'}>Pending</Select.Option>
              <Select.Option value={'APPROVED'}>Approved</Select.Option>
              <Select.Option value={'REJECTED'}>Rejected</Select.Option>
            </Select>
          </Form.Item>
          <LocationSearchV2
            hasParentFormItem={false}
            name={'locationId'}
            label="Location"
            showAll
          />
          <ProductCategorySearchV2
            showAll
            hasParentFormItem={false}
            name={['categoryId']}
            onSelect={(val: any) => {
              val === '' ? setCategoryId(null) : setCategoryId(val as number);
              form.setFieldValue(['productId'], '');
            }}
          />
          <ProductbyCategory
            categoryId={categoryId}
            hasParentFormItem={false}
            showAll
            name={['productId']}
          />

          <Form.Item name="value" label="Search">
            <Input placeholder="Search" />
          </Form.Item>
        </TableFilter>
      }>
      <GenericTable
        form={form}
        columns={columns}
        data={adjustmentWithLines.results}
        isLoading={isLoading}
        hideDefaultPagination
        scroll={{ x: 1600, y: '75vh' }}
        generateSummary
        pagination={{
          page: pagination.page,
          total: adjustmentWithLines.total,
          size: pagination.size,
          onPagination,
          scrollToTop: true
        }}
        tableName={'adjustment-lines'}
        summaryClassName="text-left"
        excludeSummaryByKeys={['sn', 'row']}
        buttons={
          <>
            <ExportAllData
              title="Adjustment Lines Report (All)"
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={onSearchAll}
            />
            <ExportCurrent
              data={adjustmentWithLines}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Adjustment Lines Report"
            />
          </>
        }
      />
    </AppContent>
  );
}

export default AdjustmentLines;
