import { get_scheduler_status_list } from '@/services/schedule/queries';
import { ISchedulerResponse } from '@/services/schedule/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import Table from './Table';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { ESchedularSuccessStatus, SchedulerStatusType } from '@/services/schedule/enum';
import { rerun_schedule } from '@/services/schedule/mutation';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import isAxiosError from '@/utils/isAxiosError';

function SchedularStatus() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<ISchedulerResponse>({ total: 0, results: [] });
  const [openRerunModal, setOpenRerunModal] = useState(false);
  const [selectedType, setSelectedType] = useState<SchedulerStatusType>();

  const [schedularOptions, setSchedularOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    const schedularTypeOptions = Object.keys(SchedulerStatusType)
      .map((label) => ({
        label,
        value: SchedulerStatusType[label as keyof typeof SchedulerStatusType]
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const typeWithAll = [{ label: 'All', value: '' }, ...schedularTypeOptions];
    setSchedularOptions(typeWithAll);
  }, []);

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_scheduler_status_list(filter);
      setData(response);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  function onRerun(type: SchedulerStatusType, isFailed: boolean) {
    if (isFailed) {
      setSelectedType(type);
      setOpenRerunModal(true);
      return;
    }

    CustomErrorModal({
      message: 'You cannot rerun this schedular. Only failed schedular can be rerun.'
    });
  }

  async function onRerunConfirm() {
    try {
      if (!selectedType) return;
      setOpenRerunModal(false);
      setSelectedType(undefined);
      setIsLoading(true);

      await rerun_schedule(selectedType);
      await getInfo();
    } catch (error) {
      if (isAxiosError(error)) return;
      const message = getErrorMessage(error);
      CustomErrorModal({ message });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Modal
        title="Rerun Schedular"
        visible={openRerunModal}
        onCancel={() => setOpenRerunModal(false)}
        onOk={onRerunConfirm}>
        <div>
          Selected schedular: <strong> {selectedType} </strong>
          <br />
          Are you sure you want to rerun this schedular?
        </div>
      </Modal>
      <AppContent
        breadcrumbItems={[
          { label: 'Schedular', link: '/scheduler' },
          { label: 'Schedular Status' }
        ]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            onPagination={(page, size) => setPagination({ page, size })}
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100,
              type: '',
              status: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <Form.Item name="type" label="Scheduler Type">
              <Select
                placeholder="Select scheduler type"
                showSearch
                options={schedularOptions}
                optionLabelProp="label"
                optionFilterProp="label"
              />
            </Form.Item>

            <Form.Item name="status" label="Status">
              <Select placeholder="Select status">
                <Select.Option value={''}>All</Select.Option>
                {Object.keys(ESchedularSuccessStatus).map((status) => (
                  <Select.Option key={status} value={status}>
                    {ESchedularSuccessStatus[status as keyof typeof ESchedularSuccessStatus]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="value" label="Name">
              <Input />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          data={data}
          form={form}
          isLoading={isLoading}
          pagination={pagination}
          onRerun={onRerun}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default SchedularStatus;
