import { CapitalizeEachWord } from '@/utils/capitalizeEachWord';
import { useState } from 'react';

interface Props {
  eventColors: Record<string, { light: string; dark: string }>;
  onToggle: (activeStatuses: Record<string, boolean>) => void; // Callback to parent
}

function CalendarLegend({ eventColors, onToggle }: Props) {
  const [activeItems, setActiveItems] = useState<Record<string, boolean>>(() =>
    Object.keys(eventColors).reduce((acc, key) => ({ ...acc, [key]: true }), {})
  );

  const toggleItem = (key: string) => {
    const updatedItems = { ...activeItems, [key]: !activeItems[key] };
    setActiveItems(updatedItems);
    onToggle(updatedItems); // Pass updated statuses to parent
  };

  return (
    <div className="flex flex-wrap justify-center gap-3 items-center">
      {Object.entries(eventColors).map(([key, { light, dark }]) => (
        <div
          key={key}
          className={`flex items-center gap-2 cursor-pointer select-none ${
            activeItems[key] ? '' : 'opacity-50'
          }`}
          onClick={() => toggleItem(key)}>
          <div
            className="w-4 h-4"
            style={{
              backgroundColor: light,
              borderLeft: `3px solid ${dark}`,
              borderRadius: '2px'
            }}
            aria-hidden="true"></div>
          <span
            className={`text-xs ${
              activeItems[key] ? 'text-black/70' : 'text-gray-500 line-through'
            }`}>
            {CapitalizeEachWord(key)}
          </span>
        </div>
      ))}
    </div>
  );
}

export default CalendarLegend;
