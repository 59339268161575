import { Routes, Route } from 'react-router-dom';

import Private from './Private.route';
import Public from './Public.route';

import Login from '../pages/login/Login';

import ProductsList from '../pages/products/lists';
import CreateProduct from '../pages/products/create';
import ProductDetails from '../pages/products/details';
import ProductPurchaseView from '../pages/purchases/purchase-form/view';

import UsersList from '../pages/users/Users/lists';
import UserDetails from '../pages/users/Users/details';

import LocationsList from '../pages/locations/lists';
import CreateLocations from '../pages/locations/create';
import LocationsDetails from '../pages/locations/details';
import VendorsList from '../pages/users/Vendors/lists';
import CreateVendor from '../pages/users/Vendors/create';
import VendorDetails from '../pages/users/Vendors/details';
import CustomersList from '../pages/users/Customers/lists';
import CustomerDetails from '../pages/users/Customers/details';
import CreateProcurement from '../pages/procurement/create/Create';
import PurchaseDetails from '../pages/purchases/view/Details';
import Preferences from '../pages/settings/Preferences';
import CategoryList from '../pages/category/lists';
import CreateCategory from '../pages/category/create';
import DetailsCategory from '../pages/category/details/Details';
import CreateOffer from '../pages/offers/create/Create';
import OfferList from '../pages/offers/lists/List';
import OfferDetails from '../pages/offers/details';
import RoutesList from '../pages/routes/lists';
import CreateRoutes from '../pages/routes/create';
import RoutesDetails from '../pages/routes/details';
import AddressCreate from '../pages/users/common/Address/Create';
import AddressDetails from '../pages/users/common/Address/Details';
import AddressList from '../pages/users/common/Address/List';
import TaxList from '../pages/users/common/Tax/List';

import TasksList from '../pages/tasks/lists';

import Invoices from '../pages/settings/invoices';
import InvoicesLayoutsCreate from '../pages/settings/invoices/Layouts/Create';
import InvoicesLayoutsDetails from '../pages/settings/invoices/Layouts/Details';
import TaxCreate from '../pages/users/common/Tax/Create';
import TaxDetails from '../pages/users/common/Tax/Details';
import LayoutToUI from '../utils/LayoutToUI';
import LotsList from '../pages/products/lots/view';
import CreateSell from '../pages/sell/create';

import CreatePurchaseOrder from '../pages/purchases/purchase-order/create';
import PurchaseOrderDetails from '../pages/purchases/purchase-order/details';
import CreateSellOrder from '../pages/sell/sell-order/create';
import SellOrderDetails from '../pages/sell/sell-order/details';
import AdjustmentsList from '../pages/adjustments/list';
import CreateAdjustments from '../pages/adjustments/create';
import AdjustmentDetails from '../pages/adjustments/details';
import TransfersList from '../pages/transfers/list';
import CreateTransfer from '../pages/transfers/create';
import CreateReasons from '../pages/adjustments/reasons/create';
import ReasonsList from '../pages/adjustments/reasons/list';
import EditReasons from '../pages/adjustments/reasons/details';
import SellOrderView from '../pages/sell/sell-order/view';
import PurchaseOrderView from '../pages/purchases/purchase-order/view';
import SellView from '../pages/sell/view';
import ChannelsList from '../pages/channel/list';
import CreateChannel from '../pages/channel/create';
import EditChannel from '../pages/channel/details';
import Room from '../pages/channel/room';
import SourceList from '../pages/sqlsource/list';
import CreateSource from '../pages/sqlsource/create';
import EditSource from '../pages/sqlsource/details';
import CustomerReport from '../pages/sqlsource/report/list/customer';
import ReportList from '../pages/sqlsource/report/list';
import UserReport from '../pages/sqlsource/report/list/user';
import VendorReport from '../pages/sqlsource/report/list/vendor';
import AddressReport from '../pages/sqlsource/report/list/address';
import PurchaseOrderLineReport from '../pages/sqlsource/report/list/line/PurchaseOrder';
import PurchaseLineReport from '../pages/sqlsource/report/list/line/Purchase';
import PurchaseReturnReport from '../pages/sqlsource/report/list/line/PurchaseReturn';
import SaleOrderLineReport from '../pages/sqlsource/report/list/line/SaleOrder';
import SaleLineReport from '../pages/sqlsource/report/list/line/Sale';
import SaleReturnLineReport from '../pages/sqlsource/report/list/line/SaleReturn';
import TransferInLineReport from '../pages/sqlsource/report/list/line/TransferIn';
import TransferOutLineReport from '../pages/sqlsource/report/list/line/TransferOut';
import AdjustmentLineReport from '../pages/sqlsource/report/list/line/Adjustment';
import TotalPurchaseReport from '../pages/sqlsource/report/list/totalpurchase';
import TotalSaleReport from '../pages/sqlsource/report/list/totalsale';
import PurchaseOrderProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/PurchaseOrder';
import PurchaseReturnProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/PurchaseReturn';
import PurchaseProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/Purchase';
import SaleOrderProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/SaleOrder';
import SaleProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/Sale';
import SaleReturnProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/SaleReturn';
import TransferInProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/TransferIn';
import TransferOutProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/TransferOut';
import AdjustmentProductIdentifierReport from '../pages/sqlsource/report/list/productidentifier/Adjustment';
import ACLList from '../pages/acl/list';
import PriceGroupsUpload from '../pages/pricegroups/upload';
import PriceGroupsDownload from '../pages/pricegroups/download';
import CreateACL from '../pages/acl/create';
import EditACL from '../pages/acl/details';
import UserACL from '../pages/acl/user';
import TransfersView from '../pages/transfers/view/view';
import { ProcurementEstimation } from '../pages/estimation/procurement';
import { SellEstimation } from '../pages/estimation/sell';
import ProductHistoryRangeReport from '../pages/sqlsource/report/list/product-history';
import PaymentSellReport from '../pages/sqlsource/report/payment-sell';
import PaymentPurchaseReport from '../pages/sqlsource/report/payment-purchase';
import StockAdjustmentReport from '../pages/sqlsource/report/stock-adjustment';
import SellReturn from '../pages/sell/sell-return/create';
import PriceGroupCreate from '../pages/pricegroups/create';
import { CustomResuableInvoice } from '../components/Common/CustomResuableInvoice/CustomResuableInvoice';
import { InvoiceForProductAndRoute } from '../components/Common/InvoiceForProductAndRoute/InvoiceForProductAndRoute';
import TotalPurchaseAndSaleReport from '../pages/sqlsource/report/purchase-and-sale';
import ProductPurchaseReport from '../pages/sqlsource/report/product-purchase';
import ProductSellReport from '../pages/sqlsource/report/product-sell';
import FullDayReport from '../pages/sqlsource/report/full-day-report';
import SellRepresentativeReport from '../pages/sqlsource/report/sell-representative';
import { AddPayment } from '../pages/payment/create';
import CustomerSupplierReport from '../pages/sqlsource/report/customer-suppliers';
import CreateOpeningStock from '../pages/open-stock/create';
import Offerupload from '../pages/offers/uploadanddownload/Offerupload';
import SellOrderCurrentStockView from '../pages/sell/order-vs-current/view';
import ErrorPage from '../components/Common/ErrorPage/ErrorPage';
import TransfersInList from '../pages/transfers/transfer-in';
import EditTransfer from '../pages/transfers/details';
import PurchaseOrderFormCreate from '../pages/purchases/purchase-form/indexv2';
import SellOrderFormCreate from '../pages/sell/sell-form';
import ProductSellOrderView from '../pages/sell/sell-form/view';
import VehicleList from '../pages/vehicle/list';
import CreateVehicle from '../pages/vehicle/create';
import EditVehicle from '../pages/vehicle/edit';
import NotificationsList from '../pages/notifications/list';
import CreateNotification from '../pages/notifications/create';
import VehicleLive from '../pages/vehicle/live';
import AllVehicleLive from '../pages/vehicle/all-live';
import NotificationUpdate from '../pages/notifications/update';
import VehicleHistory from '../pages/vehicle/history';
import ProductHistory from '../pages/products/history';
import VendorProductsList from '../pages/vendor-products/list';
import CreateVendorProducts from '../pages/vendor-products/create';
import EditVendorProducts from '../pages/vendor-products/edit';
import CreateModal from '../pages/tasks/create/CreateModal';
import DetailsModal from '../pages/tasks/create/DetailsModal';
import ArchivedTaskList from '../pages/tasks/archived/List';
import ClosedChannelList from '../pages/channel/closed/List';
import PrintMultipleInvoice from '../components/Common/PrintMultipleInvoice/PrintMultipleInvoice';
import AdjustmentReport from '../pages/sqlsource/report/adjustment-report';
import PurchaseReturnV2 from '../pages/purchases/purchase-return/create/v2';
import { InvoiceForSellandSellOrder } from '../components/Common/CustomResuableInvoice/Invoiceforsellandsellorder';
import ProductPrintInvoice from '../components/Common/ProductPrintInvoice/ProductPrintInvoice';
import ProductDownload from '../pages/products/productdowonload';
import GlobalSettings from '../pages/settings/global';
import PriceGroupListing from '../pages/pricegroups/list';
import AttendanceList from '../pages/attendance/list';
import SellInvoice from '../components/Common/SellInvoice/SellInvoice';
import PriceGroupView from '../pages/pricegroups/view';
import EstimateVSDemandView from '../pages/stock-vs-demand';
import PendingPaymentSellReport from '../pages/sqlsource/report/pending-payment-sell-report';
import OpenStockDownloadAndUpload from '../pages/open-stock/downloadandupload';
import BillPrintingInvoice from '../components/Common/BillPrinting';
import { AdjustmentInvoice } from '../components/Common/CustomResuableInvoice/AdjustmentInvoice';
import { TransferInvoice } from '../components/Common/CustomResuableInvoice/TransferInvoice';
import HRList from '../pages/hr/user-information/list';
import CropCalendar from '../pages/cropcalendar/view';
import POSCreate from '../pages/pos/create/create';
import { InvoiceForPOSSell } from '../components/Common/CustomResuableInvoice/POSSellInvoice';
import Tests from '../components/Common/Test';
import TotalLotsAndProducts from '../pages/products/totallots';
import CreateChannelReasons from '../pages/channel/reasons/create';
import ChannelReasonsList from '../pages/channel/reasons/list';
import EditChannelReasons from '../pages/channel/reasons/details';
import ApproveAdjustment from '../pages/adjustments/approve';
import ProductsSoldTotal from '../pages/products/soldtotal';
import ProductsPurchaseTotal from '../pages/products/purchasetotal';
import TransferReport from '../pages/sqlsource/report/transfer-report';
import WalletPayments from '../pages/customerwallet/list';
import WalletPaymentDetails from '../pages/customerwallet/details';
import UserLedgerSecondVersion from '../pages/users/Customers/Ledger/index2';
import VendorLedger from '../pages/users/Vendors/Ledger';
import ProfitCalculationReport from '../pages/sqlsource/report/profit-calculation-report';
import TopPurchaseProductReport from '../pages/sqlsource/report/top-purchase-product';
import TopSaleProductReport from '../pages/sqlsource/report/top-sale-product';
import WastageByProductReport from '../pages/sqlsource/report/wastage-by-product';
import CustomCustomerReport from '../pages/sqlsource/report/custom-customer-report';
import AllProductQuantityReport from '../pages/sqlsource/report/all-product-quantity-report';
import SellReturnInvoice from '../components/Common/CustomResuableInvoice/SellReturnInvoice';
import ExpenseList from '../pages/expense/list';
import CreateExpenseCategory from '../pages/expense/category/create';
import DetailsExpenseCategory from '../pages/expense/category/details';
import CreateExpense from '../pages/expense/create';
import ExpenseCategoryList from '../pages/expense/category/list';
import ExpenseView from '../pages/expense/view';
import LogsList from '../pages/logs/list';
import LogEventList from '../pages/logs/events/list';
import LogView from '../pages/logs/view';
import LogEventView from '../pages/logs/events/list/view';
import LogsBackup from '../pages/logs/backup/list';
import AllProductsSellReturnReport from '../pages/sqlsource/report/all-product-sell-return-report';
import ConvertLots from '../pages/products/lots/view/convert';
import TopSellProductsFromCustomer from '../pages/sqlsource/report/top-sell-products-from-customer';
import VehicleView from '../pages/vehicle/view';
import VehicleUsageList from '../pages/vehicle/vehicle-usage/list';
import CreateVehicleUsage from '../pages/vehicle/vehicle-usage/create';
import EditVehicleUsage from '../pages/vehicle/vehicle-usage/update';
import VehicleMaintenanceList from '../pages/vehicle/vehicles-maintenance/list';
import CreateVehicleMaintenance from '../pages/vehicle/vehicles-maintenance/create';
import VehicleFuelList from '../pages/vehicle/vehicle-fuel/list';
import CreateVehicleFuel from '../pages/vehicle/vehicle-fuel/create';
import VendorTypeList from '../pages/users/Vendors/Vendor-Types/lists';
import CreateVendorType from '../pages/users/Vendors/Vendor-Types/create';
import EditVendorType from '../pages/users/Vendors/Vendor-Types/update';
import AccountsList from '../pages/accounts/list';
import CreateAccounts from '../pages/accounts/create';
import DetailsAccounts from '../pages/accounts/details';
import JournalList from '../pages/accounts/journal/list';
import CreateJournal from '../pages/accounts/journal/create';
import JournalView from '../pages/accounts/journal/view';
import AccountsRuleList from '../pages/accounts/rule/list';
import CreateAccountsRule from '../pages/accounts/rule/create';
import DetailsAccountsRule from '../pages/accounts/rule/details';
import LedgerList from '../pages/accounts/ledger/list';
import POSCreateV2 from '../pages/posV2/create';
import FonepayList from '../pages/epay/fonepay/list';
import CreateFonepay from '../pages/epay/fonepay/create';
import DetailsFonepay from '../pages/epay/fonepay/details';
import CustomerBalanceList from '../pages/accounts/balance/customer';
import VendorBalanceList from '../pages/accounts/balance/vendor';
import VendorAuditLog from '../pages/ird/vendor-audit-log/lists';
import VendorLogEventList from '../pages/ird/vendor-audit-log/events';
import TrialBalanceList from '../pages/accounts/trial-balance/list';
import PurchaseRegisterList from '../pages/ird/purchase/purchase-register';
import SalesRegisterList from '../pages/ird/sales/sales-register';
import SalesReturnRegisterList from '../pages/ird/sales/sales-return-register';
import VatAccountList from '../pages/ird/vat-account/lists';
import PurchaseReturnRegisterList from '../pages/ird/purchase/purchase-return-register';
import MaterializedLists from '../pages/ird/materialized-view/lists';
import ProfitLossList from '../pages/accounts/profit-loss/list';
import BalanceSheetList from '../pages/accounts/balance-sheet/list';
import ProductsStatusList from '../pages/products/status/list';
import BankList from '../pages/bank/list';
import DetailsBank from '../pages/bank/details';
import CreateBank from '../pages/bank/create';
import PurchaseSellSummationReport from '../pages/sqlsource/report/purchase-sell-summation-report';
import BankView from '../pages/bank/view';
import MoneyList from '../pages/session/money/list';
import CreateMoney from '../pages/session/money/create';
import DetailsMoney from '../pages/session/money/details';
import SessionList from '../pages/session/list';
import PriceGroupDetails from '../pages/pricegroups/details';
import AccountsView from '../pages/accounts/view';
import { SellListContextComp } from '../pages/sell/list/context';
import { SellOrderListContextComp } from '../pages/sell/sell-order/list/context';
import { SellReturnListContextComp } from '../pages/sell/sell-return/list/context';
import { PurchaseListContextComp } from '../pages/purchases/lists/context';
import { PurchaseOrderListContextComp } from '../pages/purchases/purchase-order/list/context';
import { PurchaseReturnListContextComp } from '../pages/purchases/purchase-return/list/context';
import PreviousLotsList from '../pages/products/lots/view/previous-lots/list';
import CustomerGroupList from '../pages/offers/customer-group/list';
import CreateCustomerGroup from '../pages/offers/customer-group/create';
import DetailsCustomerGroup from '../pages/offers/customer-group/details';
import CustomerGroupView from '../pages/offers/customer-group/view';
import DiscountList from '../pages/offers/discount/list';
import CreateDiscount from '../pages/offers/discount/create';
import DiscountDetails from '../pages/offers/discount/details';
import DiscountCategoryList from '../pages/offers/discount-category/list';
import CreateDiscountCategory from '../pages/offers/discount-category/create';
import DiscountCategoryDetails from '../pages/offers/discount-category/details';
import DiscountProductList from '../pages/offers/discount-product/list';
import CreateDiscountProduct from '../pages/offers/discount-product/create';
import DiscountProductDetails from '../pages/offers/discount-product/details';
import RouteHistoryReport from '../pages/sqlsource/report/route-history';
import DailyRateList from '../pages/daily-rate/list';
import CreateDailyRate from '../pages/daily-rate/create';
import DetailsDailyRate from '../pages/daily-rate/details';
import TransferReject from '../pages/transfers/reject';
import StockHistoryReport from '../pages/sqlsource/report/stock-history';
import StockValueReport from '../pages/sqlsource/report/stock-value';
import CustomerNetSalesReport from '../pages/sqlsource/report/customer-net-sales';
import Archive from '../pages/products/archive';
import OpeningStockReport from '../pages/sqlsource/report/opening-stock-report';
import UserTypeList from '../pages/users/Users/User-Types/lists';
import CreateUserType from '../pages/users/Users/User-Types/create';
import EditUserType from '../pages/users/Users/User-Types/update';
import AllUserLive from '../pages/user-tracking/all-live';
import UserHistory from '../pages/user-tracking/history';
import NewJournalLinesList from '../pages/accounts/journal/newJournalLines/list';
import { MenuSetup } from '../pages/settings/menu';
import { AdminMenuList } from '../pages/dev-menu/list';
import { AdminCreateMenu } from '../pages/dev-menu/create';
import { AdminEditMenu } from '../pages/dev-menu/details';
import { MenuList } from '../pages/settings/menu-list/list';
import { MenuListDetails } from '../pages/settings/menu-list/details';
import { CreateMenuList } from '../pages/settings/menu-list/create';
import StockHistorySumamtionReport from '../pages/sqlsource/report/stock-history-summation';
import { TransferOrder } from '../pages/transfers/transfer-orders/list';
import { TransferOrderDetails } from '../pages/transfers/transfer-orders/details';
import { TransferOrderCreate } from '../pages/transfers/transfer-orders/create';
import TransferOrderView from '../pages/transfers/transfer-orders/view';
import { MergeAccounts } from '../pages/accounts/merge-accounts';
import UnitPriceReport from '@/pages/sqlsource/report/unit-price';
import AgentList from '@/pages/users/agents/list';
import CreateAgentPage from '@/pages/users/agents/create';
import EditAgentPage from '@/pages/users/agents/edit';
import ViewExpenseCategory from '@/pages/expense/category/view';
import SellsReport from '@/pages/sqlsource/report/sell-report';
import AccountFinancialYear from '@/pages/accounts/opening/list';
import CreateFinancialHistory from '@/pages/accounts/opening/create';
import ViewFinancialHistory from '@/pages/accounts/opening/view';
import CustomerRangeOfferPage from '@/pages/offers/customer-range/list';
import CustomerRangeCreate from '@/pages/offers/customer-range/create';
import UpdateCustomerDiscount from '@/pages/offers/customer-range/details';
import ViewCustomerDiscount from '@/pages/offers/customer-range/view';
import FinancialYearList from '@/pages/accounts/financial-year/list';
import FinancialYearCreate from '@/pages/accounts/financial-year/create';
import FinancialYearUpdate from '@/pages/accounts/financial-year/details';
import DetailedLedgerListV2 from '@/pages/accounts/journal/detailedLedger-2';
import AccountReceivePayable from '@/pages/accounts/receive-pay/v2';
import NotPaidSellReport from '@/pages/sqlsource/report/not-paid-sell-report';
import ExpiredProducts from '@/pages/products/expired';
import SellManualPricePage from '@/pages/sell/manual-price';
import ExpenseReport from '@/pages/expense/report';
import CustomizeDashboard from '@/pages/dashboard/v3/customize';
import DashboardV3 from '@/pages/dashboard/v3';
import AdjustmentLines from '@/pages/adjustments/lines/list';
import SellCustomReport from '@/pages/sqlsource/report/sell-custom-report';
import ItemWiseSellReport from '@/pages/sqlsource/report/item-wise-sell-report';
import ProductsRateReport from '@/pages/sqlsource/report/product-rate-report';
import ProducQtyPriceSumReport from '@/pages/sqlsource/report/product-qty-price-sum-report';
import ProductAveragePriceReport from '@/pages/sqlsource/report/product-daily-avg-price-report';
import ProductTotalByIdentifierReport from '@/pages/sqlsource/report/products-daily-identifier-wise-total';
import PurchaseAgentBillRange from '@/pages/purchases/agent-bill-range/list';
import CreatePurchaseBillDateRange from '@/pages/purchases/agent-bill-range/create';
import ViewPurchaseBillRange from '@/pages/purchases/agent-bill-range/view';
import PurchaseAgentBillCancel from '@/pages/purchases/agent-bill-range/cancel';
import AgentBillCancelList from '@/pages/purchases/agent-bill-range/cancel/list';
import ProductRateComparisonReport from '@/pages/sqlsource/report/product-price-comparison';
import AgentBillRangePurchaseList from '@/pages/purchases/agent-bill-range/purchase/list';
import ExistingClearStocksList from '@/pages/products/reset-stock/list';
import ClearAllStock from '@/pages/products/reset-stock/create';
import ViewClearStockDetails from '@/pages/products/reset-stock/view';
import VehicleDailyDistanceReport from '@/pages/sqlsource/report/vehicle-daily-distance-history';
import VehicleSumDistanceReport from '@/pages/sqlsource/report/vehicle-sum-distance-history';
import MoveAllAddressRoute from '@/pages/users/move-all-addresses-route';
import CreateUserV2 from '@/pages/users/Users/create/create-v2';
import CreateCustomerV2 from '@/pages/users/Customers/create/create_v2';
import SaleOverLakhReport from '@/pages/sqlsource/report/sale-over-lakh-report';
import PurchaseOverLakhReport from '@/pages/sqlsource/report/purchase-over-lakh-report';
import JournalLinesListv2 from '@/pages/accounts/journal/journalLines/list-v2';
import PriceAnalysisCreate from '@/pages/price-analysis/create';
import PriceAnalysisList from '@/pages/price-analysis/list';
import ViewPriceAnalysis from '@/pages/price-analysis/view';
import UpdatePriceAnalysis from '@/pages/price-analysis/update';
import ItemWiseSalesRegisterList from '@/pages/ird/item-wise/sales-register';
import ItemWiseSalesReturnRegisterList from '@/pages/ird/item-wise/sales-return-register';
import ItemWisePurchaseRegisterList from '@/pages/ird/item-wise/purchase-register';
import ItemWisePurchaseReturnRegisterList from '@/pages/ird/item-wise/purchase-return-register';
import UpdatePriceGroupByAnalysis from '@/pages/pricegroups/update_by_analysis';
import ScheduleListPage from '@/pages/schedule/list';
import CreateScheduler from '@/pages/schedule/create';
import UpdateScheduler from '@/pages/schedule/update';
import SchedularStatus from '@/pages/schedule/status';
import SchedularViewPage from '@/pages/schedule/view';
import CustomerPriceGroupList from '@/pages/pricegroups/customers-groups/list';
import CustomerPriceGroupCreate from '@/pages/pricegroups/customers-groups/create';
import CustomerPriceGroupUpdate from '@/pages/pricegroups/customers-groups/details';
import CustomerPriceGroupView from '@/pages/pricegroups/customers-groups/view';
import CustomerPriceGroupsDownload from '@/pages/pricegroups/customers-groups/download';
import CustomerPriceGroupsUpload from '@/pages/pricegroups/customers-groups/upload';
import HRHolidatList from '@/pages/hr/holiday/list';
import HRHolidayCreate from '@/pages/hr/holiday/create';
import HRHolidayUpdate from '@/pages/hr/holiday/update';
import HRGroupsList from '@/pages/hr/groups/list';
import CreateHRGroups from '@/pages/hr/groups/create';
import UpdateHRGroups from '@/pages/hr/groups/update';
import ViewHRGroups from '@/pages/hr/groups/view';
import HolidayAssignGroupsPage from '@/pages/hr/holiday/assign';
import ViewHoliday from '@/pages/hr/holiday/view';
import HRLeavesList from '@/pages/hr/leaves/list';
import CreateLeaves from '@/pages/hr/leaves/create';
import UpdateLeaves from '@/pages/hr/leaves/update';
import ViewLeaves from '@/pages/hr/leaves/view';
import HRShiftList from '@/pages/hr/shift/list';
import ViewShiftPage from '@/pages/hr/shift/view';
import CreateShiftPage from '@/pages/hr/shift/create';
import UpdateShiftPage from '@/pages/hr/shift/update';
import AssignShiftPage from '@/pages/hr/shift/assign';
import AttendanceListPage from '@/pages/hr/attendance/list';
import SelfAttendanceListPage from '@/pages/hr/attendance/self';
import CreateAttendance from '@/pages/hr/attendance/create';
import UpdateAttendance from '@/pages/hr/attendance/update';
import HRUserInfoCreate from '@/pages/hr/user-information/create';
import HRUserInfoDetails from '@/pages/hr/user-information/details';
import HRUserInfoView from '@/pages/hr/user-information/view';
import PayrollList from '@/pages/hr/payroll/list';
import CreatePayroll from '@/pages/hr/payroll/create';
import UpdatePayroll from '@/pages/hr/payroll/update';
import PayrollView from '@/pages/hr/payroll/view';
import HRSelfCalendar from '@/pages/hr/calendar/self';
import HRUserCalendar from '@/pages/hr/calendar/user';
import LastIdentifierReport from '@/pages/sqlsource/report/last-identifier-report';
import LogSupportList from '@/pages/logs/support/list';
import UpdateLogSupport from '@/pages/logs/support/update';
import OfferExcludedCustomerUpdate from '@/pages/offers/excluded-customer/details';
import OfferExcludedCustomerList from '@/pages/offers/excluded-customer/list';
import ProductHiddenStatus from '@/pages/products/hidden-status';
import LatestPurchaseLineReport from '@/pages/sqlsource/report/latest-purchase-line-report';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/test"
        element={
          <Private>
            <Tests />
          </Private>
        }
      />
      <Route
        path="/crop-calendar"
        element={
          <Private>
            <CropCalendar />
          </Private>
        }
      />

      <Route
        path="sell/possell-create"
        element={
          <Private withoutmainlayout={true}>
            <POSCreate />
          </Private>
        }
      />
      <Route
        path="sell/pos-create-v2"
        element={
          <Private withoutmainlayout={true} type="CREATE_POS">
            <POSCreateV2 />
          </Private>
        }
      />

      <Route
        path="/sell-estimate-vs-purchase-demand"
        element={
          <Private>
            <EstimateVSDemandView />
          </Private>
        }
      />
      <Route
        path="/attendance"
        element={
          <Private>
            <AttendanceList />
          </Private>
        }
      />
      <Route
        path="/price-groups"
        element={
          <Private>
            <PriceGroupListing />
          </Private>
        }
      />
      <Route
        path="/price-groups/:id"
        element={
          <Private>
            <PriceGroupView />
          </Private>
        }
      />
      <Route
        path="/price-groups/details/:id"
        element={
          <Private>
            <PriceGroupDetails />
          </Private>
        }
      />

      <Route
        path="/price-groups/analysis/:id"
        element={
          <Private type="UPDATE_PRICE_GROUP">
            <UpdatePriceGroupByAnalysis />
          </Private>
        }
      />

      <Route
        path="/settings/global"
        element={
          <Private type="ADMIN">
            <GlobalSettings />
          </Private>
        }
      />

      <Route
        path="/open-stock"
        element={
          <Private>
            <CreateOpeningStock />
          </Private>
        }
      />

      <Route
        path="/open-stock-download-upload"
        element={
          <Private>
            <OpenStockDownloadAndUpload />
          </Private>
        }
      />

      <Route
        path="/404"
        element={
          <Public>
            {/* <div>Unavailable</div> */}
            <ErrorPage />
          </Public>
        }
      />
      {/*Vendor Products*/}

      <Route
        path="/vendor-products"
        element={
          <Private>
            <VendorProductsList />
          </Private>
        }
      />
      <Route
        path="/vendor-products/new"
        element={
          <Private>
            <CreateVendorProducts />
          </Private>
        }
      />
      {/* <Route
        path="/vendor-products/view/:id"
        element={
          <Private>
            <ViewVendorProducts />
          </Private>
        }
      /> */}
      <Route
        path="/vendor-products/:id"
        element={
          <Private>
            <EditVendorProducts />
          </Private>
        }
      />
      {/*Vehicle*/}

      <Route
        path="/vehicle"
        element={
          <Private>
            <VehicleList />
          </Private>
        }
      />
      <Route
        path="/vehicle/new"
        element={
          <Private>
            <CreateVehicle />
          </Private>
        }
      />
      <Route
        path="/vehicle/:id"
        element={
          <Private>
            <EditVehicle />
          </Private>
        }
      />
      <Route
        path="/vehicle/live/:id"
        element={
          <Private>
            <VehicleLive />
          </Private>
        }
      />
      <Route
        path="/vehicle/history/:id"
        element={
          <Private>
            <VehicleHistory />
          </Private>
        }
      />
      <Route
        path="/vehicle/all-live"
        element={
          <Private>
            <AllVehicleLive />
          </Private>
        }
      />
      <Route
        path="/vehicle/view/:id"
        element={
          <Private>
            <VehicleView />
          </Private>
        }
      />
      <Route
        path="/vehicle-usage"
        element={
          <Private>
            <VehicleUsageList />
          </Private>
        }
      />
      <Route
        path="/vehicle-usage/?vehicleId=:vehicleId"
        element={
          <Private>
            <VehicleUsageList />
          </Private>
        }
      />
      <Route
        path="/vehicle-usage/update/:id"
        element={
          <Private>
            <EditVehicleUsage />
          </Private>
        }
      />
      <Route
        path="/vehicle-usage/new"
        element={
          <Private>
            <CreateVehicleUsage />
          </Private>
        }
      />
      <Route
        path="/vehicle-maintenance"
        element={
          <Private>
            <VehicleMaintenanceList />
          </Private>
        }
      />
      <Route
        path="/vehicle-maintenance/?vehicleId=:vehicleId"
        element={
          <Private>
            <VehicleMaintenanceList />
          </Private>
        }
      />

      <Route
        path="/vehicle-maintenance/new"
        element={
          <Private>
            <CreateVehicleMaintenance />
          </Private>
        }
      />
      <Route
        path="/vehicle-fuel"
        element={
          <Private>
            <VehicleFuelList />
          </Private>
        }
      />
      <Route
        path="/vehicle-fuel/?vehicleId=:vehicleId"
        element={
          <Private>
            <VehicleFuelList />
          </Private>
        }
      />
      <Route
        path="/vehicle-fuel/new"
        element={
          <Private>
            <CreateVehicleFuel />
          </Private>
        }
      />

      {/* User-Tracking */}
      <Route
        path="/users/all-live"
        element={
          <Private>
            <AllUserLive />
          </Private>
        }
      />
      <Route
        path="/users/history"
        element={
          <Private>
            <UserHistory />
          </Private>
        }
      />

      {/*Pay*/}
      <Route
        path="/pay/add"
        element={
          <Private>
            <AddPayment />
          </Private>
        }
      />
      <Route
        path="/wallet-payments"
        element={
          <Private type="READ_WALLET">
            <WalletPayments />
          </Private>
        }
      />

      <Route
        path="/wallet-payments/:id"
        element={
          <Private type="READ_WALLET">
            <WalletPaymentDetails />
          </Private>
        }
      />

      <Route
        path="/settings/menu"
        element={
          <Private type="ADMIN">
            <MenuSetup />
          </Private>
        }
      />
      <Route
        path="/admin/menu"
        element={
          <Private type="ADMIN">
            <AdminMenuList />
          </Private>
        }
      />

      <Route
        path="/admin/menu/new"
        element={
          <Private type="ADMIN">
            <AdminCreateMenu />
          </Private>
        }
      />
      <Route
        path="/admin/menu/:id"
        element={
          <Private type="ADMIN">
            <AdminEditMenu />
          </Private>
        }
      />

      <Route
        path="/settings/menu-list/create"
        element={
          <Private>
            <CreateMenuList />
          </Private>
        }
      />

      <Route
        path="/settings/menu-list"
        element={
          <Private>
            <MenuList />
          </Private>
        }
      />

      <Route
        path="/settings/menu-list/:id"
        element={
          <Private type="ADMIN">
            <MenuListDetails />
          </Private>
        }
      />

      {/*Invoice*/}
      <Route path="/custom-invoice" element={<CustomResuableInvoice />} />
      <Route path="/custom-invoice-sellandsellorder" element={<InvoiceForSellandSellOrder />} />
      <Route path="/invoicessellroute" element={<InvoiceForProductAndRoute />} />
      <Route path="/multiple-invoice" element={<PrintMultipleInvoice />} />
      <Route path="/productprintinvoice" element={<ProductPrintInvoice />} />
      <Route path="/invoice-sell" element={<SellInvoice />} />
      <Route path="/invoice-sell-return" element={<SellReturnInvoice />} />
      <Route path="billprint" element={<BillPrintingInvoice />} />
      <Route path="adjustmentinvoice" element={<AdjustmentInvoice />} />
      <Route path="transferinvoice" element={<TransferInvoice />} />
      <Route path="POSS-Sellinvoice" element={<InvoiceForPOSSell />} />
      {/* Estimation*/}
      <Route
        path="/estimate/procurement"
        element={
          <Private>
            <ProcurementEstimation />
          </Private>
        }
      />
      <Route
        path="/estimate/sell"
        element={
          <Private>
            <SellEstimation />
          </Private>
        }
      />
      {/* ACL*/}

      <Route
        path="/acl"
        element={
          <Private>
            <ACLList />
          </Private>
        }
      />
      <Route
        path="/acl/new"
        element={
          <Private>
            <CreateACL />
          </Private>
        }
      />
      <Route
        path="/acl/:id"
        element={
          <Private>
            <EditACL />
          </Private>
        }
      />
      <Route
        path="/acl/user/:id"
        element={
          <Private>
            <UserACL />
          </Private>
        }
      />
      {/* price groups */}
      <Route
        path="/price-groups-new"
        element={
          <Private type={'CREATE_PRICE_GROUP'}>
            <PriceGroupCreate />
          </Private>
        }
      />
      <Route
        path="/price-groups-upload"
        element={
          <Private type={'CREATE_PRICE_GROUP'}>
            <PriceGroupsUpload />
          </Private>
        }
      />
      <Route
        path="/price-groups-download"
        element={
          <Private type={'READ_PRICE_GROUP'}>
            <PriceGroupsDownload />
          </Private>
        }
      />
      {/* reports */}

      <Route
        path="/reports"
        element={
          <Private>
            <ReportList />
          </Private>
        }
      />
      <Route
        path="/reports/customer"
        element={
          <Private>
            <CustomerReport />
          </Private>
        }
      />
      <Route
        path="/reports/vehicle-daily-distance"
        element={
          <Private>
            <VehicleDailyDistanceReport />
          </Private>
        }
      />
      <Route
        path="/reports/vehicle-sum-distance"
        element={
          <Private>
            <VehicleSumDistanceReport />
          </Private>
        }
      />
      <Route
        path="/reports/product-rate"
        element={
          <Private>
            <ProductsRateReport />
          </Private>
        }
      />

      <Route
        path="/reports/product-qty-and-price-sum-report"
        element={
          <Private>
            <ProducQtyPriceSumReport />
          </Private>
        }
      />
      <Route
        path="/reports/product-daily-avg-price-report"
        element={
          <Private>
            <ProductAveragePriceReport />
          </Private>
        }
      />
      <Route
        path="/reports/products-daily-identifier-wise-total"
        element={
          <Private>
            <ProductTotalByIdentifierReport />
          </Private>
        }
      />

      <Route
        path="/reports/user"
        element={
          <Private>
            <UserReport />
          </Private>
        }
      />
      <Route
        path="/reports/vendor"
        element={
          <Private>
            <VendorReport />
          </Private>
        }
      />
      <Route
        path="/reports/address"
        element={
          <Private>
            <AddressReport />
          </Private>
        }
      />
      <Route
        path="/reports/purchase-order-line"
        element={
          <Private>
            <PurchaseOrderLineReport />
          </Private>
        }
      />
      <Route
        path="/reports/purchase-return-line"
        element={
          <Private>
            <PurchaseReturnReport />
          </Private>
        }
      />
      <Route
        path="/reports/purchase-line"
        element={
          <Private>
            <PurchaseLineReport />
          </Private>
        }
      />
      <Route
        path="/reports/sale-order-line"
        element={
          <Private>
            <SaleOrderLineReport />
          </Private>
        }
      />
      <Route
        path="/reports/sale-line"
        element={
          <Private>
            <SaleLineReport />
          </Private>
        }
      />
      <Route
        path="/reports/sale-return-line"
        element={
          <Private>
            <SaleReturnLineReport />
          </Private>
        }
      />
      <Route
        path="/reports/transfer-in-line"
        element={
          <Private>
            <TransferInLineReport />
          </Private>
        }
      />
      <Route
        path="/reports/transfers"
        element={
          <Private>
            <TransferReport />
          </Private>
        }
      />

      <Route
        path="/reports/sell-custom"
        element={
          <Private>
            <SellCustomReport />
          </Private>
        }
      />

      <Route
        path="/reports/item-wise-sell"
        element={
          <Private>
            <ItemWiseSellReport />
          </Private>
        }
      />

      <Route
        path="/reports/opening-stock"
        element={
          <Private>
            <OpeningStockReport />
          </Private>
        }
      />
      <Route
        path="/reports/stock-history"
        element={
          <Private>
            <StockHistoryReport />
          </Private>
        }
      />
      <Route
        path="/reports/stock-history-summation"
        element={
          <Private>
            <StockHistorySumamtionReport />
          </Private>
        }
      />
      <Route
        path="/reports/transfer-out-line"
        element={
          <Private>
            <TransferOutLineReport />
          </Private>
        }
      />
      <Route
        path="/reports/adjustment-line"
        element={
          <Private>
            <AdjustmentLineReport />
          </Private>
        }
      />
      <Route
        path="/reports/total-purchase"
        element={
          <Private>
            <TotalPurchaseReport />
          </Private>
        }
      />
      <Route
        path="/reports/total-sale"
        element={
          <Private>
            <TotalSaleReport />
          </Private>
        }
      />
      <Route
        path="/reports/purchase-order-product-identifier"
        element={
          <Private>
            <PurchaseOrderProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/purchase-return-product-identifier"
        element={
          <Private>
            <PurchaseReturnProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/purchase-product-identifier"
        element={
          <Private>
            <PurchaseProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/sale-order-product-identifier"
        element={
          <Private>
            <SaleOrderProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/custom-customer"
        element={
          <Private>
            <CustomCustomerReport />
          </Private>
        }
      />
      <Route
        path="/reports/route-history"
        element={
          <Private>
            <RouteHistoryReport />
          </Private>
        }
      />
      <Route
        path="/reports/all-product-quantity"
        element={
          <Private>
            <AllProductQuantityReport />
          </Private>
        }
      />
      <Route
        path="/reports/sale-product-identifier"
        element={
          <Private>
            <SaleProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/pending-payment-sell"
        element={
          <Private>
            <PendingPaymentSellReport />
          </Private>
        }
      />

      <Route
        path="/reports/sale-return-product-identifier"
        element={
          <Private>
            <SaleReturnProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/top-sell-products-from-customer"
        element={
          <Private>
            <TopSellProductsFromCustomer />
          </Private>
        }
      />

      <Route
        path="/reports/transfer-in-product-identifier"
        element={
          <Private>
            <TransferInProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/adjustment"
        element={
          <Private>
            <AdjustmentReport />
          </Private>
        }
      />
      <Route
        path="/reports/transfer-out-product-identifier"
        element={
          <Private>
            <TransferOutProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/adjustment-product-identifier"
        element={
          <Private>
            <AdjustmentProductIdentifierReport />
          </Private>
        }
      />
      <Route
        path="/reports/product-history"
        element={
          <Private>
            <ProductHistoryRangeReport />
          </Private>
        }
      />
      {/* <Route
        path="/reports/sell"
        element={
          <Private>
            <SellReport />
          </Private>
        }
      /> */}
      <Route
        path="/reports/payment-sell"
        element={
          <Private>
            <PaymentSellReport />
          </Private>
        }
      />
      <Route
        path="/reports/not-paid-sell"
        element={
          <Private>
            <NotPaidSellReport />
          </Private>
        }
      />
      <Route
        path="/reports/purchase-sell-summation"
        element={
          <Private>
            <PurchaseSellSummationReport />
          </Private>
        }
      />
      <Route
        path="/reports/payment-purchase"
        element={
          <Private>
            <PaymentPurchaseReport />
          </Private>
        }
      />
      <Route
        path="/reports/stock-adjustment"
        element={
          <Private>
            <StockAdjustmentReport />
          </Private>
        }
      />
      <Route
        path="/reports/stock-value"
        element={
          <Private>
            <StockValueReport />
          </Private>
        }
      />
      <Route
        path="/reports/total-sale-and-purchase"
        element={
          <Private>
            <TotalPurchaseAndSaleReport />
          </Private>
        }
      />
      <Route
        path="/reports/product-purchase"
        element={
          <Private>
            <ProductPurchaseReport />
          </Private>
        }
      />
      <Route
        path="/reports/product-sell"
        element={
          <Private>
            <ProductSellReport />
          </Private>
        }
      />
      <Route
        path="/reports/full-day-report"
        element={
          <Private>
            <FullDayReport />
          </Private>
        }
      />
      <Route
        path="/reports/sales-representative-report"
        element={
          <Private>
            <SellRepresentativeReport />
          </Private>
        }
      />
      <Route
        path="/reports/profit-calculation"
        element={
          <Private>
            <ProfitCalculationReport />
          </Private>
        }
      />
      <Route
        path="/reports/top-purchase-product"
        element={
          <Private>
            <TopPurchaseProductReport />
          </Private>
        }
      />
      <Route
        path="/reports/top-sale-product"
        element={
          <Private>
            <TopSaleProductReport />
          </Private>
        }
      />
      <Route
        path="/reports/wastage-by-product"
        element={
          <Private>
            <WastageByProductReport />
          </Private>
        }
      />

      <Route
        path="/reports/customer-supplier-report"
        element={
          <Private>
            <CustomerSupplierReport />
          </Private>
        }
      />

      <Route
        path="/reports/unit-price"
        element={
          <Private>
            <UnitPriceReport />
          </Private>
        }
      />
      <Route
        path="/reports/sells"
        element={
          <Private>
            <SellsReport />
          </Private>
        }
      />
      <Route
        path="/reports/customer-net-sales"
        element={
          <Private>
            <CustomerNetSalesReport />
          </Private>
        }
      />
      <Route
        path="/reports/product-rate-comparison"
        element={
          <Private>
            <ProductRateComparisonReport />
          </Private>
        }
      />

      <Route
        path="/reports/sale-over-lakh"
        element={
          <Private>
            <SaleOverLakhReport />
          </Private>
        }
      />

      <Route
        path="/reports/purchase-over-lakh"
        element={
          <Private>
            <PurchaseOverLakhReport />
          </Private>
        }
      />

      {/* <Route
        path="/reports/product-sale-purchase-price-comparison"
        element={
          <Private>
            <ProductSalePurchasePriceComparision />
          </Private>
        }
      /> */}
      <Route
        path="/reports/all-product-sell-return-report"
        element={
          <Private>
            <AllProductsSellReturnReport />
          </Private>
        }
      />
      <Route
        path="/source"
        element={
          <Private>
            <SourceList />
          </Private>
        }
      />
      <Route
        path="/source/new"
        element={
          <Private>
            <CreateSource />
          </Private>
        }
      />
      <Route
        path="/source/:id"
        element={
          <Private>
            <EditSource />
          </Private>
        }
      />
      <Route
        path="/channel"
        element={
          <Private type={'READ_CHANNEL'}>
            <ChannelsList />
          </Private>
        }
      />
      <Route
        path="/channel/new"
        element={
          <Private type={'CREATE_CHANNEL'}>
            <CreateChannel />
          </Private>
        }
      />
      <Route
        path="/channel/:id"
        element={
          <Private type={'READ_CHANNEL'}>
            <EditChannel />
          </Private>
        }
      />
      <Route
        path="/channel/room/:id"
        element={
          <Private type={'READ_CHANNEL'}>
            <Room />
          </Private>
        }
      />
      <Route
        path="/channel/closed"
        element={
          <Private type={'READ_CHANNEL'}>
            <ClosedChannelList />
          </Private>
        }
      />

      <Route
        path="channel/reasons/new"
        element={
          <Private type={'CREATE_REASON'}>
            <CreateChannelReasons />
          </Private>
        }
      />
      <Route
        path="channel/reasons"
        element={
          <Private type={'READ_REASON'}>
            <ChannelReasonsList />
          </Private>
        }
      />
      <Route
        path="channel/reasons/:id"
        element={
          <Private type={'UPDATE_REASON'}>
            <EditChannelReasons />
          </Private>
        }
      />
      <Route
        path="/transfers"
        element={
          <Private type={'READ_TRANSFER'}>
            <TransfersList />
          </Private>
        }
      />
      <Route
        path="/transfer/order"
        element={
          <Private type={'READ_TRANSFER_ORDER'}>
            <TransferOrder />
          </Private>
        }
      />
      <Route
        path="/transfer/order/new"
        element={
          <Private type={'CREATE_TRANSFER_ORDER'}>
            <TransferOrderCreate />
          </Private>
        }
      />
      <Route
        path="/transfer/order/edit/:id"
        element={
          <Private type={'CREATE_TRANSFER_ORDER'}>
            <TransferOrderDetails />
          </Private>
        }
      />
      <Route
        path="/transfer/order/view/:id"
        element={
          <Private type={'READ_TRANSFER_ORDER'}>
            <TransferOrderView />
          </Private>
        }
      />
      <Route
        path="/transfer-in"
        element={
          <Private type={'READ_TRANSFER'}>
            <TransfersInList />
          </Private>
        }
      />
      <Route
        path="/transfer-in/:id"
        element={
          <Private type={'READ_TRANSFER'}>
            <EditTransfer />
          </Private>
        }
      />
      <Route
        path="/transfers/new"
        element={
          <Private type={'CREATE_TRANSFER'}>
            <CreateTransfer />
          </Private>
        }
      />
      <Route
        path="/transfers/view/:id"
        element={
          <Private type={'READ_TRANSFER'}>
            <TransfersView />
          </Private>
        }></Route>
      <Route
        path="/transfer-reject/:id"
        element={
          <Private type={'APPROVE_TRANSFER'}>
            <TransferReject />
          </Private>
        }
      />
      <Route
        path="/sell"
        element={
          <Private type={'READ_SELL'}>
            <SellListContextComp />
          </Private>
        }
      />
      <Route
        path="/sell/manual-price"
        element={
          <Private type={'CREATE_PRICE_GROUP'}>
            <SellManualPricePage />
          </Private>
        }
      />
      <Route
        path="/sell/order-vs-current-stock"
        element={
          <Private>
            <SellOrderCurrentStockView />
          </Private>
        }
      />
      <Route
        path="/sell/:id"
        element={
          <Private type={'READ_SELL'}>
            <SellView />
          </Private>
        }
      />
      <Route
        path="/sell/order/new"
        element={
          <Private type={'CREATE_SELL_ORDER'}>
            <CreateSellOrder />
          </Private>
        }
      />
      <Route
        path="/sell/order/:id"
        element={
          <Private type={'UPDATE_SELL_ORDER'}>
            <SellOrderDetails />
          </Private>
        }
      />
      <Route
        path="/sell/order/view/:id"
        element={
          <Private type={'READ_SELL_ORDER'}>
            <SellOrderView />
          </Private>
        }
      />
      <Route
        path="/sell/order"
        element={
          <Private type={'READ_SELL_ORDER'}>
            <SellOrderListContextComp />
          </Private>
        }
      />
      <Route
        path="/sell/new"
        element={
          <Private type={'CREATE_SELL'}>
            <CreateSell />
          </Private>
        }
      />
      <Route
        path="sell/return"
        element={
          <Private type={'READ_SELL_RETURN'}>
            <SellReturnListContextComp />
          </Private>
        }
      />
      <Route
        path="sell/return/:id"
        element={
          <Private type={'READ_SELL_RETURN'}>
            <SellReturn />
          </Private>
        }
      />

      <Route
        path="sell/sell-list"
        element={
          <Private type={'READ_SELL'}>
            {/* <ProductPurchaseList /> */}
            {/* <PurchaseOrderFormCreate /> */}
            <SellOrderFormCreate />
          </Private>
        }
      />
      <Route
        path="sell/sell-list/view"
        element={
          <Private type={'READ_SELL'}>
            {/* <ProductPurchaseView /> */}
            <ProductSellOrderView />
          </Private>
        }
      />
      <Route
        path="/products/lots"
        element={
          <Private type={'READ_PRODUCT_LOTS'}>
            <LotsList />
          </Private>
        }
      />
      <Route
        path="/products/previous-lots"
        element={
          <Private type={'READ_PRODUCT_LOTS'}>
            <PreviousLotsList />
          </Private>
        }
      />
      <Route
        path="/products/totallots"
        element={
          <Private type={'READ_PRODUCT_LOTS'}>
            <TotalLotsAndProducts />
          </Private>
        }
      />
      <Route
        path="products/convertlots"
        element={
          <Private type={'CONVERT_LOTS'}>
            <ConvertLots />
          </Private>
        }
      />
      <Route
        path="test"
        element={
          <Private>
            <LayoutToUI />
          </Private>
        }
      />
      <Route
        path="routes"
        element={
          <Private type={'READ_ROUTE'}>
            <RoutesList />
          </Private>
        }
      />
      <Route
        path="routes/new"
        element={
          <Private type={'CREATE_ROUTE'}>
            <CreateRoutes />
          </Private>
        }
      />
      <Route
        path="routes/:id"
        element={
          <Private type={'UPDATE_ROUTE'}>
            <RoutesDetails />
          </Private>
        }
      />
      <Route
        path="offer"
        element={
          <Private type={'READ_OFFER'}>
            <OfferList />
          </Private>
        }
      />
      <Route
        path="offer/upload"
        element={
          <Private type={'CREATE_OFFER'}>
            {/* <Offeruploadanddownload /> */}
            <Offerupload />
          </Private>
        }
      />

      <Route
        path="offer/new"
        element={
          <Private type={'CREATE_OFFER'}>
            <CreateOffer />
          </Private>
        }
      />
      <Route
        path="offer/:id"
        element={
          <Private type={'UPDATE_OFFER'}>
            <OfferDetails />
          </Private>
        }
      />

      <Route
        path="offer/excluded-customers"
        element={
          <Private type={'READ_DISCOUNT'}>
            <OfferExcludedCustomerList />
          </Private>
        }
      />

      <Route
        path="offer/excluded-customers/details"
        element={
          <Private type={'UPDATE_DISCOUNT'}>
            <OfferExcludedCustomerUpdate />
          </Private>
        }
      />

      <Route
        path="offer/customer-discount"
        element={
          <Private type={'READ_DISCOUNT'}>
            <CustomerRangeOfferPage />
          </Private>
        }
      />
      <Route
        path="offer/customer-discount/create"
        element={
          <Private type={'CREATE_DISCOUNT'}>
            <CustomerRangeCreate />
          </Private>
        }
      />
      <Route
        path="offer/customer-discount/:id"
        element={
          <Private type={'UPDATE_DISCOUNT'}>
            <UpdateCustomerDiscount />
          </Private>
        }
      />

      <Route
        path="offer/customer-discount/view/:id"
        element={
          <Private type={'READ_DISCOUNT'}>
            <ViewCustomerDiscount />
          </Private>
        }
      />
      <Route
        path="offer/customer-group"
        element={
          <Private type={'READ_CUSTOMER_GROUP'}>
            <CustomerGroupList />
          </Private>
        }
      />
      <Route
        path="offer/customer-group/new"
        element={
          <Private type={'CREATE_CUSTOMER_GROUP'}>
            <CreateCustomerGroup />
          </Private>
        }
      />
      <Route
        path="offer/customer-group/:id"
        element={
          <Private type={'UPDATE_CUSTOMER_GROUP'}>
            <DetailsCustomerGroup />
          </Private>
        }
      />
      <Route
        path="offer/customer-group/view/:id"
        element={
          <Private type={'READ_CUSTOMER_GROUP'}>
            <CustomerGroupView />
          </Private>
        }
      />
      <Route
        path="offer/discount"
        element={
          <Private type={'READ_DISCOUNT'}>
            <DiscountList />
          </Private>
        }
      />
      <Route
        path="offer/discount/new"
        element={
          <Private type={'CREATE_DISCOUNT'}>
            <CreateDiscount />
          </Private>
        }
      />
      <Route
        path="offer/discount/:id"
        element={
          <Private type={'UPDATE_DISCOUNT'}>
            <DiscountDetails />
          </Private>
        }
      />
      <Route
        path="offer/discount-category"
        element={
          <Private type={'READ_DISCOUNT'}>
            <DiscountCategoryList />
          </Private>
        }
      />
      <Route
        path="offer/discount-category/new"
        element={
          <Private type={'CREATE_DISCOUNT'}>
            <CreateDiscountCategory />
          </Private>
        }
      />
      <Route
        path="offer/discount-category/:id"
        element={
          <Private type={'UPDATE_DISCOUNT'}>
            <DiscountCategoryDetails />
          </Private>
        }
      />
      <Route
        path="offer/discount-product"
        element={
          <Private type={'READ_DISCOUNT'}>
            <DiscountProductList />
          </Private>
        }
      />
      <Route
        path="offer/discount-product/new"
        element={
          <Private type={'CREATE_DISCOUNT'}>
            <CreateDiscountProduct />
          </Private>
        }
      />
      <Route
        path="offer/discount-product/:id"
        element={
          <Private type={'UPDATE_DISCOUNT'}>
            <DiscountProductDetails />
          </Private>
        }
      />

      <Route
        path="/"
        element={
          <Private>
            {/* <Dashboard /> */}
            <DashboardV3 />
          </Private>
        }
      />
      <Route
        path="/customize"
        element={
          <Private>
            <CustomizeDashboard />
          </Private>
        }
      />

      {/* <Route
        path="/dashboard/custom/new"
        element={
          <Private>
            <CreateCustomDashboard />
          </Private>
        }
      /> */}
      <Route
        path="products"
        element={
          <Private type={'READ_PRODUCT'}>
            <ProductsList />
          </Private>
        }
      />
      <Route
        path="products/archive"
        element={
          <Private type={'READ_PRODUCT'}>
            <Archive />
          </Private>
        }
      />

      <Route
        path="products/status"
        element={
          <Private type={'UPDATE_PRODUCT'}>
            <ProductHiddenStatus />
          </Private>
        }
      />

      <Route
        path="purchases/agents-bill-range"
        element={
          <Private>
            <PurchaseAgentBillRange />
          </Private>
        }
      />
      <Route
        path="purchases/agents-bill-cancel"
        element={
          <Private>
            <AgentBillCancelList />
          </Private>
        }
      />

      <Route
        path="purchases/agents-bill-purchase"
        element={
          <Private>
            <AgentBillRangePurchaseList />
          </Private>
        }
      />

      <Route
        path="purchases/agents-bill-range/new"
        element={
          <Private>
            <CreatePurchaseBillDateRange />
          </Private>
        }
      />

      <Route
        path="purchases/agents-bill-range/cancel/:id"
        element={
          <Private>
            <PurchaseAgentBillCancel />
          </Private>
        }
      />

      <Route
        path="purchases/agents-bill-range/view/:id"
        element={
          <Private>
            <ViewPurchaseBillRange />
          </Private>
        }
      />

      {/* <Route
        path="purchases/agents-bill-range/:id"
        element={
          <Private>
            <UpdatePurchaseBillRange />
          </Private>
        }
      /> */}

      <Route
        path="purchase/purchase-list"
        element={
          <Private type={'READ_PURCHASE'}>
            {/* <ProductPurchaseList /> */}
            <PurchaseOrderFormCreate />
          </Private>
        }
      />
      <Route
        path="purchase/purchase-list/view"
        element={
          <Private type={'READ_PURCHASE'}>
            <ProductPurchaseView />
          </Private>
        }
      />

      <Route
        path="products/category"
        element={
          <Private type={'READ_PRODUCT_CATEGORY'}>
            <CategoryList />
          </Private>
        }
      />
      <Route
        path="products/category/new"
        element={
          <Private type={'CREATE_PRODUCT_CATEGORY'}>
            <CreateCategory />
          </Private>
        }
      />
      <Route
        path="products/category/:id"
        element={
          <Private type={'UPDATE_PRODUCT_CATEGORY'}>
            <DetailsCategory />
          </Private>
        }
      />
      <Route
        path="procurements"
        element={
          <Private>
            <CreateProcurement />
          </Private>
        }
      />
      <Route
        path="purchase"
        element={
          <Private type={'READ_PURCHASE'}>
            <PurchaseListContextComp />
          </Private>
        }
      />
      <Route
        path="purchase/order"
        element={
          <Private type={'READ_PURCHASE_ORDER'}>
            <PurchaseOrderListContextComp />
          </Private>
        }
      />
      <Route
        path="purchase/return"
        element={
          <Private>
            <PurchaseReturnListContextComp />
          </Private>
        }
      />
      <Route
        path="purchase/return/:id"
        element={
          <Private>
            {/* <PurchaseReturn /> */}
            <PurchaseReturnV2 />
          </Private>
        }
      />
      <Route
        path="purchase/order/new"
        element={
          <Private type={'CREATE_PURCHASE_ORDER'}>
            <CreatePurchaseOrder />
          </Private>
        }
      />
      <Route
        path="purchase/order/:id"
        element={
          <Private type={'UPDATE_PURCHASE_ORDER'}>
            <PurchaseOrderDetails />
          </Private>
        }
      />
      <Route
        path="purchase/order/view/:id"
        element={
          <Private type={'READ_PURCHASE_ORDER'}>
            <PurchaseOrderView />
          </Private>
        }
      />
      <Route
        path="purchase/:id"
        element={
          <Private type={'READ_PURCHASE'}>
            <PurchaseDetails />
          </Private>
        }
      />
      <Route
        path="products/new"
        element={
          <Private type={'CREATE_PRODUCT'}>
            <CreateProduct />
          </Private>
        }
      />

      <Route
        path="products/expired"
        element={
          <Private type={'READ_PRODUCT'}>
            <ExpiredProducts />
          </Private>
        }
      />

      <Route
        path="products/:id"
        element={
          <Private type={'UPDATE_PRODUCT'}>
            <ProductDetails />
          </Private>
        }
      />
      <Route
        path="users"
        element={
          <Private type={'READ_USER'}>
            <UsersList />
          </Private>
        }
      />

      <Route
        path="users/agents"
        element={
          <Private type={'READ_USER'}>
            <AgentList />
          </Private>
        }
      />

      <Route
        path="routes/move-all-addresses/:id"
        element={
          <Private role="ADMIN">
            <MoveAllAddressRoute />
          </Private>
        }
      />

      <Route
        path="users/agents/new"
        element={
          <Private type={'READ_USER'}>
            <CreateAgentPage />
          </Private>
        }
      />

      <Route
        path="users/agents/:id"
        element={
          <Private type={'UPDATE_USER'}>
            <EditAgentPage />
          </Private>
        }
      />

      <Route
        path="ledger/:id"
        element={
          <Private>
            {/* <UserLedger /> */}
            <UserLedgerSecondVersion />
          </Private>
        }
      />

      <Route
        path="ledger/vendor/:id"
        element={
          <Private>
            {/* <UserLedger /> */}
            {/* <UserLedgerSecondVersion /> */}
            <VendorLedger />
          </Private>
        }
      />

      <Route
        path="users/new"
        element={
          <Private type={'CREATE_USER'}>
            <CreateUserV2 />
          </Private>
        }
      />

      <Route
        path="users/:id"
        element={
          <Private type={'UPDATE_USER'}>
            <UserDetails />
          </Private>
        }
      />
      <Route
        path="users/:id/address/new"
        element={
          <Private type={'CREATE_ADDRESS'}>
            <AddressCreate />
          </Private>
        }
      />
      <Route
        path="users/:id/address"
        element={
          <Private type={'READ_ADDRESS'}>
            <AddressList />
          </Private>
        }
      />
      <Route
        path="users/:id/address/:aid"
        element={
          <Private type={'UPDATE_ADDRESS'}>
            <AddressDetails />
          </Private>
        }
      />
      <Route
        path="users/:id/tax"
        element={
          <Private type={'READ_TAX_INFORMATION'}>
            <TaxList />
          </Private>
        }
      />
      <Route
        path="users/:id/tax/:tid"
        element={
          <Private type={'UPDATE_TAX_INFORMATION'}>
            <TaxDetails />
          </Private>
        }
      />
      <Route
        path="users/:id/tax/new"
        element={
          <Private type={'CREATE_TAX_INFORMATION'}>
            <TaxCreate />
          </Private>
        }
      />
      <Route
        path="users/vendors"
        element={
          <Private type={'READ_VENDOR'}>
            <VendorsList />
          </Private>
        }
      />
      <Route
        path="users/vendors/new"
        element={
          <Private type={'CREATE_VENDOR'}>
            <CreateVendor />
          </Private>
        }
      />
      <Route
        path="users/vendors/:id"
        element={
          <Private type={'UPDATE_VENDOR'}>
            <VendorDetails />
          </Private>
        }
      />
      <Route
        path="users/user-type"
        element={
          <Private type={'READ_USER'}>
            <UserTypeList />
          </Private>
        }
      />
      <Route
        path="users/user-type/new"
        element={
          <Private type={'CREATE_USER'}>
            <CreateUserType />
          </Private>
        }
      />
      <Route
        path="users/user-type/:id"
        element={
          <Private type={'UPDATE_USER'}>
            <EditUserType />
          </Private>
        }
      />
      <Route
        path="vendor-type"
        element={
          <Private type={'READ_VENDOR_TYPE'}>
            <VendorTypeList />
          </Private>
        }
      />
      <Route
        path="vendor-type/new"
        element={
          <Private type={'CREATE_VENDOR_TYPE'}>
            <CreateVendorType />
          </Private>
        }
      />
      <Route
        path="vendor-type/:id"
        element={
          <Private type={'UPDATE_VENDOR_TYPE'}>
            <EditVendorType />
          </Private>
        }
      />
      <Route
        path="users/customers"
        element={
          <Private type={'READ_CUSTOMER'}>
            <CustomersList />
          </Private>
        }
      />
      <Route
        path="users/customers/new"
        element={
          <Private type={'CREATE_CUSTOMER'}>
            <CreateCustomerV2 />
          </Private>
        }
      />

      <Route
        path="users/customers/:id"
        element={
          <Private type={'UPDATE_CUSTOMER'}>
            <CustomerDetails />
          </Private>
        }
      />
      <Route
        path="locations"
        element={
          <Private type={'READ_LOCATION'}>
            <LocationsList />
          </Private>
        }
      />
      <Route
        path="locations/new"
        element={
          <Private type={'CREATE_LOCATION'}>
            <CreateLocations />
          </Private>
        }
      />
      <Route
        path="locations/:id"
        element={
          <Private type={'UPDATE_LOCATION'}>
            <LocationsDetails />
          </Private>
        }
      />
      <Route
        path="invoices"
        element={
          <Private>
            <Invoices />
          </Private>
        }
      />
      <Route
        path="invoices/layouts/new"
        element={
          <Private>
            <InvoicesLayoutsCreate />
          </Private>
        }
      />
      <Route
        path="invoices/layouts/:id"
        element={
          <Private>
            <InvoicesLayoutsDetails />
          </Private>
        }
      />
      <Route
        path="tasks"
        element={
          <Private>
            <TasksList />
          </Private>
        }
      />
      <Route
        path="tasks/new"
        element={
          <Private>
            {/* <TasksNew /> */}
            <CreateModal />
          </Private>
        }
      />
      <Route
        path="tasks/:id"
        element={
          <Private>
            {/* <TasksDetails /> */}
            <DetailsModal />
          </Private>
        }
      />
      <Route
        path="tasks/archived"
        element={
          <Private>
            {/* <TasksDetails /> */}
            <ArchivedTaskList />
          </Private>
        }
      />
      <Route
        path="settings/preferences"
        element={
          <Private>
            <Preferences />
          </Private>
        }
      />
      <Route
        path="adjustments"
        element={
          <Private type={'READ_ADJUSTMENT'}>
            <AdjustmentsList />
          </Private>
        }
      />
      <Route
        path="adjustments/approve/:id"
        element={
          <Private type={'UPDATE_ADJUSTMENT_STATUS'}>
            <ApproveAdjustment />
          </Private>
        }
      />
      {/* <Route
        path="adjustments/pending-approval"
        element={
          <Private type={'READ_ADJUSTMENT'}>
            <PendingApprovalAdjustmentsList />
          </Private>
        }
      /> */}
      <Route
        path="adjustments/new"
        element={
          <Private type={'CREATE_ADJUSTMENT'}>
            <CreateAdjustments />
          </Private>
        }
      />
      <Route
        path="adjustments/reasons/new"
        element={
          <Private type={'CREATE_REASON'}>
            <CreateReasons />
          </Private>
        }
      />
      <Route
        path="adjustments/reasons"
        element={
          <Private type={'READ_REASON'}>
            <ReasonsList />
          </Private>
        }
      />
      <Route
        path="adjustments/lines"
        element={
          <Private type={'READ_ADJUSTMENT'}>
            <AdjustmentLines />
          </Private>
        }
      />
      <Route
        path="adjustments/reasons/:id"
        element={
          <Private type={'READ_REASON'}>
            <EditReasons />
          </Private>
        }
      />
      <Route
        path="adjustments/:id"
        element={
          <Private type={'READ_ADJUSTMENT'}>
            <AdjustmentDetails />
          </Private>
        }
      />
      <Route
        path="notification"
        element={
          <Private>
            <NotificationsList />
          </Private>
        }
      />
      <Route
        path="notification/new"
        element={
          <Private>
            <CreateNotification />
          </Private>
        }
      />
      <Route
        path="notification/:id/:locationId"
        element={
          <Private>
            <NotificationUpdate />
          </Private>
        }
      />
      <Route
        path="products/soldtotal"
        element={
          <Private>
            <ProductsSoldTotal />
          </Private>
        }
      />
      {/* <Route
        path="products/status"
        element={
          <Private>
            <ProductsStatusList />
          </Private>
        }
      /> */}
      <Route
        path="products/purchasetotal"
        element={
          <Private>
            <ProductsPurchaseTotal />
          </Private>
        }
      />
      <Route
        path="products/history"
        element={
          <Private>
            <ProductHistory />
          </Private>
        }
      />
      <Route
        path="products/clear-stocks"
        element={
          <Private role="ADMIN">
            <ExistingClearStocksList />
          </Private>
        }
      />
      <Route
        path="products/clear-stocks/create"
        element={
          <Private role="ADMIN">
            <ClearAllStock />
          </Private>
        }
      />
      <Route
        path="products/clear-stocks/:id"
        element={
          <Private role="ADMIN">
            <ViewClearStockDetails />
          </Private>
        }
      />
      <Route
        path="product-download"
        element={
          <Private>
            <ProductDownload />
          </Private>
        }
      />
      <Route
        path="expense-category"
        element={
          <Private type={'READ_EXPENSE_CATEGORY'}>
            <ExpenseCategoryList />
          </Private>
        }
      />
      <Route
        path="expense-category/new"
        element={
          <Private type={'CREATE_EXPENSE_CATEGORY'} role="SUPER_ADMIN">
            <CreateExpenseCategory />
          </Private>
        }
      />
      <Route
        path="/expense-category/:id"
        element={
          <Private type={'READ_EXPENSE_CATEGORY'}>
            <ViewExpenseCategory />
          </Private>
        }
      />
      <Route
        path="/expense-category/edit/:id"
        element={
          <Private type={'UPDATE_EXPENSE_CATEGORY'}>
            <DetailsExpenseCategory />
          </Private>
        }
      />
      <Route
        path="/expense"
        element={
          <Private type={'READ_EXPENSE'}>
            <ExpenseList />
          </Private>
        }
      />
      <Route
        path="/expense/new"
        element={
          <Private type={'CREATE_EXPENSE'}>
            <CreateExpense />
          </Private>
        }
      />

      <Route
        path="/expense/:id"
        element={
          <Private type={'READ_EXPENSE'}>
            <ExpenseView />
          </Private>
        }
      />
      <Route
        path="/expense-category-report/:id"
        element={
          <Private type={'READ_EXPENSE'}>
            <ExpenseReport />
          </Private>
        }
      />
      <Route
        path="/logs"
        element={
          <Private type={'READ_LOGS'}>
            <LogsList />
          </Private>
        }
      />
      <Route
        path="/ird/logs"
        element={
          <Private type={'ADMIN'}>
            <VendorAuditLog />
          </Private>
        }
      />

      <Route
        path="/logs/view/:id"
        element={
          <Private type={'READ_LOGS'}>
            <LogView />
          </Private>
        }
      />

      <Route
        path="/log-events"
        element={
          <Private type={'READ_LOGS'}>
            <LogEventList />
          </Private>
        }
      />
      <Route
        path="/ird-logs/events"
        element={
          <Private type={'ADMIN'}>
            <VendorLogEventList />
          </Private>
        }
      />
      <Route
        path="/ird/vat-accounts"
        element={
          <Private type={'ADMIN'}>
            <VatAccountList />
          </Private>
        }
      />
      <Route
        path="/ird/materialized-report"
        element={
          <Private type={'ADMIN'}>
            <MaterializedLists />
          </Private>
        }
      />

      <Route
        path="/log-events/view/:id"
        element={
          <Private type={'READ_LOGS'}>
            <LogEventView />
          </Private>
        }
      />
      <Route
        path="/logs/backup"
        element={
          <Private type={'READ_LOGS'}>
            <LogsBackup />
          </Private>
        }
      />

      <Route
        path="/accounts-rule"
        element={
          <Private type={'READ_ACCOUNT'}>
            <AccountsRuleList />
          </Private>
        }
      />

      <Route
        path="/accounts-rule/new"
        element={
          <Private type={'CREATE_ACCOUNT'}>
            <CreateAccountsRule />
          </Private>
        }
      />

      <Route
        path="/accounts-rule/:id"
        element={
          <Private type={'UPDATE_ACCOUNT'}>
            <DetailsAccountsRule />
          </Private>
        }
      />

      <Route
        path="/accounts"
        element={
          <Private type={'READ_ACCOUNT'}>
            <AccountsList />
          </Private>
        }
      />

      <Route
        path="/accounts/opening"
        element={
          <Private type={'READ_ACCOUNT'}>
            <AccountFinancialYear />
          </Private>
        }
      />

      <Route
        path="/accounts/opening/new"
        element={
          <Private type={'CREATE_ACCOUNT'}>
            <CreateFinancialHistory />
          </Private>
        }
      />

      <Route
        path="/accounts/opening/:id"
        element={
          <Private type={'READ_ACCOUNT'}>
            <ViewFinancialHistory />
          </Private>
        }
      />

      <Route
        path="/accounts/financial-year"
        element={
          <Private type={'READ_ACCOUNT'}>
            <FinancialYearList />
          </Private>
        }
      />

      <Route
        path="/accounts/financial-year/new"
        element={
          <Private role="SUPER_ADMIN">
            <FinancialYearCreate />
          </Private>
        }
      />

      <Route
        path="/accounts/financial-year/:id"
        element={
          <Private role="SUPER_ADMIN">
            <FinancialYearUpdate />
          </Private>
        }
      />

      <Route
        path="/accounts/new"
        element={
          <Private type={'CREATE_ACCOUNT'}>
            <CreateAccounts />
          </Private>
        }
      />

      <Route
        path="/accounts/:id"
        element={
          <Private type={'UPDATE_ACCOUNT'}>
            <DetailsAccounts />
          </Private>
        }
      />

      <Route
        path="/accounts/view"
        element={
          <Private type={'READ_ACCOUNT'}>
            <AccountsView />
          </Private>
        }
      />
      <Route
        path="/accounts/view/:id"
        element={
          <Private type={'READ_ACCOUNT'}>
            <AccountsView />
          </Private>
        }
      />

      <Route
        path="/accounts/merge-accounts/:id"
        element={
          <Private type={'UPDATE_ACCOUNT'}>
            <MergeAccounts />
          </Private>
        }
      />

      <Route
        path="/accounts/journal"
        element={
          <Private type={'READ_ACCOUNT'}>
            <JournalList />
          </Private>
        }
      />

      <Route
        path="/accounts/journal/new"
        element={
          <Private type={'CREATE_ACCOUNT'}>
            <CreateJournal />
          </Private>
        }
      />

      <Route
        path="/accounts/journal/view/:id"
        element={
          <Private type={'READ_ACCOUNT'}>
            <JournalView />
          </Private>
        }
      />

      <Route
        path="/accounts/journal-lines"
        element={
          <Private type={'READ_ACCOUNT'}>
            <JournalLinesListv2 />
          </Private>
        }
      />
      {/* <Route
        path="/accounts/journal-lines-v2"
        element={
          <Private type={'READ_ACCOUNT'}>
            <JournalLinesList />
          </Private>
        }
      /> */}

      <Route
        path="/accounts/detailed-ledger"
        element={
          <Private type={'READ_ACCOUNT'}>
            <DetailedLedgerListV2 />
          </Private>
        }
      />

      {/* <Route
        path="/accounts/detailed-ledger-v2"
        element={
          <Private type={'READ_ACCOUNT'}>
            <DetailedLedgerListV2 />
          </Private>
        }
      /> */}

      {/* <Route
        path="/accounts/detailed-ledger-v2"
        element={
          <Private type={'READ_ACCOUNT'}>
            <DetailedLedgerList />
          </Private>
        }
      /> */}

      <Route
        path="/accounts/receive-payable"
        element={
          <Private type={'READ_ACCOUNT'}>
            <AccountReceivePayable />
          </Private>
        }
      />

      <Route
        path="/accounts/new-journal-lines"
        element={
          <Private type={'READ_ACCOUNT'}>
            <NewJournalLinesList />
          </Private>
        }
      />

      <Route
        path="/ledger"
        element={
          <Private type={'READ_ACCOUNT'}>
            <LedgerList />
          </Private>
        }
      />

      <Route
        path="/trial-balance"
        element={
          <Private type={'READ_ACCOUNT'}>
            <TrialBalanceList />
          </Private>
        }
      />

      <Route
        path="/profit-loss"
        element={
          <Private type={'READ_ACCOUNT'}>
            <ProfitLossList />
          </Private>
        }
      />

      <Route
        path="/balance-sheet"
        element={
          <Private type={'READ_ACCOUNT'}>
            <BalanceSheetList />
          </Private>
        }
      />

      <Route
        path="/accounts/balance/customer/:id"
        element={
          <Private type={'READ_ACCOUNT'}>
            <CustomerBalanceList />
          </Private>
        }
      />

      <Route
        path="/accounts/balance/vendor/:id"
        element={
          <Private type={'READ_ACCOUNT'}>
            <VendorBalanceList />
          </Private>
        }
      />

      <Route
        path="/fonepay"
        element={
          <Private type={'ADMIN'}>
            <FonepayList />
          </Private>
        }
      />

      <Route
        path="/fonepay/new"
        element={
          <Private type={'ADMIN'}>
            <CreateFonepay />
          </Private>
        }
      />

      <Route
        path="/fonepay/:id"
        element={
          <Private type={'ADMIN'}>
            <DetailsFonepay />
          </Private>
        }
      />

      <Route
        path="/ird/purchase-register"
        element={
          <Private type={'ADMIN'}>
            <PurchaseRegisterList />
          </Private>
        }
      />
      <Route
        path="/ird/purchase-register-item-wise"
        element={
          <Private type={'ADMIN'}>
            <ItemWisePurchaseRegisterList />
          </Private>
        }
      />

      <Route
        path="/ird/purchase-return-register"
        element={
          <Private type={'ADMIN'}>
            <PurchaseReturnRegisterList />
          </Private>
        }
      />
      <Route
        path="/ird/purchase-return-register-item-wise"
        element={
          <Private type={'ADMIN'}>
            <ItemWisePurchaseReturnRegisterList />
          </Private>
        }
      />

      <Route
        path="/ird/sales-register"
        element={
          <Private type={'ADMIN'}>
            <SalesRegisterList />
          </Private>
        }
      />

      <Route
        path="/ird/sales-register-item-wise"
        element={
          <Private type={'ADMIN'}>
            <ItemWiseSalesRegisterList />
          </Private>
        }
      />

      <Route
        path="/ird/sales-return-register"
        element={
          <Private type={'ADMIN'}>
            <SalesReturnRegisterList />
          </Private>
        }
      />

      <Route
        path="/ird/sales-return-register-item-wise"
        element={
          <Private type={'ADMIN'}>
            <ItemWiseSalesReturnRegisterList />
          </Private>
        }
      />

      <Route
        path="/bank"
        element={
          <Private type={'READ_BANK'}>
            <BankList />
          </Private>
        }
      />

      <Route
        path="/bank/new"
        element={
          <Private type={'CREATE_BANK'}>
            <CreateBank />
          </Private>
        }
      />

      <Route
        path="/bank/:id"
        element={
          <Private type={'UPDATE_BANK'}>
            <DetailsBank />
          </Private>
        }
      />

      <Route
        path="/bank/view/:id"
        element={
          <Private type={'READ_BANK'}>
            <BankView />
          </Private>
        }
      />

      <Route
        path="/session"
        element={
          <Private type={'ADMIN'}>
            <SessionList />
          </Private>
        }
      />

      <Route
        path="/session/money"
        element={
          <Private type={'ADMIN'}>
            <MoneyList />
          </Private>
        }
      />

      <Route
        path="/session/money/new"
        element={
          <Private type={'ADMIN'}>
            <CreateMoney />
          </Private>
        }
      />

      <Route
        path="/daily-rate"
        element={
          <Private type={'ADMIN'}>
            <DailyRateList />
          </Private>
        }
      />
      <Route
        path="/daily-rate/new"
        element={
          <Private type={'ADMIN'}>
            <CreateDailyRate />
          </Private>
        }
      />
      <Route
        path="/daily-rate/:id"
        element={
          <Private type={'ADMIN'}>
            <DetailsDailyRate />
          </Private>
        }
      />

      <Route
        path="/session/money/:id"
        element={
          <Private type={'ADMIN'}>
            <DetailsMoney />
          </Private>
        }
      />

      {/* Public routes */}
      <Route
        path="/login"
        element={
          <Public>
            <Login />
          </Public>
        }
      />

      <Route
        path="/price-analysis/create"
        element={
          <Private type={['CREATE_B2B_PRICE_ANALYSIS', 'CREATE_PRICE_ANALYSIS']}>
            <PriceAnalysisCreate />
          </Private>
        }
      />

      <Route
        path="/price-analysis"
        element={
          <Private
            type={['READ_PRICE_ANALYSIS', 'READ_B2B_PRICE_ANALYSIS', 'READ_OUTLET_PRICE_ANALYSIS']}>
            <PriceAnalysisList />
          </Private>
        }
      />

      <Route
        path="/price-analysis/details/:id"
        element={
          <Private
            type={[
              'UPDATE_PRICE_ANALYSIS',
              'UPDATE_B2B_PRICE_ANALYSIS',
              'UPDATE_OUTLET_PRICE_ANALYSIS'
            ]}>
            <UpdatePriceAnalysis />
          </Private>
        }
      />

      <Route
        path="/price-analysis/:id"
        element={
          <Private
            type={['READ_PRICE_ANALYSIS', 'READ_B2B_PRICE_ANALYSIS', 'READ_OUTLET_PRICE_ANALYSIS']}>
            <ViewPriceAnalysis />
          </Private>
        }
      />

      <Route
        path="/scheduler"
        element={
          <Private role="ADMIN">
            <ScheduleListPage />
          </Private>
        }
      />

      <Route
        path="/scheduler/new"
        element={
          <Private role="ADMIN">
            <CreateScheduler />
          </Private>
        }
      />

      <Route
        path="/scheduler/status"
        element={
          <Private role="ADMIN">
            <SchedularStatus />
          </Private>
        }
      />

      <Route
        path="/scheduler/:id"
        element={
          <Private role="ADMIN">
            <UpdateScheduler />
          </Private>
        }
      />

      <Route
        path="/scheduler/view/:id"
        element={
          <Private role="ADMIN">
            <SchedularViewPage />
          </Private>
        }
      />

      <Route
        path="/price-groups/customers"
        element={
          <Private type={'READ_PRICE_GROUP'}>
            <CustomerPriceGroupList />
          </Private>
        }
      />

      <Route
        path="/price-groups/customers/new"
        element={
          <Private type={'CREATE_PRICE_GROUP'}>
            <CustomerPriceGroupCreate />
          </Private>
        }
      />

      <Route
        path="/price-groups/customers/details/:id"
        element={
          <Private type={'UPDATE_PRICE_GROUP'}>
            <CustomerPriceGroupUpdate />
          </Private>
        }
      />

      <Route
        path="/price-groups/customers/:id"
        element={
          <Private type={'READ_PRICE_GROUP'}>
            <CustomerPriceGroupView />
          </Private>
        }
      />

      <Route
        path="/price-groups/customers/upload"
        element={
          <Private type={'CREATE_PRICE_GROUP'}>
            <CustomerPriceGroupsUpload />
          </Private>
        }
      />

      <Route
        path="/price-groups/customers/download"
        element={
          <Private type={'READ_PRICE_GROUP'}>
            <CustomerPriceGroupsDownload />
          </Private>
        }
      />

      <Route
        path="/hr"
        element={
          <Private type="READ_HR_USER_INFORMATION">
            <HRList />
          </Private>
        }
      />

      <Route
        path="/hr/new"
        element={
          <Private type="CREATE_HR_USER_INFORMATION">
            <HRUserInfoCreate />
          </Private>
        }
      />

      <Route
        path="/hr/:id"
        element={
          <Private type={'READ_HR_USER_INFORMATION'}>
            <HRUserInfoView />
          </Private>
        }
      />

      <Route
        path="/hr/details/:id"
        element={
          <Private type={'UPDATE_HR_USER_INFORMATION'}>
            <HRUserInfoDetails />
          </Private>
        }
      />

      <Route
        path="/hr/holiday"
        element={
          <Private type={'READ_HOLIDAY'}>
            <HRHolidatList />
          </Private>
        }
      />

      <Route
        path="/hr/holiday/new"
        element={
          <Private type={'CREATE_HOLIDAY'}>
            <HRHolidayCreate />
          </Private>
        }
      />

      <Route
        path="/hr/holiday/details/:id"
        element={
          <Private type={'UPDATE_HOLIDAY'}>
            <HRHolidayUpdate />
          </Private>
        }
      />

      <Route
        path="/hr/holiday/:id"
        element={
          <Private type={'READ_HOLIDAY'}>
            <ViewHoliday />
          </Private>
        }
      />

      <Route
        path="/hr/holiday/assign/:id"
        element={
          <Private type={'UPDATE_HOLIDAY'}>
            <HolidayAssignGroupsPage />
          </Private>
        }
      />

      <Route
        path="/hr/groups"
        element={
          <Private type={'READ_HR_GROUPS'}>
            <HRGroupsList />
          </Private>
        }
      />

      <Route
        path="/hr/groups/new"
        element={
          <Private type={'CREATE_HR_GROUPS'}>
            <CreateHRGroups />
          </Private>
        }
      />

      <Route
        path="/hr/groups/details/:id"
        element={
          <Private type={'UPDATE_HR_GROUPS'}>
            <UpdateHRGroups />
          </Private>
        }
      />

      <Route
        path="/hr/groups/:id"
        element={
          <Private type={'READ_HR_GROUPS'}>
            <ViewHRGroups />
          </Private>
        }
      />

      <Route
        path="/hr/leaves"
        element={
          <Private type={'READ_LEAVES'}>
            <HRLeavesList />
          </Private>
        }
      />

      <Route
        path="/hr/leaves/new"
        element={
          <Private type={'CREATE_LEAVES'}>
            <CreateLeaves />
          </Private>
        }
      />

      <Route
        path="/hr/leaves/details/:id"
        element={
          <Private type={'UPDATE_LEAVES'}>
            <UpdateLeaves />
          </Private>
        }
      />

      <Route
        path="/hr/leaves/:id"
        element={
          <Private type={'READ_LEAVES'}>
            <ViewLeaves />
          </Private>
        }
      />

      <Route
        path="/hr/shift"
        element={
          <Private type={'READ_HR_SHIFT'}>
            <HRShiftList />
          </Private>
        }
      />

      <Route
        path="/hr/shift/:id"
        element={
          <Private type={'READ_HR_SHIFT'}>
            <ViewShiftPage />
          </Private>
        }
      />

      <Route
        path="/hr/shift/details/:id"
        element={
          <Private type={'UPDATE_HR_SHIFT'}>
            <UpdateShiftPage />
          </Private>
        }
      />

      <Route
        path="/hr/shift/new"
        element={
          <Private type={'CREATE_HR_SHIFT'}>
            <CreateShiftPage />
          </Private>
        }
      />

      <Route
        path="/hr/shift/assign/:id"
        element={
          <Private type={'UPDATE_HR_SHIFT'}>
            <AssignShiftPage />
          </Private>
        }
      />

      <Route
        path="/hr/attendance"
        element={
          <Private type={'READ_HR_ATTENDANCE'}>
            <AttendanceListPage />
          </Private>
        }
      />

      <Route
        path="/hr/attendance/self"
        element={
          <Private type={'READ_HR_ATTENDANCE'}>
            <SelfAttendanceListPage />
          </Private>
        }
      />

      <Route
        path="/hr/attendance/new"
        element={
          <Private type={['CREATE_HR_ATTENDANCE', 'READ_HR_SHIFT']}>
            <CreateAttendance />
          </Private>
        }
      />

      <Route
        path="/hr/attendance/details/:id"
        element={
          <Private type={'UPDATE_HR_ATTENDANCE'}>
            <UpdateAttendance />
          </Private>
        }
      />

      <Route
        path="/hr/payroll"
        element={
          <Private type={'READ_HR_PAYROLL'}>
            <PayrollList />
          </Private>
        }
      />

      <Route
        path="/hr/payroll/new"
        element={
          <Private type={'CREATE_HR_PAYROLL'}>
            <CreatePayroll />
          </Private>
        }
      />

      <Route
        path="/hr/payroll/details/:id"
        element={
          <Private type={'UPDATE_HR_PAYROLL'}>
            <UpdatePayroll />
          </Private>
        }
      />

      <Route
        path="/hr/payroll/:id"
        element={
          <Private type={'READ_HR_PAYROLL'}>
            <PayrollView />
          </Private>
        }
      />

      <Route
        path="/hr/calendar/self"
        element={
          <Private>
            <HRSelfCalendar />
          </Private>
        }
      />

      <Route
        path="/hr/calendar/:id"
        element={
          <Private type={'READ_ALL_USER_HR'}>
            <HRUserCalendar />
          </Private>
        }
      />

      <Route
        path="/reports/latest-product-line"
        element={
          <Private>
            <LastIdentifierReport />
          </Private>
        }
      />

      <Route
        path="/reports/latest-purchase-line"
        element={
          <Private>
            <LatestPurchaseLineReport />
          </Private>
        }
      />

      <Route
        path="/logs/support"
        element={
          <Private role="ADMIN">
            <LogSupportList />
          </Private>
        }
      />

      <Route
        path="/logs/support/:id"
        element={
          <Private role="ADMIN">
            <UpdateLogSupport />
          </Private>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
