import moment from 'moment';
import { getUser } from '@/services';
import { get_hr_user_info_details } from '@/services/hr/queries';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, PageHeader, Spin } from 'antd';
import AppContent from '@/components/Common/Content/Content';
import { checkAccess } from '@/routes/acl';

function HRUserInfoView() {
  const params = useParams();
  const navigate = useNavigate();
  const userInfoId = Number(params.id);
  if (isNaN(userInfoId) || userInfoId <= 0) return <Navigate to="/hr" />;

  const { isLoading, isFetching, data } = useQuery(['user-information', userInfoId], async () => {
    const data = await get_hr_user_info_details(userInfoId);
    const user = await getUser(data.userId);

    data.joinDate = moment(data.joinDate).format('YYYY-MM-DD');
    if (data.dateOfTermination) {
      data.dateOfTermination = moment(data.dateOfTermination).format('YYYY-MM-DD');
    }

    return { ...data, userName: user.name };
  });

  return (
    <Spin spinning={isLoading || isFetching}>
      <AppContent breadcrumbItems={[{ label: 'Employee', link: '/hr' }, { label: 'Details' }]}>
        <div className="p-4">
          <PageHeader title="Employee Information" style={{ padding: '8px 0' }} />

          {/* Personal Details */}
          <div className="mb-6">
            <h4 className="text-lg font-semibold mb-2">Personal Details</h4>
            <div className="grid sm:grid-cols-2 gap-2 [&>p]:my-0">
              <p>Name: {data?.userName}</p>
              <p>Gender: {data?.gender}</p>
              <p>Permanent Location: {data?.permanentLocation}</p>
              <p>Current Location: {data?.currentLocation}</p>
              <p>Blood Group: {data?.bloodGroup}</p>
              <p>Marital Status: {data?.maritalStatus}</p>
            </div>
          </div>

          {/* Bank Details */}
          <div className="mb-6">
            <h4 className="text-lg font-semibold mb-2">Bank Details</h4>
            <div className="grid sm:grid-cols-2 gap-2 [&>p]:my-0">
              <p>Account No: {data?.accountNo}</p>
              <p>Bank Name: {data?.bankName}</p>
              <p>Branch Name: {data?.branchName}</p>
            </div>
          </div>

          {/* Job Details */}
          <div className="mb-6">
            <h4 className="text-lg font-semibold mb-2">Job Details</h4>
            <div className="grid sm:grid-cols-2 gap-2 [&>p]:my-0">
              <p>Job Type: {data?.jobType}</p>
              <p>Department: {data?.departments}</p>
              <p>Join Date: {data?.joinDate}</p>
              <p>Date of Termination: {data?.dateOfTermination || 'N/A'}</p>
              <p className="col-span-2">Description: {data?.description}</p>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-5 gap-4">
          {checkAccess('UPDATE_HR_USER_INFORMATION') && (
            <Button
              type="primary"
              htmlType="button"
              onClick={() => navigate(`/hr/details/${userInfoId}`)}>
              Edit Information
            </Button>
          )}

          <Button type="default" htmlType="button" onClick={() => navigate(-1)}>
            Go back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default HRUserInfoView;
