import { Modal } from 'antd';
import React from 'react';

type Props = {
  title?: string;
  width?: number | string;
  message: string | React.ReactNode;
  onOkClick?: () => void;
  closable?: boolean;
  onCancel?: () => void;
};

const CustomErrorModal = ({
  message,
  width,
  title = 'Error',
  closable,
  onOkClick,
  onCancel
}: Props) => {
  Modal.error({
    title,
    width,
    closable,
    content: message,
    onCancel,
    onOk: onOkClick
  });
};

export default CustomErrorModal;
