import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Spin,
  message
} from 'antd';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content/Content';
import { DiscountStatus } from '../../../../services/offfers/enums';
import { IUpdateDiscountProduct } from '../../../../services/offfers/types';
import { update_discount_product_mutation } from '../../../../services/offfers/mutations';
import { get_discount_product_details } from '../../../../services/offfers/queries';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { IProductDetails } from '../../../../services/products/types';
import ProductSearch2 from '../../../../components/Common/ProductSearch2/ProductSearch2';
import { RangePickerProps } from 'antd/lib/date-picker';
import { CustomDatePresets } from '../../../sqlsource/report/utils/datePresets';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../../utils/convertToUTC';
import moment from 'moment';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import CustomErrorModal from '../../../../components/Common/CustomErrorModal';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import { get_product_list_ids } from '../../../../services/products/queries';
import getErrorMessage from '@/utils/getError';
const { RangePicker } = DatePicker;

const DiscountProductDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [productSearch, setProductSearch] = useState<IProductDetails[]>([]);
  const scrollElement = useRef<HTMLDivElement>(null);

  const breadcrumbItems = [
    {
      label: 'Product Discount',
      link: '/offer/discount-product'
    },
    {
      label: 'Details',
      link: '/offer/discount-product/:id'
    }
  ];

  useQuery(['discountList', id], async () => await getData());

  const onFinish = async (values: IUpdateDiscountProduct) => {
    setIsLoading(true);
    //console.log('Values', values);
    try {
      if (id) values.id = parseInt(id);
      await updateCustomerGroupMutation.mutateAsync(values);
    } catch (err: any) {
      setIsLoading(false);
      console.log(err.message);
    }
  };

  const updateCustomerGroupMutation = useMutation(update_discount_product_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Discount updated successfully');
      navigate('/offer/discount-product');
    },
    onError: async (e: any) => {
      setIsLoading(false);
      if (e.response.data.exception.response.data) {
        CustomErrorModal({
          message: (
            <div>
              {e.response.data.message}
              <br></br>
              <div className="grid grid-cols-1">
                {await Promise.all(
                  e.response.data.exception.response.data.map(async (value: any) => {
                    return (
                      <>
                        <span>
                          Offer : <b>{value.name}</b>
                        </span>
                        {await Promise.all(
                          value.lines.map(async (product: any) => {
                            let productDetails = await ProductsDB.getProduct(product.productId);
                            if (!productDetails) {
                              const allProducts = await get_product_list_ids([
                                ...new Set<number>(
                                  value.lines.map((val: any) => {
                                    return val.productId;
                                  })
                                )
                              ]);
                              await ProductsDB.addProducts(allProducts.data.results);
                              productDetails = await ProductsDB.getProduct(product.productId);
                            }
                            return (
                              <>
                                <span>
                                  Product :{' '}
                                  <b>
                                    {typeof productDetails === 'object' ? productDetails.name : ''}
                                  </b>
                                </span>
                              </>
                            );
                          })
                        )}
                      </>
                    );
                  })
                )}
              </div>
            </div>
          )
        });
      } else {
        CustomErrorModal({ message: getErrorMessage(e) });
      }
    }
  });

  const getData = async () => {
    setIsLoading(true);
    if (id) {
      const data = await get_discount_product_details(parseInt(id));
      // console.log('data', data);

      form.setFieldsValue({
        name: data.offer.name,
        locationId: data.offer.locationId,
        timer: {
          dateCustom: [
            moment(convertUTCStringtoLocalString(data.timer.startDate, 'YYYY-MM-DD HH:mm')),
            moment(convertUTCStringtoLocalString(data.timer.endDate, 'YYYY-MM-DD HH:mm'))
          ],
          ...data.timer
        },
        isActive: data.offer.isActive
      });

      const lines = await Promise.all(
        data.lines.map(async (line) => {
          let productDetails = await ProductsDB.getProduct(line.productId);
          if (!productDetails) {
            const allProducts = await get_product_list_ids([
              ...new Set(
                data.lines.map((value) => {
                  return value.productId;
                })
              )
            ]);
            await ProductsDB.addProducts(allProducts.data.results);
            productDetails = await ProductsDB.getProduct(line.productId);
          }

          return {
            productId: line.productId,
            productName: typeof productDetails === 'object' ? productDetails.name : '',
            percentage: line.percentage
          };
        })
      );

      form.setFieldValue(['lines'], lines);

      setIsLoading(false);
    }
  };

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['timer', 'startDate'], convertLocalToUTCString(dateStrings[0]));
      form.setFieldValue(['timer', 'endDate'], convertLocalToUTCString(dateStrings[1]));
    }
  };

  const isProductAlreadyAdded = (checkproductId: number) => {
    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    if (lines) {
      if (lines.length > 0) {
        const findOne = lines?.find((curr: any) => curr.productId == checkproductId);
        if (findOne) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          // initialValues={{  }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Discount Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Discount Name"
              name="name"
              rules={[{ required: true, message: 'Please add Discount Name!' }]}>
              <Input />
            </Form.Item>
            <LocationSearch
              formData={{ formLabel: 'Location', formName: 'locationId' }}
              allowClear={true}
              required={true}
              notAll={true}
            />
            <Form.List name={['timer']}>
              {() => (
                <>
                  <Form.Item
                    label={'Select Date'}
                    name={['dateCustom']}
                    hidden={false}
                    rules={[{ required: true, message: 'Please select Date Range!' }]}>
                    <RangePicker
                      showTime
                      ranges={CustomDatePresets}
                      onChange={onChange}
                      format={'YYYY-MM-DD HH:mm'}
                      allowClear={false}
                    />
                  </Form.Item>
                  <Form.Item label={'Start Date'} name={['startDate']} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item label={'End Date'} name={['endDate']} hidden>
                    <Input />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item
              name={'isActive'}
              label="Status"
              rules={[{ required: true, message: 'Please add Status!' }]}>
              <Select placeholder="Select status">
                {(Object.keys(DiscountStatus) as Array<keyof typeof DiscountStatus>).map((key) => (
                  <Select.Option key={key} value={key}>
                    {DiscountStatus[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Card
            ref={scrollElement}
            style={{
              maxHeight: '50vh',
              overflowY: 'scroll',
              borderRadius: '9px'
            }}>
            <Form.List name={['lines']}>
              {(fields, { add, remove }) => (
                <>
                  <div className="grid grid-cols-2">
                    <ProductSearch2
                      productSearch={productSearch}
                      setProductSearch={setProductSearch}
                      onProductChange={(val) => {
                        const selectedProduct = productSearch.find((value) => value.id == val);
                        if (isProductAlreadyAdded(val)) {
                          add({
                            productId: val,
                            productName: selectedProduct?.name,
                            percentage: 1
                          });
                        } else {
                          CustomErrorModal({
                            title: 'Error!',
                            message: selectedProduct?.name + ' is already Added!'
                          });
                          return;
                        }
                      }}
                    />
                  </div>
                  <Divider />
                  {fields.map((field, index) => (
                    <div className="flex gap-2 items-center" key={field.key}>
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div className="card">
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-7 xl:grid-cols-7'
                          }
                          key={field.key}>
                          <Form.Item name={[field.name, 'productId']} hidden></Form.Item>
                          <Form.Item name={[field.name, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Percentage"
                            name={[field.name, 'percentage']}
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp('^[1-9][0-9]?$|^100$'),
                                message: 'Can contain only number from 1-100'
                              }
                            ]}>
                            <InputNumber controls={false} min={1} />
                          </Form.Item>
                          <div className="flex items-center justify-start mt-5">
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="green"
                              onClick={async () => {
                                const x = scrollElement.current?.scrollTop;
                                remove(field.name);
                                const y = scrollElement.current?.scrollTop;
                                if (scrollElement.current && typeof y == 'number') {
                                  scrollElement.current.scrollTop = y;
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          </Card>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/offer/discount-product')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default DiscountProductDetails;
