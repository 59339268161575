import { AxiosResponse, AxiosError } from 'axios';
import moment from 'moment';
import getErrorMessage from './getError';
import { LoadingOutlined } from '@ant-design/icons';

import capitalizeFirstWord from './capitalizeFirstWord';
import { Modal } from 'antd';
import { ILogSupportCreate } from '@/services/logs/support/types';
import { safeStringify } from './safeStringify';
import { create_logs_support } from '@/services/logs/support/mutation';
import { getParsedJSON } from '.';

export interface ErrorLog {
  endpoint: string;
  requestData?: unknown;
  errorResponse: AxiosResponse;
  startTime: moment.Moment;
  endTime: moment.Moment;
  message: string;
}

interface IErrorOptions {
  showError?: boolean;
}

/**
 * Wrap an API call with error handling, logging, and modal display.
 * @param apiCall - A promise representing the API call.
 * @param options - Options for error handling and modal display.
 */

export async function apiWrapper(
  apiCall: Promise<AxiosResponse>,
  { showError = true }: IErrorOptions
): Promise<AxiosResponse> {
  const startTime = moment();
  try {
    // Wait for the API call to resolve
    const response = await apiCall;
    return response;
  } catch (error: unknown) {
    if (showError && error instanceof AxiosError) {
      const axiosResponse = error?.response as AxiosResponse;
      const request = error?.config;
      const endTime = moment();

      // Extract data safely
      const header = request?.headers;
      const frontVersion =
        typeof header?.['front-version'] === 'string' ? header['front-version'] : undefined;

      const formattedRequest = {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        headers: request?.headers,
        payload: getParsedJSON(request?.data)
      };

      const formattedResponse = {
        message: error.message,
        originatedURL: location.href,
        responseTime: getFormattedResponseTime(endTime.diff(startTime)),
        headers: axiosResponse.headers,
        response: axiosResponse.data
      };

      const createPayload: ILogSupportCreate = {
        frontVersion,
        method: request?.method || 'unknown',
        url: request?.url || 'unknown',
        statusCode: axiosResponse.status,
        requestBody: safeStringify(formattedRequest),
        content: safeStringify(formattedResponse)
      };

      const errors = axiosResponse?.data?.message || getErrorMessage(error);
      const newErrors = Array.isArray(errors)
        ? errors.length === 1
          ? capitalizeFirstWord(errors[0], true)
          : errors
        : typeof errors === 'string'
        ? capitalizeFirstWord(errors, true)
        : errors;

      let content: React.ReactNode = newErrors;
      if (Array.isArray(newErrors)) {
        content = (
          <ul className="pl-5 text-left">
            {newErrors.map((error, index) => (
              <li key={index}>{capitalizeFirstWord(error, true)}</li>
            ))}
          </ul>
        );
      }

      const modalRef = Modal.error({
        closable: true,
        title: 'Error',
        content,
        width: 600,
        okText: 'Report to Support',
        cancelText: 'Close',
        onOk: (closeFn) => {
          handleModalOk(modalRef, createPayload);
          return false;
        }
      });
    }

    throw error;
  }
}

export async function handleModalOk(
  modalRef: ReturnType<typeof Modal.error>,
  payload: ILogSupportCreate
) {
  try {
    // Update the modal
    modalRef.update({
      closable: false,
      okText: (
        <div className="inline-flex items-center gap-2">
          <LoadingOutlined style={{ fontSize: 16 }} />
          Sending
        </div>
      ),
      okButtonProps: { disabled: true }
    });

    // Call the function to log the issue
    await create_logs_support(payload);
    modalRef.destroy();

    // Update the modal with a success message
    Modal.success({
      closable: true,
      title: 'Issue Submitted',
      content:
        'The issue has been submitted to the support team. We will resolve it if a problem is found.',
      width: 600
    });
  } catch (logError) {
    // Update modal to allow retry
    modalRef?.update({
      closable: true,
      okText: 'Retry',
      okButtonProps: { disabled: false },
      cancelText: 'Close',
      content: (
        <>
          <p>
            Failed to submit the issue. Please try again. If the issue persists, contact support
            manually.
          </p>
        </>
      ),
      onOk: (closeFn) => {
        handleModalOk(modalRef, payload); // Retry submission
        return false;
      }
    });
  }
}

function getFormattedResponseTime(responseTimeMs: number) {
  // Less than 1 second, show in milliseconds
  if (responseTimeMs < 1000) {
    return `${responseTimeMs} ms`;
  }

  if (responseTimeMs < 60000) {
    return `${(responseTimeMs / 1000).toFixed(2)} sec`;
  }

  return `${(responseTimeMs / 60000).toFixed(2)} min`;
}
