import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ColumnsType } from 'antd/es/table';
import { Input, Menu, Form, Tooltip } from 'antd';
import { EditOutlined, EyeFilled } from '@ant-design/icons';

import { checkAccess } from '@/routes/acl';
import TableFilter from '@/components/FliterTable';
import UsersDB from '@/store/localstorage/UsersDB';
import handlePagination from '@/utils/handlePagination';
import ActionDropdown from '@/components/Common/Dropdownactions';

import { ConvertObjectToURL } from '@/utils/converturl';
import { get_user_details } from '@/services/users/queries';
import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import {
  get_customer_discount_details,
  get_customer_discount_list_filter
} from '@/services/offfers/queries';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { ICustomerDiscount, ICustomerDiscountResponse } from '@/services/offfers/types';
import { DEFAULT_DATE_FORMAT } from '@/constants';

function CustomerRangeOfferPage() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [discounts, setDiscounts] = useState<ICustomerDiscountResponse>({
    count: 0,
    results: []
  });

  async function onSubmitFilter(val: string) {
    await getInfo(val);
    setPagination({ page: 1, size: 100 });
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_customer_discount_list_filter(filter);
      await get_customer_discount_details(response.results[0].id);
      const discountList = await Promise.all(
        response.results.map(async (expense) => {
          const { createdBy } = expense;

          let user = await UsersDB.getUser(createdBy);
          if (!user) {
            user = (await get_user_details(createdBy)).user;
            await UsersDB.addUsers([user]);
          }

          return {
            ...expense,
            createdUser: user?.name
          };
        })
      );

      setDiscounts({ count: response.count, results: discountList });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const columns: ColumnsType<ICustomerDiscount> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => <span>{index + 1}</span>,
      fixed: 'right'
    },
    {
      title: 'Name',
      width: 8,
      dataIndex: 'name',
      key: 'name',
      render(value, record) {
        return <Link to={`/offer/customer-discount/view/${record.id}`}>{value}</Link>;
      }
    },
    { title: 'Min', width: 4, dataIndex: 'min', key: 'min' },
    { title: 'Max', width: 4, dataIndex: 'max', key: 'max' },
    {
      title: 'Discount',
      width: 4,
      dataIndex: 'discount',
      key: 'totalAmount',
      render: (discount) => <span>{discount}%</span>
    },
    {
      title: 'Created At',
      width: 6,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },
    { title: 'Created By', width: 4, dataIndex: 'createdUser', key: 'createdBy' },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: () => void;
        }[] = [];

        if (checkAccess('READ_DISCOUNT')) {
          menuItems.push({
            key: '5',
            label: (
              <Tooltip title="View" color="blue">
                <Link to={`/offer/customer-discount/view/${record.id}`}>
                  <EyeFilled style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        if (checkAccess('UPDATE_DISCOUNT')) {
          menuItems.push({
            key: '1',
            label: (
              <Tooltip title="Update" color="blue">
                <Link to={`/offer/customer-discount/${record.id}`}>
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        return <ActionDropdown menu={<Menu items={menuItems} />} />;
      }
    }
  ];

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <AppContent
      breadcrumbItems={[{ label: 'Customer Discount', link: '/customer-discount' }]}
      withfilter
      button={
        <TableFilter
          initial
          form={form}
          dateCustom={false}
          onSubmit={onSubmitFilter}
          onPagination={(page, size) => setPagination({ page, size })}
          defaultValues={{
            value: '',
            skip: 0,
            count: 100
          }}
          styleforbuttons={'flex justify-end items-center'}
          style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}
          buttons={
            <>
              {checkAccess('CREATE_DISCOUNT') && (
                <CustomButton
                  text="Add"
                  backgroundColor="#1890ff"
                  Linkto="/offer/customer-discount/create"
                />
              )}
            </>
          }>
          <Form.Item name="value" label="Search">
            <Input placeholder="Search" />
          </Form.Item>
        </TableFilter>
      }>
      <CustomizeTable
        form={form}
        columns={columns}
        data={discounts.results}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 1000, y: '75vh' }}
        paginationDatas={{
          page: pagination.page,
          total: discounts.count,
          size: pagination.size,
          onPagination
        }}
        tableName={'customer-discount-list'}
      />
    </AppContent>
  );
}

export default CustomerRangeOfferPage;
