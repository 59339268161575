import moment from 'moment';

import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import { get_purchase_agent_bill_purchase_list } from '@/services/purchases/queries';
import { ConvertObjectToURL } from '@/utils/converturl';
import getError from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input, message } from 'antd';
import { useState } from 'react';
import AgentSearchV2 from '@/components/Common/CustomSearch/Agents';

import Table from './Table';
import { IPurchaseAgentBillPurchase } from '@/services/purchases/types';
import UsersDB from '@/store/localstorage/UsersDB';
import VendorsDB from '@/store/localstorage/VendorDB';
import { IVendor } from '@/services/users/types';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list } from '@/services/locations/queries';
import { get_user_list_ids, get_vendor_list_ids } from '@/services/users/queries';
import { createEmptyPromise } from '@/utils/createEmptyPromise';
import AgentBillRangeSearch from '@/components/Common/CustomSearch/BillRange';

function AgentBillRangePurchaseList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState({ total: 0, results: [] as IPurchaseAgentBillPurchase[] });

  const agentId = Form.useWatch('agentId', form);

  const [currentFilter, setCurrentFilter] = useState('');

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      setCurrentFilter(filter);
      const response = await get_purchase_agent_bill_purchase_list(filter);

      const absentSupplier = [] as number[];
      const absentUsers = [] as number[];

      for (const item of response.results) {
        const { vendorId, createdBy, agentId, locationId, locationLCCId } = item.purchase;

        if (createdBy) {
          const createdUser = await UsersDB.getUser(createdBy);
          if (!createdUser) absentUsers.push(createdBy);
          item.purchase.createdByName = createdUser?.name;
        }

        if (agentId) {
          const agent = await UsersDB.getUser(agentId);
          if (!agent) absentUsers.push(agentId);
          item.purchase.agentName = agent?.name;
        }

        if (vendorId) {
          const vendor = (await VendorsDB.getVendors(vendorId)) as IVendor;
          if (!vendor) absentSupplier.push(vendorId);
          item.purchase.vendorName = vendor?.user?.name;
          item.purchase.userName = vendor?.user?.name;
          item.purchase.userContact = vendor?.user?.phone;
          item.purchase.userData = vendor;
        }

        if (locationId) {
          let location = await LocationsDB.getLocation(locationId);
          if (!location) {
            const allLocations = await get_location_list();
            await LocationsDB.addLocations([...allLocations.data.results]);
            location = await LocationsDB.getLocation(locationId);
          }

          item.purchase.locationName = location?.name;
        }

        if (locationLCCId) {
          let location = await LocationsDB.getLocation(locationLCCId);
          if (!location) {
            const allLocations = await get_location_list();
            await LocationsDB.addLocations([...allLocations.data.results]);
            location = await LocationsDB.getLocation(locationLCCId);
          }

          item.purchase.lccLocationName = location?.name;
        }
      }

      const usersPromise =
        absentUsers.length > 0
          ? get_user_list_ids([...new Set(absentUsers)])
          : createEmptyPromise({ data: { total: 0, results: [] } });

      const vendorsPromise =
        absentSupplier.length > 0
          ? get_vendor_list_ids([...new Set(absentSupplier)])
          : createEmptyPromise({ data: { total: 0, results: [] } });

      const [users, vendors] = await Promise.all([usersPromise, vendorsPromise]);

      await UsersDB.addUsers(users.data.results);
      await VendorsDB.addVendors(vendors.data.results);

      for (const item of response.results) {
        const { vendorId, createdBy, agentId } = item.purchase;
        if (agentId && absentUsers.includes(agentId)) {
          const agent = await UsersDB.getUser(agentId);
          item.purchase.agentName = agent?.name;
        }

        if (createdBy && absentUsers.includes(createdBy)) {
          const createdUser = await UsersDB.getUser(createdBy);
          item.purchase.createdByName = createdUser?.name;
        }

        if (vendorId && absentSupplier.includes(vendorId)) {
          const vendor = (await VendorsDB.getVendors(vendorId)) as IVendor;
          item.purchase.vendorName = vendor?.user?.name;
          item.purchase.userName = vendor?.user?.name;
          item.purchase.userContact = vendor?.user?.phone;
          item.purchase.userData = vendor;
        }
      }

      setData(response);
    } catch (error) {
      message.error(getError(error));
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'Purchase', link: '/purchase' },
        { label: 'Agent Bill Range', link: '/purchases/agents-bill-range' },
        { label: 'Agent Bill Purchase', link: '/purchases/agents-bill-purchase' }
      ]}
      withfilter
      button={
        <TableFilter
          initial
          form={form}
          onPagination={(page, size) => setPagination({ page, size })}
          onSubmit={onSubmitFilter}
          defaultValues={{
            dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
            agentId: '',
            value: '',
            skip: 0,
            count: 100,
            archiveStatus: 'ACTIVE'
          }}
          styleforbuttons={'flex justify-end items-center'}
          style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}>
          <AgentSearchV2
            showAll
            name={'agentId'}
            hasParentFormItem={false}
            onSelect={() => {
              form.setFieldValue('billRangeId', undefined);
            }}
          />
          {agentId && (
            <AgentBillRangeSearch
              hasParentFormItem={false}
              name={'billRangeId'}
              selectedAgentId={agentId}
            />
          )}

          <Form.Item name="value" label="Value">
            <Input placeholder="Value" />
          </Form.Item>
        </TableFilter>
      }>
      <Table
        data={data}
        form={form}
        getInfo={getInfo}
        filter={currentFilter}
        isLoading={isLoading}
        pagination={pagination}
        setIsLoading={setIsLoading}
        onPagination={onPagination}
      />
    </AppContent>
  );
}

export default AgentBillRangePurchaseList;
