import React, { useContext, useEffect, useState } from 'react';
import { ArrowsAltOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Select, Spin, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import AppContent from '@/components/Common/Content';
import {
  get_lots_details,
  get_unexpired_lots_details_bylocationId_productId,
  get_units_list
} from '@/services/products/queries';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { ProductsSearch } from '@/components/Common/ProductsSearch';
import { DivergeLotsModal } from './diverge';
import { MergeLotsModal } from './merge';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import LotHistoryModal from './LotHistoryModal';
import { find_locationId_preference, find_to_use_NPR } from '@/store/localstorage/preferences';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { get_created_by_opening_stock_by_ids } from '@/services/stock/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';

const { Option } = Select;
const LotsList: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allLot, setAllLot] = useState<any[]>([]);
  const { socket } = useContext(WebSocketContext);
  const [productSearch] = useState<any[]>([]);
  const [searchLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isDivergeModalOpen, setIsDivergeModalOpen] = useState(false);
  const [selectedLot, setSelectedLot] = useState<any>(null);
  const [productInfromation, setproductInformation] = useState<any>();
  const [totalInfo, setTotalinfo] = useState<any>();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [showHistoryDetails, setshowHistoryDetails] = useState<any>();
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const setDefaultLocation = () => {
    const preferenceLocationId = find_locationId_preference();
    if (preferenceLocationId) {
      form.setFieldValue('locationId', preferenceLocationId);
    }
  };

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const values = form.getFieldsValue();
      await onFinish(values);
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['locationId']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          const values = form.getFieldsValue();
          await onFinish(values);
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        let updatedProducts = data.data as { productId: number; locationId: number }[];

        const locationId = form.getFieldValue(['locationId']);
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) => value.locationId === locationId && productInfromation?.id === value.productId
          );
        }

        if (updatedProducts.length > 0) {
          const values = form.getFieldsValue();
          await onFinish(values);
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected, allLot, productInfromation]);

  useEffect(() => {
    setDefaultLocation();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDivergeModal = (id: number) => {
    setSelectedLot(id);
    setTimeout(() => {
      setIsDivergeModalOpen(true);
    }, 500);
  };

  const handleDivergeOk = () => {
    setIsDivergeModalOpen(false);
  };

  const handleDivergeCancel = () => {
    setIsDivergeModalOpen(false);
  };

  const columns: ColumnsType<any> = [
    // Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    // {
    //   title: 'S.N',
    //   key: 'SN',
    //   width: 5,
    //   // sorter: (a, b) => a.id - b.id,
    //   // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
    //   render: (text, record, index) => {
    //     return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
    //   }
    // },
    {
      title: 'Lot Number',
      key: 'lotNumber',
      render: (record) => {
        return <TableCell>{record.lotNumber}</TableCell>;
      }
    },

    {
      title: 'Grade',
      key: 'grade',
      render: (record) => {
        return <TableCell>{record.grade}</TableCell>;
      }
    },
    {
      title: 'Available (qty.)',
      key: 'qtyAvailableName',
      render: (record) => {
        return <TableCell>{record.qtyAvailableName}</TableCell>;
      }
    },
    {
      title: 'Sold (qty.)',
      key: 'qtySoldName',
      render: (record) => {
        return <TableCell>{record.qtySoldName}</TableCell>;
      }
    },
    {
      title: 'Receieved (qty.)',
      key: 'qtyReceivedName',
      render: (record) => {
        return <TableCell>{record.qtyReceivedName}</TableCell>;
      }
    },
    {
      title: 'Price Avg.',
      key: 'purchasePriceAvg',
      render: (record) => {
        return <TableCell>{parseFloat(record.purchasePriceAvg).toFixed(2)}</TableCell>;
      }
    },
    {
      title: 'IsTraceable',
      key: 'traceable',
      render: (record) => {
        const showHistory = async (record: any) => {
          const histories = JSON.parse(record.histories);
          const identifierId = histories.map((curr: any) => curr.identifierId);
          try {
            const response = await get_created_by_opening_stock_by_ids([
              ...new Set<number>(identifierId)
            ]);
            for (let i = 0; i < histories.length; i++) {
              const createdById = response.results[i].createdBy;
              let createdByName = (await UsersDB.getUser(createdById))?.name;
              if (!createdByName) {
                const allUsers = await get_user_list_ids([
                  ...new Set<number>(response.results.map((curr: any) => curr.createdBy))
                ]);
                await UsersDB.addUsers(allUsers.data.results);
                createdByName = (await UsersDB.getUser(createdById)).name;
              }
              histories[i].createdByName = createdByName;
            }
          } catch (error) {
            console.log(error);
          }
          record.histories = JSON.stringify(histories);
          setshowHistoryDetails(record);
          setIsModalOpen2(true);
        };
        return (
          <TableCell data-boolean={record?.histories ? true : false}>
            {record?.histories ? (
              <>
                <span>
                  {' '}
                  <Tooltip title="View" color="blue">
                    <div
                      style={{ color: 'blue', cursor: 'pointer' }}
                      onClick={() => {
                        showHistory(record);
                      }}>
                      View
                    </div>
                  </Tooltip>
                </span>
              </>
            ) : (
              <>
                <span>false</span>
              </>
            )}
          </TableCell>
        );
      }
    },
    {
      title: 'Lot Status',
      key: 'lotStatus',
      render: (record) => {
        return (
          <Tag color={record.lotStatus === 'ACTIVE' ? 'green' : 'red'}>{record.lotStatus}</Tag>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <>
          {checkAccess('DISTRIBUTE_LOTS') && (
            <Tooltip title="DIVERGE LOTS" color="green">
              <div
                className="flex justify-start items-center cursor-pointer"
                onClick={() => {
                  // console.log('record.qtyAvailable', record);
                  if (record.qtyAvailable > 0) showDivergeModal(record);
                  else message.error('Cant Diverge 0 quantity!');
                }}>
                <ArrowsAltOutlined />
              </div>
            </Tooltip>
          )}
        </>
      )
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Products',
      link: '/products'
    },
    {
      label: 'Lots',
      link: '/products/lots'
    }
  ];

  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));

  const modalProps = {
    selectedLot,
    setSelectedLot
    // productChange
  };

  // const handleLocationChange = async (val: any) => {
  //   if (!productId) {
  //     message.error('Please select any product');
  //     setLocationid(val);
  //     return;
  //   }
  //   if (val) {
  //     // const response = await get_lots_details(val);
  //     if (locationId) {
  //       const response = await get_unexpired_lots_details_bylocationId_productId(val, productId);
  //       setAllLot(response);
  //     } else {
  //       const response = await get_lots_details(productId);
  //       setAllLot(response);
  //     }
  //   } else {
  //     setAllLot([]);
  //   }
  //   productChange(productId);
  //   setLocationid(val);
  // };

  const onFinish = async (values = form.getFieldsValue()) => {
    // console.log('Values-->', values);
    let totalqtyAvailable: any = 0,
      totalqtyClosing: any = 0,
      totalqtyOpening: any = 0,
      totalqtySold: any = 0,
      totalqtyReceived: any = 0;
    setIsloading(true);
    let response;
    if (!values.locationId || values.locationId === '') {
      console.log(values);
      response = await get_lots_details(values.productId);
    } else {
      response = await get_unexpired_lots_details_bylocationId_productId(
        values.locationId,
        values.productId
      );
    }
    // console.log('selected product', productInfromation);
    // console.log('response-->', response);
    if (productInfromation) {
      const defaultUnit = productInfromation.productUnits.find(
        (curr: any) => curr.isDefault === true
      );
      console.log(defaultUnit);
      // console.log('Default Unit-->', defaultUnit);
      let findUnit: any = await UnitsDB.getUnit(defaultUnit.unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(defaultUnit.unitId);
      }
      // console.log('findUnit', findUnit);
      for (let ind = 0; ind < response.length; ind++) {
        totalqtyAvailable += response[ind].qtyAvailable;
        totalqtyClosing += response[ind].qtyClosing;
        totalqtyOpening += response[ind].qtyOpening;
        totalqtySold += response[ind].qtySold;
        totalqtyReceived += response[ind].qtyReceived;
        response[ind].purchasePriceAvg = `${
          parseFloat(response[ind].purchasePriceAvg) * findUnit.baseUnitMultiplier
        }`;
        response[ind].qtyAvailableName = `${
          response[ind].qtyAvailable / findUnit.baseUnitMultiplier
        } ${findUnit.shortName}`;
        response[ind].qtyClosingName = `${response[ind].qtyClosing / findUnit.baseUnitMultiplier} ${
          findUnit.shortName
        }`;
        response[ind].qtyOpeningName = `${response[ind].qtyOpening / findUnit.baseUnitMultiplier} ${
          findUnit.shortName
        }`;
        response[ind].qtySoldName = `${response[ind].qtySold / findUnit.baseUnitMultiplier} ${
          findUnit.shortName
        }`;
        response[ind].qtyReceivedName = `${
          response[ind].qtyReceived / findUnit.baseUnitMultiplier
        } ${findUnit.shortName}`;
      }
      totalqtyAvailable = `${totalqtyAvailable / findUnit.baseUnitMultiplier} ${
        findUnit.shortName
      }`;
      // console.log(
      //   'total',
      //   totalqtyAvailable,
      //   totalqtyClosing,
      //   totalqtyOpening,
      //   totalqtySold,
      //   totalqtyReceived
      // );
      totalqtyClosing = `${totalqtyClosing / findUnit.baseUnitMultiplier} ${findUnit.shortName}`;
      totalqtyOpening = `${totalqtyOpening / findUnit.baseUnitMultiplier} ${findUnit.shortName}`;
      totalqtySold = `${totalqtySold / findUnit.baseUnitMultiplier} ${findUnit.shortName}`;
      totalqtyReceived = `${totalqtyReceived / findUnit.baseUnitMultiplier} ${findUnit.shortName}`;
    }

    // console.log('Response-->', response);
    setTotalinfo({
      totalqtyAvailable,
      totalqtyClosing,
      totalqtyOpening,
      totalqtySold,
      totalqtyReceived
    });

    setAllLot(response);
    setIsloading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // const response = await get_sell_list(url);
    // setalltransferList(response.data);
    setIsloading(false);
    // ProductsDB.addProducts(productResponse.data.results);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Modal
          width={'60%'}
          title={`Lot History of ${showHistoryDetails?.lotNumber}`}
          visible={isModalOpen2}
          footer={false}
          onOk={handleOk2}
          onCancel={handleCancel2}>
          {showHistoryDetails && <LotHistoryModal record={showHistoryDetails} />}
        </Modal>
        <AppContent
          breadcrumbItems={breadcrumbItems}
          withfilter={true}
          internalElement={
            <Form form={form} layout={'vertical'} onFinish={onFinish}>
              <div className="flex items-end gap-2 flex-wrap">
                <div className="grid xs:grid-cols-3 gap-2 max-w-[40rem] w-full">
                  <LocationSearch />
                  <ProductSearchV2
                    name={'productId'}
                    required
                    hasParentFormItem={false}
                    onSelect={(val) => form.setFieldValue(['productId'], val)}
                    setSelected={(value) => setproductInformation(value)}
                  />
                  <Form.Item label={'Select Grade'} name={['grade']}>
                    <Select
                      placeholder={'Select Grade'}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      style={{ borderRadius: '9px' }}
                      filterOption={false}
                      notFoundContent={null}
                      allowClear
                      loading={searchLoading}>
                      <Option key="A" value="A">
                        A
                      </Option>
                      <Option key="B" value="B">
                        B
                      </Option>
                      <Option key="C" value="C">
                        C
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="secondary-button"
                    style={{
                      borderRadius: '9px'
                    }}>
                    Search
                  </Button>
                </Form.Item>

                {allLot?.length > 0 && (
                  <>
                    {checkAccess('MERGE_LOTS') && (
                      <CustomButton
                        text="Merge Lots"
                        onClick={showModal}
                        backgroundColor="#1890ff"
                        marginLeft="px"
                        marginBottom="6px"
                      />
                    )}
                  </>
                )}
              </div>
            </Form>
          }>
          <Table
            scroll={{ y: '75vh', x: 800 }}
            columns={columns}
            dataSource={allLot}
            rowKey={'id'}
            size="small"
            expandable={{
              expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>
            }}
            pagination={{
              pageSize: 50
            }}
            summary={(pageData) => {
              return (
                totalInfo && (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        {totalInfo.totalqtyAvailable}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>{totalInfo.totalqtySold}</Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        {totalInfo.totalqtyReceived}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              );
            }}
          />

          <MergeLotsModal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            lotsArray={allLot}
            refetch={onFinish}
            {...modalProps}
          />

          {selectedLot && (
            <DivergeLotsModal
              open={isDivergeModalOpen}
              onOk={handleDivergeOk}
              onCancel={handleDivergeCancel}
              lotsArray={allLot}
              refetch={onFinish}
              {...modalProps}
            />
          )}
        </AppContent>
      </Spin>
    </div>
  );
};

export default LotsList;
