import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { EShiftCheckOut, EShiftType, EWeekDays } from '@/services/hr/enum';
import getErrorMessage from '@/utils/getError';
import {
  Button,
  DatePicker,
  Form,
  FormListOperation,
  Input,
  message,
  Modal,
  PageHeader,
  Select,
  Spin,
  TimePicker
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RangePickerProps } from 'antd/lib/date-picker';
import { PlusOutlined } from '@ant-design/icons';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { IHRShiftPayload } from '@/services/hr/types';
import { create_hr_shift_mutation } from '@/services/hr/mutations';
import isAxiosError from '@/utils/isAxiosError';

interface FormShiftLine {
  dayOfWeek: EWeekDays;
  times: [moment.Moment, moment.Moment];
}

interface FormValues {
  name: string;
  type: EShiftType;
  checkoutType: EShiftCheckOut;
  dates: [moment.Moment, moment.Moment];
  shiftLines: FormShiftLine[];
}

function CreateShiftPage() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const selectedType = Form.useWatch('type', form);
  const shiftLines = Form.useWatch('shiftLines', form) || [];
  const allowRepeat =
    shiftLines.length === 1 ? shiftLines[0].dayOfWeek && shiftLines[0].times : false;

  async function handleRepeatShiftLines() {
    const shiftLines = form.getFieldValue('shiftLines') as FormShiftLine[];
    const firstShiftLine = shiftLines[0];

    if (!firstShiftLine || !firstShiftLine.dayOfWeek || !firstShiftLine.times) {
      message.error('Please complete the first shift line before repeating.');
      return;
    }

    const repeatedLines = Object.values(EWeekDays).map((day) => ({
      dayOfWeek: day,
      times: firstShiftLine.times
    }));

    form.setFieldValue('shiftLines', repeatedLines);
    message.success('Shift lines repeated for all days of the week.');
  }

  function isValidDateRange(start: moment.Moment | null, end: moment.Moment | null) {
    const diffInDays = end?.diff(start, 'days') || 0;
    if (diffInDays >= 7) {
      CustomErrorModal({
        message: 'For One-Time shifts, the date range cannot exceed one week.',
        title: 'Invalid Date Range'
      });
    }

    return diffInDays < 7;
  }

  const handleDateChange: RangePickerProps['onChange'] = (dates) => {
    if (!dates || selectedType !== EShiftType['One-Time']) return;

    if (dates.length === 2) {
      const [start, end] = dates;
      const isValid = isValidDateRange(start, end);
      if (!isValid) {
        form.setFieldValue(['dates'], [start]);
      }
    }
  };

  function handleTypeChange(value: EShiftType) {
    if (value === EShiftType['Recurring']) return;

    const dates = form.getFieldValue('dates');
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const isValid = isValidDateRange(start, end);
      if (!isValid) {
        form.setFieldValue(['dates'], undefined);
      }
    }
  }

  async function onFinish(values: FormValues) {
    try {
      setIsModalOpen(false);
      setIsLoading(true);

      const [start, end] = values.dates;
      const payload: IHRShiftPayload = {
        name: values.name,
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
        type: values.type,
        checkoutType: values.checkoutType,
        shiftLines: values.shiftLines.map((line) => ({
          dayOfWeek: line.dayOfWeek,
          startTime: line.times[0].format('HH:mm:ss'),
          endTime: line.times[1].format('HH:mm:ss')
        }))
      };

      await create_hr_shift_mutation(payload);
      message.success('Shift created successfully.');
      navigate('/hr/shift');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  async function onAddLines(add: FormListOperation['add']) {
    const values = form.getFieldValue('shiftLines') as FormShiftLine[];
    const lastLine = values[values.length - 1];
    const isLastLineFilled = lastLine.dayOfWeek && lastLine.times;
    if (!isLastLineFilled) {
      message.error('Please fill out the last shift line before adding more.');
      return;
    }

    add({ dayOfWeek: '', times: undefined });
  }

  function onRemoveLine(remove: FormListOperation['remove'], index: number) {
    const values = form.getFieldValue('shiftLines') as FormShiftLine[];

    // At least one line is required
    if (values.length === 1) {
      message.error('At least one shift line is required.');
      return;
    }

    remove(index);
  }

  async function onCreate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  function handleDayChange(day: EWeekDays, name: number) {
    const values = form.getFieldValue('shiftLines') as FormShiftLine[];

    const allShiftLineExceptCurrent = values.filter((_, index) => index !== name);
    const isDuplicate = allShiftLineExceptCurrent.find((u) => u.dayOfWeek === day);

    if (isDuplicate) {
      message.error(`Shift line for ${day} already exists.`);
      form.setFieldValue(['shiftLines', name, 'dayOfWeek'], undefined);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={form.submit}>
        <p>Are you sure you want to create this shift?</p>
      </Modal>
      <AppContent breadcrumbItems={[{ label: 'Shift', link: '/hr/shift' }, { label: 'Create' }]}>
        <PageHeader title="Details" style={{ padding: '8px 0' }} className="small-title" />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            shiftLines: [{ dayOfWeek: '', times: undefined }],
            checkoutType: EShiftCheckOut.NONE
          }}>
          <div className="grid sm:grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter the shift name' }]}>
              <Input placeholder="Enter Shift name" />
            </Form.Item>

            <Form.Item
              name="type"
              label="Type"
              rules={[{ required: true, message: 'Please select the shift type' }]}>
              <Select placeholder="Select a type!" onChange={handleTypeChange}>
                {Object.keys(EShiftType).map((type) => {
                  const value = EShiftType[type as keyof typeof EShiftType];
                  return (
                    <Select.Option value={value} key={type}>
                      {type}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="dates"
              label="Date"
              rules={[{ required: true, message: 'Please select the date range' }]}>
              <DatePicker.RangePicker
                className="w-full"
                onChange={handleDateChange}
                disabled={!selectedType}
              />
            </Form.Item>

            <Form.Item
              name="checkoutType"
              label="Checkout Type"
              rules={[{ required: true, message: 'Please select the checkout type' }]}>
              <Select placeholder="Select a checkout type!">
                {Object.keys(EShiftCheckOut).map((type) => {
                  const value = EShiftCheckOut[type as keyof typeof EShiftCheckOut];
                  return (
                    <Select.Option value={value} key={type}>
                      {type}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div>
            <PageHeader
              title="Shift Details"
              style={{ padding: '8px 0 4px' }}
              className="small-title"
              extra={
                <Button disabled={!allowRepeat} onClick={handleRepeatShiftLines}>
                  Repeat Shift
                </Button>
              }
            />

            <Form.List name="shiftLines">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }, index) => (
                    <div key={key} className="flex gap-2 items-center">
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card w-full"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div className={'grid grid-cols-1 gap-2 sm:grid-cols-3 lg:grid-cols-6'}>
                          <Form.Item
                            label="Day"
                            name={[name, 'dayOfWeek']}
                            rules={[{ required: true, message: 'Please select a day' }]}>
                            <Select
                              onChange={(day) => handleDayChange(day, name)}
                              placeholder="Select a day"
                              options={Object.values(EWeekDays).map((day) => ({
                                label: day,
                                value: day
                              }))}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Time Range"
                            name={[name, 'times']}
                            rules={[{ required: true, message: 'Please select a time' }]}>
                            <TimePicker.RangePicker className="w-full" format={'HH:mm'} />
                          </Form.Item>

                          <div className="flex items-center justify-start sm:mt-[1.875rem] h-fit gap-2">
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="green"
                              marginLeft="0"
                              onClick={() => onRemoveLine(remove, name)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Add Button */}
                  <Form.Item hidden={fields.length >= 7}>
                    <Button type="dashed" onClick={() => onAddLines(add)} block>
                      <PlusOutlined /> Add Lines
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className="flex justify-end mt-4 gap-3">
            <Button type="primary" onClick={onCreate} disabled={isLoading}>
              Create Shift
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CreateShiftPage;
