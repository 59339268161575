import { Button, Form, Input, Menu, Spin, TableProps, Tooltip, message } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState, useRef } from 'react';
import { getUserData } from '@/utils/auth.utils';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { ConvertObjectToURL } from '@/utils/converturl';
import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import ActionDropdown from '@/components/Common/Dropdownactions';
import TableFilter from '@/components/FliterTable';
import {
  ISalesRegisterList,
  ISalesRegisterListData,
  ISalesRegisterListExport
} from '@/services/ird/sales/types';
import { CustomModal } from '@/components/Common/CustomModal';
import { useReactToPrint } from 'react-to-print';
import { get_sales_register_list } from '@/services/ird/sales/queries';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CopyButton from '@/components/Common/CopyButton';
import CustomersDB from '@/store/localstorage/CustomerDB';
import { get_customer_list_ids, get_user_pan_ids } from '@/services/users/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { exportRegisterItemWiseExcel } from '../../exportRegister';
import { IExportNestedColumn } from '@/utils/exportNestedExcel';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import CustomTableForIRDItemWise from '@/components/Common/CustomizeTable/CustomTableForIrdItemWise';

const SalesRegisterList = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const [allSalesList, setAllSalesList] = useState<ISalesRegisterListData>({
    total: 0,
    results: [],
    qtySold: 0
  });
  const [exportData, setExportData] = useState<{
    data: ISalesRegisterListExport[];
    range: { from: string; to: string };
  }>({ data: [], range: { from: '', to: '' } });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const breadcrumbItems = [
    {
      label: 'Sales Register',
      link: '/ird/sales-register'
    }
  ];

  const columns: ColumnsType<ISalesRegisterList> = [
    {
      title: 'Tax Invoice',
      width: 100,
      children: [
        {
          title: 'Date',
          key: 'date',
          width: 20,
          dataIndex: 'date',
          sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
          sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null
        },
        {
          title: 'Miti',
          key: 'miti',
          width: 20,
          dataIndex: 'miti',
          sorter: (a, b) => a.miti.localeCompare(b.miti),
          sortOrder: sortedInfo.columnKey === 'miti' ? sortedInfo.order : null
        },
        {
          title: 'Time',
          key: 'time',
          width: 20,
          dataIndex: 'time'
        },
        {
          title: 'Bill Number',
          key: 'financialReference',
          className: 'invoice',
          width: 35,
          sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
          sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
          render: (a, record) => {
            return (
              <TableCell className="flex flex-row justify-between items-center text-xs px-1">
                {record.financialReference}
                {record.financialReference && <CopyButton text={record.financialReference} />}
              </TableCell>
            );
          }
        },
        {
          title: "Buyer's Name",
          key: 'buyersName',
          width: 20,
          sorter: (a, b) =>
            a.buyersName && b.buyersName ? a.buyersName.localeCompare(b.buyersName) : 0,
          sortOrder: sortedInfo.columnKey === 'buyersName' ? sortedInfo.order : null,
          render: (a, record) => {
            return <TableCell>{record.buyersName}</TableCell>;
          }
        },
        {
          title: `Buyer's PAN`,
          key: 'customerPan',
          width: 25,
          render: (a, record) => {
            return <TableCell>{record.customerPan}</TableCell>;
          }
        },
        {
          title: 'Goods/ Service Name',
          key: 'goodsServiceName',
          width: 25,
          render: (a, record) => {
            return <TableCell>{'Goods for: ' + record.financialReference}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Total Sales/ Export',
      width: 20,
      key: 'totalSalesExport',
      dataIndex: 'initialAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalSalesExport' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell>
            {nepaliNumberFormatter(record.totalNonTaxable + record.totalTaxable)}
          </TableCell>
        );
      }
    },
    {
      title: 'Local Tax Rebate',
      width: 20,
      key: 'localTaxRebate',
      dataIndex: 'totalNonTaxable',
      render: (a, record) => {
        return <TableCell>{nepaliNumberFormatter(record.totalNonTaxable)}</TableCell>;
      }
    },
    {
      title: 'Taxable Sales',
      width: 20,
      children: [
        {
          title: 'Amount',
          key: 'taxableSalesAmount',
          dataIndex: 'totalTaxable',
          width: 15,
          render: (a, record) => {
            return <TableCell>{nepaliNumberFormatter(record.totalTaxable)}</TableCell>;
          }
        },
        {
          title: 'Tax',
          key: 'taxablePurchaseTax',
          dataIndex: 'totalVat',
          width: 15,
          render: (a, record) => {
            return <TableCell>{nepaliNumberFormatter(record.totalVat)}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Export',
      width: 20,
      children: [
        {
          title: 'Goods/ Export Price',
          width: 20,
          key: 'goodsPurchasePrice',
          render: (a, record) => {
            return <TableCell>{nepaliNumberFormatter(0)}</TableCell>;
          }
        },
        {
          title: 'Exported Country',
          width: 20,
          key: 'exportedCountry',
          render: (a, record) => {
            return <TableCell>{''}</TableCell>;
          }
        },
        {
          title: 'PGP Number',
          width: 20,
          key: 'lcNumber',
          render: (a, record) => {
            return <TableCell>{''}</TableCell>;
          }
        },
        {
          title: 'PGP Date',
          width: 20,
          key: 'lcDateNumber',
          render: (a, record) => {
            return <TableCell>{''}</TableCell>;
          }
        }
      ]
    }
  ];

  const columsforPrint: any = [
    {
      title: 'Tax Invoice',
      width: 100,
      children: [
        {
          title: 'Date',
          dataIndex: 'date',
          width: 20,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Miti',
          dataIndex: 'miti',
          width: 20,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Time',
          dataIndex: 'time',
          width: 10,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Bill Number',
          dataIndex: 'financialReference',
          width: 30,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: `Buyer's Name`,
          dataIndex: 'buyersName',
          width: 20,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: `Buyer's PAN`,
          dataIndex: 'customerPan',
          width: 25,
          render: (text: number) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Goods/ Service Name',
          dataIndex: 'goodsServiceName',
          width: 25,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Total Sales/ Export',
      width: 20,
      dataIndex: 'totalSalesExport',
      isNumber: true,
      render: (text: number) => {
        return <TableCell>{nepaliNumberFormatter(text)}</TableCell>;
      }
    },
    {
      title: 'Local Tax Rebate',
      isNumber: true,
      width: 20,
      dataIndex: 'localTaxRebate',
      render: (text: number) => {
        return <TableCell>{nepaliNumberFormatter(text)}</TableCell>;
      }
    },
    {
      title: 'Taxable Sales',
      width: 85,
      children: [
        {
          title: 'Amount',
          dataIndex: 'taxableSalesAmount',
          isNumber: true,
          width: 55,
          render: (text: number) => {
            return <TableCell>{nepaliNumberFormatter(text)}</TableCell>;
          }
        },
        {
          title: 'Tax',
          dataIndex: 'taxableSalesTax',
          isNumber: true,
          width: 30,
          render: (text: number) => {
            return <TableCell>{nepaliNumberFormatter(text)}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Export',
      width: 20,
      children: [
        {
          title: 'Goods/ Export Price',
          width: 20,
          dataIndex: 'goodsPurchasePrice',
          render: (text: number) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Exported Country',
          width: 20,
          dataIndex: 'exportedCountry',
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'PGP Number',
          width: 20,
          dataIndex: 'lcNumber',
          render: (text: number) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'PGP Date',
          width: 20,
          dataIndex: 'lcDate',
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        }
      ]
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_sales_register_list(filter);
    // console.log('response', response);
    if (response) {
      const customerUserIds = new Set<number>();
      for (let index = 0; index < response.results.length; index++) {
        const item = response.results[index];
        const date = convertUTCStringtoLocalString(item.date, 'YYYY-MM-DD');
        const miti = convert_string_to_nepali_date_string(date).replaceAll('-', '.');
        const time = moment(item.date, 'HH:mm').format('hh:mm a');

        response.results[index].date = date;
        response.results[index].miti = miti;
        response.results[index].time = time;

        // set customer name
        let customer = await CustomersDB.getCustomer(response.results[index].customerId);
        if (!customer) {
          const allCustomers = await get_customer_list_ids([
            ...new Set(
              response.results.map((value) => {
                return value.customerId;
              })
            )
          ]);
          await CustomersDB.addCustomers(allCustomers.data.results);
          customer = await CustomersDB.getCustomer(response.results[index].customerId);
        }

        if (typeof customer === 'object' && customer.userId) {
          customerUserIds.add(customer.userId);
          response.results[index].buyersName = customer.user.name;
          response.results[index].customerUserId = customer.userId;
        }

        const currentData = response.results[index];
        currentData.initialAmount = currentData.totalNonTaxable + currentData.totalTaxable;
      }

      // pan
      const { data: userPanList } =
        customerUserIds.size > 0 ? await get_user_pan_ids([...customerUserIds]) : { data: [] };

      for (let index = 0; index < response.results.length; index++) {
        const item = response.results[index];
        const customerPan =
          userPanList.find((user) => user.userId === item.customerUserId)?.taxNumber || '';
        response.results[index].customerPan = customerPan;
      }

      const dataUpdated = response.results.map((item) => {
        return {
          ...item,
          goodsServiceName: 'Goods for: ' + item.financialReference,
          totalSalesExport: item.totalNonTaxable + item.totalTaxable,
          localTaxRebate: item.totalNonTaxable,
          taxableSalesAmount: item.totalTaxable,
          taxableSalesTax: item.totalVat,
          goodsPurchasePrice: 0,
          exportedCountry: '',
          lcNumber: '',
          lcDate: ''
        };
      });

      // set year and date
      const startDateEnglish = form.getFieldValue(['startDate']);
      const endDateEnglish = form.getFieldValue(['endDate']);

      const stringStartDate = moment(startDateEnglish).format('YYYY-MM-DD');
      const stringEndDate = moment(endDateEnglish).format('YYYY-MM-DD');

      const nepaliStartDate = convert_string_to_nepali_date_string(stringStartDate);
      const nepaliEndDate = convert_string_to_nepali_date_string(stringEndDate);

      setAllSalesList(response);
      setExportData({
        data: dataUpdated,
        range: {
          from: `${stringStartDate} (${nepaliStartDate.replaceAll('-', '.')})`,
          to: `${stringEndDate} (${nepaliEndDate.replaceAll('-', '.')})`
        }
      });
    }
    setIsloading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  function handleExcelExportDynamically() {
    const exportColumns: IExportNestedColumn<ISalesRegisterListExport>[] = [
      {
        title: 'Tax Invoice',
        dataIndex: '',
        children: [
          { title: 'Date', dataIndex: 'date', width: 12 },
          { title: 'Miti', dataIndex: 'miti', width: 12 },
          { title: 'Time', dataIndex: 'time', width: 12 },
          { title: 'Bill Number', dataIndex: 'financialReference', width: 25 },
          { title: `Buyer's Name`, dataIndex: 'buyersName', width: 25 },
          { title: `Buyer's PAN`, dataIndex: 'customerPan', width: 15 },
          { title: 'Goods/ Service Name', dataIndex: 'goodsServiceName', width: 35 }
        ]
      },
      {
        title: 'Total Sales/ Export',
        width: 12,
        dataIndex: 'totalSalesExport',
        showDashIfAbsent: true,
        formatByComma: true,
        alignment: { horizontal: 'right' }
      },
      {
        title: 'Local Tax Rebate',
        width: 12,
        dataIndex: 'localTaxRebate',
        showDashIfAbsent: true,
        formatByComma: true,
        alignment: { horizontal: 'right' }
      },
      {
        title: 'Taxable Sales',
        dataIndex: '',
        children: [
          {
            title: 'Amount',
            dataIndex: 'taxableSalesAmount',
            width: 12,
            formatByComma: true,
            showDashIfAbsent: true,
            alignment: { horizontal: 'right' }
          },
          {
            title: 'Tax',
            dataIndex: 'taxableSalesTax',
            width: 12,
            formatByComma: true,
            showDashIfAbsent: true,
            alignment: { horizontal: 'right' }
          }
        ]
      },
      {
        title: 'Export',
        dataIndex: '',
        children: [
          { title: 'Goods/ Export Price', width: 12, dataIndex: 'goodsPurchasePrice' },
          { title: 'Exported Country', width: 12, dataIndex: 'exportedCountry' },
          { title: 'PGP Number', width: 12, dataIndex: 'lcNumber' },
          { title: 'PGP Date', width: 12, dataIndex: 'lcDate' }
        ]
      }
    ];

    if (allSalesList.results.length === 0) {
      message.error('No Data to Export');
      return;
    }

    exportRegisterItemWiseExcel({
      title: 'Sales Register',
      data: exportData.data,
      exportYear: exportData.range,
      columns: exportColumns,
      fileName: 'Rule 23 Report (Sales Register)'
    });
  }

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        width="90%"
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableForIRDItemWise
            columns={columsforPrint}
            data={exportData.data}
            reff={printPDFRef}
            title={'Sales Register'}
            subTitle={'Related With Rule 23 SubRule (1) Part (H)'}
            exportYear={exportData.range}
            highlightHeader
            showEmptyRow
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  onPagination={(page, size) => {
                    setPage(page);
                    setSize(size);
                  }}
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    value: '',
                    locationId: preferenceLocationId ? preferenceLocationId : 1,
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end items-center'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  form={form}>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: handleExcelExportDynamically
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (allSalesList.results.length === 0) {
                            message.error('No Data to Export');
                            setIsloading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>

        <GenericTable
          form={form}
          columns={columns}
          data={allSalesList.results}
          hideDefaultPagination={true}
          scroll={{ x: 1750, y: '75vh' }}
          generateSummary
          summaryClassName="text-left"
          pagination={{
            page: page,
            total: allSalesList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default SalesRegisterList;
