import { Form, Input, Menu, Modal, Spin, TableProps, Tooltip, message } from 'antd';
import { useState } from 'react';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import AppContent from '../../../components/Common/Content/Content';
import TableFilter from '../../../components/FliterTable';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { checkAccess } from '../../../routes/acl';
import { Link, useNavigate } from 'react-router-dom';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import CustomErrorModal from '../../../components/Common/CustomErrorModal';
import { get_menu_items, get_menu_items_filter } from '../../../services/settings/queries';
import { IMenuDeleteResponse, IMenuList, IMenuListServer } from '../../../services/settings/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import { optionalStringSorter } from '../../../utils/sorter.utils';
import { AxiosError, AxiosResponse } from 'axios';
import { IServerError } from '../../../services/response/types';
import { delete_menu_item } from '../../../services/settings/mutations';
import MenuDB from '../../../store/localstorage/MenuDB';
import { ConvertObjectToURL } from '../../../utils/converturl';

export const AdminMenuList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [menuList, setMenuList] = useState<IMenuListServer>({ results: [], total: 0 });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [selectedIdForDelete, setSelectedIdForDelete] = useState<number>();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Dev Menu',
      link: '/admin/menu'
    }
  ];

  const columns: ColumnsType<IMenuList> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 15,
      sorter: (a, b) => optionalStringSorter(a.name, b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (txt, record) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },
    {
      title: 'Key',
      key: 'key',
      width: 15,
      sorter: (a, b) => optionalStringSorter(a.key, b.key),
      sortOrder: sortedInfo.columnKey === 'key' ? sortedInfo.order : null,
      render: (txt, record) => {
        return <TableCell>{record.key}</TableCell>;
      }
    },
    {
      title: 'Keyword',
      key: 'keyword',
      width: 15,
      sorter: (a, b) => optionalStringSorter(a.keyword, b.keyword),
      sortOrder: sortedInfo.columnKey === 'keyword' ? sortedInfo.order : null,
      render: (txt, record) => {
        return <TableCell>{record.keyword}</TableCell>;
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 5,
      sorter: (a, b) => optionalStringSorter(a.type, b.type),
      sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null,
      render: (txt, record) => {
        return <TableCell>{record.type}</TableCell>;
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 5,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: () => void;
        }[] = [];

        if (checkAccess('ADMIN')) {
          menuItems.push(
            {
              key: '1',
              label: (
                <Tooltip title="Update" color="blue">
                  <Link to={`/admin/menu/${record.id}`}>
                    <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                  </Link>
                </Tooltip>
              )
            },
            {
              key: '2',
              label: (
                <Tooltip title="Delete" color="blue">
                  <DeleteOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              ),
              onClick: () => {
                setSelectedIdForDelete(record.id);
                setDeleteConfirmOpen(true);
              }
            }
          );
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    const values = form.getFieldsValue();
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    try {
      const response = await get_menu_items_filter(filter);
      if (response) setMenuList(response.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      CustomErrorModal({ title: 'Error', message: 'Could not fetch data!' });
    }
  };

  const handleDelete = async (id?: number) => {
    if (id) {
      await deleteMenuItemMutation.mutateAsync(id, {
        onSuccess: async () => {
          setIsLoading(false);
          setSelectedIdForDelete(undefined);
          setDeleteConfirmOpen(false);
          message.success('Menu item deleted successfully');
          await MenuDB.deleteMenuItem(id);
          await onPagination();
        },
        onError: (e) => {
          setIsLoading(false);
          message.error(`${e.response?.data.message}`);
        }
      });
    } else {
      CustomErrorModal({ title: 'Error', message: 'Error please try again!' });
    }
  };

  const handleCancelDelete = () => {
    setSelectedIdForDelete(undefined);
    setDeleteConfirmOpen(false);
  };

  const deleteMenuItemMutation = useMutation<
    AxiosResponse<IMenuDeleteResponse>,
    AxiosError<IServerError>,
    number
  >(delete_menu_item);

  return (
    <Spin spinning={isLoading}>
      <Modal
        title="Confirmation"
        visible={deleteConfirmOpen}
        onOk={async () => await handleDelete(selectedIdForDelete)}
        onCancel={handleCancelDelete}>
        <div>Are you sure you want to delete this menu item?</div>
      </Modal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div className="flex flex-wrap items-center justify-end">
              <div>
                <TableFilter
                  dateCustom={false}
                  onPagination={(page, size) => {
                    setPage(page);
                    setSize(size);
                  }}
                  defaultValues={{
                    value: '',
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  form={form}
                  style={'grid grid-cols-1 gap-2'}
                  styleforbuttons={'flex justify-end items-center'}
                  buttons={
                    <>
                      {checkAccess('ADMIN') && (
                        <div>
                          <CustomButton
                            onClick={() => navigate('/admin/menu/new')}
                            text="Add"
                            backgroundColor="#1890ff"
                            Linkto="/admin/menu/new"
                          />
                        </div>
                      )}
                    </>
                  }>
                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              </div>
            </div>
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={menuList.results}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          toSort={handleChange}
          paginationDatas={{
            page: page,
            total: menuList.total,
            size: size,
            onPagination
          }}
        />
      </AppContent>
    </Spin>
  );
};
