import { useMutation } from '@tanstack/react-query';
import { Input, message, Menu, Form, TableProps, Spin, Select, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LocationFilterDrawer from '@/components/@Locations/FilterDrawer';
import AppContent from '@/components/Common/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import DeleteConfirmModal from '@/components/Common/Deleteconfirm/DeleteConfirmModal';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import TableFilter from '@/components/FliterTable';
import useFilterDrawers from '@/hooks/useFilterDrawers';
import { checkAccess, isAdmin } from '@/routes/acl';
import { delete_routes } from '@/services/routes/mutations';
import { get_routes_list_filter } from '@/services/routes/queries';
import { IRoutes, IRoutesListData } from '@/services/routes/types';

import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import ActionDropdown from '@/components/Common/Dropdownactions';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import { SwapOutlined } from '@ant-design/icons';

const List: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const { visible, onClose } = useFilterDrawers();
  const [allRoutes, setAllRoutes] = useState<IRoutesListData>({ total: 0, results: [] });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_routes_list_filter(filter);
    setAllRoutes(response.data);
    setIsloading(false);
  };

  const deleteRoutesMutation = useMutation(delete_routes, {
    onSuccess: () => {
      message.success('Route deleted successfully');
      getInfo();
    },
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
    }
  });

  const columns: ColumnsType<IRoutes> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 10,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 100,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },
    {
      title: 'Status',
      key: 'isActive',
      width: 10,
      render: (record) => {
        return (
          <Tag color={record.isActive ? 'green' : 'red'}>
            {record.isActive ? 'ACTIVE' : 'INACTIVE'}
          </Tag>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          deleteRoutesMutation.mutate(record.id);
        };

        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('UPDATE_ROUTE')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/routes/${record.id}`} />
          });
        }
        if (checkAccess('DELETE_ROUTE')) {
          menuItems.push({
            key: '2',
            label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '3',
            label: <DiscussionChatModal slug="routes" id={record.id} />
          });
        }

        if (isAdmin()) {
          menuItems.push({
            key: '4',
            label: (
              <Tooltip title="Move All Address">
                <Link
                  to={`/routes/move-all-addresses/${record.id}`}
                  className="flex justify-between">
                  <SwapOutlined style={{ transform: 'scale(1.5)', width: '105%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [{ label: 'Routes', link: '/routes' }];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    const response = await get_routes_list_filter(url);
    setAllRoutes(response.data);
    setIsloading(false);
    // ProductsDB.addProducts(productResponse.data.results);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  skip: 0,
                  count: 100,
                  archiveStatus: 'ACTIVE'
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    {checkAccess('CREATE_ROUTE') && (
                      <CustomButton
                        onClick={() => navigate(`/routes/new`)}
                        text="Add Route"
                        backgroundColor="#1890ff"
                        // marginTop="0px"
                        Linkto="/routes/new"
                      />
                    )}
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
                <Form.Item name="archiveStatus" label="Archived">
                  <Select
                    options={[
                      { value: 'ALL', label: 'All' },
                      { value: 'ACTIVE', label: 'Unarchived' },
                      { value: 'INACTIVE', label: 'Archived' }
                    ]}></Select>
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        {/* <Table
          rowSelection={{
            ...rowSelection
          }}
          scroll={{ x: true }}
          columns={columns}
          dataSource={allRoutes}
          rowKey={'id'}
          loading={isLoading}
        /> */}
        <CustomizeTable
          form={form}
          columns={columns}
          data={allRoutes.results}
          // usersLoading={isLoading}
          notshowPagination={true}
          // toshowcustomPagintion={true}
          // onPagination={onPagination}
          paginationDatas={{
            page: page,
            total: allRoutes.total,
            size: size,
            onPagination
          }}
          tableName={'routes-list'}
          toSort={handleChange}
        />

        <LocationFilterDrawer visible={visible} onClose={onClose} />
      </AppContent>
    </Spin>
  );
};

export default List;
