import CustomCalendarIcon from '@/components/Common/CustomIcons/CustomCalendarIcon';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import { IHRUserInfo, IHRUserList } from '@/services/hr/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { FormInstance, Menu, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

interface Props {
  isLoading?: boolean;
  data: IHRUserList;
  form: FormInstance;
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<void>;
}

function Table({ isLoading, data, pagination, onPagination, form }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IHRUserInfo>>({});

  const handleChange: TableProps<IHRUserInfo>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IHRUserInfo>);
  };

  const columns: ColumnsType<IHRUserInfo> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 8,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Employee',
      key: 'userName',
      dataIndex: 'userName',
      width: 30,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null
    },
    {
      title: 'Personal Details',
      children: [
        {
          title: 'Gender',
          key: 'gender',
          dataIndex: 'gender',
          width: 10,
          sorter: (a, b) => a.gender.localeCompare(b.gender),
          sortOrder: sortedInfo.columnKey === 'gender' ? sortedInfo.order : null
        },

        {
          title: 'Permanent Location',
          key: 'permanentLocation',
          dataIndex: 'permanentLocation',
          width: 25,
          sorter: (a, b) => a.permanentLocation.localeCompare(b.permanentLocation),
          sortOrder: sortedInfo.columnKey === 'permanentLocation' ? sortedInfo.order : null
        },
        {
          title: 'Current Location',
          key: 'currentLocation',
          dataIndex: 'currentLocation',
          width: 25,
          sorter: (a, b) => a.currentLocation.localeCompare(b.currentLocation),
          sortOrder: sortedInfo.columnKey === 'currentLocation' ? sortedInfo.order : null
        },
        {
          title: 'Blood Group',
          key: 'bloodGroup',
          dataIndex: 'bloodGroup',
          width: 12,
          sorter: (a, b) => a.bloodGroup.localeCompare(b.bloodGroup),
          sortOrder: sortedInfo.columnKey === 'bloodGroup' ? sortedInfo.order : null
        },
        {
          title: 'Marital Status',
          key: 'maritalStatus',
          dataIndex: 'maritalStatus',
          width: 10,
          sorter: (a, b) => a.maritalStatus.localeCompare(b.maritalStatus),
          sortOrder: sortedInfo.columnKey === 'maritalStatus' ? sortedInfo.order : null
        }
      ]
    },
    {
      title: 'Bank Details',
      children: [
        {
          title: 'Account No',
          key: 'accountNo',
          dataIndex: 'accountNo',
          width: 20,
          sorter: (a, b) => a.accountNo.localeCompare(b.accountNo),
          sortOrder: sortedInfo.columnKey === 'accountNo' ? sortedInfo.order : null
        },
        {
          title: 'Bank Name',
          key: 'bankName',
          dataIndex: 'bankName',
          width: 30,
          sorter: (a, b) => a.bankName.localeCompare(b.bankName),
          sortOrder: sortedInfo.columnKey === 'bankName' ? sortedInfo.order : null
        },
        {
          title: 'Branch Name',
          key: 'branchName',
          dataIndex: 'branchName',
          width: 20,
          sorter: (a, b) => a.branchName.localeCompare(b.branchName),
          sortOrder: sortedInfo.columnKey === 'branchName' ? sortedInfo.order : null
        }
      ]
    },
    {
      title: 'Job Details',
      children: [
        {
          title: 'Job Type',
          key: 'jobType',
          dataIndex: 'jobType',
          width: 30,
          sorter: (a, b) => a.jobType.localeCompare(b.jobType),
          sortOrder: sortedInfo.columnKey === 'jobType' ? sortedInfo.order : null
        },

        {
          title: 'Join Date',
          key: 'joinDate',
          dataIndex: 'joinDate',
          width: 15,
          sorter: (a, b) => a.joinDate.localeCompare(b.joinDate),
          sortOrder: sortedInfo.columnKey === 'joinDate' ? sortedInfo.order : null,
          render: (_, record) => convertUTCStringtoLocalString(record.joinDate, 'YYYY-MM-DD')
        },
        {
          title: 'Date of Termination',
          key: 'dateOfTermination',
          dataIndex: 'dateOfTermination',
          width: 15,
          sorter: (a, b) => (a.dateOfTermination || '').localeCompare(b.dateOfTermination || ''),
          sortOrder: sortedInfo.columnKey === 'dateOfTermination' ? sortedInfo.order : null,
          render: (dateOfTermination) =>
            dateOfTermination ? convertUTCStringtoLocalString(dateOfTermination, 'YYYY-MM-DD') : ''
        },
        {
          title: 'Departments',
          key: 'departments',
          dataIndex: 'departments',
          width: 25,
          sorter: (a, b) => a.departments.localeCompare(b.departments),
          sortOrder: sortedInfo.columnKey === 'departments' ? sortedInfo.order : null
        }
      ]
    },
    {
      title: 'Last Updated',
      key: 'lastUpdated',
      width: 25,
      dataIndex: 'updatedAt',
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'lastUpdated' ? sortedInfo.order : null,
      render: (_, record) => convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 12,
      fixed: 'right',
      render: (record: IHRUserInfo) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [
          {
            key: 'view',
            label: <CustomViewIcon link={`/hr/${record.id}`} />
          }
        ];

        if (checkAccess('UPDATE_HR_USER_INFORMATION')) {
          menuItems.push({
            key: 'update',
            label: <CustomUpdateIcon link={`/hr/details/${record.id}`} />
          });
        }

        if (checkAccess('READ_ALL_USER_HR')) {
          menuItems.push({
            key: 'calendar',
            label: <CustomCalendarIcon link={`/hr/calendar/${record.userId}`} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 2500 }}
        hideDefaultPagination
        tableName={'hr-employee-list'}
        pagination={{ ...pagination, total: data.total, onPagination, scrollToTop: true }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
