import { UsergroupAddOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface CustomIconProps {
  link: string;
  label?: string;
}

const CustomAddUserIcon: React.FC<CustomIconProps> = ({ link, label = 'Add' }) => {
  return (
    <Tooltip title={label} color="blue">
      <Link to={link}>
        <UsergroupAddOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
      </Link>
    </Tooltip>
  );
};

export default CustomAddUserIcon;
