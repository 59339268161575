import moment from 'moment';

import { Calendar, momentLocalizer, Event, Components } from 'react-big-calendar';
import Toolbar from './Toolbar';
import { useState } from 'react';
import { Button, Modal, Spin, Typography } from 'antd';

const calendarLocalizer = momentLocalizer(moment);

type IDate = Date[] | { start: Date; end: Date };

interface Props<TEvent extends object> {
  events: TEvent[];
  onFetchEvents: (startDate: string, endDate: string) => Promise<void>;
  startAccessor?: keyof TEvent;
  endAccessor?: keyof TEvent;
  eventPropGetter?: (event: TEvent) => object;
  components?: Components<TEvent>;
  renderModalContent?: (event: TEvent) => React.ReactNode;
}

function TimelineCalendar<TEvent extends Event>({
  events,
  onFetchEvents,
  startAccessor,
  endAccessor,
  eventPropGetter,
  components,
  renderModalContent
}: Props<TEvent>) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState<TEvent | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleEventClick = (event: TEvent) => {
    setSelectedEvent(event);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedEvent(null);
  };

  const onRangeChange = async (range: IDate) => {
    try {
      setIsLoading(true);
      let startDate: string, endDate: string;

      if (Array.isArray(range)) {
        startDate = moment(range[0]).startOf('day').toISOString();
        endDate = moment(range[range.length - 1])
          .endOf('day')
          .toISOString();
      } else {
        startDate = moment(range.start).startOf('day').toISOString();
        endDate = moment(range.end).endOf('day').toISOString();
      }

      await onFetchEvents(startDate, endDate);
    } finally {
      setIsLoading(false);
    }
  };

  const defaultRenderModalContent = (event: TEvent) => (
    <div className="leading-normal">
      <Typography.Paragraph style={{ marginBottom: 4 }}>
        <strong>Title:</strong> {event.title || 'No title provided.'}
      </Typography.Paragraph>
      <Typography.Paragraph style={{ marginBottom: 4 }}>
        <strong>Start:</strong> {event.start ? new Date(event.start).toLocaleString() : 'N/A'}
      </Typography.Paragraph>
      <Typography.Paragraph style={{ marginBottom: 4 }}>
        <strong>End:</strong> {event.end ? new Date(event.end).toLocaleString() : 'N/A'}
      </Typography.Paragraph>
    </div>
  );

  return (
    <Spin spinning={isLoading}>
      <Calendar
        localizer={calendarLocalizer}
        style={{ height: '100dvh' }}
        events={events}
        popup
        eventPropGetter={eventPropGetter}
        components={{ ...components, toolbar: components?.toolbar || Toolbar }}
        onRangeChange={(range) => {
          onRangeChange(range);
        }}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
        onSelectEvent={(event) => handleEventClick(event)}
      />

      <Modal
        title="Event Details"
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Close
          </Button>
        ]}>
        {selectedEvent &&
          (renderModalContent
            ? renderModalContent(selectedEvent)
            : defaultRenderModalContent(selectedEvent))}
      </Modal>
    </Spin>
  );
}

export default TimelineCalendar;
