import React, { useState, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Tooltip, Menu, Form, TableProps, message, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { Link } from 'react-router-dom';
import AppContent from '@/components/Common/Content';
import { get_purchase_list_filter } from '@/services/purchases/queries';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { get_invoices_list } from '@/services/settings/queries';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CopyButton from '@/components/Common/CopyButton';
import { ExportColumnType } from '@/utils/exportExcel';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { CustomModal } from '@/components/Common/CustomModal';
import { useReactToPrint } from 'react-to-print';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import PurchasePayment from '@/components/Common/PaymentModal/PurchasePayment';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { update_purchase_print_count_mutation } from '@/services/purchases/mutations';
import { get_sell_lists_v2 } from '@/services/sell/queries';
import { isNotEmpty } from '@/utils/common.utils';
import CustomTableForIrd from '@/components/Common/CustomizeTable/CustomTableForIrd';
import { Excel } from 'antd-table-saveas-excel';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const VatAccountList: React.FC = () => {
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState<any>();
  const [allList, setAllList] = useState<any>([]);
  const [exportList, setExportList] = useState<any>([]);
  const [fullDate, setFullDate] = useState<string>('');
  const [fullYear, setFullYear] = useState<string>('');
  const [maxTotal, setMaxTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [urlData, setUrlData] = useState('');
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });
  const getInfo = async (filter = '') => {
    setIsloading(true);
    setUrlData(filter);

    const purchaseResponse = await get_purchase_list_filter(filter);
    const sellResponse = await get_sell_lists_v2(filter);
    //setallPurchaseList(response.data);
    const allResponse = [];
    const exportResponse = [];
    const maxTotal = Math.max(purchaseResponse.data.total, sellResponse.data.total);
    const length = Math.max(purchaseResponse.data.results.length, sellResponse.data.results.length);
    for (let i = 0; i < length; i++) {
      allResponse.push({
        serialNumber: i + 1,
        purchase: purchaseResponse.data.results[i] ?? {},
        sell: sellResponse.data.results[i] ?? {}
      });
      exportResponse.push({
        serialNumber: i + 1,
        pDate: purchaseResponse.data.results[i]?.date ?? '',
        pDetails: purchaseResponse.data.results[i]?.financialReference ?? '',
        pPrice: purchaseResponse.data.results[i]?.totalAmount ?? '',
        pTaxRate: '',
        pTaxAmount: purchaseResponse.data.results[i]?.totalVat ?? '',
        sDate: sellResponse.data.results[i]?.date ?? '',
        sDetails: sellResponse.data.results[i]?.financialReference ?? '',
        sPrice: sellResponse.data.results[i]?.totalAmount ?? '',
        sTaxRate: '',
        sTaxAmount: sellResponse.data.results[i]?.totalVat ?? ''
      });
    }
    const startYear = form.getFieldValue(['startDateNepali']).split('-')[0];
    const startDate = form.getFieldValue(['startDateNepali']);
    const endYear = form.getFieldValue(['endDateNepali']).split('-')[0];
    const endDate = form.getFieldValue(['endDateNepali']);
    const fullYear = startYear === endYear ? startYear : startYear + ' to ' + endYear;
    const fullDate = startYear === endYear ? startDate : startDate + ' to ' + endDate;
    setFullDate(fullDate);
    setFullYear(fullYear);
    setAllList(allResponse);
    setExportList(exportResponse);
    setMaxTotal(maxTotal);
    setIsloading(false);
  };
  // console.log('allList-->', allList);

  const updatePrintCountMutation = useMutation(update_purchase_print_count_mutation, {
    onSuccess: () => {
      getInfo(urlData);
    }
  });
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
    setIsloading(false);
  };

  const columnsPurchase: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 10,
      sorter: (a, b) => a.serialNo - b.serialNo,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'purchase.date',
      width: 20,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'purchase.date' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {isNotEmpty(record.purchase) &&
              convertUTCStringtoLocalString(record.purchase.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Details',
      width: 20,
      key: 'purchase.financialReference',
      sorter: (a, b) => a.purchase.financialReference.localeCompare(b.purchase.financialReference),
      sortOrder: sortedInfo.columnKey === 'purchase.financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs px-1">
            {isNotEmpty(record.purchase) && (
              <>
                <Link to={`/purchase/${record.purchase.id}`} color="black">
                  {record.purchase.financialReference}
                </Link>
                {record.purchase.financialReference && (
                  <CopyButton text={record.purchase.financialReference} />
                )}
              </>
            )}
          </TableCell>
        );
      }
    },

    {
      title: 'Purchase Price',
      key: 'purchase.totalAmount',
      className: 'highlight',
      width: 20,
      sorter: (a, b) =>
        a.purchase.totalAmount -
        a.purchase.totalVat -
        (b.purchase.totalAmount - b.purchase.totalVat),
      sortOrder: sortedInfo.columnKey === 'purchase.totalAmount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {isNotEmpty(record.purchase) &&
              nepaliNumberFormatter(record.purchase.totalAmount - record.purchase.totalVat)}
          </TableCell>
        );
      }
    },

    {
      title: 'Tax Rate',
      key: 'purchase.taxRate',
      width: 20,
      sorter: (a, b) => a.purchase.taxRate - b.purchase.taxRate,
      sortOrder: sortedInfo.columnKey === 'purchase.taxRate' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell className="text-xs text-right mr-2">{record.purchase.taxRate}</TableCell>;
      }
    },
    {
      title: 'Tax Amount',
      key: 'purchase.totalVat',

      width: 20,
      sorter: (a, b) => a.purchase.totalVat - b.purchase.totalVat,
      sortOrder: sortedInfo.columnKey === 'purchase.totalVat' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/purchase/${record.purchase.id}`} color="black">
          <TableCell className="text-xs text-right mr-2">
            {isNotEmpty(record.purchase) && nepaliNumberFormatter(record.purchase.totalVat)}
          </TableCell>
          // </Link>
        );
      }
    }
  ];

  const columnsSell: ColumnsType<any> = [
    {
      title: 'Date',
      key: 'sell.date',
      width: 20,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'sell.date' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div color="black">
              {/* {moment(record.date).local().format(DEFAULT_DATE_FORMAT)} */}
              {record.sell.createdAt !== undefined &&
                convertUTCStringtoLocalString(record.sell.createdAt, DEFAULT_DATE_FORMAT)}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      width: 20,
      title: 'Details',
      key: 'sell.financialReference',
      sorter: (a, b) => a.sell.financialReference.localeCompare(b.sell.financialReference),
      sortOrder: sortedInfo.columnKey === 'sell.financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <div className="flex flex-row justify-between items-center">
              <Link to={`/sell/${record.sell.id}`} color="black">
                {record.sell.financialReference}
              </Link>
              {record.sell.financialReference ? (
                <CopyButton text={record.sell.financialReference} />
              ) : null}
            </div>
          </TableCell>
        );
      }
    },
    {
      width: 20,
      title: 'Sell Price',
      key: 'sell.totalAmount',
      className: 'highlight',
      sorter: (a, b) => a.sell.totalAmount - b.sell.totalAmount,
      sortOrder: sortedInfo.columnKey === 'sell.totalAmount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {isNotEmpty(record.sell) && nepaliNumberFormatter(record.sell.totalAmount)}
          </TableCell>
          // </Link>
        );
      }
    },

    {
      title: 'Tax Rate',
      width: 20,
      key: 'sell.taxRate',
      sorter: (a, b) => a.taxRate - b.taxRate,
      sortOrder: sortedInfo.columnKey === 'sell.taxRate' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">{record.taxRate}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Tax Amount',
      width: 20,
      key: 'sell.taxAmount',
      sorter: (a, b) => a.sell.totalVat - b.sell.totalVat,
      sortOrder: sortedInfo.columnKey === 'sell.taxAmount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {isNotEmpty(record.sell) && nepaliNumberFormatter(record.sell.totalVat)}
          </TableCell>
          // </Link>
        );
      }
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'Value Added Tax Account ',
          width: 1500,
          children: [
            {
              title: '(Rule 23 Sub Rule (1) (a))',
              width: 1120,
              children: [
                {
                  title:
                    'PAN: 609891700' +
                    `     Tax Payer's name: Freshktm` +
                    '     Year: ' +
                    fullYear +
                    '     Tax Period: ' +
                    fullDate,

                  width: 1120,
                  children: [
                    {
                      title: 'Purchase/Import',
                      width: 560,
                      children: [
                        {
                          title: 'Date',
                          dataIndex: 'pDate',
                          width: 150
                        },
                        {
                          title: 'Details',
                          dataIndex: 'pDetails',
                          width: 230
                        },
                        {
                          title: 'Purchase Price',
                          dataIndex: 'pPrice',
                          width: 60
                        },
                        {
                          title: 'Tax  Rate',
                          dataIndex: 'pTaxRate',
                          width: 60
                        },
                        {
                          title: 'Tax  Amount',
                          dataIndex: 'pTaxAmount',
                          width: 60
                        }
                      ]
                    },
                    {
                      title: 'Sell/Export',
                      width: 560,
                      children: [
                        {
                          title: 'Date',
                          dataIndex: 'sDate',
                          width: 150
                        },
                        {
                          title: 'Details',
                          dataIndex: 'sDetails',
                          width: 230
                        },
                        {
                          title: 'Sell Price',
                          dataIndex: 'sPrice',
                          width: 60
                        },
                        {
                          title: 'Tax  Rate',
                          dataIndex: 'sTaxRate',
                          width: 60
                        },
                        {
                          title: 'Tax  Amount',
                          dataIndex: 'sTaxAmount',
                          width: 60
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ];
      if (exportList.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = exportList.map((item: any, index: number) => {
        return {
          ...item,
          exportDate: convertUTCStringtoLocalString(item.Date, DEFAULT_DATE_FORMAT),
          pDate:
            item.pDate !== undefined && item.pDate != ''
              ? convertUTCStringtoLocalString(item.pDate, DEFAULT_DATE_FORMAT)
              : '',
          sDate:
            item.sDate !== undefined && item.sDate != ''
              ? convertUTCStringtoLocalString(item.sDate, DEFAULT_DATE_FORMAT)
              : ''
        };
      });
      console.log('dataUpdated', dataUpdated);
      const excel = new Excel();
      excel.setTHeadStyle({
        background: 'ffffffff'
      });
      excel
        .setTHeadStyle({
          fontSize: 10
        })
        .setTBodyStyle({
          fontSize: 10
        })
        .addSheet('VAT Account Register')
        .addColumns(columns as any[])
        .addDataSource(dataUpdated, {
          str2Percent: true
        })
        .saveAs('VAT Account Register.xlsx');
      setIsloading(false);
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const breadcrumbItems = [
    {
      label: 'VAT Account',
      link: '/ird/vat-accounts'
    }
  ];

  const columsforPrint: any = [
    {
      title: 'Purchase',
      children: [
        {
          title: 'Date',
          width: 55,
          dataIndex: 'pDate',
          render: (text: any) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Details',
          width: 55,
          dataIndex: 'pDetails',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },

        {
          title: 'Purchase Price',
          width: 55,
          dataIndex: 'pPrice',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Tax  Rate',
          width: 55,
          dataIndex: 'pTaxRate',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },

        {
          title: 'Tax  Amount',
          width: 55,
          dataIndex: 'pTaxAmount',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        }
      ]
    },
    {
      title: 'Sell',
      children: [
        {
          title: 'Date',
          width: 55,
          dataIndex: 'sDate',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Details',
          width: 55,
          dataIndex: 'sDetails',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Sell Price',
          width: 55,
          dataIndex: 'sPrice',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Tax  Rate',
          width: 55,
          dataIndex: 'sTaxRate',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Tax  Amount',
          width: 55,
          dataIndex: 'sTaxAmount',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        }
      ]
    }
  ];

  const columns = [
    {
      title: 'Purchase/Import',
      children: columnsPurchase
    },
    {
      title: 'Sell/Export',
      children: columnsSell
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="List View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          {/*  <CustomTable
            columns={columsforPrint}
            datas={allList}
            reff={printPDFRef}
            title={'VAT Account'}
          /> */}

          <CustomTableForIrd
            columns={columsforPrint}
            datas={exportList}
            reff={printPDFRef}
            title={'VAT Account'}
            subTitle="(Rule 23 Sub Rule (1) (a))"
            date={fullDate}
            year={fullYear}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  onPagination={(page, size) => {
                    setPage(page);
                    setSize(size);
                  }}
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],

                    locationId: preferenceLocationId ? preferenceLocationId : 1,

                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end items-center'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  form={form}
                  buttons={
                    <>
                      <div>
                        <ActionDropdown
                          button={true}
                          menu={
                            <Menu
                              items={[
                                {
                                  key: '1',
                                  label: (
                                    <Tooltip title="Export Excel" color="blue">
                                      <div className="text-center">Excel</div>
                                    </Tooltip>
                                  ),
                                  onClick: () => {
                                    handleExport();
                                  }
                                },
                                {
                                  key: '2',
                                  label: (
                                    <Tooltip title="Export PDF" color="blue">
                                      <div className="text-center">PDF</div>
                                    </Tooltip>
                                  ),
                                  onClick: () => {
                                    setOpenModalforexport(true);
                                  }
                                }
                              ]}
                            />
                          }
                        />
                      </div>
                    </>
                  }>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" />
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <PurchasePayment
          visible={showPaymentModal}
          setShow={setShowPaymentModal}
          selectedSale={selectedSale}
          setSelectedSale={setSelectedSale}
          form2={form}
          fetchAgain={getInfo}
        />

        <CustomizeTable
          form={form}
          columns={columns}
          data={allList}
          notshowPagination={true}
          customScroll={{ x: 1200, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: maxTotal,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default VatAccountList;
