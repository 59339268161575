import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { get_hr_user_info_details } from '@/services/hr/queries';
import { IHRUserInfoPayload } from '@/services/hr/types';
import getErrorMessage from '@/utils/getError';
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Select,
  Spin
} from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { bloodGroupOptions, genderOptions, maritalStatusOptions } from '../mutation.constant';
import { update_hr_user_info_mutation } from '@/services/hr/mutations';
import { useQuery } from '@tanstack/react-query';
import { getUser } from '@/services';
import moment from 'moment';
import VerticalForm from '@/components/FakeForm/VerticalForm';
import isAxiosError from '@/utils/isAxiosError';

interface FormValues extends IHRUserInfoPayload {
  joinDateMoment: moment.Moment;
  terminationDateMoment: moment.Moment | null;
}

function HRUserInfoDetails() {
  const [form] = Form.useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const userInfoId = Number(params.id);
  if (isNaN(userInfoId) || userInfoId <= 0) return <Navigate to="/hr" />;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const {
    isLoading: isDetailsLoading,
    isFetching,
    data
  } = useQuery(['user-information', userInfoId], async () => {
    const data = await get_hr_user_info_details(userInfoId);
    const user = await getUser(data.userId);

    const joinDateMoment = moment(data.joinDate);
    const terminationDateMoment = data.dateOfTermination ? moment(data.dateOfTermination) : null;

    form.setFieldsValue({ ...data, joinDateMoment, terminationDateMoment });
    return { ...data, joinDateMoment, terminationDateMoment, userName: user.name };
  });

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      setIsModalOpen(false);

      const { joinDateMoment, terminationDateMoment, ...payload } = values;
      payload.joinDate = joinDateMoment?.format('YYYY-MM-DD');
      payload.dateOfTermination = terminationDateMoment
        ? terminationDateMoment?.format('YYYY-MM-DD')
        : null;

      await update_hr_user_info_mutation(userInfoId, payload);
      message.success('Information updated successfully.');
      navigate('/hr');
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  async function onConfirmation() {
    setIsModalOpen(false);
    form.submit();
  }

  async function onUpdate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  return (
    <Spin spinning={isLoading || isDetailsLoading || isFetching}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={onConfirmation}>
        <p>Are you sure you want to update this information?</p>
      </Modal>

      <AppContent
        breadcrumbItems={[{ label: 'Employee', link: '/hr' }, { label: 'Update Details' }]}>
        <PageHeader title="Update Information" style={{ padding: '8px 0' }} />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name={'userId'} hidden>
            <InputNumber />
          </Form.Item>

          <VerticalForm label="Employee" value={data?.userName} />

          <div className="mt-4">
            <Collapse activeKey={['1', '2', '3']}>
              <Collapse.Panel header="Personal Information" key="1">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[{ required: true, message: 'Please select gender!' }]}>
                    <Select
                      options={genderOptions.map((gender) => ({ value: gender, label: gender }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Blood Group"
                    name="bloodGroup"
                    rules={[{ required: false, message: 'Please select blood group!' }]}>
                    <Select
                      options={bloodGroupOptions.map((bloodGroup) => ({
                        value: bloodGroup,
                        label: bloodGroup
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Martial Status"
                    name="maritalStatus"
                    rules={[{ required: false, message: 'Please select martial status!' }]}>
                    <Select
                      options={maritalStatusOptions.map((maritalStaus) => ({
                        value: maritalStaus,
                        label: maritalStaus
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Permanent Location"
                    name="permanentLocation"
                    rules={[{ required: true, message: 'Please input permanent location!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Current Location"
                    name="currentLocation"
                    rules={[{ required: true, message: 'Please input current location!' }]}>
                    <Input />
                  </Form.Item>
                </div>
              </Collapse.Panel>

              <Collapse.Panel header="Bank Information" key="2">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                  <Form.Item
                    label="Account Number"
                    name="accountNo"
                    rules={[{ required: true, message: 'Please input account number!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Bank Name"
                    name="bankName"
                    rules={[{ required: true, message: 'Please input bank name!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Branch"
                    name="branchName"
                    rules={[{ required: true, message: 'Please input branch!' }]}>
                    <Input />
                  </Form.Item>
                </div>
              </Collapse.Panel>

              <Collapse.Panel header="Job Information" key="3">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <Form.Item
                    label="Job Type"
                    name="jobType"
                    rules={[{ required: true, message: 'Please input job type!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Department"
                    name="departments"
                    rules={[{ required: true, message: 'Please input Department!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Join Date"
                    name="joinDateMoment"
                    rules={[{ required: true, message: 'Please input join date!' }]}>
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item label="Termination Date" name="terminationDateMoment">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </div>

                <div className="mt-4">
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: 'Please input description!' }]}>
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={onUpdate} disabled={isLoading}>
              Update Information
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default HRUserInfoDetails;
