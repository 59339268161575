import { Form, Input, Menu, Select, Spin, TableProps, Tooltip, message } from 'antd';
import { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { checkAccess } from '@/routes/acl';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { IAccountTypeResponseTableData, IAccountTypeTableData } from '@/services/accounts/types';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { get_account_detail_by_ids, get_account_list_filter } from '@/services/accounts/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { AccountType } from '@/services/accounts/enums';
import ActionDropdown from '@/components/Common/Dropdownactions';
import {
  BranchesOutlined,
  ClusterOutlined,
  CopyOutlined,
  EditOutlined,
  EyeFilled,
  MergeCellsOutlined
} from '@ant-design/icons';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import { getUserData } from '@/utils/auth.utils';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableAccountDuplicationModal from '@/components/Common/CustomModal/ReusableAccountDuplicationModal';
import ReusableAccountDuplicationWithChildrenModal from '@/components/Common/CustomModal/ReusableAccountDuplicationWithChildrenModal';
import ReusableAccountMultipleDuplicationWithChildrenModal from '@/components/Common/CustomModal/ReusableAccountMultipleDuplicationWithChildrenModal';
import { get_bank_detail_by_ids } from '@/services/bank/queries';
import { BankSearch } from '@/components/Common/BankSearch';
import { UsersSearch } from '@/components/Common/UsersSearch';
import { User } from '@/services/users/types';
import { AccountSwitchParent } from '@/components/Common/CustomModal/AccountSwitchParent';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';

const AccountsList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [accountsList, setAccountsList] = useState<IAccountTypeResponseTableData>(Object);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [accountType, setAccountType] = useState<AccountType>(AccountType['OTHER']);
  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);

  const [openDuplicateWithChildrenModal, setOpenDuplicateWithChildrenModal] =
    useState<boolean>(false);
  const [openMultipleDuplicateWithChildrenModal, setOpenMultipleDuplicateWithChildrenModal] =
    useState<boolean>(false);
  const [duplicationData, setDuplicationData] = useState<IAccountTypeTableData>(Object);
  const [selectedRows, setselectedRows] = useState<IAccountTypeTableData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openModalForSwitchParent, setOpenModalForSwitchParent] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Accounts',
      link: '/accounts'
    }
  ];

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const onSubmitFilter = async (val: string) => {
    const urlParams = new URLSearchParams(val);
    const type = urlParams.get('type');
    const locationId = form.getFieldValue('locationId');
    const referenceId = form.getFieldValue('referenceId');
    const value = form.getFieldValue('value');

    if (type === null) {
      val = `skip=${urlParams.get('skip')}&count=${urlParams.get('count')}`;
    } else if (locationId) {
      val = `skip=${urlParams.get('skip')}&count=${urlParams.get(
        'count'
      )}&type=${type}&locationId=${locationId}`;
    } else if (referenceId) {
      val = `skip=${urlParams.get('skip')}&count=${urlParams.get(
        'count'
      )}&type=${type}&referenceId=${referenceId}`;
    } else {
      val = `skip=${urlParams.get('skip')}&count=${urlParams.get('count')}&type=${type}`;
    }
    if (value) val += `&value=${value}`;
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    let totalBalance = 0;
    const response = await get_account_list_filter(filter);
    //console.log('Response --->', response);

    const tableData: IAccountTypeResponseTableData = response;

    // get all bank
    const bankData = await get_bank_detail_by_ids([
      ...new Set(
        tableData.results
          .filter((value) => value.type === 'BANK' && value.referenceId !== null)
          .map((value) => {
            return value.referenceId;
          })
      )
    ]);

    const allNonStaffUsers: User[] = [];
    for (let ind = 0; ind < tableData.results.length; ind++) {
      if (tableData.results[ind].createdBy !== null) {
        let createdByDetails = await UsersDB.getUser(tableData.results[ind].createdBy);
        if (!createdByDetails) {
          const allUser = await get_user_list_ids([
            ...new Set(
              tableData.results
                .filter((value) => value.createdBy !== null)
                .map((value) => {
                  return value.createdBy;
                })
            )
          ]);
          await UsersDB.addUsers(allUser.data.results);
          createdByDetails = await UsersDB.getUser(tableData.results[ind].createdBy);
        }
        tableData.results[ind].createdByName = createdByDetails.name;
      }

      if (tableData.results[ind].parentId !== null) {
        let parentAccountDetails = await AccountsDB.getAccount(tableData.results[ind].parentId);
        if (!parentAccountDetails) {
          const allAccount = await get_account_detail_by_ids([
            ...new Set(
              tableData.results
                .filter((value) => value.parentId !== null)
                .map((value) => {
                  return value.parentId;
                })
            )
          ]);
          await AccountsDB.addAccounts(allAccount.results);
          parentAccountDetails = await AccountsDB.getAccount(tableData.results[ind].parentId);
        }
        if (typeof parentAccountDetails === 'object') {
          tableData.results[ind].parentName = parentAccountDetails?.name;
        }
      }

      if (tableData.results[ind].locationId !== null) {
        let locationDetails = await LocationsDB.getLocation(tableData.results[ind].locationId);
        if (!locationDetails) {
          const allLocations = await get_location_list_for_ids([
            ...new Set(
              tableData.results
                .filter((value) => value.locationId !== null)
                .map((value) => {
                  return value.locationId;
                })
            )
          ]);
          await LocationsDB.addLocations(allLocations);
          locationDetails = await LocationsDB.getLocation(tableData.results[ind].locationId);
        }
        if (typeof locationDetails === 'object')
          tableData.results[ind].locationName = locationDetails.name;
      }

      if (tableData.results[ind].referenceId !== null) {
        if (tableData.results[ind].type === AccountType.USER) {
          let userDetails = await UsersDB.getUser(tableData.results[ind].referenceId);
          if (!userDetails) {
            const nonStaffUserDetails = allNonStaffUsers.find(
              (value) => value.id && parseInt(value.id) === tableData.results[ind].referenceId
            );
            if (nonStaffUserDetails) userDetails = nonStaffUserDetails;
            if (!userDetails) {
              const allUsers = await get_user_list_ids(
                [
                  ...new Set(
                    tableData.results
                      .filter((value) => value.referenceId !== null)
                      .map((value) => {
                        return value.referenceId;
                      })
                  )
                ],
                'yes'
              );
              await UsersDB.addUsers(allUsers.data.results.filter((val) => val.isStaff));
              allNonStaffUsers.push(...allUsers.data.results.filter((val) => !val.isStaff));
              const filteredUser = allUsers.data.results.find(
                (value) => value.id && parseInt(value.id) === tableData.results[ind].referenceId
              );
              if (filteredUser) {
                userDetails = filteredUser;
              } else {
                userDetails = await UsersDB.getUser(tableData.results[ind].referenceId);
              }
            }
          }
          tableData.results[ind].referenceName =
            typeof userDetails === 'object' && userDetails.phone
              ? `${userDetails.name} (Phn.:${userDetails.phone})`
              : typeof userDetails === 'object'
              ? userDetails.name
              : '';
        } else if (tableData.results[ind].type === AccountType.BANK) {
          const bankDetails = bankData.results.find(
            (value) => value.id === tableData.results[ind].referenceId
          );
          tableData.results[ind].referenceName = bankDetails?.name;
        } else if (
          tableData.results[ind].type === AccountType.TRANSFER_INITIATE ||
          tableData.results[ind].type === AccountType.TRANSFER_RECEIVE
        ) {
          let locationDetails = await LocationsDB.getLocation(tableData.results[ind].referenceId);
          if (!locationDetails) {
            const allLocations = await get_location_list_for_ids([
              ...new Set(
                tableData.results
                  .filter(
                    (value) =>
                      value.referenceId !== null &&
                      (value.type === AccountType.TRANSFER_INITIATE ||
                        value.type === AccountType.TRANSFER_RECEIVE)
                  )
                  .map((value) => {
                    return value.referenceId;
                  })
              )
            ]);
            await LocationsDB.addLocations(allLocations);
            locationDetails = await LocationsDB.getLocation(tableData.results[ind].referenceId);
          }
          tableData.results[ind].referenceName = locationDetails.name;
        }
      }

      // total
      totalBalance += tableData.results[ind].balance;
    }
    setAccountsList(tableData);
    setTotalBalance(totalBalance);

    setIsLoading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const handleDuplicateClick = (record: IAccountTypeTableData) => {
    // console.log('record', record);
    setDuplicationData(record);
    setOpenDuplicateModal(true);
  };

  const handleDuplicationModalClose = () => {
    setDuplicationData(Object);
    setOpenDuplicateModal(false);
  };

  const handleDuplicateWithChildrenClick = (record: IAccountTypeTableData) => {
    // console.log('record', record);
    setDuplicationData(record);
    setOpenDuplicateWithChildrenModal(true);
  };

  const handleDuplicationWithChildrenModalClose = () => {
    setDuplicationData(Object);
    setOpenDuplicateWithChildrenModal(false);
  };

  const handleMultipleDuplicateWithChildrenClick = (record: IAccountTypeTableData) => {
    // console.log('record', record);
    setDuplicationData(record);
    setOpenMultipleDuplicateWithChildrenModal(true);
  };

  const handleMultipleDuplicationWithChildrenModalClose = () => {
    setDuplicationData(Object);
    setOpenMultipleDuplicateWithChildrenModal(false);
  };

  const columns: ColumnsType<IAccountTypeTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 8,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              <Link to={'/accounts/detailed-ledger?accountId=' + record.id} target="_blank">
                {record.name}
              </Link>
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 15,
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.type}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Parent A/c',
      key: 'parentAccount',
      width: 20,
      sorter: (a, b) =>
        a.parentName && b.parentName ? a.parentName.localeCompare(b.parentName) : 0,
      sortOrder: sortedInfo.columnKey === 'parentAccount' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.parentName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Balance',
      key: 'balance',
      width: 15,
      sorter: (a, b) => a.balance - b.balance,
      sortOrder: sortedInfo.columnKey === 'balance' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell className="highlight-number">
              {nepaliNumberFormatter(record.balance)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      sorter: (a, b) =>
        a.locationName && b.locationName ? a.locationName.localeCompare(b.locationName) : 0,
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Reference',
      key: 'referenceName',
      width: 25,
      sorter: (a, b) =>
        a.referenceName && b.referenceName ? a.referenceName.localeCompare(b.referenceName) : 0,
      sortOrder: sortedInfo.columnKey === 'referenceName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.referenceName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 15,
      sorter: (a, b) =>
        a.createdByName && b.createdByName ? a.createdByName.localeCompare(b.createdByName) : 0,
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.createdByName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: () => void;
        }[] = [];

        if (record.type === AccountType.USER) {
          menuItems.push({
            key: '5',
            label: (
              <Tooltip title="View" color="blue">
                <Link to={`/accounts/view/${record.id}`}>
                  <EyeFilled style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        if (checkAccess('UPDATE_ACCOUNT')) {
          menuItems.push(
            {
              key: '1',
              label: (
                <Tooltip title="Update" color="blue">
                  <Link to={`/accounts/${record.id}`}>
                    <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                  </Link>
                </Tooltip>
              )
            },
            {
              key: '2',
              label: (
                <Tooltip title="Multiple Duplicate" color="blue">
                  <CopyOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              ),
              onClick: () => handleDuplicateClick(record)
            },
            {
              key: '3',
              label: (
                <Tooltip title="Duplicate With Children" color="blue">
                  <BranchesOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              ),
              onClick: () => handleDuplicateWithChildrenClick(record)
            },
            {
              key: '4',
              label: (
                <Tooltip title="Multiple Duplicate With Children" color="blue">
                  <ClusterOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              ),
              onClick: () => handleMultipleDuplicateWithChildrenClick(record)
            },
            {
              key: '5',
              label: (
                <Tooltip title="Merge Accounts" color="blue">
                  <MergeCellsOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              ),
              onClick: () => {
                if (record.parentId) {
                  navigate(`/accounts/merge-accounts/${record.id}`);
                } else {
                  message.error('Accounts without a parent cannot be merged', 2);
                }
              }
            }
          );
        }

        const menu = <Menu items={menuItems} />;
        return Object.values(AccountType).includes(record.type as AccountType) &&
          record.type !== AccountType.TRANSFER_INITIATE &&
          record.type !== AccountType.TRANSFER_RECEIVE ? (
          <ActionDropdown menu={menu} />
        ) : (
          <></>
        );
      }
    }
  ];

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: IAccountTypeTableData[]) => {
      // console.log('selectedRows', selectedRows);
      if (selectedRows.length <= 10) {
        if (selectedRows.length === 0) {
          setselectedRows(selectedRows);
          setSelectedRowKeys(selectedRowKeys);
        } else {
          if (areAllEqual(selectedRows)) {
            setselectedRows(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
          } else {
            message.error('Selected values cannot have different parents!');
          }
        }
      } else {
        message.error('Only 10 data can be approved at a time!');
        setselectedRows(selectedRows.slice(0, 10));
        setSelectedRowKeys(selectedRowKeys.slice(0, 10));
      }
    },
    getCheckboxProps: (record: IAccountTypeTableData) => {
      return {
        disabled:
          !record.parentId ||
          record.type === AccountType.TRANSFER_INITIATE ||
          record.type === AccountType.TRANSFER_RECEIVE
            ? true
            : false
      };
    }
  };

  const areAllEqual = (data: IAccountTypeTableData[]) => {
    const refValue = data[0].parentId;

    for (const val of data) {
      if (val.parentId !== refValue) return false;
    }

    return true;
  };

  const handleSwitchParent = () => {
    setOpenModalForSwitchParent(true);
  };

  const handleSwitchParentModalClose = async () => {
    setOpenModalForSwitchParent(false);
    setselectedRows([]);
    setSelectedRowKeys([]);
    const values = ConvertObjectToURL(form.getFieldsValue());
    await onSubmitFilter(values);
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        width={'30%'}
        footer={false}
        maskClosable={false}
        isModalOpen={openModalForSwitchParent}
        setIsModalOpen={setOpenModalForSwitchParent}
        isSticky={true}
        title="Switch Parent">
        <AccountSwitchParent
          data={selectedRows}
          locationId={form.getFieldValue(['locationId'])}
          handleModalClose={handleSwitchParentModalClose}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={openDuplicateModal}
        setIsModalOpen={setOpenDuplicateModal}
        title={'Multiple Duplicate'}
        width="60%"
        footer={false}>
        <ReusableAccountDuplicationModal
          data={duplicationData}
          handleModalClose={handleDuplicationModalClose}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={openDuplicateWithChildrenModal}
        setIsModalOpen={setOpenDuplicateWithChildrenModal}
        title={'Duplicate with Children'}
        width="60%"
        footer={false}>
        <ReusableAccountDuplicationWithChildrenModal
          data={duplicationData}
          handleModalClose={handleDuplicationWithChildrenModalClose}
        />
      </CustomModal>
      <CustomModal
        isModalOpen={openMultipleDuplicateWithChildrenModal}
        setIsModalOpen={setOpenMultipleDuplicateWithChildrenModal}
        title={'Multiple Duplicate with Children'}
        width="60%"
        footer={false}>
        <ReusableAccountMultipleDuplicationWithChildrenModal
          data={duplicationData}
          handleModalClose={handleMultipleDuplicationWithChildrenModalClose}
        />
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                dateCustom={false}
                onPagination={(page, size) => {
                  setPage(page);
                  setSize(size);
                }}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  type: 'OTHER',
                  locationId: preferenceLocationId ? preferenceLocationId : 1
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  accountType
                    ? 'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 items-center justify-center'
                    : 'grid grid-cols-2 gap-2'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {selectedRows.length > 0 && (
                      <CustomButton
                        onClick={() => {
                          handleSwitchParent();
                        }}
                        text="Switch Parent"
                        backgroundColor="#1890ff"
                      />
                    )}
                    {checkAccess('CREATE_ACCOUNT') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/accounts/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/accounts/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item label="Account Type" name="type">
                  <Select
                    placeholder="Select account type"
                    allowClear
                    onChange={(val) => {
                      form.setFieldValue(['locationId'], '');
                      form.setFieldValue(['referenceId'], '');
                      setAccountType(val);
                    }}>
                    {(Object.keys(AccountType) as Array<keyof typeof AccountType>).map((key) => (
                      <Select.Option key={key} value={key}>
                        {AccountType[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {accountType === AccountType.OTHER && (
                  <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                )}
                {accountType === AccountType.BANK && (
                  <BankSearch
                    formData={{ formLabel: 'Bank', formName: 'referenceId' }}
                    required={false}
                    isAll={false}
                  />
                )}
                {accountType === AccountType.USER && (
                  <UserSearchV2 hasParentFormItem={false} name={'referenceId'} />
                )}
                {accountType === AccountType.TRANSFER_INITIATE && (
                  <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                )}
                {accountType === AccountType.TRANSFER_RECEIVE && (
                  <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                )}
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          data={accountsList.results}
          rowSelection={rowSelection}
          rowSelectionWidth={'10px'}
          notshowPagination={true}
          customScroll={{ x: 1350, y: '75vh' }}
          tableName={'accounts-list'}
          paginationDatas={{
            page: page,
            total: accountsList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableSummaryForFilter={[
            { index: 4, render: <span className="flex">Total Balance</span> },
            {
              index: 5,
              render: <span className="flex highlight">{nepaliNumberFormatter(totalBalance)}</span>
            }
          ]}
          // tableSummary={
          //   <Table.Summary fixed={true}>
          //     <Table.Summary.Row>
          //       <Table.Summary.Cell index={0}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
          //       <Table.Summary.Cell index={2}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={3}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={4} className="text-xs">
          //         {nepaliNumberFormatter(totalBalance)}
          //       </Table.Summary.Cell>
          //       <Table.Summary.Cell index={5}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={6}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={7}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={8}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={9}></Table.Summary.Cell>
          //     </Table.Summary.Row>
          //   </Table.Summary>
          // }
        />
      </AppContent>
    </Spin>
  );
};

export default AccountsList;
