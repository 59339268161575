import { get_hr_shift_details } from '@/services/hr/queries';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Form, Input, InputNumber, message, Modal, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import { assign_hr_shift_mutation } from '@/services/hr/mutations';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import UsersDB from '@/store/localstorage/UsersDB';
import { getUser } from '@/services';
import { IUser } from '@/services/auth/types';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import isAxiosError from '@/utils/isAxiosError';

interface FormValues {
  user: number;
  users: { id: number; name: string; phone: string }[];
}

function AssignShiftPage() {
  const params = useParams();
  const navigate = useNavigate();
  const shiftId = Number(params.id);
  const [form] = Form.useForm<FormValues>();

  if (isNaN(shiftId)) return <Navigate to="/hr/shift" />;
  const [prevSelectedUser, setPrevSelectedUser] = useState<number>();

  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const {
    isLoading: isParentLoading,
    isFetching,
    data
  } = useQuery(['get_shift_details', shiftId], async () => {
    const details = await get_hr_shift_details(shiftId);
    const userIds = details.users.map((u) => u.userId);
    const uniqueUserIds = Array.from(new Set(userIds));
    await UsersDB.addUsersIfAbsent(uniqueUserIds);

    const users = await Promise.all(
      details.users.map(async (user) => {
        const userDetail = await getUser(user.userId);
        return { ...user, name: userDetail.name, phone: userDetail.phone };
      })
    );

    form.setFieldValue(
      'users',
      users.map((u) => ({ id: u.userId, name: u.name, phone: u.phone }))
    );

    return { ...details, users };
  });

  async function onFinish(values: FormValues) {
    try {
      setIsLoading(true);
      setIsVisible(false);

      const userIds = values.users.map((u) => u.id);
      await assign_hr_shift_mutation(shiftId, userIds);
      message.success('Shift assigned successfully');
      navigate(`/hr/shift/${shiftId}`);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setIsLoading(false);
    }
  }

  function onUserChange(user?: IUser) {
    if (!user || prevSelectedUser === user.id) return;
    setPrevSelectedUser(user.id);

    // Check if user is duplicate
    const currentUsers = (form.getFieldValue('users') as FormValues['users']) || [];
    const isDuplicate = currentUsers.find((u) => u.id === user.id);

    if (isDuplicate) {
      CustomErrorModal({ message: `User ${user.name} is already added` });
      return;
    }

    const updatedUsers = [...currentUsers, { id: user.id, name: user.name, phone: user.phone }];
    form.setFieldsValue({ users: updatedUsers });
  }

  return (
    <Spin spinning={isLoading || isParentLoading || isFetching}>
      <Modal
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={() => {
          form.submit();
        }}>
        <p>
          Are you sure you want to assign <span className="text-blue-400">{data?.shift?.name}</span>{' '}
          shift to selected employees?
        </p>
      </Modal>
      <AppContent breadcrumbItems={[{ label: 'Shift', link: '/hr/shift' }, { label: 'View' }]}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
          <div>
            <PageHeader subTitle="Details" style={{ padding: '0px' }} />
            <div>Name: {data?.shift?.name}</div>
            <div>Type: {data?.shift?.type}</div>
          </div>

          <div>
            <PageHeader subTitle="Date" style={{ padding: '0px' }} />
            <div>Start: {data?.shift.startDate}</div>
            <div className="mt-1">End: {data?.shift.endDate}</div>
          </div>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          initialValues={{ name: '', users: [] }}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader title="Assign Shift" style={{ padding: '8px 0' }} className="small-title" />

          <div className="mb-4">
            <UserSearchV2
              hasParentFormItem={false}
              name="user"
              hideStyle
              className="w-full max-w-lg"
              placeHolder="Select Employee"
              setSelected={onUserChange}
            />
          </div>

          <Card style={{ maxHeight: '80vh', overflowY: 'scroll', borderRadius: '9px' }}>
            <Form.List name={['users']}>
              {(fields, { remove }) =>
                fields.map((field, index) => (
                  <div key={field.key} className="flex gap-2 items-center">
                    <span className="font-bold text-sm mb-5">{index + 1}.</span>
                    <div
                      className="card  grid grid-cols-3 gap-3"
                      style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                      <Form.Item name={[field.name, 'id']} hidden>
                        <InputNumber controls={false} disabled />
                      </Form.Item>

                      <Form.Item name={[field.name, 'name']} label={'User'}>
                        <Input style={{ color: 'black' }} disabled />
                      </Form.Item>

                      <Form.Item name={[field.name, 'phone']} label={'Phone No.'}>
                        <InputNumber controls={false} style={{ color: 'black' }} disabled />
                      </Form.Item>

                      <div className="flex items-center justify-start mt-5">
                        <CustomButton
                          backgroundColor="white"
                          text="Remove"
                          textColor="green"
                          onClick={async () => {
                            remove(field.name);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </Form.List>
          </Card>

          <div className="flex justify-end mt-5">
            <Button type="primary" loading={isLoading} onClick={() => setIsVisible(true)}>
              Submit
            </Button>

            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/hr/shift')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default AssignShiftPage;
