import AppContent from '@/components/Common/Content/Content';
import { get_attendance_list } from '@/services/hr/queries';
import { IAttendanceList } from '@/services/hr/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form } from 'antd';
import { useEffect, useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import UsersDB from '@/store/localstorage/UsersDB';
import { getUser } from '@/services';
import { checkAccess } from '@/routes/acl';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import useBroadcast from '@/hooks/useBroadcast';

function AttendanceListPage() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IAttendanceList>({ total: 0, results: [] });

  const [initialFilter, setInitialFilter] = useState('');

  const { attendanceStatus } = useBroadcast();
  useEffect(() => {
    if (initialFilter) {
      onSubmitFilter(initialFilter);
    }
  }, [attendanceStatus]);

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    setInitialFilter(filter);
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_attendance_list(filter);

      const allUserIds = Array.from(new Set(response.results.map((l) => l.userId)));
      await UsersDB.addUsersIfAbsent(allUserIds);

      for (let ind = 0; ind < response.results.length; ind++) {
        const current = response.results[ind];
        const user = await getUser(current.userId);
        current.employeeName = user.name;
      }

      setData(response);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[{ label: 'HR', link: '/hr' }, { label: 'Attendance' }]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            onPagination={(page, size) => setPagination({ page, size })}
            buttons={
              <>
                {checkAccess('CREATE_HR_ATTENDANCE') && (
                  <CustomButton text="Add" backgroundColor="#1890ff" Linkto="/hr/attendance/new" />
                )}
              </>
            }
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              value: '',
              skip: 0,
              count: 100,
              userId: ''
            }}
            styleforbuttons={'flex justify-end items-center'}>
            <UserSearchV2 hasParentFormItem={false} name="userId" label="Employee" showAll />
          </TableFilter>
        }>
        <Table
          data={data}
          form={form}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default AttendanceListPage;
