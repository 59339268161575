import moment from 'moment';
import { Button, DatePicker, Form, message, Modal, PageHeader, Select, Spin } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppContent from '@/components/Common/Content/Content';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import { getUserData } from '@/utils/auth.utils';
import getErrorMessage from '@/utils/getError';
import { IHRAttendancePayload, IHRShift } from '@/services/hr/types';
import { create_hr_attendance_mutation } from '@/services/hr/mutations';
import { IUser } from '@/services/auth/types';
import { get_hr_shift_by_user_and_date } from '@/services/hr/queries';
import redirectToNewTab from '@/utils/redirectToNewTab';
import { AxiosError } from 'axios';
import { checkAccess } from '@/routes/acl';
import useBroadcast from '@/hooks/useBroadcast';
import ErrorModalWithLog from '@/components/Common/ErrorModal/ErrorModalWithLog';

interface FormValues {
  userId: number;
  shiftId: number;
  checkIn: moment.Moment;
}

function CreateAttendance() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<FormValues>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const currentUser = getUserData();
  const { handleAttendance } = useBroadcast();

  const selectedUserId = Form.useWatch('userId', form);
  const [usersShift, setUsersShift] = useState<IHRShift[]>([]);
  const [prevSelectedUser, setPrevSelectedUser] = useState<number>();

  async function onFinish(values: FormValues) {
    const isSelf = values.userId === currentUser.id;

    try {
      setIsModalOpen(false);
      setIsLoading(true);

      const payload: IHRAttendancePayload = {
        userId: values.userId,
        shiftId: values.shiftId,
        startDate: moment(values.checkIn).toISOString()
      };

      await create_hr_attendance_mutation(payload);
      message.success('Attendance created successfully');
      navigate('/hr/attendance');

      if (isSelf) handleAttendance('in');
    } catch (error) {
      const isAxiosInstance = error instanceof AxiosError;
      const message = getErrorMessage(error);
      if (!isAxiosInstance) {
        CustomErrorModal({ message });
        return;
      }

      const isAttendanceClosed = message.includes('Attendance unclosed');
      const data = error.response?.data?.data;
      if (!isAttendanceClosed || !data) {
        ErrorModalWithLog({ message: message, axiosError: error });
        return;
      }

      ErrorModalWithLog({
        axiosError: error,
        message: (
          <div>
            {isSelf && <span>You haven't checked out yet. Please check out first.</span>}
            {!isSelf && (
              <>
                <span>
                  This employee hasn't checked out yet. Please tell this employee to check out
                  first.
                </span>
                {checkAccess('UPDATE_HR_ATTENDANCE') && (
                  <span className="block mt-4">
                    You can also check out in behalf of this employee from{' '}
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={() => redirectToNewTab(`/hr/attendance/details/${data.id}`)}>
                      here
                    </span>
                    .
                  </span>
                )}
              </>
            )}
          </div>
        )
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onDisabledDate = (current: moment.Moment | null) => {
    if (!current) return false;
    return current > moment().endOf('day');
  };

  async function onCreate() {
    await form.validateFields();
    setIsModalOpen(true);
  }

  async function onUserChange(user?: IUser) {
    try {
      setIsLoading(true);
      if (!user || prevSelectedUser === user.id) return;
      setPrevSelectedUser(user.id);
      form.setFieldValue('shiftId', undefined);

      const checkInMoment = form.getFieldValue('checkIn');
      await getShiftByUserAndDate(checkInMoment);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function onCheckInChange(date: moment.Moment | null) {
    try {
      setIsLoading(true);
      form.setFieldValue('shiftId', undefined);
      if (!selectedUserId) return;
      await getShiftByUserAndDate(date);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function getShiftByUserAndDate(date: moment.Moment | null) {
    if (!selectedUserId || !date) return;
    const shifts = await get_hr_shift_by_user_and_date(selectedUserId, date.toISOString());
    setUsersShift(shifts);

    if (shifts.length === 0) {
      message.error('No shifts found for the selected date.');
      form.setFieldValue('shiftId', undefined);
      return;
    }

    const currentShift = form.getFieldValue('shiftId');
    const isPresent = shifts.some((shift) => shift.id === currentShift);
    if (!isPresent) {
      form.setFieldValue('shiftId', undefined);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={form.submit}>
        <p>Are you sure you want to create this attendance?</p>
      </Modal>
      <AppContent
        breadcrumbItems={[
          { label: 'HR', link: '/hr' },
          { label: 'Attendance', link: '/hr/attendance' },
          { label: 'Create' }
        ]}>
        <PageHeader
          title="Create Attendance"
          className="small-title"
          style={{ padding: '8px 0' }}
        />
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <UserSearchV2
              hasParentFormItem={false}
              name="userId"
              label="Employee"
              required
              placeHolder="Select Employee"
              allowClear={false}
              setSelected={onUserChange}
            />
          </div>

          <div className="mt-4 grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <Form.Item
              name="checkIn"
              label="Check In"
              hidden={!selectedUserId}
              rules={[{ required: true, message: 'Please select check in time' }]}>
              <DatePicker
                className="w-full max-w-md"
                disabledDate={onDisabledDate}
                showTime
                onChange={onCheckInChange}
                format={'YYYY-MM-DD HH:mm'}
              />
            </Form.Item>

            <Form.Item
              name="shiftId"
              label="Shift"
              hidden={!selectedUserId}
              rules={[{ required: true, message: 'Please select shift' }]}>
              <Select
                className="w-full max-w-md"
                placeholder="Select Shift"
                disabled={!form.getFieldValue('checkIn')}>
                {usersShift.map((shift) => (
                  <Select.Option key={shift.id} value={shift.id}>
                    {shift.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="flex justify-end mt-4 gap-2">
            <Button type="primary" onClick={onCreate} disabled={isLoading}>
              Add Attendance
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CreateAttendance;
