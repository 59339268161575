import { Descriptions, PageHeader } from 'antd';
import { ICalendarAttendace, ICalendarHoliday, ICalendarLeave, ICalendarShift } from './types';
import PreviewMultipleImage from '@/components/Common/PreviewMultipleImage';
import { Link } from 'react-router-dom';

export const LeaveContent = ({ event }: { event: ICalendarLeave }) => (
  <div>
    <PageHeader title={'Leave Details'} className="small-title" style={{ padding: 0 }} />

    <Descriptions bordered column={1} labelStyle={{ width: '150px' }} size="small">
      <Descriptions.Item label="Starts From">
        {event.start ? new Date(event.start).toLocaleString() : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label="Ends On">
        {event.end ? new Date(event.end).toLocaleString() : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label="Reason">
        {event.description || 'No reason provided.'}
      </Descriptions.Item>
    </Descriptions>

    <PageHeader subTitle="Attached Media" style={{ padding: 0 }} />
    <PreviewMultipleImage
      key={event.id}
      medias={event.medias}
      noMediaContent={<div>No media provided</div>}
    />
  </div>
);

export const AttendanceContent = ({ event }: { event: ICalendarAttendace }) => (
  <div>
    <PageHeader title="Attendance Details" className="small-title" style={{ padding: 0 }} />

    <Descriptions bordered column={1} labelStyle={{ width: '150px' }} size="small">
      <Descriptions.Item label="Check In">
        {event.start ? new Date(event.start).toLocaleString() : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label="Check Out">
        {event.end ? new Date(event.end).toLocaleString() : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label="Shift">
        {event.shiftName ? (
          <Link to={`/hr/shifts/${event.id}`} className="text-blue-600 hover:underline">
            {event.shiftName}
          </Link>
        ) : (
          'Shift not available'
        )}
      </Descriptions.Item>
    </Descriptions>
  </div>
);

export const HolidayContent = ({ event }: { event: ICalendarHoliday }) => (
  <div>
    <PageHeader title="Holiday Details" className="small-title" style={{ padding: 0 }} />

    <Descriptions bordered column={1} labelStyle={{ width: '150px' }} size="small">
      <Descriptions.Item label="Starts On">
        {event.start ? new Date(event.start).toLocaleString() : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label="Ends On">
        {event.end ? new Date(event.end).toLocaleString() : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label="Holiday Name">
        {event.name || 'No name provided.'}
      </Descriptions.Item>
      <Descriptions.Item label="Description">
        {event.description || 'No description provided.'}
      </Descriptions.Item>
    </Descriptions>
  </div>
);

export const ShiftContent = ({ event }: { event: ICalendarShift }) => (
  <div>
    <PageHeader title="Shift Details" className="small-title" style={{ padding: 0 }} />

    <Descriptions bordered column={1} labelStyle={{ width: '150px' }} size="small">
      <Descriptions.Item label="Starts On">
        {event.start ? new Date(event.start).toLocaleString() : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label="Ends On">
        {event.end ? new Date(event.end).toLocaleString() : 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label="Shift Name">
        {event.name ? (
          <Link to={`/hr/shifts/${event.id}`} className="text-blue-600 hover:underline">
            {event.name}
          </Link>
        ) : (
          'No shift provided.'
        )}
      </Descriptions.Item>
    </Descriptions>
  </div>
);
